/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import css
  from "../containers/ProfileSettingsPage/ProfileSettingsForm/ProfileSettingsForm.module.css";

// !!!!!!! WHEN ADDING TO THIS CONFIG YOU NEED TO ADD THE SAME IN THE FILE src/util/scriptData.js
export const areasCertifiedOptions = [
  {
    key: 'all_art',
    label: 'All Art',
    value: 'All Art',
    id: 0,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'art',
    label: 'Art',
    value: 'Art',
    id: 1,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'arts_media_entertainment',
    label: 'Arts, Media & Entertainment',
    value: 'Arts, Media & Entertainment',
    id: 2,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'ceramics',
    label: 'Ceramics',
    value: 'Ceramics',
    id: 3,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'computer_aided_design_art',
    label: 'Computer-Aided Design/Art',
    value: 'Computer-Aided Design/Art',
    id: 4,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'computer_aided_drafting_art',
    label: 'Computer-Aided Drafting/Art',
    value: 'Computer-Aided Drafting/Art',
    id: 5,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'drawing',
    label: 'Drawing',
    value: 'Drawing',
    id: 6,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'film_production_art',
    label: 'Film Production',
    value: 'Film Production',
    id: 7,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'painting',
    label: 'Painting',
    value: 'Painting',
    id: 8,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'photography',
    label: 'Photography',
    value: 'Photography',
    id: 9,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'printmaking_art',
    label: 'Printmaking',
    value: 'Printmaking',
    id: 10,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'visual_arts',
    label: 'Visual Arts',
    value: 'Visual Arts',
    id: 11,
    className: css.option,
    type: 'areasCertified',
    parent: 'Art',
    staticUrl: 'art',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'all_advanced_placement',
    label: 'All Advanced Placement (AP)',
    value: 'All Advanced Placement (AP)',
    id: 12,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_art_design',
    label: 'AP Art & Design',
    value: 'AP Art & Design',
    id: 13,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_art_history',
    label: 'AP Art History',
    value: 'AP Art History',
    id: 14,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_biology',
    label: 'AP Biology',
    value: 'AP Biology',
    id: 15,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_calculus_ab',
    label: 'AP Calculus AB',
    value: 'AP Calculus AB',
    id: 16,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_calculus_bc',
    label: 'AP Calculus BC',
    value: 'AP Calculus BC',
    id: 17,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_capstone',
    label: 'AP Capstone',
    value: 'AP Capstone',
    id: 18,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_chemistry',
    label: 'AP Chemistry',
    value: 'AP Chemistry',
    id: 19,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_chinese_language_culture',
    label: 'AP Chinese Language & Culture',
    value: 'AP Chinese Language & Culture',
    id: 20,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_comparative_government_politics',
    label: 'AP Comparative Government & Politics',
    value: 'AP Comparative Government & Politics',
    id: 21,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_computer_science_a',
    label: 'AP Computer Science A',
    value: 'AP Computer Science A',
    id: 22,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_computer_science_principles',
    label: 'AP Computer Science Principles',
    value: 'AP Computer Science Principles',
    id: 23,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_english_language_composition',
    label: 'AP English Language & Composition',
    value: 'AP English Language & Composition',
    id: 24,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_english_literature_composition',
    label: 'AP English Literature & Composition',
    value: 'AP English Literature & Composition',
    id: 25,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_environmental_science',
    label: 'AP Environmental Science',
    value: 'AP Environmental Science',
    id: 26,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_european_history',
    label: 'AP European History',
    value: 'AP European History',
    id: 27,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_french_language_culture',
    label: 'AP French Language & Culture',
    value: 'AP French Language & Culture',
    id: 28,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_german_language_culture',
    label: 'AP German Language & Culture',
    value: 'AP German Language & Culture',
    id: 29,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_human_geography',
    label: 'AP Human Geography',
    value: 'AP Human Geography',
    id: 30,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_italian_language_culture',
    label: 'AP Italian Language & Culture',
    value: 'AP Italian Language & Culture',
    id: 31,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_japanese_language_culture',
    label: 'AP Japanese Language & Culture',
    value: 'AP Japanese Language & Culture',
    id: 32,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_latin_language_culture',
    label: 'AP Latin Language & Culture',
    value: 'AP Latin Language & Culture',
    id: 33,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_macroeconomics',
    label: 'AP Macroeconomics',
    value: 'AP Macroeconomics',
    id: 34,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_music_theory',
    label: 'AP Music Theory',
    value: 'AP Music Theory',
    id: 35,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_physics_1_algebra_based',
    label: 'AP Physics 1 Algebra-Based',
    value: 'AP Physics 1 Algebra-Based',
    id: 36,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_physics_2_algebra_based',
    label: 'AP Physics 2 Algebra-Based',
    value: 'AP Physics 2 Algebra-Based',
    id: 37,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_physics_c_electricity_magnetism',
    label: 'AP Physics C: Electricity & Magnetism',
    value: 'AP Physics C: Electricity & Magnetism',
    id: 38,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_physics_c_mechanics',
    label: 'AP Physics C: Mechanics',
    value: 'AP Physics C: Mechanics',
    id: 39,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_psychology',
    label: 'AP Psychology',
    value: 'AP Psychology',
    id: 40,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_research',
    label: 'AP Research',
    value: 'AP Research',
    id: 41,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_spanish_language_culture',
    label: 'AP Spanish Language & Culture',
    value: 'AP Spanish Language & Culture',
    id: 42,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_statistics',
    label: 'AP Statistics',
    value: 'AP Statistics',
    id: 43,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_united_states_government_politics',
    label: 'AP United States Government & Politics',
    value: 'AP United States Government & Politics',
    id: 44,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_united_states_history',
    label: 'AP United States History',
    value: 'AP United States History',
    id: 45,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'ap_world_history_modern',
    label: 'AP World History: Modern',
    value: 'AP World History: Modern',
    id: 46,
    className: css.option,
    type: 'areasCertified',
    parent: 'Advanced Placement',
    staticUrl: 'advanced_placement',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'all_architecture_engineering',
    label: 'All Architecture & Engineering',
    value: 'All Architecture & Engineering',
    id: 47,
    className: css.option,
    type: 'areasCertified',
    parent: 'Architecture & Engineering',
    staticUrl: 'architecture_engineering',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'architecture',
    label: 'Architecture',
    value: 'Architecture',
    id: 48,
    className: css.option,
    type: 'areasCertified',
    parent: 'Architecture & Engineering',
    staticUrl: 'architecture_engineering',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'business_construction_trades',
    label: 'Business & Construction Trades',
    value: 'Business & Construction Trades',
    id: 49,
    className: css.option,
    type: 'areasCertified',
    parent: 'Architecture & Engineering',
    staticUrl: 'architecture_engineering',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'engineering',
    label: 'Engineering',
    value: 'Engineering',
    id: 50,
    className: css.option,
    type: 'areasCertified',
    parent: 'Architecture & Engineering',
    staticUrl: 'architecture_engineering',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'engineering_architecture',
    label: 'Engineering & Architecture',
    value: 'Engineering & Architecture',
    id: 51,
    className: css.option,
    type: 'areasCertified',
    parent: 'Architecture & Engineering',
    staticUrl: 'architecture_engineering',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'industrial_technology_education',
    label: 'Industrial Technology & Education',
    value: 'Industrial Technology & Education',
    id: 52,
    className: css.option,
    type: 'areasCertified',
    parent: 'Architecture & Engineering',
    staticUrl: 'architecture_engineering',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'transportation',
    label: 'Transportation',
    value: 'Transportation',
    id: 53,
    className: css.option,
    type: 'areasCertified',
    parent: 'Architecture & Engineering',
    staticUrl: 'architecture_engineering',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'all_business',
    label: 'All Business',
    value: 'All Business',
    id: 54,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'accounting',
    label: 'Accounting',
    value: 'Accounting',
    id: 55,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'business',
    label: 'Business',
    value: 'Business',
    id: 56,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'business_finance',
    label: 'Business & Finance',
    value: 'Business & Finance',
    id: 57,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'economics',
    label: 'Economics',
    value: 'Economics',
    id: 58,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'entrepreneurship',
    label: 'Entrepreneurship',
    value: 'Entrepreneurship',
    id: 59,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'film_production',
    label: 'Film Production',
    value: 'Film Production',
    id: 60,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'hospitality_tourism_recreation',
    label: 'Hospitality, Tourism & Recreation',
    value: 'Hospitality, Tourism & Recreation',
    id: 61,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'industrial_technology_education_bus',
    label: 'Industrial Technology & Education',
    value: 'Industrial Technology & Education',
    id: 62,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'manufacturing_product_development',
    label: 'Manufacturing & Product Development',
    value: 'Manufacturing & Product Development',
    id: 63,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'marketing',
    label: 'Marketing',
    value: 'Marketing',
    id: 64,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'marketing_sales_service',
    label: 'Marketing, Sales & Service',
    value: 'Marketing, Sales & Service',
    id: 65,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'retail_marketing',
    label: 'Retail Marketing',
    value: 'Retail Marketing',
    id: 66,
    className: css.option,
    type: 'areasCertified',
    parent: 'Business',
    staticUrl: 'business',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'all_design',
    label: 'All Design',
    value: 'All Design',
    id: 67,
    className: css.option,
    type: 'areasCertified',
    parent: 'Design',
    staticUrl: 'design',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'computer_aided_design_design',
    label: 'Computer-Aided Design/Design',
    value: 'Computer-Aided Design/Design',
    id: 68,
    className: css.option,
    type: 'areasCertified',
    parent: 'Design',
    staticUrl: 'design',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'computer_aided_drafting_design',
    label: 'Computer-Aided Drafting/Design',
    value: 'Computer-Aided Drafting/Design',
    id: 69,
    className: css.option,
    type: 'areasCertified',
    parent: 'Design',
    staticUrl: 'design',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'design_technology',
    label: 'Design Technology',
    value: 'Design Technology',
    id: 70,
    className: css.option,
    type: 'areasCertified',
    parent: 'Design',
    staticUrl: 'design',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'fashion',
    label: 'Fashion',
    value: 'Fashion',
    id: 71,
    className: css.option,
    type: 'areasCertified',
    parent: 'Design',
    staticUrl: 'design',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'fashion_interior_design',
    label: 'Fashion & Interior Design',
    value: 'Fashion & Interior Design',
    id: 72,
    className: css.option,
    type: 'areasCertified',
    parent: 'Design',
    staticUrl: 'design',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'interior_design',
    label: 'Interior Design',
    value: 'Interior Design',
    id: 73,
    className: css.option,
    type: 'areasCertified',
    parent: 'Design',
    staticUrl: 'design',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'printmaking',
    label: 'Printmaking',
    value: 'Printmaking',
    id: 74,
    className: css.option,
    type: 'areasCertified',
    parent: 'Design',
    staticUrl: 'design',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'all_elementary',
    label: 'All Elementary',
    value: 'All Elementary',
    id: 75,
    className: css.option,
    type: 'areasCertified',
    parent: 'Elementary',
    staticUrl: 'elementary',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: '1st_grade',
    label: '1st Grade',
    value: '1st Grade',
    id: 76,
    className: css.option,
    type: 'areasCertified',
    parent: 'Elementary',
    staticUrl: 'elementary',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: '2nd_grade',
    label: '2nd Grade',
    value: '2nd Grade',
    id: 77,
    className: css.option,
    type: 'areasCertified',
    parent: 'Elementary',
    staticUrl: 'elementary',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: '3rd_grade',
    label: '3rd Grade',
    value: '3rd Grade',
    id: 78,
    className: css.option,
    type: 'areasCertified',
    parent: 'Elementary',
    staticUrl: 'elementary',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: '4th_grade',
    label: '4th Grade',
    value: '4th Grade',
    id: 79,
    className: css.option,
    type: 'areasCertified',
    parent: 'Elementary',
    staticUrl: 'elementary',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: '5th_grade',
    label: '5th Grade',
    value: '5th Grade',
    id: 80,
    className: css.option,
    type: 'areasCertified',
    parent: 'Elementary',
    staticUrl: 'elementary',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: '6th_grade',
    label: '6th Grade',
    value: '6th Grade',
    id: 81,
    className: css.option,
    type: 'areasCertified',
    parent: 'Elementary',
    staticUrl: 'elementary',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'all_english',
    label: 'All English',
    value: 'All English',
    id: 82,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'american_literature',
    label: 'American Literature',
    value: 'American Literature',
    id: 83,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'british_literature',
    label: 'British Literature',
    value: 'British Literature',
    id: 84,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'comparative_literature',
    label: 'Comparative Literature',
    value: 'Comparative Literature',
    id: 85,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'communication',
    label: 'Communication',
    value: 'Communication',
    id: 86,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'composition',
    label: 'Composition',
    value: 'Composition',
    id: 87,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'debate_eng',
    label: 'Debate',
    value: 'Debate',
    id: 88,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'contemporary_literature',
    label: 'Contemporary Literature',
    value: 'Contemporary Literature',
    id: 89,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'creative_writing',
    label: 'Creative Writing',
    value: 'Creative Writing',
    id: 90,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'english',
    label: 'English',
    value: 'English',
    id: 91,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'english_as_a_second_language',
    label: 'English as a Second Language',
    value: 'English as a Second Language',
    id: 92,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'english_language_development',
    label: 'English Language Development',
    value: 'English Language Development',
    id: 93,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'foundational_reading',
    label: 'Foundational Reading',
    value: 'Foundational Reading',
    id: 94,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'english_journalism',
    label: 'Journalism',
    value: 'Journalism',
    id: 95,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'language_arts',
    label: 'Language Arts',
    value: 'Language Arts',
    id: 96,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'poetry',
    label: 'Poetry',
    value: 'Poetry',
    id: 97,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'reading',
    label: 'Reading',
    value: 'Reading',
    id: 98,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'reading_support',
    label: 'Reading Support',
    value: 'Reading Support',
    id: 99,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'rhetoric',
    label: 'Rhetoric',
    value: 'Rhetoric',
    id: 100,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'speech',
    label: 'Speech',
    value: 'Speech',
    id: 101,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'world_literature',
    label: 'World Literature',
    value: 'World Literature',
    id: 102,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'writing',
    label: 'Writing',
    value: 'Writing',
    id: 103,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'writing_support',
    label: 'Writing Support',
    value: 'Writing Support',
    id: 104,
    className: css.option,
    type: 'areasCertified',
    parent: 'English',
    staticUrl: 'english',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'all_family_consumer_science',
    label: 'All Family & Consumer Science',
    value: 'All Family & Consumer Science',
    id: 105,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'auto_repair',
    label: 'Auto Repair',
    value: 'Auto Repair',
    id: 106,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'cosmetology',
    label: 'Cosmetology',
    value: 'Cosmetology',
    id: 107,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'culinary_arts',
    label: 'Culinary Arts',
    value: 'Culinary Arts',
    id: 108,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'hidden_family_consumer_science',
    label: 'Family Consumer Science',
    value: 'Family Consumer Science',
    id: 109,
    className: css.option,
    type: 'areasCertified',
    isHidden: true,
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'home_economics',
    label: 'Home Economics',
    value: 'Home Economics',
    id: 110,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'driver_education',
    label: 'Driver Education',
    value: 'Driver Education',
    id: 111,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'education_child_development_family_services',
    label: 'Education, Child Development & Family Services',
    value: 'Education, Child Development & Family Services',
    id: 112,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'family_consumer_science',
    label: 'Family & Consumer Science',
    value: 'Family & Consumer Science',
    id: 113,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'metalworking',
    label: 'Metalworking',
    value: 'Metalworking',
    id: 114,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'personal_finance',
    label: 'Personal Finance',
    value: 'Personal Finance',
    id: 115,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'religion',
    label: 'Religion',
    value: 'Religion',
    id: 116,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'scripture',
    label: 'Scripture',
    value: 'Scripture',
    id: 117,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'woodworking',
    label: 'Woodworking',
    value: 'Woodworking',
    id: 118,
    className: css.option,
    type: 'areasCertified',
    parent: 'Family & Consumer Science',
    staticUrl: 'family_consumer_science',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'all_languages',
    label: 'All Languages',
    value: 'All Languages',
    id: 119,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'american_indian_languages_culture',
    label: 'American Indian Languages & Culture',
    value: 'American Indian Languages & Culture',
    id: 120,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'american_sign_language',
    label: 'American Sign Language',
    value: 'American Sign Language',
    id: 121,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'arabic',
    label: 'Arabic',
    value: 'Arabic',
    id: 122,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'armenian',
    label: 'Armenian',
    value: 'Armenian',
    id: 123,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'bengali',
    label: 'Bengali',
    value: 'Bengali',
    id: 124,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'bhojpuri',
    label: 'Bhojpuri',
    value: 'Bhojpuri',
    id: 125,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'czech',
    label: 'Czech',
    value: 'Czech',
    id: 126,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'dutch',
    label: 'Dutch',
    value: 'Dutch',
    id: 127,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'farsi',
    label: 'Farsi',
    value: 'Farsi',
    id: 128,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'french',
    label: 'French',
    value: 'French',
    id: 129,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'german',
    label: 'German',
    value: 'German',
    id: 130,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'greek',
    label: 'Greek',
    value: 'Greek',
    id: 131,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'gujarati',
    label: 'Gujarati',
    value: 'Gujarati',
    id: 132,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'hausa',
    label: 'Hausa',
    value: 'Hausa',
    id: 133,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'hebrew',
    label: 'Hebrew',
    value: 'Hebrew',
    id: 134,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'hindi',
    label: 'Hindi',
    value: 'Hindi',
    id: 135,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'hmong',
    label: 'Hmong',
    value: 'Hmong',
    id: 136,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'indonesian',
    label: 'Indonesian',
    value: 'Indonesian',
    id: 137,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'italian',
    label: 'Italian',
    value: 'Italian',
    id: 138,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'japanese',
    label: 'Japanese',
    value: 'Japanese',
    id: 139,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'javanese',
    label: 'Javanese',
    value: 'Javanese',
    id: 140,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'khmer',
    label: 'Khmer',
    value: 'Khmer',
    id: 141,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'korean',
    label: 'Korean',
    value: 'Korean',
    id: 142,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'latin',
    label: 'Latin',
    value: 'Latin',
    id: 143,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'maithili',
    label: 'Maithili',
    value: 'Maithili',
    id: 144,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'mandarin_chinese',
    label: 'Mandarin Chinese',
    value: 'Mandarin Chinese',
    id: 145,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'marathi',
    label: 'Marathi',
    value: 'Marathi',
    id: 146,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'nepali',
    label: 'Nepali',
    value: 'Nepali',
    id: 147,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'odia',
    label: 'Odia',
    value: 'Odia',
    id: 148,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'pashto',
    label: 'Pashto',
    value: 'Pashto',
    id: 149,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'polish',
    label: 'Polish',
    value: 'Polish',
    id: 150,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'portuguese',
    label: 'Portuguese',
    value: 'Portuguese',
    id: 151,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'punjabi',
    label: 'Punjabi',
    value: 'Punjabi',
    id: 152,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'romanian',
    label: 'Romanian',
    value: 'Romanian',
    id: 153,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'russian',
    label: 'Russian',
    value: 'Russian',
    id: 154,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'sindhi',
    label: 'Sindhi',
    value: 'Sindhi',
    id: 155,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'sinhala',
    label: 'Sinhala',
    value: 'Sinhala',
    id: 156,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'spanish',
    label: 'Spanish',
    value: 'Spanish',
    id: 157,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'swahili',
    label: 'Swahili',
    value: 'Swahili',
    id: 158,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'swedish',
    label: 'Swedish',
    value: 'Swedish',
    id: 159,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'tagalog',
    label: 'Tagalog',
    value: 'Tagalog',
    id: 160,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'tamil',
    label: 'Tamil',
    value: 'Tamil',
    id: 161,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'telugu',
    label: 'Telugu',
    value: 'Telugu',
    id: 162,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'thai',
    label: 'Thai',
    value: 'Thai',
    id: 163,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'turkish',
    label: 'Turkish',
    value: 'Turkish',
    id: 164,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'ukrainian',
    label: 'Ukrainian',
    value: 'Ukrainian',
    id: 165,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'urdu',
    label: 'Urdu',
    value: 'Urdu',
    id: 166,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'vietnamese',
    label: 'Vietnamese',
    value: 'Vietnamese',
    id: 167,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'wu_chinese',
    label: 'Wu Chinese',
    value: 'Wu Chinese',
    id: 168,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'yoruba',
    label: 'Yoruba',
    value: 'Yoruba',
    id: 169,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'yu_chinese',
    label: 'Yu Chinese',
    value: 'Yu Chinese',
    id: 170,
    className: css.option,
    type: 'areasCertified',
    parent: 'Languages',
    staticUrl: 'languages',
    filterId: 'subjects',
    heroStyles: 'languages',
  },
  {
    key: 'all_history_social_studies',
    label: 'All History & Social Studies',
    value: 'All History & Social Studies',
    id: 171,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'american_history',
    label: 'American History',
    value: 'American History',
    id: 172,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'civics',
    label: 'Civics',
    value: 'Civics',
    id: 173,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'current_events',
    label: 'Current Events',
    value: 'Current Events',
    id: 174,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'debate',
    label: 'Debate',
    value: 'Debate',
    id: 175,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'european_history',
    label: 'European History',
    value: 'European History',
    id: 176,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'geography',
    label: 'Geography',
    value: 'Geography',
    id: 177,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'history',
    label: 'History',
    value: 'History',
    id: 178,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'international_relations',
    label: 'International Relations',
    value: 'International Relations',
    id: 179,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'social_studies',
    label: 'Social Studies',
    value: 'Social Studies',
    id: 180,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'us_government',
    label: 'US Government',
    value: 'US Government',
    id: 181,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'world_history',
    label: 'World History',
    value: 'World History',
    id: 182,
    className: css.option,
    type: 'areasCertified',
    parent: 'History & Social Studies',
    staticUrl: 'history_social_studies',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'all_leadership_life_skills',
    label: 'All Leadership & Life Skills',
    value: 'All Leadership & Life Skills',
    id: 183,
    className: css.option,
    type: 'areasCertified',
    parent: 'Leadership & Life Skills',
    staticUrl: 'leadership_life_skills',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'jrotc',
    label: 'JROTC',
    value: 'JROTC',
    id: 184,
    className: css.option,
    type: 'areasCertified',
    parent: 'Leadership & Life Skills',
    staticUrl: 'leadership_life_skills',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'public_services',
    label: 'Public Services',
    value: 'Public Services',
    id: 185,
    className: css.option,
    type: 'areasCertified',
    parent: 'Leadership & Life Skills',
    staticUrl: 'leadership_life_skills',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: '1all_mathematics',
    label: 'All Mathematics',
    value: 'All Mathematics',
    id: 186,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'arithmetic',
    label: 'Arithmetic',
    value: 'Arithmetic',
    id: 187,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'algebra_1',
    label: 'Algebra 1',
    value: 'Algebra 1',
    id: 188,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'algebra_2',
    label: 'Algebra 2',
    value: 'Algebra 2',
    id: 189,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'calculus',
    label: 'Calculus',
    value: 'Calculus',
    id: 190,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'foundational_level_mathematics',
    label: 'Foundational-Level Mathematics',
    value: 'Foundational-Level Mathematics',
    id: 191,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'geometry',
    label: 'Geometry',
    value: 'Geometry',
    id: 192,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'integrated_mathematics',
    label: 'Integrated Mathematics',
    value: 'Integrated Mathematics',
    id: 193,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'mathematics',
    label: 'Mathematics',
    value: 'Mathematics',
    id: 194,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'math_support',
    label: 'Math Support',
    value: 'Math Support',
    id: 195,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'pre_algebra',
    label: 'Pre-Algebra',
    value: 'Pre-Algebra',
    id: 196,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'pre_calculus',
    label: 'Pre-Calculus',
    value: 'Pre-Calculus',
    id: 197,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'probability',
    label: 'Probability',
    value: 'Probability',
    id: 198,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'statistics',
    label: 'Statistics',
    value: 'Statistics',
    id: 199,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'trigonometry',
    label: 'Trigonometry',
    value: 'Trigonometry',
    id: 200,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: '1st_grade_math',
    label: '1st Grade Math',
    value: '1st Grade Math',
    id: 201,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: '2nd_grade_math',
    label: '2nd Grade Math',
    value: '2nd Grade Math',
    id: 202,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: '3rd_grade_math',
    label: '3rd Grade Math',
    value: '3rd Grade Math',
    id: 203,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: '4th_grade_math',
    label: '4th Grade Math',
    value: '4th Grade Math',
    id: 204,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: '5th_grade_math',
    label: '5th Grade Math',
    value: '5th Grade Math',
    id: 205,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: '6th_grade_math',
    label: '6th Grade Math',
    value: '6th Grade Math',
    id: 206,
    className: css.option,
    type: 'areasCertified',
    parent: 'Mathematics',
    staticUrl: 'mathematics',
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'all_music',
    label: 'All Music',
    value: 'All Music',
    id: 207,
    className: css.option,
    type: 'areasCertified',
    parent: 'Music',
    staticUrl: 'music',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'choir',
    label: 'Choir',
    value: 'Choir',
    id: 208,
    className: css.option,
    type: 'areasCertified',
    parent: 'Music',
    staticUrl: 'music',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'concert_band',
    label: 'Concert Band',
    value: 'Concert Band',
    id: 209,
    className: css.option,
    type: 'areasCertified',
    parent: 'Music',
    staticUrl: 'music',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'jazz_band',
    label: 'Jazz Band',
    value: 'Jazz Band',
    id: 210,
    className: css.option,
    type: 'areasCertified',
    parent: 'Music',
    staticUrl: 'music',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'marching_band',
    label: 'Marching Band',
    value: 'Marching Band',
    id: 211,
    className: css.option,
    type: 'areasCertified',
    parent: 'Music',
    staticUrl: 'music',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'music',
    label: 'Music',
    value: 'Music',
    id: 212,
    className: css.option,
    type: 'areasCertified',
    parent: 'Music',
    staticUrl: 'music',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'music_theory',
    label: 'Music Theory',
    value: 'Music Theory',
    id: 213,
    className: css.option,
    type: 'areasCertified',
    parent: 'Music',
    staticUrl: 'music',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'orchestra',
    label: 'Orchestra',
    value: 'Orchestra',
    id: 214,
    className: css.option,
    type: 'areasCertified',
    parent: 'Music',
    staticUrl: 'music',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'all_physical_education_health',
    label: 'All Physical Education & Health',
    value: 'All Physical Education & Health',
    id: 215,
    className: css.option,
    type: 'areasCertified',
    parent: 'Physical Education & Health',
    staticUrl: 'physical_education_health',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'cpr',
    label: 'CPR',
    value: 'CPR',
    id: 216,
    className: css.option,
    type: 'areasCertified',
    parent: 'Physical Education & Health',
    staticUrl: 'physical_education_health',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'gym',
    label: 'Gym',
    value: 'Gym',
    id: 217,
    className: css.option,
    type: 'areasCertified',
    parent: 'Physical Education & Health',
    staticUrl: 'physical_education_health',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'health',
    label: 'Health',
    value: 'Health',
    id: 218,
    className: css.option,
    type: 'areasCertified',
    parent: 'Physical Education & Health',
    staticUrl: 'physical_education_health',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'physical_education_health_health_sciences',
    label: 'Health Sciences',
    value: 'Health Sciences',
    id: 219,
    className: css.option,
    type: 'areasCertified',
    parent: 'Physical Education & Health',
    staticUrl: 'physical_education_health',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'physical_education_health_health_science_medical_technology',
    label: 'Health Science & Medical Technology',
    value: 'Health Science & Medical Technology',
    id: 220,
    className: css.option,
    type: 'areasCertified',
    parent: 'Physical Education & Health',
    staticUrl: 'physical_education_health',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'nutrition',
    label: 'Nutrition',
    value: 'Nutrition',
    id: 221,
    className: css.option,
    type: 'areasCertified',
    parent: 'Physical Education & Health',
    staticUrl: 'physical_education_health',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'physical_education',
    label: 'Physical Education',
    value: 'Physical Education',
    id: 222,
    className: css.option,
    type: 'areasCertified',
    parent: 'Physical Education & Health',
    staticUrl: 'physical_education_health',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'sports_exercise_health_science',
    label: 'Sports, Exercise, & Health Science',
    value: 'Sports, Exercise, & Health Science',
    id: 223,
    className: css.option,
    type: 'areasCertified',
    parent: 'Physical Education & Health',
    staticUrl: 'physical_education_health',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'all_science',
    label: 'All Science',
    value: 'All Science',
    id: 224,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'agriculture',
    label: 'Agriculture',
    value: 'Agriculture',
    id: 225,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'agriculture_natural_resources',
    label: 'Agriculture & Natural Resources',
    value: 'Agriculture & Natural Resources',
    id: 226,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'anatomy',
    label: 'Anatomy',
    value: 'Anatomy',
    id: 227,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'astronomy',
    label: 'Astronomy',
    value: 'Astronomy',
    id: 228,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'biological_sciences',
    label: 'Biological Sciences',
    value: 'Biological Sciences',
    id: 229,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'biology',
    label: 'Biology',
    value: 'Biology',
    id: 230,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'botany',
    label: 'Botany',
    value: 'Botany',
    id: 231,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'chemistry',
    label: 'Chemistry',
    value: 'Chemistry',
    id: 232,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'earth_science',
    label: 'Earth Science',
    value: 'Earth Science',
    id: 233,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'energy_environment_utilities',
    label: 'Energy, Environment & Utilities',
    value: 'Energy, Environment & Utilities',
    id: 234,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'environmental_science',
    label: 'Environmental Science',
    value: 'Environmental Science',
    id: 235,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'future_farmers_of_america',
    label: 'Future Farmers of America (FFA)',
    value: 'Future Farmers of America (FFA)',
    id: 236,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'forensic_science',
    label: 'Forensic Science',
    value: 'Forensic Science',
    id: 237,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'foundational_level_general_science',
    label: 'Foundational-Level General Science',
    value: 'Foundational-Level General Science',
    id: 238,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'geosciences',
    label: 'Geosciences',
    value: 'Geosciences',
    id: 239,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'science_health_sciences',
    label: 'Health Sciences',
    value: 'Health Sciences',
    id: 240,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'science_health_science_medical_technology',
    label: 'Health Science & Medical Technology',
    value: 'Health Science & Medical Technology',
    id: 241,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'information_communication_technologies',
    label: 'Information & Communication Technologies',
    value: 'Information & Communication Technologies',
    id: 242,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'information_technology',
    label: 'Information Technology',
    value: 'Information Technology',
    id: 243,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'life_science',
    label: 'Life Science',
    value: 'Life Science',
    id: 244,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'nutrition_science',
    label: 'Nutrition',
    value: 'Nutrition',
    id: 245,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'oceanography',
    label: 'Oceanography',
    value: 'Oceanography',
    id: 246,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'organic_chemistry',
    label: 'Organic Chemistry',
    value: 'Organic Chemistry',
    id: 247,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'physical_science',
    label: 'Physical Science',
    value: 'Physical Science',
    id: 248,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'physics',
    label: 'Physics',
    value: 'Physics',
    id: 249,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'science_psychology',
    label: 'Psychology',
    value: 'Psychology',
    id: 250,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'zoology',
    label: 'Zoology',
    value: 'Zoology',
    id: 251,
    className: css.option,
    type: 'areasCertified',
    parent: 'Science',
    staticUrl: 'science',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'all_social_sciences',
    label: 'All Social Sciences',
    value: 'All Social Sciences',
    id: 252,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'anthropology',
    label: 'Anthropology',
    value: 'Anthropology',
    id: 253,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'art_history',
    label: 'Art History',
    value: 'Art History',
    id: 254,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'global_politics',
    label: 'Global Politics',
    value: 'Global Politics',
    id: 255,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'social_sciences_journalism',
    label: 'Journalism',
    value: 'Journalism',
    id: 256,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'philosophy',
    label: 'Philosophy',
    value: 'Philosophy',
    id: 257,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'political_science',
    label: 'Political Science',
    value: 'Political Science',
    id: 258,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'social_sciences_psychology',
    label: 'Psychology',
    value: 'Psychology',
    id: 259,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'religious_studies',
    label: 'Religious Studies',
    value: 'Religious Studies',
    id: 260,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'sociology',
    label: 'Sociology',
    value: 'Sociology',
    id: 261,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'womens_studies',
    label: 'Women\'s Studies',
    value: 'Women\'s Studies',
    id: 262,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'world_religions',
    label: 'World Religions',
    value: 'World Religions',
    id: 263,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social Sciences',
    staticUrl: 'social_sciences',
    filterId: 'subjects',
    heroStyles: 'history',
  },
  {
    key: 'all_special_education',
    label: 'All Special Education',
    value: 'All Special Education',
    id: 264,
    className: css.option,
    type: 'areasCertified',
    parent: 'Special Education',
    staticUrl: 'special_education',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'math',
  },
  {
    key: 'all_technology',
    label: 'All Technology',
    value: 'All Technology',
    id: 265,
    className: css.option,
    type: 'areasCertified',
    parent: 'Technology',
    staticUrl: 'technology',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'artificial_intelligence',
    label: 'Artificial Intelligence',
    value: 'Artificial Intelligence',
    id: 266,
    className: css.option,
    type: 'areasCertified',
    parent: 'Technology',
    staticUrl: 'technology',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'coding',
    label: 'Coding',
    value: 'Coding',
    id: 267,
    className: css.option,
    type: 'areasCertified',
    parent: 'Technology',
    staticUrl: 'technology',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'computer_aided_design_technology',
    label: 'Computer-Aided Design/Technology',
    value: 'Computer-Aided Design/Technology',
    id: 268,
    className: css.option,
    type: 'areasCertified',
    parent: 'Technology',
    staticUrl: 'technology',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'computer_aided_drafting_technology',
    label: 'Computer-Aided Drafting/Technology',
    value: 'Computer-Aided Drafting/Technology',
    id: 269,
    className: css.option,
    type: 'areasCertified',
    parent: 'Technology',
    staticUrl: 'technology',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'computer_science',
    label: 'Computer Science',
    value: 'Computer Science',
    id: 270,
    className: css.option,
    type: 'areasCertified',
    parent: 'Technology',
    staticUrl: 'technology',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'digital_media',
    label: 'Digital Media',
    value: 'Digital Media',
    id: 271,
    className: css.option,
    type: 'areasCertified',
    parent: 'Technology',
    staticUrl: 'technology',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'electronics',
    label: 'Electronics',
    value: 'Electronics',
    id: 272,
    className: css.option,
    type: 'areasCertified',
    parent: 'Technology',
    staticUrl: 'technology',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'networking',
    label: 'Networking',
    value: 'Networking',
    id: 273,
    className: css.option,
    type: 'areasCertified',
    parent: 'Technology',
    staticUrl: 'technology',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'robotics',
    label: 'Robotics',
    value: 'Robotics',
    id: 274,
    className: css.option,
    type: 'areasCertified',
    parent: 'Technology',
    staticUrl: 'technology',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'software_development',
    label: 'Software Development',
    value: 'Software Development',
    id: 275,
    className: css.option,
    type: 'areasCertified',
    parent: 'Technology',
    staticUrl: 'technology',
    filterId: 'subjects',
    heroStyles: 'science',
  },
  {
    key: 'all_test_prep_college_prep',
    label: 'All Test Prep & College Prep',
    value: 'AllTest Prep & College Prep',
    id: 276,
    className: css.option,
    type: 'areasCertified',
    parent: 'Test Prep & College Prep',
    staticUrl: 'test_prep_college_prep',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'act_prep',
    label: 'ACT Prep',
    value: 'ACT Prep',
    id: 277,
    className: css.option,
    type: 'areasCertified',
    parent: 'Test Prep & College Prep',
    staticUrl: 'test_prep_college_prep',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'college_guidance',
    label: 'College Guidance',
    value: 'College Guidance',
    id: 278,
    className: css.option,
    type: 'areasCertified',
    parent: 'Test Prep & College Prep',
    staticUrl: 'test_prep_college_prep',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'counseling',
    label: 'Counseling',
    value: 'Counseling',
    id: 279,
    className: css.option,
    type: 'areasCertified',
    parent: 'Test Prep & College Prep',
    staticUrl: 'test_prep_college_prep',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'psat_prep',
    label: 'PSAT Prep',
    value: 'PSAT Prep',
    id: 280,
    className: css.option,
    type: 'areasCertified',
    parent: 'Test Prep & College Prep',
    staticUrl: 'test_prep_college_prep',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'sat_prep',
    label: 'SAT Prep',
    value: 'SAT Prep',
    id: 281,
    className: css.option,
    type: 'areasCertified',
    parent: 'Test Prep & College Prep',
    staticUrl: 'test_prep_college_prep',
    filterId: 'subjects',
    heroStyles: 'english',
  },
  {
    key: 'all_theater_drama',
    label: 'All Theater & Drama',
    value: 'All Theater & Drama',
    id: 282,
    className: css.option,
    type: 'areasCertified',
    parent: 'Theater & Drama',
    staticUrl: 'theater_drama',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'acting',
    label: 'Acting',
    value: 'Acting',
    id: 283,
    className: css.option,
    type: 'areasCertified',
    parent: 'Theater & Drama',
    staticUrl: 'theater_drama',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'dance',
    label: 'Dance',
    value: 'Dance',
    id: 284,
    className: css.option,
    type: 'areasCertified',
    parent: 'Theater & Drama',
    staticUrl: 'theater_drama',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'drama',
    label: 'Drama',
    value: 'Drama',
    id: 285,
    className: css.option,
    type: 'areasCertified',
    parent: 'Theater & Drama',
    staticUrl: 'theater_drama',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'theater',
    label: 'Theater',
    value: 'Theater',
    id: 286,
    className: css.option,
    type: 'areasCertified',
    parent: 'Theater & Drama',
    staticUrl: 'theater_drama',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'other',
    label: 'All Other',
    value: 'All Other',
    id: 287,
    className: css.option,
    type: 'areasCertified',
    parent: 'Other',
    staticUrl: 'other',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'all_social_emotional_learning',
    label: 'All Social & Emotional learning',
    value: 'All Social & Emotional learning',
    id: 288,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social & Emotional Learning',
    staticUrl: 'social_emotional_learning',
    allIn: true,
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'self_awareness',
    label: 'Self-awareness',
    value: 'Self-awareness',
    id: 289,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social & Emotional Learning',
    staticUrl: 'social_emotional_learning',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'self_management',
    label: 'Self-management',
    value: 'Self-management',
    id: 290,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social & Emotional Learning',
    staticUrl: 'social_emotional_learning',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'social_awareness',
    label: 'Social awareness',
    value: 'Social awareness',
    id: 291,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social & Emotional Learning',
    staticUrl: 'social_emotional_learning',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'relationship_skills',
    label: 'Relationship skills',
    value: 'Relationship skills',
    id: 292,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social & Emotional Learning',
    staticUrl: 'social_emotional_learning',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'responsible_decision_making',
    label: 'Responsible decision-making',
    value: 'Responsible decision-making',
    id: 293,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social & Emotional Learning',
    staticUrl: 'social_emotional_learning',
    filterId: 'subjects',
    heroStyles: 'art',
  },
  {
    key: 'adult_education',
    label: 'Adult Education',
    value: 'Adult Education',
    id: 294,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social & Emotional Learning',
    staticUrl: 'social_emotional_learning',
    heroStyles: 'art',
  },
  {
    key: 'early_childhood_education',
    label: 'Early Childhood Education',
    value: 'Early Childhood Education',
    id: 295,
    className: css.option,
    type: 'areasCertified',
    parent: 'Social & Emotional Learning',
    staticUrl: 'social_emotional_learning',
    filterId: 'subjects',
    heroStyles: 'art',
  },
];

// !!!!!!! WHEN ADDING TO THIS CONFIG YOU NEED TO ADD THE SAME IN THE FILE src/util/scriptData.js
export const stateOptions = [
  { key: 'alabama', short: 'al', label: 'Alabama', value: 'Alabama', id: 0, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'alaska', short: 'ak', label: 'Alaska', value: 'Alaska', id: 1, className: css.option, type: 'usState' , filterId: 'state' },
  { key: 'arizona', short: 'az', label: 'Arizona', value: 'Arizona', id: 2, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'arkansas', short: 'ar', label: 'Arkansas', value: 'Arkansas', id: 3, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'california', short: 'ca', label: 'California', value: 'California', id: 4, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'colorado', short: 'co', label: 'Colorado', value: 'Colorado', id: 5, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'connecticut', short: 'ct', label: 'Connecticut', value: 'Connecticut', id: 6, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'delaware', short: 'de', label: 'Delaware', value: 'Delaware', id: 7, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'florida', short: 'fl', label: 'Florida', value: 'Florida', id: 8, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'georgia', short: 'ga', label: 'Georgia', value: 'Georgia', id: 9, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'hawaii', short: 'hi', label: 'Hawaii', value: 'Hawaii', id: 10, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'idaho', short: 'id', label: 'Idaho', value: 'Idaho', id: 11, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'illinois', short: 'il', label: 'Illinois', value: 'Illinois', id: 12, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'indiana', short: 'in', label: 'Indiana', value: 'Indiana', id: 13, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'iowa', short: 'ia', label: 'Iowa', value: 'Iowa', id: 14, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'kansas', short: 'ks', label: 'Kansas', value: 'Kansas', id: 15, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'kentucky', short: 'ky', label: 'Kentucky', value: 'Kentucky', id: 16, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'louisiana', short: 'la', label: 'Louisiana', value: 'Louisiana', id: 17, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'maine', short: 'me', label: 'Maine', value: 'Maine', id: 18, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'maryland', short: 'md', label: 'Maryland', value: 'Maryland', id: 19, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'massachusetts', short: 'ma', label: 'Massachusetts', value: 'Massachusetts', id: 20, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'michigan', short: 'mi', label: 'Michigan', value: 'Michigan', id: 21, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'minnesota', short: 'mn', label: 'Minnesota', value: 'Minnesota', id: 22, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'mississippi', short: 'ms', label: 'Mississippi', value: 'Mississippi', id: 23, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'missouri', short: 'mo', label: 'Missouri', value: 'Missouri', id: 24, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'montana', short: 'mt', label: 'Montana', value: 'Montana', id: 25, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'nebraska', short: 'ne', label: 'Nebraska', value: 'Nebraska', id: 26, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'nevada', short: 'nv', label: 'Nevada', value: 'Nevada', id: 27, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'new_hampshire', short: 'nh', label: 'New Hampshire', value: 'New Hampshire', id: 28, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'new_jersey', short: 'nj', label: 'New Jersey', value: 'New Jersey', id: 29, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'new_mexico', short: 'nm', label: 'New Mexico', value: 'New Mexico', id: 30, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'new_york', short: 'ny', label: 'New York', value: 'New York', id: 31, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'north_carolina', short: 'nc', label: 'North Carolina', value: 'North Carolina', id: 32, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'north_dakota', short: 'nd', label: 'North Dakota', value: 'North Dakota', id: 33, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'ohio', short: 'oh', label: 'Ohio', value: 'Ohio', id: 34, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'oklahoma', short: 'ok', label: 'Oklahoma', value: 'Oklahoma', id: 35, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'oregon', short: 'or', label: 'Oregon', value: 'Oregon', id: 36, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'pennsylvania', short: 'pa', label: 'Pennsylvania', value: 'Pennsylvania', id: 37, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'puerto_rico', short: 'pr', label: 'Puerto Rico', value: 'Puerto Rico', id: 37, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'rhode_island', short: 'ri', label: 'Rhode Island', value: 'Rhode Island', id: 38, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'south_carolina', short: 'sc', label: 'South Carolina', value: 'South Carolina', id: 39, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'south_dakota', short: 'sd', label: 'South Dakota', value: 'South Dakota', id: 40, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'tennessee', short: 'tn', label: 'Tennessee', value: 'Tennessee', id: 41, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'texas', short: 'tx', label: 'Texas', value: 'Texas', id: 42, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'utah', short: 'it', label: 'Utah', value: 'Utah', id: 43, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'usa', short: 'usa', label: 'USA', value: 'USA', id: 44, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'vermont', short: 'vt', label: 'Vermont', value: 'Vermont', id: 45, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'virginia', short: 'va', label: 'Virginia', value: 'Virginia', id: 46, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'washington', short: 'wa', label: 'Washington', value: 'Washington', id: 47, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'west_virginia', short: 'wv', label: 'West Virginia', value: 'West Virginia', id: 48, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'wisconsin', short: 'wi', label: 'Wisconsin', value: 'Wisconsin', id: 49, className: css.option, type: 'usState', filterId: 'state' },
  { key: 'wyoming', short: 'wy', label: 'Wyoming', value: 'Wyoming', id: 50, className: css.option, type: 'usState', filterId: 'state' },
];

export const filters = [
  {
    id: 'state',
    label: 'States',
    placeholder: 'Search states',
    type: 'StatesFilter',
    group: 'primary',
    queryParamNames: ['pub_state'],
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      options: stateOptions,
    },
  },
  {
    id: 'subjects',
    label: 'Subjects',
    placeholder: 'Search subjects',
    type: 'SubjectsFilter',
    group: 'primary',
    queryParamNames: ['pub_subjects'],
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      options: areasCertifiedOptions,
    },
  },
  {
    id: 'experience',
    label: 'Experience',
    type: 'Experience',
    group: 'primary',
    queryParamNames: ['pub_experience'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'multi-enum',
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '0-3', label: '0-3 years', filterId: 'experience' },
        { key: '4-9', label: '4-9 years', filterId: 'experience' },
        { key: '10+', label: '10+ years', filterId: 'experience' },
      ],
    },
  },
  {
    id: 'gradeLevels',
    label: 'Grade levels certified to teach',
    type: 'GradeLevels',
    group: 'primary',
    queryParamNames: ['pub_gradeLevels'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'multi-enum',
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'Kindergarten', label: 'Kindergarten', filterId: 'gradeLevels' },
        { key: 'Pre-K', label: 'Pre-K', long: '', filterId: 'gradeLevels' },
        { key: '1', label: '1', long: '1 grade', filterId: 'gradeLevels' },
        { key: '2', label: '2', long: '2 grade', filterId: 'gradeLevels' },
        { key: '3', label: '3', long: '3 grade', filterId: 'gradeLevels' },
        { key: '4', label: '4', long: '4 grade', filterId: 'gradeLevels' },
        { key: '5', label: '5', long: '5 grade', filterId: 'gradeLevels' },
        { key: '6', label: '6', long: '6 grade', filterId: 'gradeLevels' },
        { key: '7', label: '7', long: '7 grade', filterId: 'gradeLevels' },
        { key: '8', label: '8', long: '8 grade', filterId: 'gradeLevels' },
        { key: '9', label: '9', long: '9 grade', filterId: 'gradeLevels' },
        { key: '10', label: '10', long: '10 grade', filterId: 'gradeLevels' },
        { key: '11', label: '11', long: '11 grade', filterId: 'gradeLevels' },
        { key: '12', label: '12', long: '12 grade', filterId: 'gradeLevels' },
      ],
    },
  },
  {
    id: 'license',
    label: 'License',
    type: 'RadioButtonFilter',
    group: 'primary',
    queryParamNames: ['pub_license'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'boolean',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      // options: [
      //   { key: '0-3', label: '0-3 years' },
      //   { key: '4-7', label: '4-7 years' },
      //   { key: '8+', label: '8+ years' },
      // ],
    },
  },
  {
    id: 'education',
    label: 'Level of education',
    type: 'Education',
    group: 'primary',
    queryParamNames: ['pub_education'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.

      // !!!!!!! WHEN ADDING TO THIS CONFIG YOU NEED TO ADD THE SAME IN THE FILE src/util/scriptData.js
      options: [
        { key: 'phd', label: 'Phd', filterId: 'education' },
        { key: 'masters', label: 'Masters', filterId: 'education' },
        { key: 'bachelors', label: 'Bachelors', filterId: 'education' },
        { key: 'associates', label: 'Associates', filterId: 'education' },
      ],
      // !!!!!!! WHEN ADDING TO THIS CONFIG YOU NEED TO ADD THE SAME IN THE FILE src/util/scriptData.js
    },
  },
  {
    id: 'rate',
    label: 'Hourly rate',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_rate'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 100,
      step: 1,
    },
  },
  {
    id: 'price',
    label: 'Price per hour',
    type: 'PriceJobFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 100,
      step: 1,
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceCourseFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 1,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },


  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Best match', longLabel: 'Best match' },

    { key: 'pub_rate', label: 'Highest rate', longLabel: 'Hourly rate (highest to lowest)' },
    { key: '-pub_rate', label: 'Lowest rate', longLabel: 'Hourly rate (lowest to highest)' },
    { key: 'pub_experienceSort', label: 'Most experience', longLabel: 'Experience (most to least)' },
    { key: '-pub_experienceSort', label: 'Least experience', longLabel: 'Experience (least to most)'},
    { key: 'createdAt', label: 'AllCourse tenure (newest)', longLabel: 'AllCourse tenure (newest to earliest)'},
    { key: '-createdAt', label: 'AllCourse tenure (earliest)', longLabel: 'AllCourse tenure (earliest to newest)'},
    { key: 'pub_rating', label: 'Ratings', longLabel: 'Ratings (from high to low)'},
    // { key: '-price', label: 'Lowest price' },
    // { key: 'price', label: 'Highest price' },


  ],
};

export const sortCourseConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.

    { key: 'relevance', label: 'Best match', longLabel: 'Best match' },
    { key: '-price', label: 'Price (lowest to highest)' },
    { key: 'price', label: 'Price (highest to lowest)' },
    { key: 'createdAt', label: 'Newest'},
    { key: '-createdAt', label: 'Oldest'},

  ],
};

export const sortJobConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Best match', longLabel: 'Best match' },

    { key: 'price', label: 'Highest rate', longLabel: 'Hourly rate (highest to lowest)' },
    { key: '-price', label: 'Lowest rate', longLabel: 'Hourly rate (lowest to highest)' },
    { key: 'createdAt', label: 'AllCourse tenure (newest)', longLabel: 'AllCourse tenure (newest to earliest)'},
    { key: '-createdAt', label: 'AllCourse tenure (earliest)', longLabel: 'AllCourse tenure (earliest to newest)'},
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['size', 'brand', 'category'],
};

export const weeksOptions = [
  { key: '1', label: '1', value: '1', id: 0, className: css.option, type: 'weeks'},
  { key: '2', label: '2', value: '2', id: 1, className: css.option, type: 'weeks'},
  { key: '3', label: '3', value: '3', id: 2, className: css.option, type: 'weeks'},
  { key: '4', label: '4', value: '4', id: 3, className: css.option, type: 'weeks'},
  { key: '5', label: '5', value: '5', id: 4, className: css.option, type: 'weeks'},
  { key: '6', label: '6', value: '6', id: 5, className: css.option, type: 'weeks'},
  { key: '7', label: '7', value: '7', id: 6, className: css.option, type: 'weeks'},
]

export const periodOptions = [
  { key: '1_week', label: '1 week', value: '1 week', id: 0, className: css.option, type: 'period'},
  { key: '2_weeks', label: '2 weeks', value: '2 weeks', id: 1, className: css.option, type: 'period'},
  { key: '3_weeks', label: '3 weeks', value: '3 weeks', id: 2, className: css.option, type: 'period'},
  { key: '4_weeks', label: '4 weeks', value: '4 weeks', id: 3, className: css.option, type: 'period'},
  { key: '5_weeks', label: '5 weeks', value: '5 weeks', id: 4, className: css.option, type: 'period'},
  { key: '6_weeks', label: '6 weeks', value: '6 weeks', id: 5, className: css.option, type: 'period'},
  { key: '7_weeks', label: '7 weeks', value: '7 weeks', id: 6, className: css.option, type: 'period'},
  { key: '8_weeks', label: '8 weeks', value: '8 weeks', id: 7, className: css.option, type: 'period'},
  { key: '9_weeks', label: '9 weeks', value: '9 weeks', id: 8, className: css.option, type: 'period'},
  { key: '10_weeks', label: '10 weeks', value: '10 weeks', id: 9, className: css.option, type: 'period'},
  { key: '11_weeks', label: '11 weeks', value: '11 weeks', id: 10, className: css.option, type: 'period'},
  { key: '12_weeks', label: '12 weeks', value: '12 weeks', id: 11, className: css.option, type: 'period'},
  { key: '3_month', label: '3+ months', value: '3+ months', id: 12, className: css.option, type: 'period'},
  { key: 'semester', label: 'Semester', value: 'Semester', id: 13, className: css.option, type: 'period'},
  { key: 'school_year', label: 'School year', value: 'School year', id: 13, className: css.option, type: 'period'},
]

export const experienceOptions = [
  { key: '0-3', label: '0-3', value: '0-3', id: 0, className: css.option, type: 'experience'},
  // { key: '4-9', label: '4-9', value: '4-9', id: 1, className: css.option, type: 'experience'},
  { key: '3+', label: '3+', value: '3+', id: 1, className: css.option, type: 'experience'},
  { key: '10+', label: '10+', value: '10+', id: 2, className: css.option, type: 'experience'},
];

export const licenseOptions = [
  { key: 'Yes', label: 'Yes', value: 'Yes', id: 0, className: css.option, type: 'license', filterId: 'license' },
  { key: 'i_dont_have_an_active_license', label: 'I don\'t have an active license', value: 'I don\'t have an active license', id: 1, className: css.option, type: 'license', filterId: 'license' },
  { key: 'my_license_is_being_processed', label: 'My license is being processed', value: 'My license is being processed', id: 2, className: css.option, type: 'license', filterId: 'license' },
];

export const educationOptions = [
  { key: 'Phd', label: 'Phd', value: 'Phd', id: 0, className: css.option, type: 'education', filterId: 'education' },
  { key: 'Masters', label: 'Masters', value: 'Masters', id: 1, className: css.option, type: 'education', filterId: 'education' },
  { key: 'Bachelors', label: 'Bachelors', value: 'Bachelors', id: 2, className: css.option, type: 'education', filterId: 'education' },
  { key: 'Associates', label: 'Associates', value: 'Associates', id: 3, className: css.option, type: 'education', filterId: 'education' },
];

export const gradeLevelsOptions = [
  { key: 'Kindergarten', label: 'Kindergarten', value: 'Kindergarten', id: 0, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: 'Pre-K', label: 'Pre-K', value: 'Pre-K', id: 1, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '1', label: '1', value: '1', long: '1 grade', id: 2, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '2', label: '2', value: '2', long: '2 grade', id: 3, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '3', label: '3', value: '3', long: '3 grade', id: 4, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '4', label: '4', value: '4', long: '4 grade', id: 5, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '5', label: '5', value: '5', long: '5 grade', id: 6, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '6', label: '6', value: '6', long: '6 grade', id: 7, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '7', label: '7', value: '7', long: '7 grade', id: 8, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '8', label: '8', value: '8', long: '8 grade', id: 9, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '9', label: '9', value: '9', long: '9 grade', id: 10, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '10', label: '10', value: '10', long: '10 grade', id: 11, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '11', label: '11', value: '11', long: '11 grade', id: 12, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
  { key: '12', label: '12', value: '12', long: '12 grade', id: 13, className: css.option, type: 'gradeLevels', filterId: 'gradeLevels' },
]

export const standardsAlignmentLabelOptions = [
  { key: 'all_states', label: 'All states', value: 'All states', id: 12, className: css.option, type: 'standardsAlignment'},
  { key: 'common_core', label: 'Common Core', value: 'Common Core', id: 12, className: css.option, type: 'standardsAlignment'},
  { key: 'ap', label: 'AP', value: 'AP', id: 12, className: css.option, type: 'standardsAlignment'},
  { key: 'international_baccalaureate', label: 'International Baccalaureate', value: 'International Baccalaureate', id: 12, className: css.option, type: 'standardsAlignment'},
]



export const oldAreasCertifiedOptions = [
  { key: 'adult_education', label: 'Adult Education', value: 'Adult Education', id: 0, className: css.option, type: 'areasCertified'},
  { key: 'agriculture', label: 'Agriculture', value: 'Agriculture', id: 1, className: css.option, type: 'areasCertified'},
  { key: 'agriculture and Natural Resources', label: 'Agriculture and Natural Resources', value: 'Agriculture and Natural Resources', id: 2, className: css.option, type: 'areasCertified' },
  { key: 'armenian', label: 'Armenian', value: 'v', id: 3, className: css.option, type: 'areasCertified' },
  { key: 'art', label: 'Art', value: 'Art', id: 4, className: css.option, type: 'areasCertified' },
  { key: 'arts, Media, and Entertainment', label: 'Arts, Media, and Entertainment', value: 'Arts, Media, and Entertainment', id: 5, className: css.option, type: 'areasCertified' },
  { key: 'bengali', label: 'Bengali', value: 'Bengali', id: 6, className: css.option, type: 'areasCertified' },
  { key: 'biological Sciences', label: 'Biological Sciences', value: 'Biological Sciences', id: 7, className: css.option, type: 'areasCertified' },
  { key: 'building_and_construction_trades', label: 'Building and Construction Trades', value: 'Building and Construction Trades', id: 8, className: css.option, type: 'areasCertified' },
  { key: 'business', label: 'Business', value: 'Business', id: 9, className: css.option, type: 'areasCertified' },
  { key: 'business_and_finance', label: 'Business and Finance', value: 'Business and Finance', id: 10, className: css.option, type: 'areasCertified' },
  { key: 'chemistry', label: 'Chemistry', value: 'Chemistry', id: 11, className: css.option, type: 'areasCertified' },
  { key: 'civics', label: 'Civics', value: 'Civics', id: 12, className: css.option, type: 'areasCertified' },
  { key: 'computer_science', label: 'Computer Science', value: 'Computer Science', id: 13, className: css.option },
  { key: 'dance', label: 'Dance', value: 'Dance', id: 14, className: css.option, type: 'areasCertified' },
  { key: 'early_childhood_education', label: 'Early Childhood Education', value: 'Early Childhood Education', id: 15, className: css.option, type: 'areasCertified' },
  { key: 'economics', label: 'Economics', value: 'Economics', id: 16, className: css.option, type: 'areasCertified' },
  { key: 'education_child_development_and_family_services', label: 'Education, Child Development, and Family Services', value: 'Education, Child Development, and Family Services', id: 17, className: css.option, type: 'areasCertified' },
  { key: 'elementary_education_(all_subjects)', label: 'Elementary Education (All Subjects)', value: 'Elementary Education (All Subjects)', id: 18, className: css.option, type: 'areasCertified', popular: true },
  { key: 'energy_environment_and_utilities', label: 'Energy, Environment, and Utilities', value: 'Energy, Environment, and Utilities', id: 19, className: css.option, type: 'areasCertified' },
  { key: 'engineering_and_architecture', label: 'Engineering and Architecture', value: 'Engineering and Architecture', id: 20, className: css.option, type: 'areasCertified' },
  { key: 'english', label: 'English', value: 'English', id: 21, className: css.option, type: 'areasCertified', popular: true },
  { key: 'farsi', label: 'Farsi', value: 'Farsi', id: 22, className: css.option, type: 'areasCertified' },
  { key: 'fashion_and_interior_design', label: 'Fashion and Interior Design', value: 'Fashion and Interior Design', id: 23, className: css.option, type: 'areasCertified' },
  { key: 'foundational_level_general_science', label: 'Foundational-Level General Science', value: 'Foundational-Level General Science', id: 24, className: css.option, type: 'areasCertified', popular: true },
  { key: 'foundational_level_mathematics', label: 'Foundational-Level Mathematics', value: 'Foundational-Level Mathematics', id: 25, className: css.option, type: 'areasCertified', popular: true },
  { key: 'french', label: 'French', value: 'French', id: 26, className: css.option, type: 'areasCertified' },
  { key: 'geosciences', label: 'Geosciences', value: 'Geosciences', id: 27, className: css.option, type: 'areasCertified' },
  { key: 'german', label: 'German', value: 'German', id: 28, className: css.option, type: 'areasCertified' },
  { key: 'hausa', label: 'Hausa', value: 'Hausa', id: 29, className: css.option, type: 'areasCertified' },
  { key: 'health_science', label: 'Health Science', value: 'Health Science', id: 30, className: css.option, type: 'areasCertified' },
  { key: 'health_science_and_medical_technology', label: 'Health Science and Medical Technology', value: 'Health Science and Medical Technology', id: 31, className: css.option, type: 'areasCertified' },
  { key: 'hebrew', label: 'Hebrew', value: 'Hebrew', id: 32, className: css.option, type: 'areasCertified' },
  { key: 'hindi', label: 'Hindi', value: 'Hindi', id: 33, className: css.option, type: 'areasCertified' },
  { key: 'hmong', label: 'Hmong', value: 'Hmong', id: 34, className: css.option, type: 'areasCertified' },
  { key: 'home_economics', label: 'Home Economics', value: 'Home Economics', id: 35, className: css.option, type: 'areasCertified' },
  { key: 'hospitality_tourism_and_recreation', label: 'Hospitality, Tourism, and Recreation', value: 'Hospitality, Tourism, and Recreation', id: 36, className: css.option, type: 'areasCertified' },
  { key: 'indonesian', label: 'Indonesian', value: 'Indonesian', id: 37, className: css.option, type: 'areasCertified' },
  { key: 'industrial_and_technology_education', label: 'Industrial and Technology Education', value: 'Industrial and Technology Education', id: 38, className: css.option, type: 'areasCertified' },
  { key: 'information_and_communication_technologies', label: 'Information and Communication Technologies', value: 'Information and Communication Technologies', id: 39, className: css.option, type: 'areasCertified' },
  { key: 'italian', label: 'Italian', value: 'Italian', id: 40, className: css.option, type: 'areasCertified' },
  { key: 'japanese', label: 'Japanese', value: 'Japanese', id: 41, className: css.option, type: 'areasCertified' },
  { key: 'journalism', label: 'Journalism', value: 'Journalism', id: 42, className: css.option },
  { key: 'khmer', label: 'Khmer', value: 'Khmer', id: 43, className: css.option, type: 'areasCertified' },
  { key: 'korean', label: 'Korean', value: 'Korean', id: 44, className: css.option, type: 'areasCertified' },
  { key: 'latin', label: 'Latin', value: 'Latin', id: 45, className: css.option, type: 'areasCertified' },
  { key: 'mandarin_chinese', label: 'Mandarin Chinese', value: 'Mandarin Chinese', id: 46, className: css.option, type: 'areasCertified' },
  { key: 'manufacturing_and_product_development', label: 'Manufacturing and Product Development', value: 'Manufacturing and Product Development', id: 47, className: css.option, type: 'areasCertified' },
  { key: 'marathi', label: 'Marathi', value: 'Marathi', id: 48, className: css.option, type: 'areasCertified' },
  { key: 'marketing_sales_and_service', label: 'Marketing, Sales, and Service', value: 'Marketing, Sales, and Service', id: 49, className: css.option, type: 'areasCertified' },
  { key: 'mathematics', label: 'Mathematics', value: 'Mathematics', id: 50, className: css.option, type: 'areasCertified', popular: true },
  { key: 'music', label: 'Music', value: 'Music', id: 51, className: css.option, type: 'areasCertified' },
  { key: 'other', label: 'Other', value: 'Other', id: 52, className: css.option, type: 'areasCertified' },
  { key: 'physical_education', label: 'Physical Education', value: 'Physical Education', id: 53, className: css.option, type: 'areasCertified' },
  { key: 'physics', label: 'Physics', value: 'Physics', id: 54, className: css.option, type: 'areasCertified' },
  { key: 'polish', label: 'Polish', value: 'Polish', id: 55, className: css.option, type: 'areasCertified' },
  { key: 'portuguese', label: 'Portuguese', value: 'Portuguese', id: 56, className: css.option, type: 'areasCertified' },
  { key: 'psychology', label: 'Psychology', value: 'Psychology', id: 57, className: css.option, type: 'areasCertified' },
  { key: 'public_services', label: 'Public Services', value: 'Public Services', id: 58, className: css.option, type: 'areasCertified' },
  { key: 'punjabi', label: 'Punjabi', value: 'Punjabi', id: 59, className: css.option, type: 'areasCertified' },
  { key: 'russian', label: 'Russian', value: 'Russian', id: 60, className: css.option, type: 'areasCertified' },
  { key: 'social_science', label: 'Social Science', value: 'Social Science', id: 61, className: css.option, type: 'areasCertified', popular: true },
  { key: 'spanish', label: 'Spanish', value: 'Spanish', id: 62, className: css.option, type: 'areasCertified' },
  { key: 'special_education', label: 'Special Education', value: 'Special Education', id: 63, className: css.option, type: 'areasCertified' },
  { key: 'special_education_deaf_and_hard_of_hearing', label: 'Special Education: Deaf and Hard of Hearing', value: 'Special Education: Deaf and Hard of Hearing', id: 64, className: css.option, type: 'areasCertified' },
  { key: 'special_education_early_childhood_special_education', label: 'Special Education: Early Childhood Special Education', value: 'Special Education: Early Childhood Special Education', id: 65, className: css.option, type: 'areasCertified' },
  { key: 'special_education_education_specialist_instruction_credential', label: 'Special Education: Education Specialist Instruction Credential', value: 'Special Education: Education Specialist Instruction Credential', id: 66, className: css.option, type: 'areasCertified' },
  { key: 'special_education_exceptional_students_gifted_and_talented', label: 'Special Education: Exceptional Students/Gifted and Talented', value: 'Special Education: Exceptional Students/Gifted and Talented', id: 67, className: css.option, type: 'areasCertified' },
  { key: 'special_education_mild_to_moderate_support_needs', label: 'Special Education: Mild to Moderate Support Needs', value: 'Special Education: Mild to Moderate Support Needs', id: 68, className: css.option, type: 'areasCertified' },
  { key: 'special_education_moderate_to_severe_support_needs', label: 'Special Education: Moderate to Severe Support Needs', value: 'Special Education: Moderate to Severe Support Needs', id: 69, className: css.option, type: 'areasCertified' },
  { key: 'special_education_visual_impairments', label: 'Special Education: Visual Impairments', value: 'Special Education: Visual Impairments', id: 70, className: css.option, type: 'areasCertified' },
  { key: 'speech_language_pathologist', label: 'Speech Language Pathologist', value: 'Speech Language Pathologist', id: 71, className: css.option },
  { key: 'swahili', label: 'Swahili', value: 'Swahili', id: 72, className: css.option, type: 'areasCertified' },
  { key: 'tagalog', label: 'Tagalog', value: 'Tagalog', id: 73, className: css.option, type: 'areasCertified' },
  { key: 'tamil', label: 'Tamil', value: 'Tamil', id: 74, className: css.option, type: 'areasCertified' },
  { key: 'telugu', label: 'Telugu', value: 'Telugu', id: 75, className: css.option, type: 'areasCertified' },
  { key: 'thai', label: 'Thai', value: 'Thai', id: 76, className: css.option, type: 'areasCertified' },
  { key: 'theater', label: 'Theater', value: 'Theater', id: 77, className: css.option, type: 'areasCertified' },
  { key: 'transportation', label: 'Transportation', value: 'Transportation', id: 78, className: css.option, type: 'areasCertified' },
  { key: 'turkish', label: 'Turkish', value: 'Turkish', id: 79, className: css.option, type: 'areasCertified' },
  { key: 'urdu', label: 'Urdu', value: 'Urdu', id: 80, className: css.option, type: 'areasCertified' },
  { key: 'vietnamese', label: 'Vietnamese', value: 'Vietnamese', id: 81, className: css.option, type: 'areasCertified' },
  { key: 'wu_chinese', label: 'Wu Chinese', value: 'Wu Chinese', id: 82, className: css.option, type: 'areasCertified' },
  { key: 'yue_chinese', label: 'Yue Chinese', value: 'Yue Chinese', id: 83, className: css.option, type: 'areasCertified' },
];

export const popularAreasCertifiedOptions = [
  { key: 'special_education', label: 'Special Education', value: 'Special Education', id: 1, className: css.option},
  { key: 'journalism', label: 'Journalism', value: 'Journalism', id: 2, className: css.option},
  { key: 'speech_language_pathologist', label: 'Speech Language Pathologist', value: 'Speech Language Pathologist', id: 3, className: css.option },
  { key: 'english', label: 'English', value: 'English', id: 4, className: css.option },
  { key: 'economics', label: 'Economics', value: 'Economics', id: 5, className: css.option },
  { key: 'computer_science', label: 'Computer Science', value: 'Computer Science', id: 6, className: css.option },
];

export const yearOptions = [
  { key: '2023', label: '2023', value: '2023', id: 0, className: css.option},
  { key: '2022', label: '2022', value: '2022', id: 1, className: css.option},
  // { key: '2021', label: '2021', value: '2021', id: 2, className: css.option},
  // { key: '2020', label: '2020', value: '2020', id: 3, className: css.option},
  // { key: '2019', label: '2019', value: '2019', id: 4, className: css.option},
  // { key: '2018', label: '2018', value: '2018', id: 5, className: css.option},
  // { key: '2017', label: '2017', value: '2017', id: 6, className: css.option},
  // { key: '2016', label: '2016', value: '2016', id: 7, className: css.option},
  // { key: '2015', label: '2015', value: '2015', id: 8, className: css.option},
  // { key: '2014', label: '2014', value: '2014', id: 9, className: css.option},

];

export const userRoleOptions = [
  { key: 'teacher', label: 'Teacher', value: 'teacher', id: 0, className: css.option},
  { key: 'schoolAdministrator', label: 'School administrator', value: 'schoolAdministrator', id: 1, className: css.option},
  { key: 'parent', label: 'Parent', value: 'parent', id: 2, className: css.option},

];

// List of holidays:
//   1. New Year's Day - 1 January
// 2. Birthday of Martin Luther King, Jr. - Third Monday of January
// 3. Washington's Birthday - Third Monday of February
// 4. Memorial Day - Last Monday of May
// 5. Juneteenth - 19 June
// 6. Independence Day - 4 July
// 7. Labor Day - First Monday of September
// 8. Columbus Day - Second Monday of October
// 9. Veterans Day - 11 November
// 10. Thanksgiving Day - Fourth Thursday of November
// 11. Christmas Day - 25 December

export const teachersCustomCard = [{
  attributes: {
    profile: {
      abbreviatedName: "TT",
      displayName: "Test T",
      bio: null,
      publicData: {
        education: ["associates"],
        experience: ["0-3"],
        experienceSort: 0,
        firstName: "Test",
        gradeLevels: ["10"],
        lastName: "Test",
        license: false,
        nickname: "Test",
        onboardingProcess: {
          education: "Associates",
          experience: "0-3",
          license: "I don't have an active license",
          licenses: [
            {
              areasCertified: [
                {
                  className: "ProfileSettingsForm_option__2SYxP",
                  id: 201,
                  key: "1st_grade_math",
                  label: "1st Grade Math",
                  parent: "Mathematics",
                  type: "areasCertified",
                  value: "1st Grade Math"
                },
                {
                  className: "ProfileSettingsForm_option__2SYxP",
                  id: 77,
                  key: "2nd_grade",
                  label: "2nd Grade",
                  parent: "Elementary",
                  type: "areasCertified",
                  value: "2nd Grade"
                }
              ],
              gradeLevels: [
                {
                  className: "ProfileSettingsForm_option__2SYxP",
                  id: 10,
                  key: "10",
                  label: "10",
                  type: "gradeLevels",
                  value: "10"
                }
              ],
              usState: {
                className: "ProfileSettingsForm_option__2SYxP",
                id: 2,
                key: "arizona",
                label: "Arizona",
                type: "usState",
                value: "Arizona"
              }
            }
          ],
          rate: 4500,
          referral: null,
          youtubeLink: null
        },
        onboardingProcessCompleted: true,
        rate: 45,
        searchParams: [
          "test",
        ],
        state: ["arizona"],
        subjects: ["1st_grade_math", "2nd_grade"],
        userRole: "teacher",
        withProfileImage: true
      },
      metadata: {}
    },
    pendingEmail: null,
    emailVerified: true
  },
  id: { uuid: "test" },
  profileImage: null,
  type: "user"
},
  {
    attributes: {
      profile: {
        abbreviatedName: "TT",
        displayName: "Test T",
        bio: null,
        publicData: {
          education: ["associates"],
          experience: ["0-3"],
          experienceSort: 0,
          firstName: "Test",
          gradeLevels: ["10"],
          lastName: "Test",
          license: false,
          nickname: "Test",
          onboardingProcess: {
            education: "Associates",
            experience: "0-3",
            license: "I don't have an active license",
            licenses: [
              {
                areasCertified: [
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 201,
                    key: "1st_grade_math",
                    label: "1st Grade Math",
                    parent: "Mathematics",
                    type: "areasCertified",
                    value: "1st Grade Math"
                  },
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 77,
                    key: "2nd_grade",
                    label: "2nd Grade",
                    parent: "Elementary",
                    type: "areasCertified",
                    value: "2nd Grade"
                  }
                ],
                gradeLevels: [
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 10,
                    key: "10",
                    label: "10",
                    type: "gradeLevels",
                    value: "10"
                  }
                ],
                usState: {
                  className: "ProfileSettingsForm_option__2SYxP",
                  id: 2,
                  key: "arizona",
                  label: "Arizona",
                  type: "usState",
                  value: "Arizona"
                }
              }
            ],
            rate: 4500,
            referral: null,
            youtubeLink: null
          },
          onboardingProcessCompleted: true,
          rate: 45,
          searchParams: [
            "test",
          ],
          state: ["arizona"],
          subjects: ["1st_grade_math", "2nd_grade"],
          userRole: "teacher",
          withProfileImage: true
        },
        metadata: {}
      },
      pendingEmail: null,
      emailVerified: true
    },
    id: { uuid: "test" },
    profileImage: null,
    type: "user"
  },
  {
    attributes: {
      profile: {
        abbreviatedName: "TT",
        displayName: "Test T",
        bio: null,
        publicData: {
          education: ["associates"],
          experience: ["0-3"],
          experienceSort: 0,
          firstName: "Test",
          gradeLevels: ["10"],
          lastName: "Test",
          license: false,
          nickname: "Test",
          onboardingProcess: {
            education: "Associates",
            experience: "0-3",
            license: "I don't have an active license",
            licenses: [
              {
                areasCertified: [
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 201,
                    key: "1st_grade_math",
                    label: "1st Grade Math",
                    parent: "Mathematics",
                    type: "areasCertified",
                    value: "1st Grade Math"
                  },
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 77,
                    key: "2nd_grade",
                    label: "2nd Grade",
                    parent: "Elementary",
                    type: "areasCertified",
                    value: "2nd Grade"
                  }
                ],
                gradeLevels: [
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 10,
                    key: "10",
                    label: "10",
                    type: "gradeLevels",
                    value: "10"
                  }
                ],
                usState: {
                  className: "ProfileSettingsForm_option__2SYxP",
                  id: 2,
                  key: "arizona",
                  label: "Arizona",
                  type: "usState",
                  value: "Arizona"
                }
              }
            ],
            rate: 4500,
            referral: null,
            youtubeLink: null
          },
          onboardingProcessCompleted: true,
          rate: 45,
          searchParams: [
            "test",
          ],
          state: ["arizona"],
          subjects: ["1st_grade_math", "2nd_grade"],
          userRole: "teacher",
          withProfileImage: true
        },
        metadata: {}
      },
      pendingEmail: null,
      emailVerified: true
    },
    id: { uuid: "test" },
    profileImage: null,
    type: "user"
  },
  {
    attributes: {
      profile: {
        abbreviatedName: "TT",
        displayName: "Test T",
        bio: null,
        publicData: {
          education: ["associates"],
          experience: ["0-3"],
          experienceSort: 0,
          firstName: "Test",
          gradeLevels: ["10"],
          lastName: "Test",
          license: false,
          nickname: "Test",
          onboardingProcess: {
            education: "Associates",
            experience: "0-3",
            license: "I don't have an active license",
            licenses: [
              {
                areasCertified: [
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 201,
                    key: "1st_grade_math",
                    label: "1st Grade Math",
                    parent: "Mathematics",
                    type: "areasCertified",
                    value: "1st Grade Math"
                  },
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 77,
                    key: "2nd_grade",
                    label: "2nd Grade",
                    parent: "Elementary",
                    type: "areasCertified",
                    value: "2nd Grade"
                  }
                ],
                gradeLevels: [
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 10,
                    key: "10",
                    label: "10",
                    type: "gradeLevels",
                    value: "10"
                  }
                ],
                usState: {
                  className: "ProfileSettingsForm_option__2SYxP",
                  id: 2,
                  key: "arizona",
                  label: "Arizona",
                  type: "usState",
                  value: "Arizona"
                }
              }
            ],
            rate: 4500,
            referral: null,
            youtubeLink: null
          },
          onboardingProcessCompleted: true,
          rate: 45,
          searchParams: [
            "test",
          ],
          state: ["arizona"],
          subjects: ["1st_grade_math", "2nd_grade"],
          userRole: "teacher",
          withProfileImage: true
        },
        metadata: {}
      },
      pendingEmail: null,
      emailVerified: true
    },
    id: { uuid: "test" },
    profileImage: null,
    type: "user"
  },
  {
    attributes: {
      profile: {
        abbreviatedName: "TT",
        displayName: "Test T",
        bio: null,
        publicData: {
          education: ["associates"],
          experience: ["0-3"],
          experienceSort: 0,
          firstName: "Test",
          gradeLevels: ["10"],
          lastName: "Test",
          license: false,
          nickname: "Test",
          onboardingProcess: {
            education: "Associates",
            experience: "0-3",
            license: "I don't have an active license",
            licenses: [
              {
                areasCertified: [
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 201,
                    key: "1st_grade_math",
                    label: "1st Grade Math",
                    parent: "Mathematics",
                    type: "areasCertified",
                    value: "1st Grade Math"
                  },
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 77,
                    key: "2nd_grade",
                    label: "2nd Grade",
                    parent: "Elementary",
                    type: "areasCertified",
                    value: "2nd Grade"
                  }
                ],
                gradeLevels: [
                  {
                    className: "ProfileSettingsForm_option__2SYxP",
                    id: 10,
                    key: "10",
                    label: "10",
                    type: "gradeLevels",
                    value: "10"
                  }
                ],
                usState: {
                  className: "ProfileSettingsForm_option__2SYxP",
                  id: 2,
                  key: "arizona",
                  label: "Arizona",
                  type: "usState",
                  value: "Arizona"
                }
              }
            ],
            rate: 4500,
            referral: null,
            youtubeLink: null
          },
          onboardingProcessCompleted: true,
          rate: 45,
          searchParams: [
            "test",
          ],
          state: ["arizona"],
          subjects: ["1st_grade_math", "2nd_grade"],
          userRole: "teacher",
          withProfileImage: true
        },
        metadata: {}
      },
      pendingEmail: null,
      emailVerified: true
    },
    id: { uuid: "test" },
    profileImage: null,
    type: "user"
  },
]


export const listingCustomCard = [
  {
    id: { uuid: "test" },
    type: "listing",
    attributes: {
      title: "test",
      description: "test",
      geolocation: null,
      price: { amount: 7500, currency: "USD" },
      publicData: {
        customId: 'test'
      }
    },
    author: {
      id: { uuid: "1" },
      type: "user",
      attributes: {
        profile: {
          abbreviatedName: "tt",
          displayName: "test t"
        }
      },
      profileImage: null
    },
    images: [null]
  }
];

export const holidays = [
  '01/01/2023',
  '01/16/2023',
  '02/20/2023',
  '05/29/2023',
  '06/19/2023',
  '07/04/2023',
  '09/04/2023',
  '10/10/2022',
  '10/09/2023',
  '11/11/2022',
  '11/11/2023',
  '11/24/2022',
  '11/23/2023',
  '12/25/2022',
  '12/25/2023',
]

export const discountCode = 'AC23!';


export const allMonths = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

export const allWeekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", ];

