import classNames from 'classnames';
import css from './GenericNotification.module.css';
import React from 'react';

const GenericNotification = props => {
  const { show, type, text } = props;

  const classes = classNames(css.genericRoot, {
    [css.genericRootVisible]: show,
    [css.genericRootSuccess]: type === 'success',
    [css.genericRootError]: type === 'error',
  });

  return (
    <div className={classes}>
      <div className={css.genericContent}>
        <p className={css.genericText}>
          {text}
        </p>
      </div>
    </div>
  );
};

export default GenericNotification;
