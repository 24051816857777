import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TermsOfServiceContentEn from './TermsOfServiceContentEn';
import css from './TermsOfService.module.css';

const TermsOfService = () => {

  // prettier-ignore
  return (
    <TermsOfServiceContentEn />
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
