import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { parse } from '../../../util/urlHelpers';

import config from '../../../config';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { isMainSearchTypeKeywords } from '../../../util/search';

import { FieldSelect, FieldSelect2, Form, LocationAutocompleteInput, Menu,
  MenuLabel,
  MenuContent,
  MenuItem, } from '../../../components';

import IconSearchDesktop from './IconSearchDesktop';
import css from './TopbarSearchForm.module.css';
import { numberAtLeast } from '../../../util/validators';

const TEACHER = "teacher"
const COURSE = "course"
const JOB = "job"

const identity = v => v;

const KeywordSearchField = props => {
  const { keywordSearchWrapperClasses, iconClass, intl, isMobile, inputRef, searchPlaceholder } = props;
  return (
    <div className={keywordSearchWrapperClasses}>
      <div className={css.keywordSearchIcon}><IconSearchDesktop /></div>
      <Field
        name="keywords"
        render={({ input, meta }) => {
          return (
            <input
              className={isMobile ? css.mobileInput : css.desktopInput}
              {...input}
              id="keyword-search"
              ref={inputRef}
              type="text"
              placeholder={searchPlaceholder || intl.formatMessage({id: 'TopbarSearchForm.placeholder'})}
              autoComplete="off"
            />
          );
        }}
      />
    </div>
  );
};

const LocationSearchField = props => {
  const { desktopInputRootClass, intl, isMobile, inputRef, onLocationChange, searchPlaceholder } = props;
  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const searchOnChange = value => {
          onChange(value);
          onLocationChange(value);
        };

        return (
          <LocationAutocompleteInput
            className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
            iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
            inputClassName={isMobile ? css.mobileInput : css.desktopInput}
            predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
            predictionsAttributionClassName={isMobile ? css.mobilePredictionsAttribution : null}
            placeholder={searchPlaceholder || intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
            closeOnBlur={!isMobile}
            inputRef={inputRef}
            input={{ ...restInput, onChange: searchOnChange }}
            meta={meta}
          />
        );
      }}
    />
  );
};

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFormType: this.props.isTeacher ? JOB : TEACHER,
    }
    // onChange is used for location search
    this.onChange = this.onChange.bind(this);
    // onSubmit is used for keywords search
    this.onSubmit = this.onSubmit.bind(this);
    this.onTypeChangeHandle = this.onTypeChangeHandle.bind(this);

    // Callback ref
    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.setSearchInput = element;
    };
  }

  onChange(location) {
    const { appConfig, onSubmit } = this.props;
    if (!isMainSearchTypeKeywords(appConfig) && location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      onSubmit({ location });
      // blur search input to hide software keyboard
      this.searchInput?.blur();
    }
  }

  onTypeChangeHandle(type, form) {
    this.setState({
      searchFormType: type
    });

    form.change("type", type)
  }

  onSubmit(values) {
    const { appConfig, onSubmit } = this.props;
    if (isMainSearchTypeKeywords(appConfig)) {
      onSubmit({ keywords: values.keywords, type: values.type });
      // blur search input to hide software keyboard
      this.searchInput?.blur();
    }
  }

  componentDidMount() {
    const pathLabel = parse(location?.search);

    if (pathLabel?.type !== undefined) {
      this.setState({
        searchFormType: pathLabel.type
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pathLabel = parse(location?.search);

    if(prevState.searchFormType !== this.state.searchFormType && pathLabel?.type !== prevState.searchFormType) {
      this.setState({
        searchFormType: pathLabel.type
      });
    }
  }

  render() {
    const { onSubmit, appConfig, searchPlaceholder, newSearchDesign, location, isTeacher, ...restOfProps } = this.props;
    const isKeywordsSearch = isMainSearchTypeKeywords(appConfig);
    const submit = isKeywordsSearch ? this.onSubmit : onSubmit;
    const typeInititalValue = isTeacher ? JOB : TEACHER;
    return (
      <FinalForm
        {...restOfProps}
        onSubmit={submit}
        initialValues={{type: isTeacher ? JOB : this.state.searchFormType}}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            handleSubmit,
            form,
            values,
          } = formRenderProps;
          const classes = classNames(rootClassName, className, {[css.newSearchDesign]: newSearchDesign});
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          // Location search: allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();
          const submitFormFn = isKeywordsSearch ? handleSubmit : preventFormSubmit;

          const keywordSearchWrapperClasses = classNames(
            css.keywordSearchWrapper,
            isMobile ? css.mobileInputRoot : desktopInputRootClass
          );

          const teacherLabel = isTeacher ? <div>Jobs</div> : <div>Teachers</div>;
          const courseLabel = <div>Courses</div>;
          const customLabel = this.state.searchFormType === "teacher" || this.state.searchFormType === "job" ? teacherLabel : courseLabel;

          return (
            <Form className={classes} onSubmit={submitFormFn} enforcePagePreloadFor="SearchPage">
              {isKeywordsSearch ? (
                <KeywordSearchField
                  keywordSearchWrapperClasses={keywordSearchWrapperClasses}
                  iconClass={classNames(isMobile ? css.mobileIcon : css.desktopIcon || css.icon)}
                  intl={intl}
                  isMobile={isMobile}
                  inputRef={this.setSearchInputRef}
                  searchPlaceholder={searchPlaceholder}
                />
              ) : (
                <LocationSearchField
                  desktopInputRootClass={desktopInputRootClass}
                  intl={intl}
                  isMobile={isMobile}
                  inputRef={this.setSearchInputRef}
                  onLocationChange={this.onChange}
                  searchPlaceholder={searchPlaceholder}
                />
              )}

              {isTeacher ? <div className={css.topbarRoleSelectContainer}>
                <div className={css.topbarRoleSelect}>{customLabel}</div>
              </div> : <Menu className={css.topbarRoleSelectContainer} useArrow={false}>
                <MenuLabel withArrow={true} className={css.topbarRoleSelect} isOpenClassName={css.profileMenuIsOpen}>
                  {customLabel}
                </MenuLabel>
                <MenuContent className={css.topbarRoleSelectContent}>
                  <MenuItem className={css.topbarRoleSelectItem} key="teacher">
                    <div onClick={() => this.onTypeChangeHandle(TEACHER, form)}>Teachers
                      <div className={css.extraLabel}>Find online teachers</div>
                    </div>
                  </MenuItem>
                  <MenuItem className={css.topbarRoleSelectItem} key="course">
                    <div onClick={() => this.onTypeChangeHandle(COURSE, form)}>Courses
                      <div className={css.extraLabel}>Browse for-credit courses</div>
                    </div>
                  </MenuItem>
                </MenuContent>
              </Menu>}
            </Form>
          );
        }}
      />
    );
  }
}

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  appConfig: config,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func,
  isMobile: bool,
  appConfig: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
