import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { isTeacherRole } from '../../util/types';
import { Button, NamedLink } from '../index';

import css from './ModalInviteFriend.module.css';

const {
  REACT_APP_CANONICAL_ROOT_URL,
  REACT_APP_REWARD_FOR_INVITATION_TEACHER,
  REACT_APP_REWARD_FOR_INVITATION_PARENT,
  REACT_APP_REWARD_FOR_INVITATION_SCHOOL_ADMINISTRATOR,
} = process.env;
const CENTS = 100;

const ModalInviteFriend = props => {
  const { currentUser, currentPage } = props;

  const [isCopied, setIsCopied] = useState(false);

  const path = `${REACT_APP_CANONICAL_ROOT_URL}/signup?ref=${currentUser?.id?.uuid}`;

  const copyLink = () => {
    if (typeof window === undefined) {
      return;
    }
    window.navigator.clipboard.writeText(path);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  // const formattedMessageId = !!currentUser ? `ModalInviteFriend.${getUserRole(currentUser)}Reward` : `ModalInviteFriend.reward`;
  const createBankAccountLink = currentPage !== 'StripePayoutPage' ? (
    <NamedLink name="StripePayoutPage">
      <FormattedMessage id="ModalInviteFriend.payoutDetails" />
    </NamedLink>
  ) : (
    <FormattedMessage id="ModalInviteFriend.payoutDetails" />
  );
  const needBankAccount = (
    <p className={css.blueDescription}>
      <FormattedMessage id="ModalInviteFriend.needBankAccount" values={{ details: createBankAccountLink }} />
    </p>
  )

  const payoutDetailsLink = (
    <NamedLink name="StripePayoutPage">
      <FormattedMessage id="ModalInviteFriend.payoutDetails" />
    </NamedLink>
  )

  return (
    <div className={css.root}>
      <h2 className={css.title}>
        <FormattedMessage id="ModalInviteFriend.title" />
      </h2>
      <h3 className={css.subTitle}>
        <FormattedMessage id="ModalInviteFriend.subTitle" />
      </h3>

      <ul className={css.yellowDescription}>
        <li>
          <FormattedMessage
            id="ModalInviteFriend.rewardDescriptionTeacher"
            values={{ price: <b>${REACT_APP_REWARD_FOR_INVITATION_TEACHER / CENTS}</b> }}
          />
        </li>
        <li>
          <FormattedMessage
            id="ModalInviteFriend.rewardDescriptionParent"
            values={{ price: <b>${REACT_APP_REWARD_FOR_INVITATION_PARENT / CENTS}</b> }}
          />
        </li>
        <li>
          <FormattedMessage
            id="ModalInviteFriend.rewardDescriptionSchoolAdmin"
            values={{ price: <b>${REACT_APP_REWARD_FOR_INVITATION_SCHOOL_ADMINISTRATOR / CENTS}</b> }}
          />
        </li>
      </ul>

      {/*<p className={css.blueDescription}>*/}
      {/*  {isTeacherRole(currentUser) && <FormattedMessage id="ModalInviteFriend.youWillReceiveTeacher" values={{ price: <b>${REACT_APP_REWARD_FOR_INVITATION_TEACHER / CENTS}</b> }} />}*/}
      {/*  {isSchoolAdminRole(currentUser) && <FormattedMessage id="ModalInviteFriend.youWillReceiveAdmin" values={{ price: <b>${REACT_APP_REWARD_FOR_INVITATION_TEACHER / CENTS}</b> }} />}*/}
      {/*  {isParentRole(currentUser) && <FormattedMessage id="ModalInviteFriend.youWillReceiveParent" values={{ price: <b>${REACT_APP_REWARD_FOR_INVITATION_TEACHER / CENTS}</b> }} />}*/}
      {/*</p>*/}

      {!isTeacherRole(currentUser) && currentUser?.stripeAccount && needBankAccount}

      {isTeacherRole(currentUser) && (
        <p className={css.rememberText}>
          <FormattedMessage
            id="ModalInviteFriend.rememberText"
            values={{
              redText: <span className={css.redText}><FormattedMessage id="ModalInviteFriend.rememberLabel" /></span>,
              payoutDetailsLink
            }}
          />
        </p>
      )}

      <div className={css.copyBlock}>
        <div className={css.copyBlockContent}>
          <label className={css.copyBlockLabel}>
            <FormattedMessage id="ModalInviteFriend.referralLink" />
          </label>
          <p className={css.copyBlockLinkValue}>
            <span>
              {path}
            </span>
          </p>
        </div>
        <Button type="button" onClick={copyLink} className={css.copyBlockButton}>
          {isCopied ? (
            <FormattedMessage id="ModalInviteFriend.copiedLink" />
          ) : (
            <FormattedMessage id="ModalInviteFriend.copyLink" />
          )}
        </Button>
      </div>
    </div>
  );
}

ModalInviteFriend.displayName = 'ModalMissingInformation';

export default ModalInviteFriend;
