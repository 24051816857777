import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import {
  listingsBulkUpdate,
  updateUser,
  updateUserDB,
  delayedEmailNotification,
  editInHubspot,
} from '../../util/api';
import config from '../../config';

const educations = [
  { key: 'phd', label: 'Phd' },
  { key: 'masters', label: 'Masters' },
  { key: 'bachelors', label: 'Bachelors' },
  { key: 'associates', label: 'Associates' },
];

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_ERROR';

export const TRANSACTION_EXIST = 'app/ProfileSettingsPage/TRANSACTION_EXIST';

// ================ Reducer ================ //

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  transactionsExist: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    case TRANSACTION_EXIST:
      return { ...state, transactionsExist: true };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

export const updateTransactionExist = () => ({
  type: TRANSACTION_EXIST
});

// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const updateProfile = (actionPayload, bulk, imageURL) => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const isOnboardingCompeted = actionPayload?.publicData?.onboardingProcessCompleted ? actionPayload?.publicData?.onboardingProcessCompleted : false;

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(async response => {
        if (parseInt(config.hubspotWorking)) {
          const { email, emailVerified, profile, createdAt } = response?.data?.data?.attributes;
          const { firstName, lastName, publicData, privateData } = profile;
          const { userRole, license, gradeLevels, experience, discountCode, state, education, subjects, hubspotId } = publicData;

          const data = {
            id: hubspotId,
            email,
            firstname: firstName,
            lastname: lastName,
            email_verified: emailVerified ? "Verified" : "Not verified",
            user_role: userRole,
            referral: privateData?.referral?.id ? "Yes" : "No",
            license: license ? "Yes" : "No",
            allcourse_user_since: new Date(createdAt).setUTCHours(0,0,0,0)
          }

          if (!!subjects?.length) {
            const subjectsArr = subjects.map(s => config.custom.areasCertifiedOptions.find(i => i.key === s)?.label);
            data.subject = subjectsArr.join(";");
          }
          if (!!state?.length) {
            const states = state.map(s => config.custom.stateOptions.find(i => i.key === s)?.label);
            data.state = states.join(",");
            data.states = states.join(";");
          }
          if (!!gradeLevels?.length) data.grade_level = gradeLevels.join(";");
          if (!!education?.length) data.level_of_education = educations.find(i => i.key === education[0])?.label;
          if (!!experience?.length) data.experience = experience[0];
          if (!!discountCode) data.discount_code = discountCode;

          await editInHubspot(data);
        }
        return response;
      })
      .then(async response => {
        if (actionPayload?.privateData?.referral) {
          await updateUser({ userId: actionPayload.privateData.referral.id, refId: response?.data?.data?.id?.uuid });
        }
        return response;
      })
      .then(async response => {
        if (isOnboardingCompeted) await delayedEmailNotification({userId: response?.data?.data?.id?.uuid});
        return response;
      })
      .then(async response => {
        const { id, attributes } = response.data.data;

        await updateUserDB({
          flexId: id.uuid,
          firstName: attributes.profile.firstName,
          lastName: attributes.profile.lastName,
          imageURL
        });

        return response;
      })
      .then(async response => {
        if (bulk) await listingsBulkUpdate({ id: response?.data?.data?.id?.uuid });
        return response;
      })
      .then(response => {
        dispatch(updateProfileSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};

export const transactionExist = () => {
  return (dispatch, getState, sdk) => {
    const queryParams = { per_page: 1 };

    return sdk.transactions.query(queryParams)
      .then(response => {
        if (!!response?.data?.data?.length) dispatch(updateTransactionExist());
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
}
