// These helpers are calling FTW's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';
import Decimal from 'decimal.js';
import axios from 'axios';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return typeof window !== 'undefined' ? `${window.location.origin}` : '';
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const request = (method, path, body) => {
  if (typeof window === 'undefined'){
    return Promise.resolve(null);
  }

  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };
  if (method !== "GET") {
    options.body = serialize(body);
  }
  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const post = request.bind(null, 'POST');
const get = request.bind(null, 'GET');

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Flex with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const updateUser = body => {
  return post('/api/users/update', body);
};

export const updateUserProfile = body => {
  return post('/api/users/update-user-profile', body);
};

export const newUser = body => {
  return post('/api/users/new', body);
};

export const updateUserDB = body => {
  return post('/api/users/update-db', body);
};

export const getFiles = listingId => {
  return get(`/api/files/${listingId}`);
};

export async function uploadFiles(data) {
  const options = {
    baseURL: apiBaseUrl(),
    method: 'post',
    url: `${apiBaseUrl()}/api/files`,
    headers: { 'Content-Type': 'application/x-www-urlencoded' },
    data,
  }

  return await axios(options).catch(e => console.log(e));
}

export async function deleteFile(key) {
  const options = {
    baseURL: apiBaseUrl(),
    method: 'delete',
    url: `${apiBaseUrl()}/api/files/${key}`,
    headers: {
      'Content-Type': 'application/json'
    },
  }
  return await axios(options).catch(e => console.log(e));
}

export const sendMail = body => post('/api/emails/contact-us', body);
export const getNotified = body => post('/api/emails/get-notified', body);
export const sendReadyToLearnMore = body => post('/api/emails/ready-to-learn-more', body);

export const delayedEmailNotification = body => {
  return post('/api/emails/delayed', body);
};

export async function uploadSubmitApplication(data) {
  const options = {
    baseURL: apiBaseUrl(),
    method: 'post',
    url: `${apiBaseUrl()}/api/emails/submit-application`,
    headers: { 'Content-Type': 'application/x-www-urlencoded' },
    data,
  }

  return await axios(options).catch(e => console.log(e));
}

export const searchByUser = value => {
  return post('/api/users', value);
}

export const generateCustomId = () => {
  return get('/api/users/generate-custom-id');
}

export const getUserByCustomId = customId => {
  return get(`/api/users/get-by-custom-id/${customId}`);
}

export const getUserById = id => {
  return get(`/api/users/${id}`);
}

export const signups = value => {
  return get(`/api/dashboard/signups?${value}`, value);
}

export const signupsByState = (value) => {
  return get(`/api/dashboard/signups-by-state?${value}`, value);
}

export const signupsBySelectedState = value => {
  return get(`/api/dashboard/signups-by-selected-state?${value}`, value);
}

export const referrals = value => {
  return value ? get(`/api/dashboard/referrals?${value}`, value) : get('/api/dashboard/referrals');
}

export const referralsPerState = value => {
  return get(`/api/dashboard/referrals/per-state?${value}`, value);
}

export const referralsPerYear = value => {
  return get(`/api/dashboard/referrals/per-year?${value}`, value);
}

export const savedProviderStripeAccount = body => {
  return post('/api/users/stripe-account-saved', body)
}

export const updateUserRating = params => {
  return post('/api/users/update-rating', params);
}

export const saveTransaction = body => {
  return post('/api/transactions/save', body);
};

export const cancelTransaction = body => {
  return post('/api/transactions/cancel', body);
};

export const queryAllTransactionsWithReview = body => {
  return get('/api/transactions/query-all-transactions-with-review', body);
};

export const updateTransactionMetadata = body => {
  return post('/api/transactions/update-transaction-metadata', body);
};

export const chargeCustomer = body => {
  return post('/api/transactions/charge', body)
}

export const refundCustomer = body => {
  return post('/api/transactions/refund', body)
}

export const jobRefund = body => {
  return post('/api/transactions/job-refund', body)
}

export const listingsBulkUpdate = body => {
  return post('/api/listings/bulk_update', body);
};

export const customUpdateListing = body => {
  return post('/api/listings/update-listing', body);
};

export const publish = body => {
  return post('/api/listings/publish', body);
};

export const generateListingCustomId = () => {
  return get('/api/listings/generate-custom-id');
}

export const getListingByCustomId = customId => {
  return get(`/api/listings/get-by-custom-id/${customId}`);
}

export const newListing = body => {
  return post('/api/listings/new', body);
};

export const newConversation = body => {
  return post('/api/conversations/new', body);
};

export const getConversation = body => {
  const conversationId = body.conversationId;
  const currentUserId = body.currentUserId;
  return get(`/api/conversations/${conversationId}?currentUserId=${currentUserId}`);
};

export const getConversations = body => {
  const userId = body.userId;
  return get(`/api/conversations/user/${userId}`);
};

export const newMessage = body => {
  return post('/api/message/new', body);
};

export const addToHubspot = body => {
  return post('/api/users/add-to-hubspot', body);
};

export const editInHubspot = body => {
  return post('/api/users/edit-in-hubspot', body);
};

export const getJobs = params => {
  return get(`/api/jobs?${params}`, params);
};

export const getOrCreateHiddenJob = authorId => {
  return get(`/api/jobs/hidden/${authorId}`);
};

export const getContentfulPage = (contentType, slug) => {
  return get(`/api/contentful/${contentType}/${slug}`);
};
