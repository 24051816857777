import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import moment from 'moment';
import config from '../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_UNITS, isTeacherRole, isSchoolAdminRole, isParentRole,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  ModalInMobile,
  Button,
  AvatarSmall,
  PrimaryButton,
  Form,
  SectionAdditionallOrdersInfoMaybe,
  Modal
} from '../../components';

import BookingDatesForm from './BookingDatesForm/BookingDatesForm';
import ProductOrderForm from './ProductOrderForm/ProductOrderForm';
import OrderParamsForm from './OrderParamsForm/OrderParamsForm';
import css from './OrderPanel.module.css';
import { TRANSITION_REQUEST_NEGOTIATION, TRANSITION_ENQUIRE_JOB } from "../../util/transaction";
import { dateFromAPIToLocalNoon } from "../../util/dates";
import EnquiryForm from "../../containers/ListingPage/EnquiryForm/EnquiryForm";
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, new Money(price.amount, price.currency));
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    author,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    onContactJob,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    discount,
    showOrderParamsForm,
    onSubmitOrderNegotiations,
    negotiationParamsInProgress,
    negotiationParamsError,
    currentUser,
    isCourse,
    isJob,
    isJobEnquiryModalOpen,
    onCloseJobEnquiryModal,
    onSubmitJobEnquiry,
    transition,
    sendJobEnquiryError,
    sendJobEnquiryInProgress,
    showEnquiryModal,
    onSubmitJob,
    jobSetParamsInProgress,
    jobSetParamsError,
    transactionPage,
    declineEnquiryJobProps,
    handleGetStarted,
    customPeriods,
  } = props;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const isUnits = unitType === LINE_ITEM_UNITS;
  const shouldHaveBooking = isNightly || isDaily;

  const isTeacher = isTeacherRole(listing.author);
  const currentUserIsTeacher = isTeacherRole(currentUser);
  const isSchoolAdmin = isSchoolAdminRole(listing.author);
  const isParent = isParentRole(listing.author);


  const price = new Money(listing.attributes.price.amount, listing.attributes.price.currency);
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = shouldHaveBooking && hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isOrderOpen = !!parse(location.search).orderOpen;
  const { publicData } = listing.attributes;

  const maxPrice = publicData?.maxPrice;
  const seats = publicData?.seats;
  const weeks = publicData?.weeks;
  const period = publicData?.period;

  const scheduleType = publicData && publicData.scheduleType;
  const isContactToSchedule = scheduleType && scheduleType.key === 'contact_to_schedule';
  const maxSeats = publicData && publicData.maxSeats && publicData.maxSeats;
  const minSeats = publicData && publicData.minSeats && publicData.minSeats;
  const dates = publicData && publicData.dates;
  const endDate = dates && moment(dates.endDate).format('MM/DD/YYYY');

  const today = moment().format('MM/DD/YYYY');
  const courseIsEnded = endDate && !isContactToSchedule ? moment(endDate).isBefore(today) : isContactToSchedule ? false : true;

  const timeSlot = timeSlots && timeSlots.filter((slot) => {
      const formattedEnd = moment(dateFromAPIToLocalNoon(slot.attributes.end)).format('MM/DD/YYYY');
      return moment(formattedEnd).isSame(moment(endDate))
    }
  );

  const availableSeatsLeft = timeSlot && timeSlot[0] && timeSlot[0].attributes.seats;

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser(isContactToSchedule ? TRANSITION_REQUEST_NEGOTIATION : currentUser);
  };

  const onClickApplyJob = e => {
    e.preventDefault();
    onContactJob(TRANSITION_ENQUIRE_JOB);
  };


  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  // const currentStock = parseInt(maxSeats);
  const isOutOfStock = config.listingManagementType === 'stock' && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm =
    (config.listingManagementType === 'stock' && typeof currentStock === 'number') ||isJob;



  const pickupEnabled = false, shippingEnabled = false;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const unitTranslationKey = isNightly
    ? 'OrderPanel.perNight'
    : isDaily
    ? 'OrderPanel.perDay'
    : 'OrderPanel.perUnit';

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>

        {isCourse && <div className={css.orderHeading}>
          <h2 className={titleClasses}>{title}</h2>
          {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null}
        </div>}
        {isCourse ?
          (<p className={css.price}>{formatMoney(intl, price)} <span className={css.priceTip}>per seat</span></p>) :
          (<p className={css.price}><span className={css.priceTip}>up to</span> {`$${maxPrice/100}`}<span className={css.priceTip}> per hour</span></p>)
        }

        {seats && !isCourse && <p className={css.item}>Number of seats: <span className={css.subItem}>{seats}</span></p>}


        <ul className={css.list}>
          {minSeats && <li className={css.item}>Minimum Seats: <span className={css.subItem}>{minSeats}</span></li>}
          {maxSeats && <li className={css.item}>Maximum Seats: <span className={css.subItem}>{maxSeats}</span></li>}
        </ul>

        {isCourse && <div className={css.author}>
          <AvatarSmall user={author} className={css.providerAvatar}/>

          {isTeacher &&
          <FormattedMessage id="OrderPanel.soldByTeacher" values={{name: authorDisplayName}}/>}
          {isSchoolAdmin &&
          <FormattedMessage id="OrderPanel.soldByAdmin" values={{name: authorDisplayName}}/>}
          {isParent &&
          <FormattedMessage id="OrderPanel.soldByParent" values={{name: authorDisplayName}}/>}
        </div>}

        <SectionAdditionallOrdersInfoMaybe
          onManageDisableScrolling={onManageDisableScrolling}
          listing={listing}
          className={css.orderPanelInfo}
          isTeacher={currentUserIsTeacher}
          period={period}
          weeks={weeks}
          isCourse={isCourse}
          isJob={isJob}
          customPeriods={customPeriods}
        />


        {showBookingDatesForm && !showOrderParamsForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : showProductOrderForm && !showOrderParamsForm ? (
          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={onSubmit}
            price={price}
            currentStock={currentStock}
            pickupEnabled={pickupEnabled}
            shippingEnabled={shippingEnabled}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            onContactJob={onContactJob}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            discount={discount}
            timeSlots={timeSlots}
            endDate={endDate}
            courseIsEnded={courseIsEnded}
            isContactToSchedule={isContactToSchedule}
            currentUserIsTeacher={currentUserIsTeacher}
            isCourse={isCourse}
            isJob={isJob}
            currentUser={currentUser}
            showProductOrderForm={showProductOrderForm}
            transactionPage={transactionPage}
            handleGetStarted={handleGetStarted}
            location={location}
          />
        ) : showOrderParamsForm ? (
          <OrderParamsForm
            onSubmit={isJob ? onSubmitJob : onSubmitOrderNegotiations}
            listing={listing}
            isJob={isJob}
            negotiationParamsInProgress={negotiationParamsInProgress}
            negotiationParamsError={negotiationParamsError}
            jobSetParamsInProgress={jobSetParamsInProgress}
            jobSetParamsError={jobSetParamsError}
            declineEnquiryJobProps={declineEnquiryJobProps}
            currentUserIsTeacher={currentUserIsTeacher}
          />
        ) : null}
      </ModalInMobile>
      <div className={classNames(css.openOrderForm, {[css.openOrderFormUnlogged]: !currentUser})}>
        <div className={css.priceContainer}>
          {isJob && <span>up to</span>}
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

        {isClosed ? (
            <div className={css.closedListingButton}>
              <FormattedMessage id="OrderPanel.closedListingButtonText"/>
            </div>)
          : isContactToSchedule && !currentUserIsTeacher && !isJob ? (
              <PrimaryButton
                className={css.button}
                onClick={onClickContactUser}
                enforcePagePreloadFor="SignupPage"
              >
                <FormattedMessage id="ProductOrderForm.contactSeller"/>
              </PrimaryButton>)
          : !isJob && !isContactToSchedule && !currentUserIsTeacher && !courseIsEnded && availableSeatsLeft && availableSeatsLeft !== null ? (
            <Button
              rootClassName={css.orderButton}
              onClick={() => openOrderModal(isOwnListing, isClosed, history, location)}
              disabled={isOutOfStock}
            >
              {isOutOfStock ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock"/>
              ) : (
                <FormattedMessage id="OrderPanel.ctaButtonMessage"/>
              )}
            </Button>)
          : !transactionPage && isJob && currentUserIsTeacher ? (
            <PrimaryButton
              className={css.button}
              onClick={onClickApplyJob}
              enforcePagePreloadFor="SignupPage"
            >
              <FormattedMessage id="ProductOrderForm.applyJob"/>
            </PrimaryButton>)
          : (
            <Button
              rootClassName={css.orderButton}
              onClick={() => openOrderModal(isOwnListing, isClosed, history, location)}
              disabled={isOutOfStock}
            >
              <FormattedMessage id="OrderPanel.showDetails"/>
            </Button>)
        }

        {/*{!transactionPage && isJob && currentUserIsTeacher && <PrimaryButton*/}
        {/*  className={css.button}*/}
        {/*  onClick={onClickApplyJob}*/}
        {/*  enforcePagePreloadFor="SignupPage"*/}
        {/*>*/}
        {/*  <FormattedMessage id="ProductOrderForm.applyJob" />*/}

        {/*</PrimaryButton>}*/}

        {courseIsEnded && !isContactToSchedule  && isCourse &&  <PrimaryButton className={css.endButton} disabled={true}>
          <FormattedMessage id="ProductOrderForm.endButton"/>
        </PrimaryButton>}

        {(availableSeatsLeft === undefined || availableSeatsLeft === null) && !courseIsEnded && !isContactToSchedule ? <PrimaryButton className={css.endButton} disabled={true}>
          <FormattedMessage id="ProductOrderForm.soldButton"/>
        </PrimaryButton> : null}


        {showEnquiryModal && <Modal
          id="ListingPage.enquiryJob"
          contentClassName={css.enquiryModalContent}
          isOpen={isJobEnquiryModalOpen}
          onClose={onCloseJobEnquiryModal}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <EnquiryForm
            className={css.enquiryForm}
            submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
            listingTitle={title}
            authorDisplayName={authorDisplayName}
            sendEnquiryError={sendJobEnquiryError}
            onSubmit={onSubmitJobEnquiry}
            inProgress={sendJobEnquiryInProgress}
            transition={transition}
            isJob={isJob}
          />
        </Modal>}

      </div>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.lineItemUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.lineItemUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
