import { referrals, referralsPerState, referralsPerYear } from "../../util/api";

// ================ Action types ================ //

export const GET_REFERRALS_REQUEST = 'app/ReferralsAnalyticsPage/GET_REFERRALS_REQUEST';
export const GET_REFERRALS_SUCCESS = 'app/ReferralsAnalyticsPage/GET_REFERRALS_SUCCESS';
export const GET_REFERRALS_ERROR = 'app/ReferralsAnalyticsPage/GET_REFERRALS_ERROR';

export const GET_REFERRALS_WITH_SELECTED_STATE_REQUEST = 'app/ReferralsAnalyticsPage/GET_REFERRALS_WITH_SELECTED_STATE_REQUEST';
export const GET_REFERRALS_WITH_SELECTED_STATE_SUCCESS = 'app/ReferralsAnalyticsPage/GET_REFERRALS_WITH_SELECTED_STATE_SUCCESS';
export const GET_REFERRALS_WITH_SELECTED_STATE_ERROR = 'app/ReferralsAnalyticsPage/GET_REFERRALS_WITH_SELECTED_STATE_ERROR';

export const GET_REFERRALS_WITH_SELECTED_YEAR_REQUEST = 'app/ReferralsAnalyticsPage/GET_REFERRALS_WITH_SELECTED_YEAR_REQUEST';
export const GET_REFERRALS_WITH_SELECTED_YEAR_SUCCESS = 'app/ReferralsAnalyticsPage/GET_REFERRALS_WITH_SELECTED_YEAR_SUCCESS';
export const GET_REFERRALS_WITH_SELECTED_YEAR_ERROR = 'app/ReferralsAnalyticsPage/GET_REFERRALS_WITH_SELECTED_YEAR_ERROR';

// ================ Reducer ================ //

const initialState = {
  getReferralsInProgress: false,
  getReferralsError: null,
  referrals: [],

  getReferralsWithSelectedStateInProgress: false,
  getReferralsWithSelectedStateError: null,
  referralsWithSelectedState: [],

  getReferralsWithSelectedYearInProgress: false,
  getReferralsWithSelectedYearError: null,
  referralsWithSelectedYear: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_REFERRALS_REQUEST:
      return { ...state, getReferralsInProgress: true, referrals: [], getReferralsError: null };
    case GET_REFERRALS_SUCCESS:
      return { ...state, getReferralsInProgress: false, referrals: payload, getReferralsError: null };
    case GET_REFERRALS_ERROR:
      return { ...state, getReferralsInProgress: false, referrals: [], getReferralsError: payload };

    case GET_REFERRALS_WITH_SELECTED_STATE_REQUEST:
      return { ...state, getReferralsWithSelectedStateInProgress: true, referralsWithSelectedState: [], getReferralsWithSelectedStateError: null };
    case GET_REFERRALS_WITH_SELECTED_STATE_SUCCESS:
      return { ...state, getReferralsWithSelectedStateInProgress: false, referralsWithSelectedState: payload, getReferralsWithSelectedStateError: null };
    case GET_REFERRALS_WITH_SELECTED_STATE_ERROR:
      return { ...state, getReferralsWithSelectedStateInProgress: false, referralsWithSelectedState: [], getReferralsWithSelectedStateError: payload };

    case GET_REFERRALS_WITH_SELECTED_YEAR_REQUEST:
      return { ...state, getReferralsWithSelectedYearInProgress: true, referralsWithSelectedYear: [], getReferralsWithSelectedYearError: null };
    case GET_REFERRALS_WITH_SELECTED_YEAR_SUCCESS:
      return { ...state, getReferralsWithSelectedYearInProgress: false, referralsWithSelectedYear: payload, getReferralsWithSelectedYearError: null };
    case GET_REFERRALS_WITH_SELECTED_YEAR_ERROR:
      return { ...state, getReferralsWithSelectedYearInProgress: false, referralsWithSelectedYear: [], getReferralsWithSelectedYearError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const getReferralsRequest = () => ({ type: GET_REFERRALS_REQUEST });
const getReferralsSuccess = payload => ({ type: GET_REFERRALS_SUCCESS, payload});
const getReferralsError = e => ({ type: GET_REFERRALS_ERROR, payload: e});

const getReferralsWithSelectedStateRequest = () => ({ type: GET_REFERRALS_WITH_SELECTED_STATE_REQUEST });
const getReferralsWithSelectedStateSuccess = payload => ({ type: GET_REFERRALS_WITH_SELECTED_STATE_SUCCESS, payload});
const getReferralsWithSelectedStateError = e => ({ type: GET_REFERRALS_WITH_SELECTED_STATE_ERROR, payload: e});

const getReferralsWithSelectedYearRequest = () => ({ type: GET_REFERRALS_WITH_SELECTED_YEAR_REQUEST });
const getReferralsWithSelectedYearSuccess = payload => ({ type: GET_REFERRALS_WITH_SELECTED_YEAR_SUCCESS, payload});
const getReferralsWithSelectedYearError = e => ({ type: GET_REFERRALS_WITH_SELECTED_YEAR_ERROR, payload: e});

// ================ Thunks ================ //

export const getReferrals = params => (dispatch, getState, sdk) => {
  dispatch(getReferralsRequest());

  const searchParams = new URLSearchParams(params).toString();

  return referrals(searchParams)
    .then(response => dispatch(getReferralsSuccess(response)))
    .catch(e => dispatch(getReferralsError(e)));
};

export const getReferralsWithSelectedState = params => (dispatch, getState, sdk) => {
  dispatch(getReferralsWithSelectedStateRequest());

  const searchParams = new URLSearchParams(params).toString();

  return referralsPerState(searchParams)
    .then(response => dispatch(getReferralsWithSelectedStateSuccess(response?.referralsByMonth)))
    .catch(e => dispatch(getReferralsWithSelectedStateError(e)));
};

export const getReferralsWithSelectedYear = params => (dispatch, getState, sdk) => {
  dispatch(getReferralsWithSelectedYearRequest());

  const searchParams = new URLSearchParams(params).toString();

  return referralsPerYear(searchParams)
    .then(response => dispatch(getReferralsWithSelectedYearSuccess(response)))
    .catch(e => dispatch(getReferralsWithSelectedYearError(e)));
};

export const loadData = (params, search, location) => (dispatch, getState, sdk) => {
  const substringToRemove = '/dashboard/referrals/';
  const getUserRoleString = location.pathname.replace(substringToRemove, '');
  let userRole;

  if (getUserRoleString === 'teachers') userRole = 'teacher';
  if (getUserRoleString === 'parents') userRole = 'parent';
  if (getUserRoleString === 'school-admins') userRole = 'schoolAdministrator';

  const bodyParams = userRole ? { pub_userRole: userRole } : {};

  return Promise.all([
    dispatch(getReferrals(bodyParams)),
    dispatch(getReferralsWithSelectedState(bodyParams)),
    dispatch(getReferralsWithSelectedYear(bodyParams)),
  ]);
};
