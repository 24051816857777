import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes an order for a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';
export const TRANSITION_FREE_REQUEST_PAYMENT = 'transition/free-request-payment';
export const TRANSITION_REQUEST_NEGOTIATION = 'transition/request-negotiation';
export const TRANSITION_REQUEST_PAYMENT_AFTER_NEGOTIATION = 'transition/request-payment-after-negotiation';
export const TRANSITION_FREE_REQUEST_PAYMENT_AFTER_NEGOTIATION = 'transition/free-request-payment-after-negotiation';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
export const TRANSITION_ENQUIRE = 'transition/enquire';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';
export const TRANSITION_FREE_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/free-request-payment-after-enquiry';

// Jobs process transitions
export const TRANSITION_ENQUIRE_JOB = 'transition/enquire-job';
export const TRANSITION_DECLINE_ENQUIRY_JOB = 'transition/decline-enquiry-job';
export const TRANSITION_ACCEPT_ENQUIRY_JOB = 'transition/accept-enquiry-job';
export const TRANSITION_EXPIRE_ENQUIRY_JOB = 'transition/expire-enquiry-job';

export const TRANSITION_ACCEPT_JOB = 'transition/accept-job';
export const TRANSITION_DECLINE_JOB = 'transition/decline-job';
export const TRANSITION_ACCEPT_HIDDEN_JOB = 'transition/accept-hidden-job';
export const TRANSITION_DECLINE_HIDDEN_JOB = 'transition/decline-hidden-job';
export const TRANSITION_EXPIRE_JOB = 'transition/expire-acceptance-job';
export const TRANSITION_PAY_FOR_JOB = 'transition/pay-for-job';
export const TRANSITION_EXPIRE_JOB_PAYMENT = 'transition/expire-job-payment';
export const TRANSITION_JOB_COMPLETE_REQUEST = 'transition/job-complete-request';
export const TRANSITION_JOB_COMPLETE_EXPIRE_CONFIRMATION = 'transition/job-expire-confirmation';
export const TRANSITION_JOB_COMPLETE = 'transition/job-complete';
export const TRANSITION_JOB_COMPLETE_DECLINE = 'transition/job-complete-decline';
export const TRANSITION_REWORK_DONE_REQUEST = 'transition/rework-done-request';
export const TRANSITION_CONFIRM_JOB_PAYMENT = 'transition/confirm-job-payment';

export const TRANSITION_TEACHER_CANCEL_JOB = 'transition/teacher-cancel-job';
export const TRANSITION_ADMIN_CANCEL_JOB = 'transition/admin-cancel-job';
export const TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE = 'transition/school-admin-cancel-job-before-complete';
export const TRANSITION_ADMIN_CANCEL_JOB_BEFORE_COMPLETE = 'transition/admin-cancel-job-before-complete';
export const TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK = 'transition/school-admin-cancel-job-before-rework';
export const TRANSITION_ADMIN_CANCEL_JOB_BEFORE_REWORK = 'transition/admin-cancel-job-before-rework';
export const TRANSITION_SCHOOL_ADMIN_REFUND_INITIATED = 'transition/school-admin-refund-initiated';

export const TRANSITION_SET_PARAMS = 'transition/set-params';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';
export const TRANSITION_FREE_CONFIRM_PAYMENT = 'transition/free-confirm-payment';

// If the payment is not confirmed in the time limit set in transaction process (by default 15min)
// the transaction will expire automatically.
export const TRANSITION_EXPIRE_PAYMENT = 'transition/expire-payment';
export const TRANSITION_FREE_EXPIRE_PAYMENT = 'transition/free-expire-payment';
export const TRANSITION_EXPIRE_PAYMENT_AFTER_NEGOTIATION = 'transition/expire-payment-after-negotiation';
export const TRANSITION_ADMIN_EXPIRE_PAYMENT_AFTER_NEGOTIATION = 'transition/admin-expire-payment-after-negotiation';

// Provider can mark the product shipped/delivered
export const TRANSITION_MARK_DELIVERED = 'transition/mark-delivered';

export const TRANSITION_CANCEL_PROVIDER = 'transition/cancel-provider';
export const TRANSITION_FREE_CANCEL_PROVIDER = 'transition/free-cancel-provider';
export const TRANSITION_CANCEL_CUSTOMER = 'transition/cancel-customer';
export const TRANSITION_FREE_CANCEL_CUSTOMER = 'transition/free-cancel-customer';
export const TRANSITION_CANCEL_CUSTOMER_AFTER_START = 'transition/cancel-customer-after-start';
export const TRANSITION_FREE_CANCEL_CUSTOMER_AFTER_START = 'transition/free-cancel-customer-after-start';
export const TRANSITION_CANCEL_CUSTOMER_AFTER_START_WITHOUT_REFUND = 'transition/cancel-customer-after-start-without-refund';
export const TRANSITION_START = 'transition/start';
export const TRANSITION_COMPLETE = 'transition/complete';
export const TRANSITION_ADMIN_COMPLETE = 'transition/admin-complete';

// Customer can mark the product received (e.g. picked up from provider)
export const TRANSITION_MARK_RECEIVED_FROM_PURCHASED = 'transition/mark-received-from-purchased';

// Automatic cancellation happens if none marks the delivery happened
export const TRANSITION_AUTO_CANCEL = 'transition/auto-cancel';

// Operator can cancel the purchase before product has been marked as delivered / received
export const TRANSITION_CANCEL = 'transition/cancel';

// If provider has marked the product delivered (e.g. shipped),
// customer can then mark the product received
export const TRANSITION_MARK_RECEIVED = 'transition/mark-received';

// If customer doesn't mark the product received manually, it can happen automatically
export const TRANSITION_AUTO_MARK_RECEIVED = 'transition/auto-mark-received';

// When provider has marked the product delivered, customer can dispute the transaction
export const TRANSITION_DISPUTE = 'transition/dispute';

// If nothing is done to disputed transaction it ends up to Canceled state
export const TRANSITION_AUTO_CANCEL_FROM_DISPUTED = 'transition/auto-cancel-from-disputed';

// Operator can cancel disputed transaction manually
export const TRANSITION_CANCEL_FROM_DISPUTED = 'transition/cancel-from-disputed';

// Operator can mark the disputed transaction as received
export const TRANSITION_MARK_RECEIVED_FROM_DISPUTED = 'transition/mark-received-from-disputed';

// System moves transaction automatically from received state to complete state
// This makes it possible to to add notifications to that single transition.
export const TRANSITION_AUTO_COMPLETE = 'transition/auto-complete';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

export const TRANSITION_REVIEW_BY_CUSTOMER = 'transition/review-by-customer';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_ENQUIRY = 'enquiry';
const STATE_JOB_ENQUIRY = 'enquiry-job';
const STATE_JOB_ENQUIRY_DECLINED = 'declined-enquiry-job';
const STATE_JOB_ENQUIRY_ACCEPTED = 'pending-teacher-acceptance-job';
const STATE_JOB_ENQUIRY_EXPIRED = 'expired-enquiry-job';
const STATE_DECLINED_JOB = 'state/declined-job';
const STATE_JOB_STARTS = 'job-starts';
const STATE_JOB_AWAIT_CONFIRMATION = 'job-awaiting-confirmation';
const STATE_JOB_COMPLETED = 'job-completed';
const STATE_JOB_NEED_REWORK = 'job-need-rework';
const STATE_TEACHER_CANCELED_JOB = 'teacher-canceled-job';
const STATE_ADMIN_CANCELED_JOB = 'admin-canceled-job';
const STATE_SCHOOL_ADMIN_CANCELED_JOB = 'school-admin-canceled-job';
const STATE_SCHOOL_ADMIN_REFUNDED = 'refunded';
const STATE_NEGOTIATION = 'negotiation';
const STATE_WAITING_PAYMENT = 'waiting-payment';
const STATE_PAYMENT_JOB_EXPIRED = 'payment-job-expired';
const STATE_PENDING_PAYMENT = 'pending-payment';
const STATE_PAYMENT_EXPIRED = 'payment-expired';
const STATE_WAITING_PAYMENT_EXPIRED = 'waiting-payment-expired';
const STATE_PURCHASED = 'purchased';
const STATE_PREAUTHORIZED = 'preauthorized';
const STATE_IN_PROGRESS = 'in-progress';
const STATE_CANCELLED = 'cancelled';
const STATE_CANCELLED_AFTER_START = 'cancelled-after-start';
const STATE_DELIVERED = 'delivered';
const STATE_RECEIVED = 'received';
const STATE_DISPUTED = 'disputed';
const STATE_CANCELED = 'canceled';
const STATE_COMPLETED = 'completed';
const STATE_REVIEWED = 'reviewed';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';


/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'flex-default-process/release-1',
  // id: 'flex-default-process/release-2',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_ENQUIRE]: STATE_ENQUIRY,
        [TRANSITION_ENQUIRE_JOB]: STATE_JOB_ENQUIRY,
        [TRANSITION_ACCEPT_HIDDEN_JOB]: STATE_WAITING_PAYMENT,
        [TRANSITION_DECLINE_HIDDEN_JOB]: STATE_DECLINED_JOB,
        [TRANSITION_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
        [TRANSITION_FREE_REQUEST_PAYMENT]: STATE_PENDING_PAYMENT,
        [TRANSITION_REQUEST_NEGOTIATION]: STATE_NEGOTIATION,
      },
    },
    [STATE_JOB_ENQUIRY]: {
      on: {
        [TRANSITION_DECLINE_ENQUIRY_JOB]: STATE_JOB_ENQUIRY_DECLINED,
        [TRANSITION_ACCEPT_ENQUIRY_JOB]: STATE_JOB_ENQUIRY_ACCEPTED,
        [TRANSITION_EXPIRE_ENQUIRY_JOB]: STATE_JOB_ENQUIRY_EXPIRED,
      },
    },
    [STATE_JOB_ENQUIRY_ACCEPTED]: {
      on: {
        [TRANSITION_ACCEPT_JOB]: STATE_WAITING_PAYMENT,
        [TRANSITION_DECLINE_JOB]: STATE_DECLINED_JOB,
        [TRANSITION_EXPIRE_JOB]: STATE_DECLINED_JOB,
      },
    },

    [STATE_JOB_AWAIT_CONFIRMATION]: {
      on: {
        [TRANSITION_JOB_COMPLETE_EXPIRE_CONFIRMATION]: STATE_JOB_COMPLETED,
        [TRANSITION_JOB_COMPLETE]: STATE_JOB_COMPLETED,
        [TRANSITION_JOB_COMPLETE_DECLINE]: STATE_JOB_NEED_REWORK,
      },
    },
    [STATE_JOB_NEED_REWORK]: {
      on: {
        [TRANSITION_REWORK_DONE_REQUEST]: STATE_JOB_AWAIT_CONFIRMATION,
        [TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK]: STATE_SCHOOL_ADMIN_CANCELED_JOB,
        [TRANSITION_ADMIN_CANCEL_JOB_BEFORE_REWORK]: STATE_ADMIN_CANCELED_JOB,
      },
    },
    [STATE_SCHOOL_ADMIN_CANCELED_JOB]: {
      on: {
        [TRANSITION_SCHOOL_ADMIN_REFUND_INITIATED]: STATE_SCHOOL_ADMIN_REFUNDED,
      },
    },
    [STATE_JOB_COMPLETED]: {
      on: {
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIEWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIEWED,
      },
    },
    [STATE_ENQUIRY]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT,
        [TRANSITION_FREE_REQUEST_PAYMENT_AFTER_ENQUIRY]: STATE_PENDING_PAYMENT,
      },
    },
    [STATE_NEGOTIATION]: {
      on: {
        [TRANSITION_SET_PARAMS]: STATE_WAITING_PAYMENT,
      },
    },
    [STATE_WAITING_PAYMENT]: {
      on: {
        [TRANSITION_REQUEST_PAYMENT_AFTER_NEGOTIATION]: STATE_PENDING_PAYMENT,
        [TRANSITION_FREE_REQUEST_PAYMENT_AFTER_NEGOTIATION]: STATE_PENDING_PAYMENT,
        [TRANSITION_EXPIRE_PAYMENT_AFTER_NEGOTIATION]: STATE_WAITING_PAYMENT_EXPIRED,
        [TRANSITION_ADMIN_EXPIRE_PAYMENT_AFTER_NEGOTIATION]: STATE_WAITING_PAYMENT_EXPIRED,
        [TRANSITION_PAY_FOR_JOB]: STATE_PENDING_PAYMENT,
        [TRANSITION_EXPIRE_JOB_PAYMENT]: STATE_PAYMENT_JOB_EXPIRED,
      },
    },

    [STATE_PENDING_PAYMENT]: {
      on: {
        [TRANSITION_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_FREE_EXPIRE_PAYMENT]: STATE_PAYMENT_EXPIRED,
        [TRANSITION_CONFIRM_JOB_PAYMENT]: STATE_JOB_STARTS,
        [TRANSITION_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
        [TRANSITION_FREE_CONFIRM_PAYMENT]: STATE_PREAUTHORIZED,
        [TRANSITION_TEACHER_CANCEL_JOB]: STATE_TEACHER_CANCELED_JOB,
        [TRANSITION_ADMIN_CANCEL_JOB]: STATE_ADMIN_CANCELED_JOB,
      },
    },
    [STATE_JOB_STARTS]: {
      on: {
        [TRANSITION_JOB_COMPLETE_REQUEST]: STATE_JOB_AWAIT_CONFIRMATION,
        [TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE]: STATE_SCHOOL_ADMIN_CANCELED_JOB,
        [TRANSITION_ADMIN_CANCEL_JOB_BEFORE_COMPLETE]: STATE_ADMIN_CANCELED_JOB,
      },
    },

    [STATE_PAYMENT_EXPIRED]: {},
    [STATE_PREAUTHORIZED]: {
      on: {
        [TRANSITION_START]: STATE_IN_PROGRESS,
        [TRANSITION_CANCEL_PROVIDER]: STATE_CANCELLED,
        [TRANSITION_FREE_CANCEL_PROVIDER]: STATE_CANCELLED,
        [TRANSITION_CANCEL_CUSTOMER]: STATE_CANCELLED,
        [TRANSITION_FREE_CANCEL_CUSTOMER]: STATE_CANCELLED,
      },
    },

    [STATE_IN_PROGRESS]: {
      on: {
        [TRANSITION_COMPLETE]: STATE_COMPLETED,
        [TRANSITION_ADMIN_COMPLETE]: STATE_COMPLETED,
        [TRANSITION_CANCEL_CUSTOMER_AFTER_START]: STATE_CANCELLED_AFTER_START,
        [TRANSITION_FREE_CANCEL_CUSTOMER_AFTER_START]: STATE_CANCELLED_AFTER_START,
        [TRANSITION_CANCEL_CUSTOMER_AFTER_START_WITHOUT_REFUND]: STATE_CANCELLED_AFTER_START,
      },
    },

    [STATE_COMPLETED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIEWED,
        [TRANSITION_REVIEW_BY_CUSTOMER]: STATE_REVIEWED,
      },
    },

    [STATE_REVIEWED]: { type: 'final' },
    [STATE_JOB_ENQUIRY_DECLINED]: { type: 'final' },
    [STATE_SCHOOL_ADMIN_REFUNDED]: { type: 'final' },
    [STATE_JOB_ENQUIRY_EXPIRED]: { type: 'final' },
    [STATE_PAYMENT_JOB_EXPIRED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
const GLOBAL_TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key,
);

export const JOB_TRANSITIONS = [
  TRANSITION_ENQUIRE_JOB,
  TRANSITION_DECLINE_ENQUIRY_JOB,
  TRANSITION_ACCEPT_ENQUIRY_JOB,
  TRANSITION_EXPIRE_ENQUIRY_JOB,
  TRANSITION_ACCEPT_JOB,
  TRANSITION_DECLINE_JOB,
  TRANSITION_ACCEPT_HIDDEN_JOB,
  TRANSITION_DECLINE_HIDDEN_JOB,
  TRANSITION_EXPIRE_JOB,
  TRANSITION_JOB_COMPLETE_REQUEST,
  TRANSITION_JOB_COMPLETE,
  TRANSITION_JOB_COMPLETE_EXPIRE_CONFIRMATION,
  TRANSITION_JOB_COMPLETE_DECLINE,
  TRANSITION_REWORK_DONE_REQUEST,
  TRANSITION_TEACHER_CANCEL_JOB,
  TRANSITION_ADMIN_CANCEL_JOB,
  TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE,
  TRANSITION_ADMIN_CANCEL_JOB_BEFORE_COMPLETE,
  TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK,
  TRANSITION_ADMIN_CANCEL_JOB_BEFORE_REWORK,
  TRANSITION_EXPIRE_JOB_PAYMENT,
  TRANSITION_SCHOOL_ADMIN_REFUND_INITIATED,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_REVIEW_BY_CUSTOMER,
  TRANSITION_CONFIRM_JOB_PAYMENT
];

export const COURSE_TRANSITIONS = [
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_FREE_REQUEST_PAYMENT,
  TRANSITION_REQUEST_NEGOTIATION,
  TRANSITION_REQUEST_PAYMENT_AFTER_NEGOTIATION,
  TRANSITION_FREE_REQUEST_PAYMENT_AFTER_NEGOTIATION,
  TRANSITION_ENQUIRE,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_FREE_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_FREE_CONFIRM_PAYMENT,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_FREE_EXPIRE_PAYMENT,
  TRANSITION_EXPIRE_PAYMENT_AFTER_NEGOTIATION,
  TRANSITION_ADMIN_EXPIRE_PAYMENT_AFTER_NEGOTIATION,
  TRANSITION_MARK_DELIVERED,
  TRANSITION_CANCEL_PROVIDER,
  TRANSITION_FREE_CANCEL_PROVIDER,
  TRANSITION_CANCEL_CUSTOMER,
  TRANSITION_FREE_CANCEL_CUSTOMER,
  TRANSITION_CANCEL_CUSTOMER_AFTER_START,
  TRANSITION_FREE_CANCEL_CUSTOMER_AFTER_START,
  TRANSITION_CANCEL_CUSTOMER_AFTER_START_WITHOUT_REFUND,
  TRANSITION_START,
  TRANSITION_COMPLETE,
  TRANSITION_ADMIN_COMPLETE,
  TRANSITION_MARK_RECEIVED_FROM_PURCHASED,
  TRANSITION_AUTO_CANCEL,
  TRANSITION_CANCEL,
  TRANSITION_MARK_RECEIVED,
  TRANSITION_AUTO_MARK_RECEIVED,
  TRANSITION_DISPUTE,
  TRANSITION_AUTO_CANCEL_FROM_DISPUTED,
  TRANSITION_CANCEL_FROM_DISPUTED,
  TRANSITION_MARK_RECEIVED_FROM_DISPUTED,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_REVIEW_2_BY_PROVIDER,
  TRANSITION_REVIEW_1_BY_CUSTOMER,
  TRANSITION_REVIEW_2_BY_CUSTOMER,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_REVIEW_PERIOD,
  TRANSITION_REVIEW_BY_CUSTOMER,
];

export const TRANSITIONS = [...GLOBAL_TRANSITIONS, ...JOB_TRANSITIONS];

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline

const transitionsPurchased = getTransitionsToState(STATE_PURCHASED);
const transitionsForJobEnquiry = [TRANSITION_ENQUIRE_JOB]
const transitionsForJob = [TRANSITION_ACCEPT_ENQUIRY_JOB, TRANSITION_JOB_COMPLETE_REQUEST, TRANSITION_REWORK_DONE_REQUEST]



export const transitionsToRequested = [...transitionsPurchased, ...transitionsForJobEnquiry, ...transitionsForJob];
export const allTransitionsToRequested = TRANSITIONS;

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsEnquired = tx =>
  getTransitionsToState(STATE_ENQUIRY).includes(txLastTransition(tx));

export const txIsJobEnquired = tx =>
  getTransitionsToState(STATE_JOB_ENQUIRY).includes(txLastTransition(tx));

export const txIsJobEnquiryDeclined = tx =>
  getTransitionsToState(STATE_JOB_ENQUIRY_DECLINED).includes(txLastTransition(tx));

export const txIsJobEnquiryAccepted = tx =>
  getTransitionsToState(STATE_JOB_ENQUIRY_ACCEPTED).includes(txLastTransition(tx));

export const txIsJobEnquiryExpired = tx =>
  getTransitionsToState(STATE_JOB_ENQUIRY_EXPIRED).includes(txLastTransition(tx));

export const txIsJobDeclined = tx =>
  getTransitionsToState(STATE_DECLINED_JOB).includes(txLastTransition(tx));

export const txIsPayForJobExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_JOB_EXPIRED).includes(txLastTransition(tx));

export const txIsJobStarted = tx =>
  getTransitionsToState(STATE_JOB_STARTS).includes(txLastTransition(tx));

export const txIsTeacherCancelledJob = tx =>
  getTransitionsToState(STATE_TEACHER_CANCELED_JOB).includes(txLastTransition(tx));

export const txIsAdminCancelledJob = tx =>
  getTransitionsToState(STATE_ADMIN_CANCELED_JOB).includes(txLastTransition(tx));

export const txIsSchoolAdminCancelledJob = tx =>
  getTransitionsToState(STATE_SCHOOL_ADMIN_CANCELED_JOB).includes(txLastTransition(tx));

export const txIsSchoolAdminRefunded = tx =>
  getTransitionsToState(STATE_SCHOOL_ADMIN_REFUNDED).includes(txLastTransition(tx));


export const txIsJobAwaitConfirmation = tx =>
  getTransitionsToState(STATE_JOB_AWAIT_CONFIRMATION).includes(txLastTransition(tx));

export const txIsJobCompleted = tx =>
  getTransitionsToState(STATE_JOB_COMPLETED).includes(txLastTransition(tx));

export const txIsJobNeedsRework = tx =>
  getTransitionsToState(STATE_JOB_NEED_REWORK).includes(txLastTransition(tx));

export const txIsNegotiation = tx =>
  getTransitionsToState(STATE_NEGOTIATION).includes(txLastTransition(tx));

export const txIsWaitingPayment = tx =>
  getTransitionsToState(STATE_WAITING_PAYMENT).includes(txLastTransition(tx));

export const txIsWaitingPaymentExpired = tx =>
  getTransitionsToState(STATE_WAITING_PAYMENT_EXPIRED).includes(txLastTransition(tx));

export const txIsPaymentPending = tx =>
  getTransitionsToState(STATE_PENDING_PAYMENT).includes(txLastTransition(tx));

export const txIsPaymentExpired = tx =>
  getTransitionsToState(STATE_PAYMENT_EXPIRED).includes(txLastTransition(tx));

export const txIsPurchased = tx =>
  getTransitionsToState(STATE_PURCHASED).includes(txLastTransition(tx));

export const txIsPaid = tx =>
  getTransitionsToState(STATE_PREAUTHORIZED).includes(txLastTransition(tx));

export const txIsInProgress = tx =>
  getTransitionsToState(STATE_IN_PROGRESS).includes(txLastTransition(tx));

export const txIsCanceled = tx =>
  getTransitionsToState(STATE_CANCELLED).includes(txLastTransition(tx)) ||
  getTransitionsToState(STATE_CANCELLED_AFTER_START).includes(txLastTransition(tx));

export const txIsDelivered = tx =>
  getTransitionsToState(STATE_DELIVERED).includes(txLastTransition(tx));

export const txIsDisputed = tx =>
  getTransitionsToState(STATE_DISPUTED).includes(txLastTransition(tx));

export const txIsReceived = tx =>
  getTransitionsToState(STATE_RECEIVED).includes(txLastTransition(tx));

export const txIsCompleted = tx =>
  getTransitionsToState(STATE_COMPLETED).includes(txLastTransition(tx));

export const txIsReviewedByCustomer = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx));

export const txIsReviewedByProvider = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIEWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

// Helper function to check if the transaction has passed a certain state
export const txHasPassedPaymentPending = hasPassedStateFn(STATE_PENDING_PAYMENT);
export const txHasBeenReceived = hasPassedStateFn(STATE_RECEIVED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIEWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_ENQUIRE,
    TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_FREE_REQUEST_PAYMENT_AFTER_ENQUIRY,
    TRANSITION_ENQUIRE_JOB,
    TRANSITION_DECLINE_ENQUIRY_JOB,
    TRANSITION_ACCEPT_ENQUIRY_JOB,
    TRANSITION_EXPIRE_ENQUIRY_JOB,
    TRANSITION_ACCEPT_HIDDEN_JOB,
    TRANSITION_DECLINE_HIDDEN_JOB,
    TRANSITION_ACCEPT_JOB,
    TRANSITION_EXPIRE_JOB_PAYMENT,
    TRANSITION_DECLINE_JOB,
    TRANSITION_EXPIRE_JOB,
    TRANSITION_PAY_FOR_JOB,
    TRANSITION_JOB_COMPLETE,
    TRANSITION_JOB_COMPLETE_REQUEST,
    TRANSITION_JOB_COMPLETE_EXPIRE_CONFIRMATION,
    TRANSITION_JOB_COMPLETE_DECLINE,
    TRANSITION_TEACHER_CANCEL_JOB,
    TRANSITION_ADMIN_CANCEL_JOB,
    TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE,
    TRANSITION_ADMIN_CANCEL_JOB_BEFORE_COMPLETE,
    TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK,
    TRANSITION_ADMIN_CANCEL_JOB_BEFORE_REWORK,
    TRANSITION_SCHOOL_ADMIN_REFUND_INITIATED,
    TRANSITION_REWORK_DONE_REQUEST,
    TRANSITION_CONFIRM_PAYMENT,
    TRANSITION_FREE_CONFIRM_PAYMENT,
    TRANSITION_AUTO_CANCEL,
    TRANSITION_CANCEL,
    TRANSITION_CANCEL_CUSTOMER,
    TRANSITION_FREE_CANCEL_CUSTOMER,
    TRANSITION_CANCEL_PROVIDER,
    TRANSITION_FREE_CANCEL_PROVIDER,
    TRANSITION_CANCEL_CUSTOMER_AFTER_START,
    TRANSITION_FREE_CANCEL_CUSTOMER_AFTER_START,
    TRANSITION_CANCEL_CUSTOMER_AFTER_START_WITHOUT_REFUND,
    TRANSITION_START,
    TRANSITION_EXPIRE_PAYMENT_AFTER_NEGOTIATION,
    TRANSITION_ADMIN_EXPIRE_PAYMENT_AFTER_NEGOTIATION,
    TRANSITION_REQUEST_NEGOTIATION,
    TRANSITION_SET_PARAMS,
    TRANSITION_MARK_RECEIVED_FROM_PURCHASED,
    TRANSITION_MARK_DELIVERED,
    TRANSITION_DISPUTE,
    TRANSITION_MARK_RECEIVED,
    TRANSITION_COMPLETE,
    TRANSITION_ADMIN_COMPLETE,
    TRANSITION_AUTO_MARK_RECEIVED,
    TRANSITION_MARK_RECEIVED_FROM_DISPUTED,
    TRANSITION_AUTO_CANCEL_FROM_DISPUTED,
    TRANSITION_CANCEL_FROM_DISPUTED,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
    TRANSITION_REVIEW_BY_CUSTOMER,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER, TRANSITION_REVIEW_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [TRANSITION_REQUEST_PAYMENT, TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY, TRANSITION_REQUEST_PAYMENT_AFTER_NEGOTIATION, TRANSITION_FREE_REQUEST_PAYMENT_AFTER_NEGOTIATION, TRANSITION_FREE_REQUEST_PAYMENT, TRANSITION_ACCEPT_JOB, TRANSITION_ACCEPT_HIDDEN_JOB].includes(
    transition,
  );
};
export const isNegotiation = transaction => {
  return !!transaction?.attributes?.transitions.filter(t => t.transition === TRANSITION_REQUEST_NEGOTIATION).length;
};
