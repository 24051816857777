import React, { Component } from 'react';
import { array, arrayOf, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';

import { injectIntl, intlShape } from '../../../util/reactIntl';
import { parseSelectFilterOptions } from '../../../util/search';

import {FieldCheckbox, FieldRadioButton, Form} from '../../../components';

import FilterPlain from '../FilterPlain/FilterPlain';
import FilterPopup from '../FilterPopup/FilterPopup';

import css from './RadioButtonFilter.module.css';

// SelectMultipleFilter doesn't need array mutators since it doesn't require validation.
// TODO: Live edit didn't work with FieldCheckboxGroup
//       There's a mutation problem: formstate.dirty is not reliable with it.
const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options } = props;
  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckbox id={fieldId} name={name} label={option.label} value={option.key} />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamName) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const value = hasOptionsSelected && selectedOptions === 'Yes'
      ? 'true' : hasOptionsSelected && selectedOptions === 'No'
      ? 'false' : null;
  return { [queryParamName]: value };
};

class RadioButtonFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;

    this.state = {
      checked: null,
    }

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  componentDidMount() {
    const { initialValues, queryParamNames } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    const selectedOptions = hasInitialValues
      ? parseSelectFilterOptions(initialValues[queryParamName])
      : [];

    if(selectedOptions && selectedOptions[0] === 'true') this.setState({checked: 'Yes'})
    if(selectedOptions && selectedOptions[0] === 'false') this.setState({checked: 'No'})
  }


  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      schemaType,
      searchMode,
      intl,
      showAsPopup,
      ...rest
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    // Parse options from param strings like "has_all:a,b,c" or "a,b,c"
    const selectedOptions = hasInitialValues
      ? parseSelectFilterOptions(initialValues[queryParamName])
      : [];

    let checked = 'No';

    if(selectedOptions && selectedOptions[0] === 'true') checked = 'Yes';
    if(selectedOptions && selectedOptions[0] === 'false') checked = 'No';

    const labelForPopup = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilter.labelSelected' },
          { labelText: label, count: selectedOptions.length }
        )
      : label;

    const labelSelectionForPlain = hasInitialValues
      ? intl.formatMessage(
          { id: 'SelectMultipleFilterPlainForm.labelSelected' },
          { count: selectedOptions.length }
        )
      : '';

    const contentStyle = this.positionStyleForContent();

    // pass the initial values with the name key so that
    // they can be passed to the correct field
    const namedInitialValues = { [name]: selectedOptions };

    const handleSubmit = values => {
      const usedValue = values ? values[name] : values;
      onSubmit(format(usedValue, queryParamName, schemaType, searchMode));
    };


    return showAsPopup ? (
      <FilterPopup
        className={classes}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={labelForPopup}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <FieldRadioButton
          id={`${id}-option-id1`}
          name={name}
          label="Required"
          value="Yes"
          svgClassName={checked === 'Yes' ? css.checked : css.unChecked}
        />
        {/*<FieldRadioButton*/}
        {/*  id={`${id}-option-id2`}*/}
        {/*  name={name}*/}
        {/*  label="No License Necessary"*/}
        {/*  value="No"*/}
        {/*  svgClassName={this.state.checked === 'No' ? css.checked : css.unChecked}*/}
        {/*/>*/}
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={label}
        labelSelection={labelSelectionForPlain}
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        {...rest}
      >
        <FieldRadioButton
          id={`${id}-option-id1`}
          name={name}
          label="Licensed"
          value="Yes"
          svgClassName={checked === 'Yes' ? css.checked : css.unChecked}
        />
        {/*<FieldRadioButton*/}
        {/*  id={`${id}-option-id2`}*/}
        {/*  name={name}*/}
        {/*  label="No License Necessary"*/}
        {/*  value="No"*/}
        {/*  svgClassName={this.state.checked === 'No' ? css.checked : css.unChecked}*/}
        {/*/>*/}
      </FilterPlain>
    );
  }
}

RadioButtonFilter.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

RadioButtonFilter.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  name: string.isRequired,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSubmit: func.isRequired,
  options: array.isRequired,
  initialValues: object,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(RadioButtonFilter);
