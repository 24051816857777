import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfServiceContentEn = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Updated and Effective July 21st, 2022.</p>

      <h2>1. Introduction</h2>
      <p>
        AllCourse Inc. (“AllCourse,” “us,” or “we”) owns and operates the AllCourse® web and mobile-based platform (“AllCourse Platform” or “Platform”).
        The Platform helps teachers sell for-credit teaching services to school administrators, and for school administrators to find and solicit teachers
        to provide for-credit teaching services. By accessing or using the AllCourse Platform, you agree to be bound by these Terms of Use (these “Terms”).
        You represent that you are lawfully able to enter into contracts and agree to be bound by these Terms. If you have agreed to these Terms on behalf
        of your organization, you represent that you have the authority to bind that organization to these Terms.
      </p>
      <p>
        <b>
          IF YOU DO NOT AGREE WITH THESE TERMS, OR IF YOU DO NOT HAVE THE NECESSARY AUTHORITY, THEN YOU MAY NOT USE THE ALLCOURSE PLATFORM.
        </b>
      </p>

      <h2>
        2. The Platform and Permitted Users
      </h2>
      <p>
        The AllCourse Platform is solely for use by teachers (“Teachers”), school administrators responsible for fulfilling curriculums (School Admins”),
        and the parents or guardians of Students (“Parents”). Only Teachers, School Admins, and Parents who agree to the Terms (each, a “User” or “you”)
        may use the AllCourse Platform. In general, Teachers will post course listings that represent live, web-based, for-credit courses on the Plaform,
        and School Admins and Parents will be able to purchase seats in those courses on behalf of their Students. School Admins will also post course proposals
        that represent gaps in their curriculums they wish to fill, and Teachers will be able to apply to teach those proposed courses. Users may access and use
        the Platform through mobile software applications (“Software”) made available by AllCourse, or through third party browsers or similar applications.
        You may only use the Platform for purposes permitted by these Terms and in compliance with all applicable laws.
      </p>

      <h2>
        3. Intellectual Property
      </h2>
      <p>
        By agreeing to these Terms, you have the limited right to use the Platform, subject to all of these Terms. These Terms do not transfer or convey any
        rights of ownership in or related to the AllCourse Platform, or intellectual property rights owned by AllCourse to you. The AllCourse Platform,
        including all trademarks, service marks and logos, is owned by or licensed to AllCourse and it is protected by copyright and other intellectual
        property rights. You have no rights to transfer or reproduce the AllCourse Platform, or to prepare any derivative works with respect to, or to
        disclose confidential information pertaining to, the Platform. You may not modify, copy, distribute, broadcast, transmit, reproduce, publish,
        license, transfer, sell, scrape, mirror, frame, or otherwise use the Platform or any information or material obtained from or through the AllCourse
        Platform. In order to use the Platform, you will need to accept the End User License Agreement terms and conditions (“EULA”) for any Software needed
        to use the Platform and for any other Software that you may choose to use with the Platform. You must accept the EULAs for the Software before installing
        or using Software in connection with the Platform. The requirements and restrictions in these Terms apply to your use of Software for the purposes of the
        Platform regardless of whether such terms are included in the relevant EULA(s).
      </p>

      <h2>
        4. License for User Content
      </h2>
      <p>
        In order to allow AllCourse to provide the Platform, you hereby grant to AllCourse a limited, non-exclusive, sublicensable, worldwide, royalty-free
        right and license to (i) use the information, data, content, reviews, comments and other materials imported by you to the AllCourse Platform (“User Content”)
        solely for purposes of furnishing the services provided by the AllCourse Platform to you; (ii) use, copy, store, distribute, publicly perform and display,
        modify, and create derivative works (such as changes we make so that User Content works better with our Platform) from User Content as necessary to provide,
        improve and make the Platform available to you and other Users, including through any future media in which the Platform may be distributed; (iii) use and
        disclose metrics and analytics regarding the User Content in an aggregate or other non-personally identifiable manner (including, for use in improving the
        Platform or in marketing and business development purposes); (iv) use any User Content that has been de-identified for product development, research or
        other purposes in accordance with applicable laws; and (v) use for other purposes as permitted by the AllCourse Privacy Policy. You are solely responsible
        for obtaining all rights, permissions, and authorizations to provide the User Content for use as contemplated under this Section. You also warrant to
        AllCourse that you will not use the AllCourse Platform for any purpose that are unlawful, prohibited by any applicable regulation or are otherwise inconsistent
        with these Terms.
      </p>

      <h2>
        5. User Responsibilities
      </h2>
      <p>
        To use the AllCourse Platform, you must establish an account and provide information necessary to use the Platform. You are solely responsible for all
        activities that occur in connection with your account. You must maintain the confidentiality of your account authentication details.
        That means that you should not share your account credentials with anyone. Of course, you may not impersonate another User or provide false information
        to gain access to the AllCourse Platform. AllCourse may permit you to establish your account on the Platform through, or associate your account with,
        certain third party social networking or online services, such as Facebook and Google (each an “Integrated Service”). By registering for the AllCourse
        Platform using (or otherwise granting access to) an Integrated Service, you agree that AllCourse may access your account information from the Integrated
        Service, and you agree to all terms of the Integrated Service regarding your use of the AllCourse Platform through the Integrated Service.
        AllCourse does not control the practices of Integrated Services, and you are advised to read the privacy policy and terms and conditions of any
        Integrated Service that you use to understand their practices.
      </p>

      <p>
        <b>
          PLEASE NOTIFY US IMMEDIATELY OF ANY UNAUTHORIZED ACCESS OR USE OF YOUR ACCOUNT.
        </b>
      </p>

      <h2>
        6. Your Conduct
      </h2>
      <p>
        You agree that you will NOT (and will not allow any third party to) use the Platform to:
      </p>
      <ul>
        <li>import, post, email or otherwise transmit any User Content that is unlawful, harmful, threatening, intimidating, abusive, defamatory, obscene, libelous, invasive of another’s privacy, disrespectful, hateful, or racially, ethnically or otherwise objectionable;</li>
        <li>forge headers or otherwise manipulate identifiers in order to disguise the origin of any materials transmitted to or through the Platform;</li>
        <li>import, post, email or otherwise transmit any materials that you do not have the right to transmit under any law or under contractual relationships;</li>
        <li>import, post, email or otherwise transmit any material that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party;</li>
        <li>import, post, email or otherwise transmit any material that contains software viruses or worms, or any other computer code, files or programs designed to disable, interrupt, destroy, redirect, monitor another User’s usage, limit or otherwise inhibit the functionality of any computer software or hardware or telecommunications equipment;</li>
        <li>interfere with or disrupt the AllCourse Platform or servers or networks connected to the AllCourse Platform, or disobey any requirements, procedures, policies or regulations of networks connected to the AllCourse Platform;</li>
        <li>Plan or engage in violation of any applicable local, state, national or international law or regulation;</li>
        <li>Provide inaccurate information or impersonate another person or entity;</li>
      </ul>

      <h2>
        7. Fees
      </h2>
      <p>
        Certain services provided by AllCourse through the Platform are only available for a fee (“Paid Services”). For Paid Services, you agree to pay us the
        applicable fees and to additional terms relevant to the Paid Services. Failure to pay these fees will result in the termination of your Paid Services.
        Also, you agree that: We may store and continue billing your payment method (e.g. credit card), to avoid interruptions in your Paid Services and to use
        to pay other Paid Services you may buy. If you purchase a subscription, your payment method automatically will be charged at the start of each subscription
        period for the fees and taxes applicable to that period. To avoid future charges, cancel before the renewal date. There are no refunds for Paid Services,
        but you may terminate your use of the Platform, and the Paid Services, at any time. We may calculate taxes payable by you based on the billing information
        that you provide us at the time of purchase. All fees exclude any taxes, levies, or duties imposed by taxing authorities, and you are responsible for payment
        of all such taxes, levies, or duties. They will be assessed and added to the fees charged, as applicable. AllCourse uses a third-party payment processor to
        handle all payment transactions, and all monetary transactions made through your use of the Platform are subject to any fees and additional terms that the
        processor may impose as well.
      </p>

      <h2>
        8. Privacy
      </h2>
      <p>
        We are committed to maintaining your privacy, and maintain a AllCourse Website Privacy Policy, as well as the AllCourse Platform Privacy Policy for the Platform.
        All personally identifiable information you provide to us (“Personal Information”) is subject to the Privacy Policy, and acceptance of these Terms constitutes
        consent to our collection and use of Personal Information as described in the Privacy Policy. AllCourse reserves the right to take steps AllCourse believes
        are reasonably necessary or appropriate to enforce and/or verify compliance with any part of these Terms. You acknowledge and agree that AllCourse may,
        without liability to you, access, use, preserve or disclose your Account information and User Content to law enforcement authorities, government officials,
        or a third party, as AllCourse believes is reasonably necessary or appropriate, if legally required to do so or if AllCourse has a good faith belief that
        such access, use, disclosure, or preservation is reasonably necessary to: (a) comply with legal process or request; (b) enforce these Terms, including
        investigation of any potential violation thereof; (c) detect, prevent or otherwise address security, fraud or technical issues; or (d) protect the rights,
        property or safety of AllCourse, its Users, a third party, or the public as required or permitted by law.
      </p>

      <h2>
        9. Family Educational Rights and Privacy Act (“FERPA”)
      </h2>
      <p>
        If you are employed by a school or district subject to the Family Educational Rights and Privacy Act (“FERPA”), you agree to appoint AllCourse as
        a “school official” as that term is defined in FERPA and as interpreted by the Family Policy Compliance Office, and determine that AllCourse has
        a “legitimate educational interest” for the purpose of delivering the Platform’s services in accordance with these Terms. AllCourse agrees that
        it will be bound by all relevant provisions of FERPA, including operating under the direct control of your school or district with respect to handling
        of “personally identifiable information” from “education records,” as those terms are defined in FERPA (together, “PII”). AllCourse further agrees that
        PII will not be disclosed to third parties except as required to provide the Platform services to you contemplated in these Terms. Any third parties used
        will be bound to manage the PII in compliance with the AllCourse Platform Privacy Policy, security policies and all applicable laws, and to use the PII
        for the sole and limited purpose of providing the Platform to the User. AllCourse may use education records that have been de-identified for product
        development, research or other purposes permitted by applicable law (“de-Identified data”), including: To demonstrate the effectiveness of the
        AllCourse Platform, including in our marketing materials and To develop and improve our products. AllCourse agrees not to attempt to re-identify
        the de-Identified data and not to transfer the de-Identified data to a third-party unless that party agrees not to attempt re-identification.
      </p>

      <h2>
        10. Children's Online Privacy Protection Act (“COPPA”)
      </h2>
      <p>
        AllCourse complies with the Children's Online Privacy Protection Act (“COPPA”), and collects no information from Students in order to operate the AllCourse Platform.
      </p>

      <h2>
        11. Security
      </h2>
      <p>
        AllCourse implements and maintains commercially reasonable security practices appropriate to the nature of the Personal Information collected and maintained
        through the AllCourse Platform, in order to protect such information from unauthorized access, destruction, use, modification or disclosure.
        Such practices include, but are not limited to use of firewalls, encryption and authentication techniques. The Internet, however, is not perfectly secure,
        nor are all security risks reasonably foreseeable, and AllCourse will not be responsible for security incidents not reasonably within its control.
        You must promptly notify AllCourse in the event that you learn or believe that any person, or entity, has breached your security measures or has gained
        unauthorized access to: (a) your Personal Information; (b) any restricted areas of the Platform; or (c) AllCourse's conﬁdential information
        (collectively, “Information Security Breach”). In the event of an Information Security Breach, you will provide AllCourse with reasonable assistance
        and support to minimize the harm and secure the data at issue.
      </p>

      <h2>
        12. User Content
      </h2>
      <p>
        The Platform may contain areas in which you may post reviews or make comments. By using these areas, you acknowledge and agree that the User Content
        you provide in these areas may be available to other Users. AllCourse is not liable for any statements, representations or comments provided by its
        Users in any public forum. Although AllCourse has no obligation to screen, edit or monitor any of the comments posted to its Platform, AllCourse reserves
        the right to remove, edit or refuse to post such User Content in its sole discretion. The AllCourse Platform will provide access to User Content as
        indicated when you import it, and as provided in these Terms. Without further notice, however, work assigned by Teachers will be made available to
        the Teacher's designated Students. Students will be able to make work responses available to their Teachers, and the Students will be able to view their
        own progress information and history as provided through the Platform. Parents will have some visibility into Student-generated User Content as well.
        Teachers will be able to view the Student progress information and history for all applicable Students in their class or school. If You opt out or
        terminate your account, all personally identifiable information associated with that account will be deleted. You may also provide us with suggestions,
        comments or other feedback (“Feedback“) about our products and services, including, without limitation, the Platform. We may use Feedback for any purpose
        without obligation of any kind in connection with our business, including the enhancement of our products and the Platform.
      </p>

      <h2>
        13. Changes to the Platform or Terms
      </h2>
      <p>
        AllCourse may add, change, discontinue or remove any portion of the AllCourse Platform at any time, without notice. AllCourse may change these
        Terms at any time, however we will provide you with notice prior to making such changes. Your continued use of the
        AllCourse Platform constitutes your agreement to any updated terms. Should we make material changes to the Terms impacting the
        AllCourse Platform, we will provide you with notice prior to making such changes and request your consent in accordance with applicable law.
        The “last updated” date indicates the effective date of when these Terms were last revised.
      </p>

      <h2>
        14. Links
      </h2>
      <p>
        The AllCourse Platform may contain links to third party websites, resources, or content, including social networking websites, including,
        without limitation, those of the applicable Integrated Services. These links are provided for your convenience, and inclusion of links on
        the AllCourse Platform does not suggest an endorsement. We are not responsible for the contents or transmission of any linked site or resource,
        or for ensuring that the linked resources are error and virus free. Linked websites, resources, or content are subject to their own terms of
        use and privacy policies, and we encourage you to read them.
      </p>

      <h2>
        15. Indemnification
      </h2>
      <p>
        To the extent permissible by law, you will defend and indemnify AllCourse and hold it and its affiliates, officers, directors, managers,
        employees, agents, vendors, merchants sponsors, providers, and licensors harmless from any and all claims, actions, demands, proceedings,
        losses, deficiencies, damages, liabilities, costs, and expenses (including but not limited to reasonable attorneys' fees and all related costs and expenses)
        incurred by them as a result of any claim, judgment, or adjudication related to or arising directly or indirectly from any or all of the
        following: (i) your use of the AllCourse Platform; (ii) any information you submit, post, or transmit through the AllCourse Platform, including,
        without limitation, your User Content; (iii) breach of any of your obligations, representations, or warranties in these Terms; or (iv) your
        violation of any rights of another person.
      </p>

      <h2>
        16. DISCLAIMER OF WARRANTIES
      </h2>
      <p>
        THE AllCourse PLATFORM IS PROVIDED “AS IS” AND “AS-AVAILABLE,” WITH ALL FAULTS, AND WITHOUT WARRANTY OF ANY KIND. EXCEPT FOR ANY EXPRESS WARRANTY
        PROVIDED HEREIN, ALLCOURSE AND ITS LICENSORS AND SUPPLIERS DISCLAIM ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, QUALITY OF INFORMATION, OR TITLE/NON-INFRINGEMENT, AND ALL
        SUCH WARRANTIES ARE HEREBY SPECIFICALLY DISCLAIMED. ALLCOURSE ASSUMES NO RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS ON THE ALLCOURSE PLATFORM, ANY FAILURES,
        DELAYS OR INTERRUPTIONS IN THE PLATFORM'S ACCESSIBILITY, ANY LOSSES OR DAMAGES ARISING FROM THE USE OF THE PLATFORM, ANY CONDUCT BY OTHER USERS ON THE
        ALLCOURSE PLATFORM, OR UNAUTHORIZED ACCESS TO OR USE OF THE ALLCOURSE PLATFORM
      </p>

      <h2>
        17. LIMITATION OF LIABILITY
      </h2>
      <p>
        YOU UNDERSTAND AND AGREE THAT ALLCOURSE WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES,
        INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PRODUCTS, USE, DATA OR OTHER INTANGIBLE LOSSES, EVEN IF ALLCOURSE HAS BEEN ADVISED OF
        THE POSSIBILITIES OF THOSE DAMAGES, RESULTING FROM YOUR USE OR INABILITY TO USE THE ALLCOURSE PLATFORM, SERVICES, CONTENT OR SOFTWARE,
        THE COST OF OBTAINING SUBSTITUTE SERVICES RESULTING FROM ANY LOSS OF DATA, INFORMATION, ENTERED INTO THROUGH THE PLATFORM, OR STATEMENTS OR CONDUCT
        OF ANY THIRD PARTY, OR ANY OTHER MATTER RELATED TO THE PLATFORM, SERVICES, CONTENT OR SOFTWARE. YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE
        ALLCOURSE PLATFORM IS PREDICATED UPON YOUR WAIVER OF ANY RIGHT TO SUE ALLCOURSE OR ITS AFFILIATES DIRECTLY OR TO PARTICIPATE IN A CLASS ACTION
        SUIT FOR ANY LOSSES OR DAMAGES RESULTING FROM YOUR USE OF THE ALLCOURSE PLATFORM. CERTAIN STATE JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED
        WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR
        LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
      </p>

      <h2>
        18. NOTICES
      </h2>
      <p>
        We will provide you any notices regarding the AllCourse Platform by posting the notice on the Platform, as applicable, or by sending to
        you by email to the email address you maintain as part of your account.
      </p>

      <h2>
        19. APPLICABLE LAW; JURISDICTION AND VENUE
      </h2>
      <p>
        We control the AllCourse Platform from our offices within the United States. We make no representation that the AllCourse Platform is appropriate,
        legal or available for use in other locations. You may not use or export the Content in violation of United States export laws and regulations.
        Any claim relating to the AllCourse Platform will be governed by the laws of the State of New York, without reference to its choice of law provisions.
        If there is a dispute between you and us, you expressly agree that exclusive jurisdiction and venue reside in the state and federal courts located in New York, NY.
      </p>

      <h2>
        20. RELATIONSHIP OF THE PARTIES
      </h2>
      <p>
        These Terms will not be construed as creating any agency relationship, or a partnership, joint venture, ﬁduciary duty, or any other form of legal
        association between you and AllCourse, and you may not represent to the contrary, whether expressly, by implication, appearance or otherwise.
        Except as otherwise expressly provided in these Terms, these Terms are not for the beneﬁt of any third parties.
      </p>

      <h2>
        21. WAIVER; ASSIGNMENT
      </h2>
      <p>
        No delay or failure to take action under these Terms will constitute a waiver unless expressly waived in writing and signed by a duly authorized
        representative of AllCourse, and no single waiver will constitute a continuing or subsequent waiver. These Terms may not be assigned by you in
        whole or in part. Any assignment will be null and void.
      </p>

      <h2>
        22. FEDERAL GOVERNMENT END USERS
      </h2>
      <p>
        The Platform, Software, and related documentation are “Commercial Items,” as that term is deﬁned at 48 C.F.R. §2.101, consisting of
        “Commercial Computer Software” and “Commercial Computer Software Documentation”, as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R.
        §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial
        Computer Software and Commercial Computer Software Documentation are being licensed to U.S. Government end users (a) only as Commercial
        Items, and (b) with only those rights as are granted to all other end users pursuant to the terms and conditions herein. Unpublished-rights
        reserved under the copyright laws of the United States.
      </p>

      <h2>
        23. SEVERABILITY
      </h2>
      <p>
        If any of the part of these Terms are determined to be invalid or unenforceable pursuant to applicable law, they will be severable
        from the remainder of these Terms and will not cause the invalidity or unenforceability of the remainder of these Terms.
      </p>

      <h2>
        24. DIGITAL MILLENNIUM COPYRIGHT ACT
      </h2>
      <p>
        If you believe that materials hosted by AllCourse infringe your copyright, you (or your agent) may notice requesting that the materials be removed.
        Notice must be provided in writing and must include the following information: A signature of the person authorized to act on behalf of the owner of
        the copyright interest; Identification of the copyrighted work that you claim has been infringed The location of the work you claim is
        infringing (e.g., URL) or enough detail that we may find it; Your name, address, telephone number, and email address; A statement by you
        that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and A statement,
        made under penalty of perjury, that the information in your notice is accurate and that you are the copyright owner or authorized to act on
        the copyright owner's behalf. Notices should be sent to the AllCourse copyright agent at the street address set out below.
      </p>

      <h2>
        25. CONTACT INFORMATION
      </h2>
      <p>
        If you have any questions regarding these Terms, please contact us at:
        <br />
        AllCourse, Inc.
        <br />
        P.O. Box 164
        <br />
        Milford, NJ 08848
        <br />
        <a href="mailto:privacy@AllCourse.com">privacy@AllCourse.com</a> | phone <a href="tel:8568184162">(856) 818-4162</a>
      </p>


    </div>
  );
};

TermsOfServiceContentEn.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfServiceContentEn.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfServiceContentEn;
