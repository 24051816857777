import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { Form, Button, FieldTextInput } from '../../../components';

import css from './SelectMultipleWithInputFilter.module.css';


const OptionsSearchFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        placeholder,
        handleSubmit,
        onChange,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.optionsForm, className);

      return (
        <Form
          className={classes}
          onSubmit={handleSubmit}
        >
          <FormSpy onChange={onChange} />
          <FieldTextInput
            className={css.optionsInput}
            type="text"
            id='optionString'
            name='optionString'
            placeholder={placeholder}
          />
        </Form>
      );
    }}
  />
);

OptionsSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

OptionsSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,

};

const OptionsSearchForm = compose(injectIntl)(OptionsSearchFormComponent);
OptionsSearchForm.displayName = 'OptionsSearchForm';

export default OptionsSearchForm;
