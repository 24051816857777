import React, {useRef, useState, useLayoutEffect, useEffect} from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { IconCheckbox } from '../../../components/FieldCheckbox/FieldCheckbox';

import css from './StatesFilter.module.css';
import { FieldSelect2} from "../../../components";

import config from "../../../config";

const StatesPanel = props => {
  const {
    options,
    isPanelOpen,
    asideWidth,
    handleSubmit,
    selectedOptions,
    name,
    onTogglePanel,
    parentCallback,
  } = props;

  const [checked, setChecked] = useState([]);
  const filterListRef = useRef();

  useEffect(() => {
    const initialStates = config.custom.stateOptions.map(objFromA => {
      return selectedOptions.some(objFromB => {
        if(objFromB.key === objFromA.key) {
          return true
        }
      })
    })
    setChecked(initialStates)
  }, [checked && selectedOptions])

  const handleSubjectsClick = (index) => {
    checked[index] = !checked[index];
    setChecked([...checked]);
  }

  const handleSubjectItemClick = (e, item, index) => {
    e.preventDefault();
    e.stopPropagation();

    handleSubjectsClick(index);

    const itemObject1 =  config.custom.stateOptions.find(area => area.label === item.label);
    const itemObject =  { key: itemObject1.key, label: item, value: item, id: index, type: 'usState' };

    const hasItemObject = selectedOptions.some(o => o.key === itemObject.key);
    let updatedSelectedOptions = []
    if(hasItemObject) {
      updatedSelectedOptions = selectedOptions.filter(o => o.key !== itemObject.key)
    } else {
      updatedSelectedOptions = [...selectedOptions, itemObject]
    }

    const namedInitialValues = { [name]: updatedSelectedOptions };

    handleSubmit(namedInitialValues);
  }

  useEffect(() => {
    parentCallback(filterListRef.current?.clientHeight)
  }, [isPanelOpen])


  const innerWidth = typeof window !== 'undefined' && window.innerWidth;
  const isMobile = innerWidth < 768;
  const classes = isPanelOpen ? classNames(css.filterPanel, css.showPanel) : classNames(css.filterPanel, css.hidePanel);

  return (
    <div style={!isMobile ? {left: asideWidth + 'px', width:  innerWidth - asideWidth + 'px'} : {}} className={classes}>
      <div className={css.filterInner} ref={filterListRef}>
        <FieldSelect2
          id={`state`}
          name={`state`}
          className={css.select}
          label={''}
          placeholder={'Search states'}
          value={'selectedOption'}
          isSearchable={true}
          isClearable={false}
          isMulti={true}
          showMultiValues={true}
          showSearchIcon={false}
          options={config.custom.stateOptions}
        />

        <div className={css.titleSectionsHolder}>
          <span className={css.sectionsTitle} onClick={onTogglePanel}>Apply</span>
        </div>

        <div className={css.statesList}>
          {options.map((o, index) => {
            return <div className={css.statesItem}>
              <input
                id={o.id}
                type={'checkbox'}
                className={css.input}
              />
              <label
                onClick={event => handleSubjectItemClick(event, o, index)}
                htmlFor={o.id} className={css.label}>
            <span className={css.checkboxWrapper}>
              <IconCheckbox
                checkedClassName={checked[index] ? css.checkedSuccess : css.unChecked}
              />
            </span>
                <span className={classNames(css.text, css.textClassName || css.textRoot)}>{o.label}
              </span>
              </label>
            </div>

          })}
        </div>
      </div>


    </div>
  );
};

StatesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  isOpen: true,
};

StatesPanel.propTypes = {
  className: string,
  rootClassName: string,
  isOpen: bool,
};

export default StatesPanel;
