import React, { useRef } from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import moment from 'moment';
import config from '../../../config';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { numberAtLeast, required } from '../../../util/validators';
import { dateFromAPIToLocalNoon } from '../../../util/dates';
import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton, FieldCheckbox, NamedLink,
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';

import css from './ProductOrderForm.module.css';
import {
  TRANSITION_REQUEST_NEGOTIATION,
  TRANSITION_ENQUIRE_JOB
} from '../../../util/transaction';

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    hasMultipleDeliveryMethods,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    onContactUser,
    onContactJob,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    values,
    discount,
    isContactToSchedule,
    timeSlots,
    endDate,
    courseIsEnded,
    currentUserIsTeacher,
    isCourse,
    isJob,
    currentUser,
    transactionPage,
    handleGetStarted,
    location,
  } = formRenderProps;

  const discountStatus = currentUser?.attributes?.profile?.publicData?.discountStatus;

  const handleOnChange = formValues => {
    const { quantity: quantityRaw, customerDiscount } = formValues.values;
    const quantity = Number.parseInt(quantityRaw, 10);
    const isBrowser = typeof window !== 'undefined';


    if (isBrowser && quantity && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        orderData: {
          seats: quantity,
          units: 1,
          discount,
          ...(discountStatus && customerDiscount && customerDiscount[0] === 'true' && { customerDiscount: true }),
        },
        listingId,
        isOwnListing,
      });
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
    const { quantity } = values || {};
    if (!quantity || quantity < 1) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else {
      handleSubmit(e);
    }
  };

  const breakdownData = {};
  const showBreakdown =
    breakdownData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;
  const breakdown = showBreakdown ? (
    <div className={css.breakdownWrapper}>
      <h3 className={css.breakdownTitle}>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={lineItems}
      />
    </div>
  ) : null;

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser(isContactToSchedule ? TRANSITION_REQUEST_NEGOTIATION : currentUser);

  };

  const onClickApplyJob = e => {
    e.preventDefault();
    onContactJob(TRANSITION_ENQUIRE_JOB);
  };

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );

  const quantityRequiredMsg = intl.formatMessage({ id: 'ProductOrderForm.quantityRequired' });


  const timeSlot = timeSlots && timeSlots.filter((slot) => {
    const formattedEnd = moment(dateFromAPIToLocalNoon(slot.attributes.end)).format('MM/DD/YYYY');
      return moment(formattedEnd).isSame(moment(endDate))
    }
  );


  const availableSeatsLeft = timeSlot && timeSlot[0] && timeSlot[0].attributes.seats;

  const hasStock = currentStock && currentStock > 0;
  const quantities = availableSeatsLeft > 0 ? [...Array(availableSeatsLeft).keys()].map(i => i + 1) : [];
  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = typeof currentStock != null && currentStock === 1;

  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled = !hasStock;

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
      {!isContactToSchedule && isCourse && (
        <FieldSelect
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          disabled={!hasStock}
          label={intl.formatMessage({ id: 'ProductOrderForm.quantityLabel' })}
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'ProductOrderForm.selectQuantityOption' })}
          </option>
          {quantities.map(quantity => (
            <option key={quantity} value={quantity}>
              {intl.formatMessage({ id: 'ProductOrderForm.quantityOption' }, { quantity })}
            </option>
          ))}
        </FieldSelect>
      ) }

      {breakdown}

      {discountStatus && !isJob && !isContactToSchedule && !currentUserIsTeacher && <FieldCheckbox
        id="customerDiscount"
        className={css.checkbox}
        name="customerDiscount"
        label={'Apply the discount'}
        value="true"
      />}

      {isContactToSchedule && !currentUserIsTeacher && (
        <PrimaryButton
          className={css.button}
          onClick={onClickContactUser}
          enforcePagePreloadFor="SignupPage"
        >
          <FormattedMessage id="ProductOrderForm.contactSeller" />
        </PrimaryButton>
      )}

      {!transactionPage && isJob && currentUserIsTeacher && (
        <PrimaryButton
          className={css.button}
          onClick={onClickApplyJob}
          enforcePagePreloadFor="SignupPage"
        >
          <FormattedMessage id="ProductOrderForm.applyJob" />

        </PrimaryButton>
      )}

      {!currentUser && isJob && (<NamedLink name="SignupTeacherPage" to={{state: { from: `${location?.pathname}${location?.search}${location?.hash}`, fromPage: 'ListingPage' }}} className={css.button}>
        <FormattedMessage id="ProductOrderForm.applyJob" />
      </NamedLink>)}

      {!isContactToSchedule && !currentUserIsTeacher && !courseIsEnded && availableSeatsLeft && availableSeatsLeft !== null &&  <>
        <div className={css.submitButton}>
          <PrimaryButton className={css.button} type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
            {hasStock ? (
              <FormattedMessage id="ProductOrderForm.ctaButton" />
            ) : (
              <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
            )}
          </PrimaryButton>
        </div>
      </>}

      {courseIsEnded && isCourse && !isContactToSchedule &&  <PrimaryButton className={css.endButton} disabled={true}>
        <FormattedMessage id="ProductOrderForm.endButton"/>
      </PrimaryButton>}

      {(availableSeatsLeft === undefined || availableSeatsLeft === null) && !courseIsEnded && !isContactToSchedule ? <PrimaryButton className={css.endButton} disabled={true}>
        <FormattedMessage id="ProductOrderForm.soldButton"/>
      </PrimaryButton> : null}

    </Form>
  );
};

const ProductOrderForm = props => {
  const intl = useIntl();
  const {
    price,
    currentStock,
    pickupEnabled,
    shippingEnabled,
    discount,
    isContactToSchedule,
    timeSlots,
    endDate,
    courseIsEnded,
    handleGetStarted,
  } = props;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  // if (!pickupEnabled && !shippingEnabled) {
  //   return (
  //     <p className={css.error}>
  //       <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
  //     </p>
  //   );
  // }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }
  const hasOneItemLeft = currentStock && currentStock === 1;
  const quantityMaybe = hasOneItemLeft ? { quantity: '1' } : {};
  const singleDeliveryMethodAvailableMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
      ? { deliveryMethod: 'pickup' }
      : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = { ...quantityMaybe, ...singleDeliveryMethodAvailableMaybe };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      render={renderForm}
      discount={discount}
      timeSlots={timeSlots}
      endDate={endDate}
      courseIsEnded={courseIsEnded}
      isContactToSchedule={isContactToSchedule}
      handleGetStarted={handleGetStarted}
    />
  );
};

ProductOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  lineItems: null,
  fetchLineItemsError: null,
};

ProductOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
};

export default ProductOrderForm;
