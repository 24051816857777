import React, { useState, useEffect } from 'react';
import { any, bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import {
  propTypes,
  isTeacherRole,
  isSchoolAdminRole,
  isParentRole,
  getSuperAdminRole
} from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import TopbarSearchFormTeachers from '../TopbarSearchForm/TopbarSearchFormTeachers';
import MenuIcon from '../MenuIcon';

import css from './TopbarDesktop.module.css';

const InboxIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="28"
      fill="none"
      viewBox="0 0 40 28"
    >
      <path
        fill="#4A4A4A"
        d="M27.473 13.96l10.252-8.907v17.712L27.473 13.96zm-13.21 1.508l3.6 3.125c.565.48 1.302.77 2.107.77h.05c.807 0 1.543-.291 2.115-.775l-.005.003 3.6-3.125 10.943 9.399H3.325l10.938-9.397zM3.31 2.941h33.383L20.658 16.868a1.02 1.02 0 01-.633.22h-.045c-.23 0-.455-.078-.635-.222l.002.002L3.31 2.942zm-1.035 2.11l10.25 8.907-10.25 8.8V5.052zm36-4.068c-.4-.2-.87-.316-1.368-.316H3.098c-.483 0-.96.112-1.391.328l.018-.008A3.093 3.093 0 000 3.758v20.287a3.1 3.1 0 003.097 3.096h33.805a3.1 3.1 0 003.096-3.096V3.758A3.088 3.088 0 0038.283.992l-.018-.009h.01z"
      ></path>
    </svg>
  )
}

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    // onSearchSubmit,
    onSearchSubmitListings,
    onSearchSubmitTeachers,
    initialSearchFormValues,
    handleInviteFriendOpen,
    isJobSearch,
    newSearchDesign  = true,
    teacherTopbar = true,
    location,
    handleSignUpOpen,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const isTeacher = isTeacherRole(currentUser);
  const isSchoolAdmin = isSchoolAdminRole(currentUser);
  const isParent = isParentRole(currentUser);
  const isSuperAdmin = getSuperAdminRole(currentUser);

  const publicData = currentUser?.attributes?.profile?.publicData || {gradeLevels: [], subjects: [], state: []};
  const { gradeLevels = [], subjects = [], state = [] } = publicData;

  const searchParams = { pub_gradeLevels: 'has_any:' + gradeLevels.join(','), pub_subjects: 'has_any:' + subjects.join(','), pub_state: 'has_any:' + state.join(','), type: 'job' }
  const searchParamsUrl =  Object.entries(searchParams).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')

  const classes = classNames(rootClassName || css.root, className);

  const searchCourses = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={values => {
        return values.type === 'teacher' ? onSearchSubmitTeachers(values) : onSearchSubmitListings(values, values.type);
      }}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
      // searchPlaceholder={intl.formatMessage({ id: isJobSearch ? 'TopbarSearchForm.jobsPlaceholder' : 'TopbarSearchForm.coursesPlaceholder' })}
      searchPlaceholder={'Search'}
      newSearchDesign={newSearchDesign}
      location={location}
      isTeacher={isTeacher}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: isTeacher ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <InboxIcon />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };


  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="DashboardPage">
          {isSuperAdmin && (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('DashboardPage'))}
              name="DashboardPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.dashboardLink" />
            </NamedLink>
          )}
        </MenuItem>

        <MenuItem key="SearchPage">
          {isTeacher && (
            <NamedLink
              name="SearchPage"
              to={{ search: searchParamsUrl }}
              className={classNames(css.yourListingsLink, currentPageClass('SearchPage'))}
            >
              <FormattedMessage id="TopbarDesktop.suitableJob" />
            </NamedLink>
          )}
        </MenuItem>

        <MenuItem key="ManageListingsPage">
          {!!currentUser && (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              {isTeacher ? <FormattedMessage id="TopbarDesktop.yourListingsLink" /> : <FormattedMessage id="ManageListingsPage.yourJobs" />}
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const tabletMenu = !!currentUser && isTeacher && (
    <Menu>
      <MenuLabel className={css.tabletMenu} isOpenClassName={css.tabletMenuOpen}>
        <MenuIcon />
      </MenuLabel>
      <MenuContent className={css.tabletMenuContent} useDefaulStyles={false} >
        <MenuItem key="createListing">
          <NamedLink className={css.createListingLink} name="ReferralPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.refer" />
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="createCourse">
          <NamedLink className={css.createListingLink} name="NewListingPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createCourse" />
            </span>
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  )

  const referText = !isParentRole(currentUser) ? 'TopbarDesktop.referTeacher' : 'TopbarDesktop.referOther'

  const desktopNavLinks = (
    <div className={css.topbarNavLinksContainer}>
      {!currentUser && (
        <>
          <NamedLink name="OnlineTeachersForHirePage" className={css.createListingLink}>
            <FormattedMessage id="TopbarDesktop.hireTeacher" />
          </NamedLink>
          <NamedLink name="OnlineTeachingJobsPage" className={css.createListingLink}>
            <FormattedMessage id="TopbarDesktop.findJob"/>
          </NamedLink>
        </>
      )}

      {currentUser && (!isTeacherRole(currentUser) ? (
        <NamedLink name="SearchPage" to={{ search: 'keywordsTeachers=false&type=teacher' }} className={css.createListingLink}>
          <FormattedMessage id="TopbarDesktop.findTeachers" />
        </NamedLink>
      ) : (
        <NamedLink name="SearchPage" to={{ search: 'type=job' }} className={css.createListingLink}>
          <FormattedMessage id="TopbarDesktop.browseJob" />
        </NamedLink>
      ))}

      {currentUser && (!isTeacherRole(currentUser) ? (
        <NamedLink className={css.createListingLink} name="NewJobPage">
          <FormattedMessage id="TopbarDesktop.createJob" />
        </NamedLink>
      ) : (
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarDesktop.createCourse" />
        </NamedLink>
      ))}

      {currentUser && (
        <NamedLink className={css.createListingLink} name="ReferralPage">
          <FormattedMessage id={referText} />
        </NamedLink>
      )}

      <NamedLink name="ContactUsPage" className={classNames(css.createListingLink, css.createListingLinkRegular)}>
        <FormattedMessage id="TopbarDesktop.contactUs" />
      </NamedLink>
      <NamedLink name="AboutPage" className={classNames(css.createListingLink, css.createListingLinkRegular)}>
        <FormattedMessage id="TopbarDesktop.about" />
      </NamedLink>
    </div>
  )

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage" ariaLabel="Logo">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {desktopNavLinks}
      <div className={css.searchHolder}>{searchCourses}</div>
      {tabletMenu}
      {inboxLink}
      {profileMenu}
      {loginLink}
      {!authenticatedOnClientSide && (
        <span className={css.signupLink} onClick={() => handleSignUpOpen(true)}>
          <FormattedMessage id="TopbarDesktop.signup" />
        </span>)}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
