import pick from 'lodash/pick';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {queryOwnListings} from "../ManageListingsPage/ManageListingsPage.duck";
import { requestUpdateListing } from '../EditListingPage/EditListingPage.duck';
import {savedProviderStripeAccount} from "../../util/api";
import {storableError} from "../../util/errors";


// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/StripePayoutPage/SET_INITIAL_VALUES';

export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_ERROR';

export const SAVE_PROVIDER_STRIPE_ACCOUNT_REQUEST = 'app/StripePayoutPage/SAVE_PROVIDER_STRIPE_ACCOUNT_REQUEST';
export const SAVE_PROVIDER_STRIPE_ACCOUNT_SUCCESS = 'app/StripePayoutPage/SAVE_PROVIDER_STRIPE_ACCOUNT_SUCCESS';
export const SAVE_PROVIDER_STRIPE_ACCOUNT_ERROR = 'app/StripePayoutPage/SAVE_PROVIDER_STRIPE_ACCOUNT_ERROR';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,

  providerStripeAccountSaveInProgress: false,
  providerStripeAccountSaved: false,

  fromReturnURL: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };


    case SAVE_PROVIDER_STRIPE_ACCOUNT_REQUEST:
      return { ...state, providerStripeAccountSaveInProgress: true };
    case SAVE_PROVIDER_STRIPE_ACCOUNT_SUCCESS:
      return { ...state, providerStripeAccountSaveInProgress: false };
    case SAVE_PROVIDER_STRIPE_ACCOUNT_ERROR:
      return { ...state, providerStripeAccountSaveInProgress: false, providerStripeAccountSaved: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const savePayoutDetailsRequest = () => ({ type: SAVE_PAYOUT_DETAILS_REQUEST, });
export const savePayoutDetailsError = () => ({ type: SAVE_PAYOUT_DETAILS_ERROR, });
export const savePayoutDetailsSuccess = () => ({ type: SAVE_PAYOUT_DETAILS_SUCCESS, });

export const saveProviderStripeAccountRequest = () => ({ type: SAVE_PROVIDER_STRIPE_ACCOUNT_REQUEST, });
export const saveProviderStripeAccountError = () => ({ type: SAVE_PROVIDER_STRIPE_ACCOUNT_ERROR, });
export const saveProviderStripeAccountSuccess = () => ({ type: SAVE_PROVIDER_STRIPE_ACCOUNT_SUCCESS, });

// ================ Thunks ================ //

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());
  const currentUser = getState().user.currentUser;

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      dispatch(saveProviderStripeAccount(currentUser));
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};

export const saveProviderStripeAccount = (currentUser) => (dispatch, getState, sdk) => {
  dispatch(saveProviderStripeAccountRequest());

  const userId = currentUser.id.uuid;

  const bodyParams = {
    userId
  };

  return savedProviderStripeAccount(bodyParams)
    .then(response => {
      dispatch(saveProviderStripeAccountSuccess(true));
      return response;
    })
    .catch(e => {
      dispatch(saveProviderStripeAccountError(storableError(e)));
    });
};


export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());


  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    dispatch(saveProviderStripeAccount(currentUser));
    if (currentUser && currentUser.stripeAccount !== null) {
      dispatch(fetchStripeAccount()).then(() => {
        const stripeAccount = getState().stripeConnectAccount.stripeAccount;
        const stripeConnected = !!stripeAccount && !!stripeAccount.id;
        const stripeAccountData = stripeConnected ? stripeAccount.attributes.stripeAccountData : null;
        const hasRequirements = (stripeAccountData, requirementType) =>
          stripeAccountData != null &&
          stripeAccountData.requirements &&
          Array.isArray(stripeAccountData.requirements[requirementType]) &&
          stripeAccountData.requirements[requirementType].length > 0;

        const requirementsMissing =
          stripeAccount &&
          (hasRequirements(stripeAccountData, 'past_due') ||
            hasRequirements(stripeAccountData, 'currently_due'));

        if (!requirementsMissing) {
          dispatch(queryOwnListings({})).then(response => {
            response.data.data.length > 0 && response.data.data.map(listing => {
              return dispatch(requestUpdateListing('DETAILS', {
                publicData: {
                  isStripeAccount: true
                },
                id: listing.id
              }))
            })
          });
        }
      })
    }
    return response;
  });
};
