import React, { Component } from 'react';
// import scheduler from "dhtmlx-scheduler";
// import "dhtmlx-scheduler/codebase/dhtmlxscheduler.css";
import './dhtmlxscheduler_material.css';
import Button from '../Button/Button';


export default class Scheduler extends Component {
  componentDidMount() {
    if(typeof window === 'undefined') return;

    require('dhtmlx-scheduler');
    const scheduler = window.scheduler;
    scheduler.clearAll();

    scheduler.config.start_on_monday = false;
    // scheduler.config.hour_date="%G %A";
    // scheduler.config.hour_date = '%g %A';
    scheduler.config.hour_date="%h:%i %A";
    // scheduler.skin = 'material';

    scheduler.config.header = [
      // {html:"click me!", click:function(){alert("done!")}},
      // 'day',
      // 'week',
      // 'month',
      // 'date',
      // 'prev',
      // 'today',
      // 'next'
    ];

    const format = scheduler.date.date_to_str("%D");

    scheduler.templates.week_scale_date = function(date){
      return `<div class="wrapper"><div></div>${format(date)}</div>`;
    };
    scheduler.config.responsive_lightbox = true;
    scheduler.load("/data/events");

    scheduler.locale.labels.new_event = "Period";
    scheduler.templates.event_bar_text = function(start,end,event){
      return "Schedule";
    };
    scheduler.config.details_on_create=false;

    scheduler.config.dblclick_create = false;
    scheduler.config.edit_on_create = false;
    scheduler.config.details_on_dblclick = false;
    scheduler.config.details_on_create = false;
    scheduler.config.icons_select=["icon_delete"];

    scheduler.attachEvent("onDblClick", function(){ return false });

    scheduler.init("scheduler_here", new Date(2024,1,1), "week");
    const { events } = this.props;
    scheduler.parse(events);
  }

  render() {

    return (
      <div id="scheduler_here" style={{ width: "100%", height: "500px" }}></div>
    );
  }
}
