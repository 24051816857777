import React from 'react';

const CheckMarkIcon = props => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_952)">
      <path d="M18 0C8.0749 0 0 8.0749 0 18C0 27.9251 8.0749 36 18 36C27.9251 36 36 27.9251 36 18C36 8.0749 27.9251 0 18 0ZM14.7115 27.0329L7.73654 19.2816L9.79529 17.4288L14.6414 22.8133L26.1346 9.12635L28.2583 10.9038L14.7115 27.0329Z" fill="#EE4D68"/>
      </g>
      <defs>
      <clipPath id="clip0_1_952">
      <rect width="36" height="36" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
};

export default CheckMarkIcon;
