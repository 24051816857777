import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { randomImage } from '../../util/randomImage';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import {isTeacherRole, LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';
import ReactHtmlParser from 'react-html-parser';

import css from './JobCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });


export const JobCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, description = '' } = currentListing.attributes;
  const slug = `${createSlug(title)}-${publicData.customId}`;
  const author = ensureUser(listing.author);
  const updatedDescription = publicData?.descriptionHtml ? publicData.descriptionHtml : description;

  const displayName = author.attributes.profile.displayName;

  const searchParams = author?.attributes?.profile?.publicData?.searchParams;
  const lastName = author?.attributes?.profile?.publicData?.lastName ? author?.attributes?.profile?.publicData?.lastName : null;
  const firstName = author?.attributes?.profile?.publicData?.firstName ? author?.attributes?.profile?.publicData?.firstName : null;
  const authorName =
    !lastName && !firstName && searchParams?.[0] && searchParams?.[1]
      ? (searchParams?.[0] + " " + searchParams?.[1])
      : lastName && firstName ? firstName + " " + lastName
      : displayName;

  const { formattedPrice, priceTitle } = priceData(price, intl);


  // const minPrice = publicData?.minPrice;
  const license = publicData?.license;
  const licenseState = publicData?.state;
  const maxPrice = publicData?.maxPrice;
  const seats = publicData?.seats;
  const weeks = publicData?.weeks;
  const period = publicData?.period;

  const previewGradeLevels = config.custom.gradeLevelsOptions.filter(objFromA => (publicData?.gradeLevels?.find(objFromB => (objFromA.key === objFromB))));
  const renderGradeLevels = previewGradeLevels?.map(item => <span className={css.itemSpan}>{item.value}</span>);

  const previewAreasCertified = config.custom.areasCertifiedOptions.filter(objFromA => (publicData?.areasCertified?.find(objFromB => (objFromA.key === objFromB))));
// areas filtered by parent category
  const getAreaCertifiedByParent = previewAreasCertified?.map((area) => {
    const parent = area.parent ? area.parent : 'Other';
    return { [parent]: [area.label] }
  });

  const associatedAreaCertifiedByParent = getAreaCertifiedByParent?.reduce((acc, curr) => {
    const key = Object.keys(curr)[0]
    const found = acc.find(i => i[key])
    if (!found) { acc.push(curr) }
    else { found[key] = [ ...found[key], ...curr[key] ]}
    return acc;
  }, []);

  const renderAreaCertified = (
    <span className={css.subItem}>{
      associatedAreaCertifiedByParent?.map(item => {
        return Object.entries(item).map(([key, value], i) => {
          return (
            <span className={css.itemParent} key={i}>
              <span className={css.itemParentValue}>{key} </span>
              ({value.map(v => <span className={css.itemSpan}>{v}</span>)})
            </span>
          )
        })
      })}
    </span>
  );


  return (
    <NamedLink className={classes} name="ListingJobPage" params={{ slug }}>
      <div className={css.info}>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            <p className={css.price}><span className={css.priceSeat}>up to &nbsp; </span> {`$${maxPrice/100}`}<span className={css.priceSeat}> per hour</span></p>

          </div>

        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>

          <div className={css.description}>
            {ReactHtmlParser(updatedDescription)}
          </div>

          <div className={css.separator}></div>

          {license && <div className={css.contentLine}><span
            className={css.contentLineTitle}>State: </span>{licenseState[0]}</div>}
          <div className={css.contentLine}><span className={css.contentLineTitle}>Subject Areas: </span>{renderAreaCertified}</div>
          <div className={css.contentLine}><span className={css.contentLineTitle}>Grade levels: </span>{renderGradeLevels}</div>
          <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.author" values={{ authorName }} />
          </div>

        </div>
      </div>
    </NamedLink>
  );
};

JobCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

JobCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(JobCardComponent);
