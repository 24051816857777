
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  searchByUser,
  queryAllTransactionsWithReview,
  getContentfulPage,
  sendMail, getNotified,
} from '../../util/api';
import {denormalisedEntities, denormalisedResponseEntities, updatedEntities} from '../../util/data';
import {convertUnitToSubUnit, unitDivisor} from "../../util/currency";
import config from "../../config";
import {getExclusiveEndDate, parseDateFromISO8601} from "../../util/dates";
import {addMarketplaceEntities} from "../../ducks/marketplaceData.duck";
import {createImageVariantConfig} from "../../util/sdkLoader";
import {isOriginInUse} from "../../util/search";
import {
  updateProfileError,
  updateTransactionExist
} from "../ProfileSettingsPage/ProfileSettingsPage.duck";

import { TRANSITION_REVIEW_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_PROVIDER } from '../../util/transaction';
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import {
  CONTACT_US_ERROR,
  CONTACT_US_REQUEST, CONTACT_US_SUCCESS,
  contactUsError,
  contactUsRequest,
  contactUsSuccess,
} from '../../ducks/ContactUs.duck';


// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 25;
const RESULT_JOBS = 4;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

// ================ Action types ================ //


export const SEARCH_TEACHERS_REQUEST = 'app/OnlineTeachingJobsPage/SEARCH_TEACHERS_REQUEST';
export const SEARCH_TEACHERS_SUCCESS = 'app/OnlineTeachingJobsPage/SEARCH_TEACHERS_SUCCESS';
export const SEARCH_TEACHERS_ERROR = 'app/OnlineTeachingJobsPage/SEARCH_TEACHERS_ERROR';

export const SEARCH_LISTINGS_REQUEST = 'app/OnlineTeachingJobsPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/OnlineTeachingJobsPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/OnlineTeachingJobsPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_JOBS_REQUEST = 'app/OnlineTeachingJobsPage/SEARCH_JOBS_REQUEST';
export const SEARCH_JOBS_SUCCESS = 'app/OnlineTeachingJobsPage/SEARCH_JOBS_SUCCESS';
export const SEARCH_JOBS_ERROR = 'app/OnlineTeachingJobsPage/SEARCH_JOBS_ERROR';

export const FETCH_TRANSACTIONS_REQUEST = 'app/OnlineTeachingJobsPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/OnlineTeachingJobsPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/OnlineTeachingJobsPage/FETCH_TRANSACTIONS_ERROR';

export const CONTENT_REQUEST = 'app/OnlineTeachingJobsPage/CONTENT_REQUEST';
export const CONTENT_SUCCESS = 'app/OnlineTeachingJobsPage/CONTENT_SUCCESS';
export const CONTENT_ERROR = 'app/OnlineTeachingJobsPage/CONTENT_ERROR';

export const NOTIFY_ME_REQUEST = 'app/Contact/NOTIFY_ME_REQUEST';
export const NOTIFY_ME_SUCCESS = 'app/Contact/NOTIFY_ME_SUCCESS';
export const NOTIFY_ME_ERROR = 'app/Contact/NOTIFY_ME_ERROR';

// ================ Reducer ================ //

const initialState = {
  searchTeachersInProgress: false,
  searchTeachersError: null,
  teachers: [],
  searchParams: null,
  searchInProgress: true,
  searchMapListingIds: [],
  searchListingsError: null,
  searchJobsInProgress: true,
  searchMapJobsIds: [],
  searchJobsError: null,
  currentPageResultIds: [],
  pagination: null,
  fetchTransactionsInProgress: false,
  fetchTransactionsError: null,
  transactionRefs: [],

  //content
  content: null,
  contentError: null,
  contentInProgress: false,

  notifyMeError: null,
  notifyMeInProgress: false,
  notifyMeSuccess: false,
};

const resultIds = data => data.data.map(l => l.id);

const onlineTeachingJobsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_TEACHERS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchTeachersInProgress: true,
        teachers: [],
        searchTeachersInError: null,
      };
    case SEARCH_TEACHERS_SUCCESS:
      return {
        ...state,
        teachers: payload?.users,
        pagination: payload?.response?.data?.meta || null,
        searchTeachersInProgress: false,
      };
    case SEARCH_TEACHERS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchTeachersInProgress: false, searchTeachersError: payload };

    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_JOBS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchJobsInProgress: true,
        searchMapJobsIds: [],
        searchJobsError: null,
      };
    case SEARCH_JOBS_SUCCESS:
      return {
        ...state,
        searchMapJobsIds: resultIds(payload.data),
        searchJobsInProgress: false,
      };
    case SEARCH_JOBS_ERROR:
      console.error(payload);
      return { ...state, searchJobsInProgress: false, searchJobsError: payload };

    case FETCH_TRANSACTIONS_REQUEST:
      return { ...state, fetchTransactionsInProgress: true, fetchTransactionsError: null };
    case FETCH_TRANSACTIONS_SUCCESS: {
      // const transactions = sortedTransactions(payload.txWithReview);
      const transactions = sortedTransactions(payload.data.data);
      const reviews = payload.reviews;
      return { ...state, fetchTransactionsInProgress: false, transactionRefs: entityRefs(transactions), reviews };
    }
    case FETCH_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionsInProgress: false, fetchTransactionsError: payload };

    case CONTENT_REQUEST:
      return { ...state, contentInProgress: true, contentError: null };
    case CONTENT_SUCCESS:
      return { ...state, contentInProgress: false, content: payload };
    case CONTENT_ERROR:
      return { ...state, contentInProgress: false, contentError: true };

    case NOTIFY_ME_REQUEST:
      return { ...state, notifyMeInProgress: true, notifyMeSuccess: null, notifyMeError: null };
    case NOTIFY_ME_SUCCESS:
      return { ...state, notifyMeInProgress: false, notifyMeSuccess: payload };
    case NOTIFY_ME_ERROR:
      return { ...state, notifyMeInProgress: false, notifyMeSuccess: false, notifyMeError: payload };

    default:
      return state;
  }
};

export default onlineTeachingJobsPageReducer;

// ================ Action creators ================ //

export const searchTeachersRequest = searchParams => ({
  type: SEARCH_TEACHERS_REQUEST,
  payload: { searchParams },
});

export const searchTeachersSuccess = (response, users) => ({
  type: SEARCH_TEACHERS_SUCCESS,
  payload: {response, users},
});

export const searchTeachersError = e => ({
  type: SEARCH_TEACHERS_ERROR,
  error: true,
  payload: e,
});

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchJobsRequest = (searchParams) => ({ type: SEARCH_JOBS_REQUEST, payload: { searchParams } });

export const searchJobsSuccess = response => ({
  type: SEARCH_JOBS_SUCCESS,
  payload: { data: response.data },
});

export const searchJobsError = e => ({
  type: SEARCH_JOBS_ERROR,
  error: true,
  payload: e,
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTIONS_ERROR, error: true, payload: e });

const contentRequest = () => ({ type: CONTENT_REQUEST });
const contentSuccess = content => ({ type: CONTENT_SUCCESS, payload: content });
const contentError = () => ({ type: CONTENT_ERROR });

const notifyMeRequest = () => ({ type: NOTIFY_ME_REQUEST });
const notifyMeSuccess = res => ({ type: NOTIFY_ME_SUCCESS, payload: res });
const notifyMeError = error => ({ type: NOTIFY_ME_ERROR, payload: error, error: true });

export const searchTeachers = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchTeachersRequest(searchParams));

  const params = {
    page: searchParams.page,
    perPage: searchParams.perPage,
    pub_onboardingProcessCompleted: true,
    pub_withProfileImage: true,
    sort: 'pub_completeness,createdAt',
  }


  if (searchParams?.state) params.pub_state = `has_any:${config.custom.stateOptions.find(i => i.short === searchParams.state).key}`;

  return searchByUser(params)
    .then(res => {
      const users = !!res ? denormalisedResponseEntities(res) : null;
      dispatch(searchTeachersSuccess(res, users));
      return !!res?.data ? res?.data : res;
    })
    .catch(e => {
      dispatch(searchTeachersError(storableError(e)));
      throw e;
    });
}

export const notifyMe = params => (dispatch) => {
  dispatch(notifyMeRequest());

  return getNotified(params)
    .then(() => dispatch(notifyMeSuccess(true)))
    .then(() => {
      setTimeout(() => {
        dispatch(notifyMeSuccess(false));
      }, 5000)
    })
    .catch(e => dispatch(notifyMeError(storableError(e))));
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.lineItemUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking
        ? values[1]
        : hasValues
        ? getExclusiveEndDate(values[1], 'Etc/UTC')
        : null;

    return hasValues
      ? {
        start: parseDateFromISO8601(startDate, 'Etc/UTC'),
        end: parseDateFromISO8601(endDate, 'Etc/UTC'),
        // Availability can be full or partial. Default value is full.
        availability: 'full',
      }
      : {};
  };

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = sort === config.custom.sortConfig.relevanceKey ? {} : { sort };

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...sortMaybe,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price'],
    'fields.user': ['profile.firstName', 'profile.lastName', 'profile.displayName', 'profile.abbreviatedName', 'profile.publicData.nickname', 'profile.publicData.searchParams'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
    perPage: RESULT_PAGE_SIZE,
    pub_type: 'course'
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

const generateSearchParams = parent => {
  return config.custom.areasCertifiedOptions.filter(i => i.parent === parent).map(({ key }) => key).join(',');
}

export const searchJobs = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchJobsRequest(searchParams));

  const params = {
    ...searchParams,
    'fields.listing': [
      'title',
      'geolocation',
      'price',
      'publicData.customId',
      'publicData.maxPrice',
      'publicData.license',
      'publicData.areasCertified',
      'publicData.state',
      'publicData.gradeLevels',
      'publicData.weeks',
      'publicData.period',
      'publicData.estimatedHours',
    ],
    perPage: RESULT_JOBS,
    pub_type: 'job'
  };

  const allInWithSameParent = config.custom.areasCertifiedOptions.filter(a => (a.staticUrl === searchParams.subject) && a.allIn);

  if (searchParams?.state) params.pub_state = `has_any:${config.custom.stateOptions.find(i => i.short === searchParams.state).key}`;
  if (searchParams?.subject) {
    const parentSubject = config.custom.areasCertifiedOptions.find(i => i.staticUrl === `${searchParams.subject}`);
    params.pub_subjects = `has_any:${generateSearchParams(parentSubject?.parent)}`  + `${allInWithSameParent[0].key}`;
  }

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchJobsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchJobsError(storableError(e)));
      throw e;
    });
};

export const fetchTransactions = () => {
  return (dispatch, getState, sdk) => {
    const transitions = `${TRANSITION_REVIEW_BY_CUSTOMER}, ${TRANSITION_REVIEW_2_BY_PROVIDER}, ${TRANSITION_REVIEW_2_BY_CUSTOMER}`

    const queryParams = { per_page: 100, lastTransitions: transitions, processNames: "'jobs-process', 'flex-default-process'" };

    dispatch(fetchTransactionRequest())

    return queryAllTransactionsWithReview(queryParams)
      .then(response => {
        if(response && response.data) {
          dispatch(addMarketplaceEntities(response));
          dispatch(fetchTransactionSuccess(response));
        }

        return response;
      })
      .catch(e => {
        dispatch(fetchTransactionError(storableError(e)));
        throw e;
      });
  };
}

export const getContent = (stateMaybe, subjectMaybe) => dispatch => {
  dispatch(contentRequest());

  let slug = stateMaybe ? `teaching-jobs-${stateMaybe}` : 'teaching-jobs';
  slug = subjectMaybe ? `${slug}-${subjectMaybe}` : slug;

  return getContentfulPage('teachingJobs', slug)
    .then(response => dispatch(contentSuccess(response.items[0].fields)))
    .catch(e => dispatch((contentError(e))));
};

export const loadData = (params, search) => dispatch => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const { page = 1, address, origin, type, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const listingSearchParams = {
    ...rest,
    ...params,
    ...originMaybe,
    page,
  };

  return Promise.all([
    dispatch(searchTeachers({ ...rest, ...params, page, perPage: RESULT_PAGE_SIZE })),
    dispatch(searchJobs(listingSearchParams)),
    dispatch(getContent(params?.state, params?.subject)),
  ]);
};
