
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  getJobs,
} from '../../util/api';
import {denormalisedEntities, denormalisedResponseEntities, updatedEntities} from '../../util/data';
import {convertUnitToSubUnit, unitDivisor} from "../../util/currency";
import config from "../../config";
import {getExclusiveEndDate, parseDateFromISO8601} from "../../util/dates";
import {addMarketplaceEntities} from "../../ducks/marketplaceData.duck";
import {createImageVariantConfig} from "../../util/sdkLoader";
import {isOriginInUse} from "../../util/search";
import {
  updateProfileError,
  updateTransactionExist
} from "../ProfileSettingsPage/ProfileSettingsPage.duck";

import { TRANSITION_REVIEW_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_PROVIDER } from '../../util/transaction';
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import {fetchCurrentUser} from "../../ducks/user.duck";


// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 25;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

// ================ Action types ================ //


export const GET_JOBS_REQUEST = 'app/OnlineTeachingJobsPage/GET_JOBS_REQUEST';
export const GET_JOBS_SUCCESS = 'app/OnlineTeachingJobsPage/GET_JOBS_SUCCESS';
export const GET_JOBS_ERROR = 'app/OnlineTeachingJobsPage/GET_JOBS_ERROR';

// ================ Reducer ================ //

const initialState = {
  getJobsInProgress: false,
  getJobsSuccess: false,
  getJobsError: null,
  jobs: [],
};


const suitableJobsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {


    case GET_JOBS_REQUEST:
      return {
        ...state,
        getJobsInProgress: true,
        jobs: [],
        getJobsSuccess: false,
        getJobsInError: null,
      };
    case GET_JOBS_SUCCESS:
      return {
        ...state,
        jobs: payload,
        getJobsSuccess: true,
        getJobsInProgress: false,
      };
    case GET_JOBS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, getJobsInProgress: false, getJobsSuccess: false, getJobsError: payload };



    default:
      return state;
  }
};

export default suitableJobsPageReducer;

// ================ Action creators ================ //



export const getJobsRequest = () => ({
  type: GET_JOBS_REQUEST,
});

export const getJobsSuccess = (response) => ({
  type: GET_JOBS_SUCCESS,
  payload: response,
});

export const getJobsError = e => ({
  type: GET_JOBS_ERROR,
  error: true,
  payload: e,
});



export const getSuitableJobs = params => (dispatch, getState, sdk) => {
  dispatch(getJobsRequest());

  const searchParams = new URLSearchParams(params).toString();

  return getJobs(searchParams)
    .then(response => {
      dispatch(getJobsSuccess(response?.jobs));

      return response;
    })
    .catch(e => {
      dispatch((getJobsError(e)));
    });
};



export const loadData = params => async (dispatch, getState, sdk) => {
  let currentUser = null;

  dispatch(getJobsRequest());

  return dispatch(fetchCurrentUser())
    .then(() => {
      currentUser = getState().user.currentUser;

      const publicData = currentUser?.attributes?.profile?.publicData || {gradeLevels: [], subjects: [], state: [],};
      const email = currentUser?.attributes?.email;
      const { gradeLevels = [], subjects = [], state = [], suitableJobsShown } = publicData;
      const params = { gradeLevels, subjects, states: state, email }

      if(!suitableJobsShown) dispatch(getSuitableJobs(params));
    })
};
