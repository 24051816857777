import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemBaseRateMaybe = props => {
  const { lineItems, unitType, intl } = props;

  const unitPurchase = lineItems.find(item => item.code === unitType && !item.reversal);
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;

  return unitPrice ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        Rate:
      </span>
      <span className={css.itemValue}>{unitPrice} <span className={css.itemValueM}> per hour</span></span>
    </div>
  ) : null;
};

LineItemBaseRateMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  unitType: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBaseRateMaybe;
