import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './AnalyticsBlock.module.css';
import {
  IconLoader
} from '../../components';
import downArrow from './images/downArrow.svg';
import upArrow from './images/upArrow.svg';



const AnalyticsBlock = props => {
  const {
    className,
    rootClassName,
    inProgress,
    loaderClassName,
    analytics,
    teacherInfo,
    adminInfo,
    parentInfo,
  } = props;

  if(analytics.length === 0 ) return null;


  const current = new Date();
  current.setMonth(current.getMonth()-1);
  const previousMonth = current.toLocaleString('default', { month: 'long' });

  let titles =  {
    totalSignUps: 'Total sign ups',
    prevSignUps: `Total sign up in ${previousMonth}`,
    totalConversion: 'Conversion rate',
    prevConversion: `Conversion rate in ${previousMonth}`,
  }

    if (teacherInfo) {
      titles = {
        totalSignUps: 'Teacher sign ups (total)',
        prevSignUps: `Teacher sign ups in ${previousMonth}`,
        totalConversion: 'Teacher conversion rate',
        prevConversion: `Teacher conversion rate in ${previousMonth}`,
      }
    }
    if(adminInfo) {
      titles = {
        totalSignUps: 'Admin sign ups (total)',
        prevSignUps: `Admin sign ups in ${previousMonth}`,
        totalConversion: 'Admin conversion rate',
        prevConversion: `Admin conversion rate in ${previousMonth}`,
      }
    }
    if(parentInfo) {
      titles = {
        totalSignUps: 'Parent sign ups (total)',
        prevSignUps: `Parent sign ups in ${previousMonth}`,
        totalConversion: 'Parent conversion rate',
        prevConversion: `Parent conversion rate in ${previousMonth}`,
      }
    }

  const loader = <IconLoader className={loaderClassName} />;

  const {conversion, signups } = analytics;

  const { lastMonthTotalItems, totalItems, monthBeforeLastItems } = signups;
  const { conversionTotal, conversionLastMonth, conversionMonthBeforeLast } = conversion;

  const signupsDiff = lastMonthTotalItems - monthBeforeLastItems;
  const signupsDiffMoreThenZero = signupsDiff > 0;
  const signupsDiffPercentage = signupsDiff > 0 ? ((signupsDiff*100)/monthBeforeLastItems).toFixed() : 0;


  const conversionDiff = conversionLastMonth - conversionMonthBeforeLast;
  const conversionDiffPercentage = conversionDiff > 0 ? ((conversionDiff*100)/conversionMonthBeforeLast).toFixed() : 0;
  const conversionDiffMoreThenZero = conversionDiff > 0;

  return (
    <ul className={css.blockList}>
      <li className={css.blockItem}>
        <span className={css.blockTitle}>{titles.totalSignUps}</span>
        <span className={css.blockInfo}>{inProgress ? loader : totalItems}</span>
      </li>
      <li className={css.blockItem}>
        <span className={css.blockTitle}>{titles.prevSignUps}</span>
        <div className={css.blockInfoHolder}>
          {inProgress ? loader : (
            <>
              <span className={css.blockInfo}>{lastMonthTotalItems}</span>
              <span className={css.blockDiff}>
                {signupsDiffMoreThenZero ? <img src={upArrow} alt="up arrow" /> : <img src={downArrow} alt="down arrow"/>}
                {signupsDiffMoreThenZero ? '+' : '-'}
                {signupsDiffPercentage}% than last month
              </span>
            </>
            )}
        </div>
      </li>
      <li className={css.blockItem}>
        <span className={css.blockTitle}>{titles.totalConversion}</span>
        <span className={css.blockInfo}>{inProgress ? loader : conversionTotal}</span>
      </li>
      <li className={css.blockItem}>
        <span className={css.blockTitle}>{titles.prevConversion}</span>
        <div className={css.blockInfoHolder}>
          {inProgress ? loader : (
            <>
              <span className={css.blockInfo}>{conversionLastMonth}</span>
              <span className={css.blockDiff}>
                {conversionDiffMoreThenZero ? <img src={upArrow} alt="up arrow" /> : <img src={downArrow} alt="down arrow"/>}
                {signupsDiffMoreThenZero ? '+' : '-'}
                {conversionDiffPercentage}% than last month
              </span>
            </>

            )}
        </div>
      </li>
    </ul>
  );
};

const { string } = PropTypes;

AnalyticsBlock.defaultProps = {
  className: null,
  rootClassName: null,
};

AnalyticsBlock.propTypes = {
  className: string,
  rootClassName: string,
};

export default AnalyticsBlock;
