import { postUpdateTransactionMetadata } from '../containers/TransactionPage/TransactionPage.duck';
import { isTeacherRole } from './types';

export const getMetaDataParams = (hasTransitionsToNotify, transaction, isJob, isShow = false, isTeacher) => {
  const transactionId = transaction.id;
  const transitions = transaction.attributes?.metadata?.transitionsToNotify;

  let transitionsToNotify = [];

  if (hasTransitionsToNotify) {
    transitionsToNotify = transitions.map(t => {
      if (isTeacher) {
        t.isReadByTeacher = true;
        if (!isShow) {
          t.isReadByAdmin = false;
        }
      }
      if (!isTeacher) {
        t.isReadByAdmin = true;
        if (!isShow) {
          t.isReadByTeacher = false;
        }
      }

      return t;
    });
  } else {

    const transitions = transaction.attributes.transitions;
    const type = isJob ? { isJob: true } : { isCourse: true };

    transitionsToNotify = transitions.map(t => {

      return { transition: t.transition, isReadByTeacher: true, isReadByAdmin: false, ...type };
    });
  }

  return {
    transactionId,
    transitionsToNotify,
  };
};

export const getTransitionsToNotify = (transaction) => !!transaction.attributes?.metadata?.transitionsToNotify;

export const updateMetaData = (transaction, getState, dispatch, isJob, isShow) => {
  const hasTransitionsToNotify = getTransitionsToNotify(transaction);
  if (hasTransitionsToNotify) {
    let currentUser = getState().user.currentUser;
    const isTeacher = isTeacherRole(currentUser);
    const metaDataParams = getMetaDataParams(hasTransitionsToNotify, transaction, isJob, isShow, isTeacher);
    dispatch(postUpdateTransactionMetadata(metaDataParams));
  } else {
    const metaDataParams = getMetaDataParams(hasTransitionsToNotify, transaction, isJob, isShow);
    dispatch(postUpdateTransactionMetadata(metaDataParams));
  }

};
