import firstListingCardImage from "../assets/images/randomListingCardImage/1.png";
import secondListingCardImage from "../assets/images/randomListingCardImage/2.png";
import thirdListingCardImage from "../assets/images/randomListingCardImage/3.png";
import foursListingCardImage from "../assets/images/randomListingCardImage/4.png";
import fifthListingCardImage from "../assets/images/randomListingCardImage/5.png";
import sixthListingCardImage from "../assets/images/randomListingCardImage/6.png";
import seventhListingCardImage from "../assets/images/randomListingCardImage/7.png";
import eighthListingCardImage from "../assets/images/randomListingCardImage/8.png";
import ninthListingCardImage from "../assets/images/randomListingCardImage/9.png";
import tenthListingCardImage from "../assets/images/randomListingCardImage/10.png";

import firstAvatarImage from "../assets/images/randomAvatarImage/1.png";
import secondAvatarImage from "../assets/images/randomAvatarImage/2.png";
import thirdAvatarImage from "../assets/images/randomAvatarImage/3.png";
import foursAvatarImage from "../assets/images/randomAvatarImage/4.png";
import fifthAvatarImage from "../assets/images/randomAvatarImage/5.png";
import sixthAvatarImage from "../assets/images/randomAvatarImage/6.png";
import seventhAvatarImage from "../assets/images/randomAvatarImage/7.png";
import eighthAvatarImage from "../assets/images/randomAvatarImage/8.png";
import ninthAvatarImage from "../assets/images/randomAvatarImage/9.png";
import tenthAvatarImage from "../assets/images/randomAvatarImage/10.png";



export const randomImage = (type) => {
  const min = 1;
  const max = 10;
  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  if(type == 'listingCard') {
    switch (randomNumber) {
      case 1: return firstListingCardImage;
      case 2: return secondListingCardImage;
      case 3: return thirdListingCardImage;
      case 4: return foursListingCardImage;
      case 5: return fifthListingCardImage;
      case 6: return sixthListingCardImage;
      case 7: return seventhListingCardImage;
      case 8: return eighthListingCardImage;
      case 9: return ninthListingCardImage;
      case 10: return tenthListingCardImage;
    }
  }

  if(type === 'avatar') {
    switch (randomNumber) {
      case 1: return firstAvatarImage;
      case 2: return secondAvatarImage;
      case 3: return thirdAvatarImage;
      case 4: return foursAvatarImage;
      case 5: return fifthAvatarImage;
      case 6: return sixthAvatarImage;
      case 7: return seventhAvatarImage;
      case 8: return eighthAvatarImage;
      case 9: return ninthAvatarImage;
      case 10: return tenthAvatarImage;
    }
  }


}
