import React from 'react';
import PropTypes from 'prop-types';

import css from './IconUpload.module.css';

const IconUpload = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
    >
      <path
        fill="#23A0EC"
        d="M16.08 10.42L9.9 16.61a4.25 4.25 0 01-6-6l8-8a2.57 2.57 0 013.54 0 2.52 2.52 0 010 3.54l-6.9 6.89A.765.765 0 017.42 12l5.13-5.12a1.004 1.004 0 00-1.42-1.42L6 10.6a2.74 2.74 0 000 3.89 2.82 2.82 0 003.89 0l6.89-6.9a4.5 4.5 0 00-6.36-6.36l-8 8A6.25 6.25 0 0011.31 18l6.19-6.18a1.005 1.005 0 00-1.42-1.42v.02z"
      ></path>
    </svg>
  );
};

IconUpload.defaultProps = { className: null };

const { string } = PropTypes;

IconUpload.propTypes = {
  className: string,
};

export default IconUpload;
