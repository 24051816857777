import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './MainPanelHeader.module.css';
import { IconClose } from '../../../components';
import {
  getSelectedFiltersArray,
  parseSelect2FilterOptions,
  parseSelectFilterOptions,
} from '../../../util/search';
import { pickSearchParamsOnly } from '../SearchPage.helpers';
import { parse } from '../../../util/urlHelpers';
import config from '../../../config';

const format = (selectedOptions, queryParamName, schemaType, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = schemaType === 'multi-enum' && searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;

  return { [queryParamName]: value };
};

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    courseSearchPage,
    selectedFiltersArray,
    handleRemovalOfSelectedFilter,
    getHandleChangedValueFn,
    filterConfig,
    resetAll
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const handleFilterRemove = (e, values) => {
    e.preventDefault();
    const queryParamName = values.filterId === 'price' ? values.filterId : 'pub_' + values.filterId;

    const { ...searchInURL } = parse(props.location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const urlQueryParams = pickSearchParamsOnly(searchInURL, config.custom.filters, config.custom.sortConfig);
    const selectedFiltersArray = parseSelectFilterOptions(urlQueryParams[queryParamName]);

    let usedValue = [];
    let schemaType = '';
    let searchMode = '';

    if(queryParamName !== 'pub_rate' && queryParamName !== 'pub_license' && queryParamName !== 'price') {
      usedValue = selectedFiltersArray.filter(s => s !== values.key);
      schemaType = 'multi-enum';
      searchMode = 'has_any';
    }

    getHandleChangedValueFn(format(usedValue, queryParamName, schemaType, searchMode));
  };

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <div className={css.searchResultSummary}>
          {/*<h1 className={css.searchOptionsTitle}>*/}
          {/*  {!!searchTeacherPresent ? <FormattedMessage id="MainPanelHeader.searchByTeachers" /> : <FormattedMessage id="MainPanelHeader.searchByCourses" />}*/}
          {/*</h1>*/}
          <span className={css.resultsFound}>
            {searchInProgress ? (
              <FormattedMessage id="MainPanelHeader.loadingResults" />
            ) : (
              <FormattedMessage
                id="MainPanelHeader.foundResults"
                values={{ count: resultsCount }}
              />
            )}
          </span>
        </div>
        <div className={css.sortyByWrapper}>
          <span className={css.sortyBy}>
            <FormattedMessage id="MainPanelHeader.sortBy"/>
          </span>
          {sortByComponent}
        </div>

      </div>

      {selectedFiltersArray?.length > 0 && <>
        <h3 className={css.selectedFiltersTitle}><FormattedMessage id={'SearchPage.yourFilters'} /></h3>
        <div className={css.selectedFiltersHolder}>
          <ul className={css.selectedFiltersList}>
            {selectedFiltersArray?.map((el, index) => {
              return <li key={index} className={css.selectedFiltersItem}>
                {el?.long ? el?.long : el?.label}
                <IconClose
                  onClick={e => handleFilterRemove(e, el)}
                  rootClassName={css.closeIcon} size="small"
                />
              </li>
            })}
          </ul>

          <span className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
            <FormattedMessage id={'SearchPage.clearAllFilters'} />
          </span>
        </div>
      </>}




      {children}

      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
