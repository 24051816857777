import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconLoader.module.css';



const IconLoader = props => {
  const {className} = props;
  return (

    <svg
      className={className}
      version="1.1"
      id="L2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
       viewBox="0 0 100 100"
      enable-background="new 0 0 100 100">
    <circle fill="none" stroke="#454545" stroke-width="4" stroke-miterlimit="10" cx="50" cy="50" r="48"/>
          <line fill="none" stroke-linecap="round" stroke="#454545" stroke-width="4" stroke-miterlimit="10" x1="50" y1="50" x2="85" y2="50.5">
      <animateTransform
        attributeName="transform"
        dur="2s"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        repeatCount="indefinite" />
    </line>
          <line fill="none" stroke-linecap="round" stroke="#454545" stroke-width="4" stroke-miterlimit="10" x1="50" y1="50" x2="49.5" y2="74">
      <animateTransform
        attributeName="transform"
        dur="15s"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        repeatCount="indefinite" />
    </line>
    </svg>


  );
}

IconLoader.defaultProps = {
  className: null,
  rootClassName: null,
  brand: 'default',
};

IconLoader.propTypes = {
  className: string,
  rootClassName: string,
  brand: string,
};

export default IconLoader;
