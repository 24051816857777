import config from "../config";
import {uniqBy} from "lodash";
import React from "react";

export const associateAreasWithParent = (array) => {
  return array.map(l => {

    const initAreasCertified = l?.areasCertified;

    let filteredInitAreasCertified = initAreasCertified ? config.custom.areasCertifiedOptions.filter(objFromA => {
      return initAreasCertified.find(objFromB => {
        return objFromA.label === objFromB.label
      })
    }) : [];

    const createdAreasCertified = initAreasCertified ? initAreasCertified.filter(objFromA => {
      return config.custom.areasCertifiedOptions.find(objFromB => {
        return objFromA.label !== objFromB.label
      })
    }) : [];
    filteredInitAreasCertified = [...filteredInitAreasCertified, ...createdAreasCertified];
    const uniqInitAreasCertified = uniqBy(filteredInitAreasCertified, item => item.label);

    const formattedAreasCertified = uniqInitAreasCertified ? uniqInitAreasCertified?.map(objFromA => {
      const temp = config.custom.areasCertifiedOptions.find(objFromB => objFromB.label === objFromA.label);
      if(temp) {
        return temp
      } else {
        return {key: objFromA.key, label: objFromA.label, value: objFromA.value, id: 0, type: 'areasCertified', parent: 'Other' }
      }
    }) : [];

    const getByParent = formattedAreasCertified?.map((area, index) => {
      const parent = area.parent ? area.parent : 'Other';
      return {
        [parent]: [area.label],
      }
    })

    const associatedResult = getByParent?.reduce((acc, curr) => {
      const key = Object.keys(curr)[0]
      const found = acc.find(i => i[key])
      if (!found) {
        acc.push(curr)
      } else {
        found[key] = [ ...found[key], ...curr[key] ]
      }
      return acc;
    }, [])

    l.areasAssociatedWithParent = associatedResult;

    return l;
  });
}

export const associateResult = (array) => {
  const getByParent = array.map((area, index) => {
    const parent = area.parent ? area.parent : 'Other';
    return {
      [parent]: [area.label],
    }
  })

  const associatedResult = getByParent.reduce((acc, curr) => {
    const key = Object.keys(curr)[0]
    const found = acc.find(i => i[key])
    if (!found) {
      acc.push(curr)
    } else {
      found[key] = [ ...found[key], ...curr[key] ]
    }
    return acc;
  }, [])


  return associatedResult;
}

export const associateResultAsObject = (array) => {
  const getByParent = array.map((area, index) => {
    const parent = area.parent ? area.parent : 'Other';
    return {
      [parent]: [area],
    }
  })

  const associatedResult = getByParent.reduce((acc, curr) => {
    const key = Object.keys(curr)[0]
    const found = acc.find(i => i[key])
    if (!found) {
      acc.push(curr)
    } else {
      found[key] = [ ...found[key], ...curr[key] ]
    }
    return acc;
  }, [])

  const sortedAssociatedResult = associatedResult.sort((a, b) => {
    const textA = Object.keys(a);
    const textB = Object.keys(b);

    return (textA[0] < textB[0]) ? -1 : (textA[0] > textB[0]) ? 1 : 0;
  });

  return sortedAssociatedResult;
}

export const getFormattedAreas = (onboardingProcess) => {
  const areasCertifiedIn = onboardingProcess && onboardingProcess?.licenses ? onboardingProcess?.licenses.map(l => l.areasCertified) : onboardingProcess?.areasCertified
  const isAreasCertifiedIn = onboardingProcess && onboardingProcess?.licenses ? areasCertifiedIn[0] : areasCertifiedIn;
  const initAreasCertified = onboardingProcess && areasCertifiedIn && isAreasCertifiedIn && isAreasCertifiedIn.map((area, index) => {
    if(area.label) {
      return ({
        key: area.key,
        label: area.label.replace(/_/g, ' '),
        id: index
      })
    } else {
      return ({
        key: area?.toLowerCase(),
        label: area.replace(/_/g, ' '),
        id: index
      })
    }
  });

  let filteredInitAreasCertified = initAreasCertified ? config.custom.areasCertifiedOptions.filter(objFromA => {
    return initAreasCertified.find(objFromB => {
      return objFromA.key === objFromB.key
    })
  }) : [];

  const createdAreasCertified = initAreasCertified ? initAreasCertified.filter(objFromA => {
    return config.custom.areasCertifiedOptions.find(objFromB => {
      return objFromA.key !== objFromB.key
    })
  }) : [];
  filteredInitAreasCertified = [...filteredInitAreasCertified, ...createdAreasCertified];
  const uniqInitAreasCertified = uniqBy(filteredInitAreasCertified, item => item.key);

  const formattedAreasCertified = uniqInitAreasCertified ? uniqInitAreasCertified?.map(objFromA => {
    const temp = config.custom.areasCertifiedOptions.find(objFromB => objFromB.label === objFromA.label);
    if(temp) {
      return temp
    } else {
      return {key: objFromA.key, label: objFromA.label, value: objFromA.value, id: 0, type: 'areasCertified', parent: 'Other' }
    }
  }) : [];

  return formattedAreasCertified;
}


export const getAssociatedAreas = (onboardingProcess) => {
  const formattedAreasCertified = getFormattedAreas(onboardingProcess);
  const associatedAreas = formattedAreasCertified && associateResult(formattedAreasCertified);

  return associatedAreas;
}
