import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.css';

export const IconCheckbox = props => {
  const { className, checkedClassName, boxClassName } = props;
  return (
    <svg className={className} width="18" height="18" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect
          className={boxClassName || css.box}
          width="17"
          height="17"
          x="0.5"
          y="0.5"
          stroke="#B2B2B2"
          rx="3.5"
        ></rect>
        <rect
          className={checkedClassName || css.checked}
          width="18"
          height="18"
          fill="#23A0EC"
          rx="4">
        </rect>
        <path
          fill="#fff"
          d="M7.98 12.827a1.278 1.278 0 01-1.807 0l-2.799-2.799A1.277 1.277 0 015.18 8.222l1.668 1.667c.126.126.33.126.456 0l4.515-4.515a1.278 1.278 0 011.807 1.806l-5.647 5.647z"
        ></path>
      </g>
    </svg>
  );
};



IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    rootLabelClassName,
    rootInputClassName,
    rootCheckboxWrapper,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const inputClasses = classNames(rootInputClassName, css.input);
  const labelClasses = classNames(rootLabelClassName, css.label);
  const rootCheckboxWrapperClasses = classNames(rootCheckboxWrapper, css.checkboxWrapper);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);

    // If onChange has been passed as a props to FieldCheckbox
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  const successColorVariantMaybe = useSuccessColor
    ? {
      checkedClassName: css.checkedSuccess,
      boxClassName: css.boxSuccess,
    }
    : {};

  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const input = props.input;
          return (
            <input
              id={id}
              className={inputClasses}
              {...input}
              onChange={event => handleOnChange(input, event)}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={labelClasses}>
        <span className={rootCheckboxWrapperClasses}>
          <IconCheckbox className={svgClassName} {...successColorVariantMaybe} />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxComponent;
