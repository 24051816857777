import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SubjectsFilter.module.css';

const IconMinus = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="24"
      height="2"
      viewBox="0 0 24 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="2" fill="#454545"/>
    </svg>
  );
};

const { string } = PropTypes;

IconMinus.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMinus.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMinus;
