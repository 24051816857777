import React, { useState } from 'react';

import SelectSingleFilter from './SelectSingleFilter/SelectSingleFilter';
import SelectMultipleFilter from './SelectMultipleFilter/SelectMultipleFilter';
import SelectMultipleWithInputFilter from './SelectMultipleWithInputFilter/SelectMultipleWithInputFilter';
import RadioButtonFilter from './RadioButtonFilter/RadioButtonFilter';
import BookingDateRangeFilter from './BookingDateRangeFilter/BookingDateRangeFilter';
import KeywordFilter from './KeywordFilter/KeywordFilter';
import PriceFilter from './PriceFilter/PriceFilter';
import SubjectsFilter from './SubjectsFilter/SubjectsFilter';
import StatesFilter from './StatesFilter/StatesFilter';

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
const FilterComponent = props => {
  const {
    idPrefix,
    filterConfig,
    urlQueryParams,
    initialValues,
    getHandleChangedValueFn,
    onManageDisableScrolling,
    asideWidth,
    handleStatesFilterToggle,
    handleSubjectsFilterToggle,
    isStatesFilterOpened,
    isSubjectsFilterOpened,
    parentCallback,
    isCourseSearch,
    isJobSearch,
    ...rest
  } = props;

  const { id, type, queryParamNames, label, placeholder, config } = filterConfig;
  const { liveEdit, showAsPopup, showPriceFilterAsPlain } = rest;

  const useHistoryPush = liveEdit || showAsPopup || showPriceFilterAsPlain;
  const prefix = idPrefix || 'SearchPage';
  const componentId = `${prefix}.${id.toLowerCase()}`;
  const name = id.replace(/\s+/g, '-').toLowerCase();

  switch (true) {
    case type === 'SelectSingleFilter': {
      return (<SelectSingleFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSelect={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />);
    }
    case type === 'SelectMultipleFilter': {
      return (<SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />);
    }
    case type === 'Experience': {
      return (<SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />);
    }
    case type === 'GradeLevels': {
      return (<SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />);
    }
    case type === 'Education' && !isJobSearch: {
      return (<SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />);
    }
    case type === 'RadioButtonFilter': {
      return (<RadioButtonFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />);
    }
    case type === 'BookingDateRangeFilter': {
      return (<BookingDateRangeFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />);
    }
    case type === 'PriceFilter' && !isCourseSearch && !isJobSearch: {
      return (<PriceFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          showPriceFilterAsPlain={showPriceFilterAsPlain}
          {...config}
          {...rest}
        />);
    }
    case type === 'PriceCourseFilter' && isCourseSearch: {
      return (<PriceFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />);
    }
    case type === 'PriceJobFilter' && isJobSearch: {
      return (<PriceFilter
          id={componentId}
          label={label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, false)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          showPriceFilterAsPlain={showPriceFilterAsPlain}
          {...config}
          {...rest}
        />);
    }
    case type === 'SubjectsFilter': {
      return (<SelectMultipleWithInputFilter
        id={componentId}
        label={label}
        placeholder={placeholder}
        name={name}
        queryParamNames={queryParamNames}
        initialValues={initialValues(queryParamNames, liveEdit)}
        onSubmit={getHandleChangedValueFn(useHistoryPush)}
        {...config}
        {...rest}
      />);
    }
    case type === 'StatesFilter': {
      return (<SelectMultipleWithInputFilter
        id={componentId}
        label={label}
        placeholder={placeholder}
        name={name}
        queryParamNames={queryParamNames}
        initialValues={initialValues(queryParamNames, liveEdit)}
        onSubmit={getHandleChangedValueFn(useHistoryPush)}
        {...config}
        {...rest}
      />);
    }
    // case type === 'SubjectsFilter': {
    //   return (<SubjectsFilter
    //       id={componentId}
    //       label={label}
    //       name={name}
    //       queryParamNames={queryParamNames}
    //       initialValues={initialValues(queryParamNames, liveEdit)}
    //       onSubmit={getHandleChangedValueFn(useHistoryPush)}
    //       onManageDisableScrolling={onManageDisableScrolling}
    //       asideWidth={asideWidth}
    //       {...config}
    //       {...rest}
    //       onTogglePanel={handleSubjectsFilterToggle}
    //       isPanelOpen={isSubjectsFilterOpened}
    //       parentCallback={parentCallback}
    //     />);
    // }
    // case type === 'StatesFilter': {
    //   return (<StatesFilter
    //       id={componentId}
    //       label={label}
    //       name={name}
    //       queryParamNames={queryParamNames}
    //       isJobSearch={isJobSearch}
    //       initialValues={initialValues(queryParamNames, liveEdit)}
    //       onSubmit={getHandleChangedValueFn(useHistoryPush)}
    //       onManageDisableScrolling={onManageDisableScrolling}
    //       asideWidth={asideWidth}
    //       onTogglePanel={handleStatesFilterToggle}
    //       isPanelOpen={isStatesFilterOpened}
    //       parentCallback={parentCallback}
    //       {...config}
    //       {...rest}
    //     />);
    // }
    case type === 'KeywordFilter':
      return (<KeywordFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...config}
          {...rest}
        />);
    default:
      return null;
  }
};

export default FilterComponent;
