/**
 * Export loadData calls from ducks modules of different containers
 */
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as SignUpStateAnalyticsPageLoader } from './SignUpStateAnalyticsPage/SignUpStateAnalyticsPage.duck';
import { loadData as SignUpAnalyticsPageLoader } from './SignUpAnalyticsPage/SignUpAnalyticsPage.duck';
import { loadData as ReferralsAnalyticsPageLoader } from './ReferralsAnalyticsPage/ReferralsAnalyticsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EditJobPageLoader } from './EditJobPage/EditJobPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as LandingPageTexasTeachersLoader } from './LandingPageTexasTeachers/LandingPageTexasTeachers.duck';
import { loadData as LandingPageGeneralAdminLoader } from './LandingPageGeneralAdmin/LandingPageGeneralAdmin.duck';
import { loadData as SuccessPageLoader } from './SuccessPage/SuccessPage.duck';
import { loadData as OnlineTeachingJobsPageLoader } from './OnlineTeachingJobsPage/OnlineTeachingJobsPage.duck';
import { loadData as ExternalOnlineTeachingJobsPageLoader } from './ExternalOnlineTeachingJobsPage/ExternalOnlineTeachingJobsPage.duck';
import { loadData as OnlineTeachersForHirePageLoader } from './OnlineTeachersForHirePage/OnlineTeachersForHirePage.duck';
import { loadData as SuitableJobsPageLoader } from './SuitableJobsPage/SuitableJobsPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as ConversationPageLoader } from './ConversationPage/ConversationPage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import SuccessPage from './SuccessPage/SuccessPage';

const getPageDataLoadingAPI = () => {
  return {
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    LandingPageTexasTeachers: {
      loadData: LandingPageTexasTeachersLoader,
    },
    LandingPageGeneralAdmin: {
      loadData: LandingPageGeneralAdminLoader,
    },
    SuccessPage: {
      loadData: SuccessPageLoader,
    },
    OnlineTeachingJobsPage: {
      loadData: OnlineTeachingJobsPageLoader,
    },
    ExternalOnlineTeachingJobsPage: {
      loadData: ExternalOnlineTeachingJobsPageLoader,
    },
    OnlineTeachersForHirePage: {
      loadData: OnlineTeachersForHirePageLoader,
    },
    SuitableJobsPage: {
      loadData: SuitableJobsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EditJobPage: {
      loadData: EditJobPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    ConversationPage: {
      loadData: ConversationPageLoader,
    },
    SignUpAnalyticsPage: {
      loadData: SignUpAnalyticsPageLoader,
    },
    SignUpStateAnalyticsPage: {
      loadData: SignUpStateAnalyticsPageLoader,
    },
    ReferralsAnalyticsPage: {
      loadData: ReferralsAnalyticsPageLoader,
    }
  };
};

export default getPageDataLoadingAPI;
