import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../index';

import css from './ModalSignUp.module.css';
import classNames from 'classnames';

const ModalSignUp = props => {
  const { onClose, location, routes, offerPage } = props;

  const checkRole = name => routes.find(el => el.name === name)?.path === location.pathname;

  const state = offerPage ? { fromOfferPage: true } : null;

  const createAccount = <FormattedMessage id='ModalSignUp.createAccount' />;

  const withoutRedirect =
    <div className={css.signupLink} onClick={onClose}>{createAccount}</div>;

  const namedLink = name => (
    <NamedLink className={css.signupLink} name={name} to={{ state }}>{createAccount}</NamedLink>
  );

  // Sign Up page names
  const signupSchoolAdminPage = 'SignupSchoolAdminPage';
  const signupTeacherPage = 'SignupTeacherPage';
  const signupParentPage = 'SignupParentPage';

  const isAdmin = checkRole(signupSchoolAdminPage);
  const isTeacher = checkRole(signupTeacherPage);
  const isParent = checkRole(signupParentPage);

  return (
    <div className={css.root}>
      <h2 className={css.title}>
        <FormattedMessage id='ModalSignUp.title' />
      </h2>
      <div className={css.optionsList}>
        <div className={classNames(css.optionItem, css.admin)}>
          <div className={css.optionTitle}>
            <FormattedMessage id='ModalSignUp.schoolAdminText' />
          </div>
          {isAdmin ? withoutRedirect : namedLink(signupSchoolAdminPage)}
        </div>
        <div className={classNames(css.optionItem, css.parent)}>
          <div className={css.optionTitle}>
            <FormattedMessage id='ModalSignUp.teacherText' />
          </div>
          {isTeacher ? withoutRedirect : namedLink(signupTeacherPage)}
        </div>
        <div className={classNames(css.optionItem, css.teacher)}>
          <div className={css.optionTitle}>
            <FormattedMessage id='ModalSignUp.parentText' values={{linebreak: <br/>}}/>
          </div>
          {isParent ? withoutRedirect : namedLink(signupParentPage)}
        </div>
      </div>

      <div className={css.haveAccount}>
        <FormattedMessage id='ModalSignUp.haveAccount'/>
        <NamedLink name='LoginPage'><FormattedMessage id='ModalSignUp.loginText'/></NamedLink>
      </div>
    </div>
  );
};

ModalSignUp.displayName = 'ModalSignUp';

export default ModalSignUp;
