import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SubjectsFilter.module.css';

const IconPlus = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes}
         width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <rect y="11" width="24" height="2" fill="#454545"/>
      <rect x="13" width="24" height="2" transform="rotate(90 13 0)" fill="#454545"/>
    </svg>
  );
};

const { string } = PropTypes;

IconPlus.defaultProps = {
  className: null,
  rootClassName: null,
};

IconPlus.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPlus;
