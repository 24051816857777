import React, {useState, useEffect, useRef} from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';

import css from './SubjectsFilter.module.css';
import { FieldSelect2} from "../../../components";
import { associateResult, associateResultAsObject } from '../../../util/areasHelpers';
import IconMinus from "./IconMinus";
import IconPlus from "./IconPlus";

import config from "../../../config";
import {IconCheckbox} from "../../../components/FieldCheckbox/FieldCheckbox";

const SubjectsPanel = props => {
  const {
    options,
    isPanelOpen,
    asideWidth,
    handleSubmit,
    selectedOptions,
    name,
    parentCallback,
    onTogglePanel,
  } = props;

  const [openSections, setOpenSections] = useState(true);
  const [openSubjects, setOpenSubjects] = useState([]);
  const [checked, setChecked] = useState([]);
  const filterListRef = useRef();

  const innerWidth = typeof window !== 'undefined' && window.innerWidth;
  const isMobile = innerWidth < 768;
  const classes = isPanelOpen ? classNames(css.filterPanel, css.showPanel) : classNames(css.filterPanel, css.hidePanel);
  const associatedOptions = associateResultAsObject(options);
  const allInOptions = config.custom.areasCertifiedOptions.filter(area => area.allIn);



  useEffect(() => {
    const initialStates = config.custom.areasCertifiedOptions.map(objFromA => {
      return selectedOptions.some(objFromB => {
        if(objFromB.key === objFromA.key) {
          return true
        }
      })
    })
    setChecked(initialStates)
  }, [checked && selectedOptions])

  const handleSectionsToggler = (e) => {
    e.preventDefault();

    setOpenSections(!openSections);

    const allSubjects = associatedOptions.map(item => {
      if(openSections) {
        return item = true
      } else {
        return item = false
      }
    })

    setOpenSubjects([...allSubjects]);
  }

  useEffect(() => {
    parentCallback(filterListRef.current?.clientHeight)
  }, [openSections, openSubjects])

  const handleSubjectsClick = (e, index) => {
    e.preventDefault();
    openSubjects[index] = !openSubjects[index];
    setOpenSubjects([...openSubjects]);
    parentCallback(filterListRef.current?.clientHeight)
  }

  const handleSubjectItemClick = (e, item, index, innerIndex) => {
    e.preventDefault();
    e.stopPropagation();

    checked[innerIndex] = !checked[innerIndex];
    setChecked([...checked]);

    const itemObject1 =  config.custom.areasCertifiedOptions.find(area => area.key === item.key);
    const itemObject =  {key: itemObject1.key, label: item.label, value: item.label, id: index, type: 'areasCertified' };

    const hasItemObject = selectedOptions.some(o => o.key === itemObject.key);
    let updatedSelectedOptions = []
    if(hasItemObject) {
      updatedSelectedOptions = selectedOptions.filter(o => o.key !== itemObject.key)
    } else {
      const hasAllIn = allInOptions.some(opt => item.key === opt.key);
      const sameParent = config.custom.areasCertifiedOptions.filter(area => item.parent === area.parent);

      if(hasAllIn) {
        updatedSelectedOptions = [...selectedOptions, ...sameParent]
      } else {
        updatedSelectedOptions = [...selectedOptions, itemObject]
      }

    }

    const namedInitialValues = { [name]: updatedSelectedOptions };

    handleSubmit(namedInitialValues);
  }

  const variableText = !openSections ? 'Hide' : 'Expand';

  return (
    <div style={!isMobile ? {left: asideWidth + 'px', width:  innerWidth - asideWidth + 'px'} : {}} className={classes}>
      <div className={css.filterInner} ref={filterListRef}>
        <FieldSelect2
          id={`subjects`}
          name={`subjects`}
          className={css.select}
          label={''}
          placeholder={'Search areas'}
          value={'selectedOption'}
          isSearchable={true}
          isClearable={false}
          isMulti={true}
          showMultiValues={true}
          showSearchIcon={false}
          options={config.custom.areasCertifiedOptions}
        />

        <div className={css.titleSectionsHolder}>
          <div onClick={(e) => handleSectionsToggler(e)} className={css.sectionsToggler}>
            <span>{variableText} all sections</span>
          </div>
          <span className={css.sectionsTitle} onClick={onTogglePanel}>Apply</span>
        </div>



        <div className={classNames(css.subjectsList, css.subjectsListOpen)}>
          {associatedOptions?.map((item, index) => {
            return Object.entries(item).map(([key, value], i) => {
              return (
                <span className={css.subjectsItem} key={i}>
                  <span
                    onClick={(e) => handleSubjectsClick(e, index)}
                    className={css.subjectsParent}>
                    {key}
                    {openSubjects[index] ? <IconMinus className={classNames(css.icon, css.iconMinus)} /> : <IconPlus className={css.icon} />}
                  </span>
                  {value.map((v, i) => {
                    if (v?.isHidden) return null;

                    return (
                      <span
                        onClick={(e) => handleSubjectItemClick(e, v, index)}
                        className={openSubjects[index] ? classNames(css.subjectItem, css.subjectItemOpen) : css.subjectItem}>
                        <div className={css.statesItem}>
                          <input
                            id={v.id}
                            type={'checkbox'}
                            className={css.input}
                          />
                          <label
                            onClick={event => handleSubjectItemClick(event, v, index, v.id)}
                            htmlFor={v.id} className={css.label}>
                            <span className={css.checkboxWrapper}>
                              <IconCheckbox
                                checkedClassName={checked[v.id] ? css.checkedSuccess : css.unChecked}
                              />
                            </span>
                            <span className={classNames(css.text, css.textClassName || css.textRoot)}>{v.label}
                            </span>
                          </label>
                        </div>
                      </span>
                    )}
                  )}
                </span>
              )
            })
          })}
        </div>

      </div>

    </div>
  );
};

SubjectsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  isOpen: true,
};

SubjectsPanel.propTypes = {
  className: string,
  rootClassName: string,
  isOpen: bool,
};

export default SubjectsPanel;
