import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { randomImage } from '../../util/randomImage';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import {
  getListingType, isJobType,
  isTeacherRole,
  LINE_ITEM_DAY,
  LINE_ITEM_NIGHT,
  propTypes,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { AspectRatioWrapper, NamedLink, ResponsiveImage, Avatar } from '../../components';

import css from './ListingCard.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}

const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });


export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    showDescription
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  let { title = '', description, price, publicData } = currentListing.attributes;
  price = price.amount ? new Money(price.amount, price.currency) : price;
  const slug = `${createSlug(title)}-${publicData.customId}`;
  const author = ensureUser(listing.author);
  const nickname = author?.attributes?.profile?.publicData?.nickname;
  const isTeacher = isTeacherRole(author);
  const rImage = randomImage('listingCard');

  // const authorName = author.attributes.profile.firstName  + " " + author.attributes.profile.lastName;
  const displayName = author.attributes.profile.displayName;

  const searchParams = author?.attributes?.profile?.publicData?.searchParams;
  const lastName = author?.attributes?.profile?.publicData?.lastName ? author?.attributes?.profile?.publicData?.lastName : null;
  const firstName = author?.attributes?.profile?.publicData?.firstName ? author?.attributes?.profile?.publicData?.firstName : null;
  const authorName =
    !lastName && !firstName && searchParams?.[0] && searchParams?.[1]
      ? (searchParams?.[0] + " " + searchParams?.[1])
      : lastName && firstName ? firstName + " " + lastName
        : displayName;
  // const authorName = searchParams?.[0] && searchParams?.[1] ? (searchParams?.[0] + " " + searchParams?.[1]) : displayName;
  const firstImage = currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const unitType = config.lineItemUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  return (
    <NamedLink className={classes} name="ListingCoursePage" params={{ slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
          isTeacher={isTeacher}
          randomImage={rImage}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice} <span className={css.priceSeat}>per seat</span>
          </div>
          {config.listing.showUnitTypeTranslations ? (
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
          ) : null}
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.authorInfo}>
            {showAuthorInfo && <Avatar className={css.avatar} user={author} />}
            <FormattedMessage id="ListingCard.author" values={{ authorName }} />
          </div>
          {showDescription && description && (
            <div className={css.description}>
              {description}
            </div>
          )}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
