import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import checkMarkAsset from './check-mark-asset.svg';

import css from './IconCheckMarkAssets.module.css';

const IconCheckMarkAssets = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <img className={classes} src={checkMarkAsset} alt="icon check" />
  );
};

IconCheckMarkAssets.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCheckMarkAssets.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCheckMarkAssets;
