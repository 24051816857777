import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { currentUserShowSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  getConversation,
  getListingByCustomId,
  getUserByCustomId,
  newConversation,
} from '../../util/api';
import { requestShowListing } from '../EditJobPage/EditJobPage.duck';
import merge from 'lodash/merge';
import { saveContactDetailsSuccess } from '../ContactDetailsPage/ContactDetailsPage.duck';
import {
  GET_LISTING_CUSTOM_ID_ERROR,
  GET_LISTING_CUSTOM_ID_REQUEST,
  GET_LISTING_CUSTOM_ID_SUCCESS, getCustomListingId,
  getListingCustomIdError,
  getListingCustomIdRequest,
  getListingCustomIdSuccess,
} from '../ListingPage/ListingPage.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const CONTACT_TEACHER_REQUEST = 'app/ProfilePage/CONTACT_TEACHER_REQUEST';
export const CONTACT_TEACHER_SUCCESS = 'app/ProfilePage/CONTACT_TEACHER_SUCCESS';
export const CONTACT_TEACHER_ERROR = 'app/ProfilePage/CONTACT_TEACHER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const GET_USER_BY_CUSTOM_ID_REQUEST = 'app/ProfilePage/GET_USER_BY_CUSTOM_ID_REQUEST';
export const GET_USER_BY_CUSTOM_ID_SUCCESS = 'app/ProfilePage/GET_USER_BY_CUSTOM_ID_SUCCESS';
export const GET_USER_BY_CUSTOM_ID_ERROR = 'app/ProfilePage/GET_USER_BY_CUSTOM_ID_ERROR';


// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  contactTeacherRequest: false,
  contactTeacherSuccess: false,
  contactTeacherError: null,

  getUserByCustomIdInProgress: false,
  getUserByCustomIdError: null,
  getUserByCustomIdSuccess: false,
  customUserId: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case CONTACT_TEACHER_REQUEST:
      return { ...state, contactTeacherRequest: true };
    case CONTACT_TEACHER_SUCCESS:
      return { ...state, contactTeacherRequest: false, contactTeacherSuccess: true };
    case CONTACT_TEACHER_ERROR:
      return { ...state, contactTeacherRequest: false, contactTeacherError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };


    case GET_USER_BY_CUSTOM_ID_REQUEST:
      return { ...state, getUserByCustomIdInProgress: true, getUserByCustomIdError: null };
    case GET_USER_BY_CUSTOM_ID_SUCCESS:
      return {
        ...state,
        getUserByCustomIdInProgress: false,
        getUserByCustomIdSuccess: true,
        getUserByCustomIdError: false,
        customUserId: payload,
      };
    case GET_USER_BY_CUSTOM_ID_ERROR:
      return { ...state, getUserByCustomIdInProgress: false, getUserByCustomIdSuccess: false, getUserByCustomIdError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const contactTeacherRequest = () => ({
  type: CONTACT_TEACHER_REQUEST,
});

export const contactTeacherSuccess = () => ({
  type: CONTACT_TEACHER_SUCCESS,
});

export const contactTeacherError = e => ({
  type: CONTACT_TEACHER_ERROR,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const getUserCustomIdRequest = () => ({ type: GET_USER_BY_CUSTOM_ID_REQUEST });
export const getUserCustomIdSuccess = (response) => ({ type: GET_USER_BY_CUSTOM_ID_SUCCESS, payload: response });
export const getUserCustomIdError = e => ({ type: GET_USER_BY_CUSTOM_ID_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const queryUserListings = userId => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      author_id: userId,
      include: ['author', 'images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => {
      dispatch(showUserError(storableError(e)));
    });
};

export const contactTeacher = values => (dispatch, getState, sdk) => {
  dispatch(contactTeacherRequest());

  return newConversation(values)
    .then(response => {
      dispatch(contactTeacherSuccess());
      return response;
    })
    .catch(e => dispatch(contactTeacherError(e.error)));
};

export const getUserIdByCustomId = (params) => (dispatch, getState, sdk) => {
  dispatch(getUserCustomIdRequest());

  const slug = params.slug.split('-').pop();

  return getUserByCustomId(slug)
    .then(response => {
      dispatch(getUserCustomIdSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(getUserCustomIdError(e.message));
      throw e;
    });
};

export const loadData = params => async (dispatch, getState, sdk) => {
  let userId = null;

  if (params?.id) {
    userId = new UUID(params.id);
  } else {
    await dispatch(getUserIdByCustomId(params));
    const customUserId = getState().ProfilePage?.customUserId?.id;
    userId = new UUID(customUserId);
  }


  // Clear state so that previously loaded data is not visible
  // in case this page load fails.

  return Promise.all([
    dispatch(setInitialState()),
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId)),
    dispatch(queryUserReviews(userId)),
  ]);
};
