import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer
} from "recharts";

import css from './LineChart.module.css';



const CustomizedDot = (props) => {
  const { cx, cy } = props;

  return (
    <svg x={cx - 8} y={cy - 8} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#23A0EC"/>
    </svg>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const LineChartComponent = props => {
  const { className, rootClassName, data, dataKey, xAxisDataKey } = props;
  const classes = classNames(rootClassName || css.root, className);



  const renderLineChart = (
    <ResponsiveContainer width='100%' height={200}>
      <ComposedChart

        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis  dataKey={xAxisDataKey}  />
        <YAxis interval={0} tick={{stroke: '#616161', strokeWidth: 0.1}} />
        <Tooltip content={<CustomTooltip />} />
        {/*<Legend />*/}
        <Area type="monotone" dataKey={dataKey} fill="#f2f9fe" stroke="#e6ecf1" />
        <Line type="monotone" dataKey={dataKey} stroke="#91cff5" strokeWidth={3} dot={<CustomizedDot />} />
      </ComposedChart>
    </ResponsiveContainer>

  );

  return (
    <div className={classes}>
      {renderLineChart}
    </div>
  );
};

const { string } = PropTypes;

LineChartComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

LineChartComponent.propTypes = {
  className: string,
  rootClassName: string,
};

export default LineChartComponent;
