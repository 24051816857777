import {
  getConversation,
  getOrCreateHiddenJob, initiatePrivileged,
  newListing,
  newMessage, transitionPrivileged,
  customUpdateListing,
  uploadFiles, updateUserProfile,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import {
  contactTeacherError,
  contactTeacherRequest,
  contactTeacherSuccess,
} from '../ProfilePage/ProfilePage.duck';

import * as log from '../../util/log';
import { storableError } from '../../util/errors';
import { isTeacherRole } from '../../util/types';
import {
  TRANSITION_ACCEPT_HIDDEN_JOB, TRANSITION_ACCEPT_JOB,
  TRANSITION_DECLINE_HIDDEN_JOB,
  TRANSITION_ENQUIRE_JOB,
} from '../../util/transaction';
import config from '../../config';
import {
  sendJobEnquiryError,
  sendJobEnquiryRequest,
  sendJobEnquirySuccess,
  showListing,
} from '../ListingPage/ListingPage.duck';
import moment from 'moment/moment';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { requestUpdateListing } from '../EditListingPage/EditListingPage.duck';
import { postUpdateTransactionMetadata } from '../TransactionPage/TransactionPage.duck';
import { updateMetaData } from '../../util/metaDataHelper';
const { Money } = sdkTypes;

// ================ Action types ================ //

export const RENDER_ERROR = 'app/ConversationPage/RENDER_ERROR';
export const FETCH_CONVERSATION_REQUEST = 'app/ConversationPage/FETCH_CONVERSATION_REQUEST';
export const FETCH_CONVERSATION_SUCCESS = 'app/ConversationPage/FETCH_CONVERSATION_SUCCESS';
export const FETCH_CONVERSATION_ERROR = 'app/ConversationPage/FETCH_CONVERSATION_ERROR';

export const SET_TEACHER = 'app/ConversationPage/SET_TEACHER';
export const SET_USER = 'app/ConversationPage/SET_USER';

export const NEW_MESSAGE_REQUEST = 'app/ConversationPage/NEW_MESSAGE_REQUEST';
export const NEW_MESSAGE_SUCCESS = 'app/ConversationPage/NEW_MESSAGE_SUCCESS';
export const NEW_MESSAGE_ERROR = 'app/ConversationPage/NEW_MESSAGE_ERROR';

export const INITIATE_JOB_TX_BY_ADMIN_REQUEST = 'app/ConversationPage/INITIATE_JOB_TX_BY_ADMIN_REQUEST';
export const INITIATE_JOB_TX_BY_ADMIN_SUCCESS = 'app/ConversationPage/INITIATE_JOB_TX_BY_ADMIN_SUCCESS';
export const INITIATE_JOB_TX_BY_ADMIN_ERROR = 'app/ConversationPage/INITIATE_JOB_TX_BY_ADMIN_ERROR';

export const ACCEPT_HIDDEN_JOB_REQUEST = 'app/ConversationPage/ACCEPT_HIDDEN_JOB_REQUEST';
export const ACCEPT_HIDDEN_JOB_SUCCESS = 'app/ConversationPage/ACCEPT_HIDDEN_JOB_SUCCESS';
export const ACCEPT_HIDDEN_JOB_ERROR = 'app/ConversationPage/ACCEPT_HIDDEN_JOB_ERROR';

export const DECLINE_JOB_REQUEST = 'app/ConversationPage/DECLINE_JOB_REQUEST';
export const DECLINE_JOB_SUCCESS = 'app/ConversationPage/DECLINE_JOB_SUCCESS';
export const DECLINE_JOB_ERROR = 'app/ConversationPage/DECLINE_JOB_ERROR';

export const HIDDEN_JOB_SUCCESS = 'app/ConversationPage/HIDDEN_JOB_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  renderError: null,
  fetchConversationInProgress: false,
  conversation: null,
  fetchConversationError: null,
  teacher: null,
  user: null,
  newMessageRequest: false,
  newMessageError: null,

  initiateJobTxByAdminInProgress: false,
  initiateJobTxByAdminSuccess: false,
  initiateJobTxByAdminError: null,

  acceptHiddenJobInProgress: false,
  acceptHiddenJobError: null,
  declineHiddenJobInProgress: false,
  declineHiddenJobError: null,
  hiddenTransaction: null,

  hiddenJob: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case RENDER_ERROR:
      return { ...state, renderError: 'Error' };
    case FETCH_CONVERSATION_REQUEST:
      return { ...state, fetchConversationInProgress: true, fetchConversationError: null };
    case FETCH_CONVERSATION_SUCCESS: {
      return { ...state, fetchConversationInProgress: false, conversation: payload };
    }
    case FETCH_CONVERSATION_ERROR:
      return { ...state, fetchConversationInProgress: false, fetchConversationError: payload };
    case SET_TEACHER:
      return { ...state, teacher: payload };
    case SET_USER:
      return { ...state, user: payload };
    case NEW_MESSAGE_REQUEST:
      return { ...state, newMessageRequest: true };
    case NEW_MESSAGE_SUCCESS:
      return { ...state, newMessageRequest: false };
    case NEW_MESSAGE_ERROR:
      return { ...state, newMessageRequest: false, newMessageError: payload };

    case INITIATE_JOB_TX_BY_ADMIN_REQUEST:
      return { ...state, initiateJobTxByAdminInProgress: true, initiateJobTxByAdminSuccess: false, initiateJobTxByAdminError: null };
    case INITIATE_JOB_TX_BY_ADMIN_SUCCESS:
      return { ...state, initiateJobTxByAdminInProgress: false, initiateJobTxByAdminSuccess: true };
    case INITIATE_JOB_TX_BY_ADMIN_ERROR:
      return { ...state, initiateJobTxByAdminInProgress: false, initiateJobTxByAdminError: payload };

    case ACCEPT_HIDDEN_JOB_REQUEST:
      return { ...state, acceptHiddenJobInProgress: true, acceptHiddenJobError: null };
    case ACCEPT_HIDDEN_JOB_SUCCESS:
      return { ...state, acceptHiddenJobInProgress: false, hiddenTransaction: payload };
    case ACCEPT_HIDDEN_JOB_ERROR:
      return { ...state, acceptHiddenJobInProgress: false, acceptHiddenJobError: payload };

    case DECLINE_JOB_REQUEST:
      return { ...state, declineHiddenJobInProgress: true, declineHiddenJobError: null };
    case DECLINE_JOB_SUCCESS:
      return { ...state, declineHiddenJobInProgress: false };
    case DECLINE_JOB_ERROR:
      return { ...state, declineHiddenJobInProgress: false, declineHiddenJobError: payload };

    case HIDDEN_JOB_SUCCESS:
      return { ...state, hiddenJob: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const renderError = () => ({ type: RENDER_ERROR });

const fetchConversationRequest = () => ({ type: FETCH_CONVERSATION_REQUEST });
const fetchConversationSuccess = response => ({ type: FETCH_CONVERSATION_SUCCESS, payload: response });
const fetchConversationError = e => ({ type: FETCH_CONVERSATION_ERROR, payload: e });

const setTeacher = response => ({ type: SET_TEACHER, payload: response });
const setUser = response => ({ type: SET_USER, payload: response });

const newMessageRequest = () => ({ type: NEW_MESSAGE_REQUEST });
const newMessageSuccess = () => ({ type: NEW_MESSAGE_SUCCESS });
const newMessageError = response => ({ type: NEW_MESSAGE_ERROR, payload: response });

const initiateJobTxByAdminRequest = () => ({ type: INITIATE_JOB_TX_BY_ADMIN_REQUEST });
const initiateJobTxByAdminSuccess = () => ({ type: INITIATE_JOB_TX_BY_ADMIN_SUCCESS });
const initiateJobTxByAdminError = response => ({ type: INITIATE_JOB_TX_BY_ADMIN_ERROR, payload: response });

const acceptHiddenJobRequest = () => ({ type: ACCEPT_HIDDEN_JOB_REQUEST });
const acceptHiddenJobSuccess = (order) => ({ type: ACCEPT_HIDDEN_JOB_SUCCESS, payload: order });
const acceptHiddenJobError = e => ({ type: ACCEPT_HIDDEN_JOB_ERROR, error: true, payload: e });

const declineHiddenJobRequest = () => ({ type: DECLINE_JOB_REQUEST });
const declineHiddenJobSuccess = () => ({ type: DECLINE_JOB_SUCCESS });
const declineHiddenJobError = e => ({ type: DECLINE_JOB_ERROR, error: true, payload: e });

const hiddenJobSuccess = response => ({ type: HIDDEN_JOB_SUCCESS, payload: response });

// ================ Thunks ================ //

export const createNewMessage = params => async (dispatch, getState, sdk) => {
  dispatch(newMessageRequest());
  const currentUser = getState().user.currentUser;

  return newMessage(params)
    .then(() => {
      dispatch(newMessageSuccess());
      return getConversation({ conversationId : params.conversationId, currentUserId: currentUser.id.uuid  })
    })
    .then(response => dispatch(fetchConversationSuccess(response)))
    .catch(e => dispatch(newMessageError(e.error)));
}

export const initiateJobTxByAdmin = params => async (dispatch, getState, sdk) => {
  const hiddenJob = getState()?.ConversationPage?.hiddenJob;
  const { conversationId, hours, pricePerSeats } = params;
  const currentUser = getState().user.currentUser;

  // 15% commission, so provider gets 85% from payment
  const priceTotal = pricePerSeats * hours;
  const priceTotalWithCommission = ((pricePerSeats * hours)*85)/100;
  const commission = (((pricePerSeats * hours) * -15)/100) * -1;
  const halfPriceTotalWithCommission = (((pricePerSeats * hours)*85)/100)/2;

  const newFutureTransactions = {
    conversationId,
    commission,
    halfPriceTotalWithCommission,
    hours,
    pricePerSeats,
    priceTotal,
    priceTotalWithCommission
  };

  const messageParams = {
    userId: currentUser.id.uuid,
    text: "We are pleased to offer you a job. " +
      "We think that your experience and skills will be a valuable asset to us. " +
      "Looking forward to further discussing with you!",
    conversationId,
  }

  const futureTransactions = hiddenJob.attributes.publicData?.futureTransactions || [];

  const data = {
    id: hiddenJob.id.uuid,
    publicData: {
      futureTransactions: [...futureTransactions, newFutureTransactions],
    }
  };

  dispatch(initiateJobTxByAdminRequest());

  return sdk.ownListings.update(data, { expand: true })
    .then(() => getOrCreateHiddenJob(currentUser?.id?.uuid))
    .then(response => dispatch(hiddenJobSuccess(response)))
    // .then(() => dispatch(postUpdateTransactionMetadata({transitionsToNotify: {}})))
    .then(() => dispatch(initiateJobTxByAdminSuccess()))
    .then(() => dispatch(newMessage(messageParams)))
    .catch(e => dispatch(initiateJobTxByAdminError(storableError(e))));
}

export const acceptHiddenJob = (params) => (dispatch, getState, sdk) => {
  dispatch(acceptHiddenJobRequest());

  const isJob = true;
  const pricePerSeats = params?.pricePerSeats;
  const hours = Number.parseInt(params?.units, 10);
  const priceTotal = pricePerSeats * hours;
  const priceTotalWithCommission = ((pricePerSeats * hours)*85)/100;
  const commission = (((pricePerSeats * hours) * -15)/100) * -1;
  const halfPriceTotalWithCommission = (((pricePerSeats * hours)*85)/100)/2;


  const transitionParams = {
    listingId: params.listingId,
    seats: 1,
    units: Number.parseInt(params?.units, 10),
    protectedData: {
      conversationId: params?.conversationId,
      hours,
      pricePerSeats,
      priceTotal,
      commission,
      priceTotalWithCommission,
      halfPriceTotalWithCommission,
    },
  };

  const bodyParams = {
    transition: TRANSITION_ACCEPT_HIDDEN_JOB,
    processAlias: config.jobProcessAlias,
    params: transitionParams,
  }

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const orderData = {
    conversationId: params?.conversationId,
    seats: 1,
    units: Number.parseInt(params?.units, 10),
  };

  return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
    .then(response => {
      const transaction = response.data.data;
      updateMetaData(transaction, getState, dispatch, isJob);
      dispatch(addMarketplaceEntities(response));
      const transactionId = response.data.data.id;
      dispatch(acceptHiddenJobSuccess());
      dispatch(fetchCurrentUserHasOrdersSuccess(true));

      const transitions = response.data.data.attributes.transitions;
      const transitionsToNotify = transitions.map(t => {
        return {transition: t.transition, isReadByTeacher: true, isReadByAdmin: false, isJob: true}
      })

      const metaDataParams = {
        transactionId,
        transitionsToNotify
      }
      dispatch(postUpdateTransactionMetadata(metaDataParams));

      customUpdateListing({
        listingId: params.listingId,
        publicData: {
          futureTransactions: params?.removedFutureTransaction
        },
      })

      return transactionId;
    })
    .catch(e => {
      dispatch(acceptHiddenJobError(storableError(e)));
      throw e;
    });
};

export const declineHiddenJob = (params) => (dispatch, getState, sdk) => {
  dispatch(sendJobEnquiryRequest());
  const isJob = true;
  const bodyParams = {
    transition: TRANSITION_DECLINE_HIDDEN_JOB,
    processAlias: config.jobProcessAlias,
    params: { listingId: params.listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;
      const transaction = response.data.data;
      updateMetaData(transaction, getState, dispatch, isJob);
      dispatch(declineHiddenJobSuccess());
      dispatch(fetchCurrentUserHasOrdersSuccess(true));

      customUpdateListing({
        listingId: params.listingId,
        publicData: {
          futureTransactions: params?.removedFutureTransaction
        },
      })
      return transactionId;
    })
    .catch(e => {
      dispatch(declineHiddenJobError(storableError(e)));
      throw e;
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => async (dispatch, getState, sdk) => {
  let currentUserIsTeacher = false;
  let currentUser, initiatorId, receiverId, conversation = null;

  dispatch(fetchConversationRequest());

  return dispatch(fetchCurrentUser())
    .then(() => {
      currentUser = getState().user.currentUser;
      return getConversation({conversationId: params.id, currentUserId: currentUser.id.uuid });
    })
    .then(response => {
      conversation = response;
      initiatorId = response?.initiatorId;
      receiverId = response?.receiverId;

      currentUserIsTeacher = isTeacherRole(currentUser) && currentUser?.id.uuid === receiverId;

      return getOrCreateHiddenJob(!currentUserIsTeacher ? currentUser?.id.uuid : initiatorId);
    })
    .then(response => {
      dispatch(hiddenJobSuccess(response));

      if (currentUser?.id.uuid === initiatorId || currentUser?.id.uuid === receiverId) {
        currentUserIsTeacher ? dispatch(setTeacher(currentUser)) : dispatch(setUser(currentUser));
        dispatch(fetchConversationSuccess(conversation));

        return sdk.users.show({
          id: currentUserIsTeacher ? initiatorId : receiverId,
          include: ['profileImage'],
          'fields.image': ['variants.square-small', 'variants.square-small2x', 'variants.landscape-crop', 'variants.landscape-crop2x'],
        });
      } else {
        dispatch(renderError());
      }
    })
    .then(response => {
      const user = denormalisedResponseEntities(response);
      return currentUserIsTeacher ? dispatch(setUser(user[0])) : dispatch(setTeacher(user[0]));
    })
    .catch(e => dispatch(fetchConversationError(e.error)));
};
