import merge from 'lodash/merge';
import {denormalisedResponseEntities} from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';

import { signups } from "../../util/api";


// ================ Action types ================ //

export const GET_ALL_SIGNUPS_REQUEST = 'app/SignUpAnalyticsPage/GET_ALL_SIGNUPS_REQUEST';
export const GET_ALL_SIGNUPS_SUCCESS = 'app/SignUpAnalyticsPage/GET_ALL_SIGNUPS_SUCCESS';
export const GET_ALL_SIGNUPS_ERROR = 'app/SignUpAnalyticsPage/GET_ALL_SIGNUPS_ERROR';

export const GET_TEACHERS_SIGNUPS_REQUEST = 'app/SignUpAnalyticsPage/GET_TEACHERS_SIGNUPS_REQUEST';
export const GET_TEACHERS_SIGNUPS_SUCCESS = 'app/SignUpAnalyticsPage/GET_TEACHERS_SIGNUPS_SUCCESS';
export const GET_TEACHERS_SIGNUPS_ERROR = 'app/SignUpAnalyticsPage/GET_TEACHERS_SIGNUPS_ERROR';

export const GET_PARENTS_SIGNUPS_REQUEST = 'app/SignUpAnalyticsPage/GET_PARENTS_SIGNUPS_REQUEST';
export const GET_PARENTS_SIGNUPS_SUCCESS = 'app/SignUpAnalyticsPage/GET_PARENTS_SIGNUPS_SUCCESS';
export const GET_PARENTS_SIGNUPS_ERROR = 'app/SignUpAnalyticsPage/GET_PARENTS_SIGNUPS_ERROR';

export const GET_SCHOOL_ADMINS_SIGNUPS_REQUEST = 'app/SignUpAnalyticsPage/GET_SCHOOL_ADMINS_SIGNUPS_REQUEST';
export const GET_SCHOOL_ADMINS_SIGNUPS_SUCCESS = 'app/SignUpAnalyticsPage/GET_SCHOOL_ADMINS_SIGNUPS_SUCCESS';
export const GET_SCHOOL_ADMINS_SIGNUPS_ERROR = 'app/SignUpAnalyticsPage/GET_SCHOOL_ADMINS_SIGNUPS_ERROR';

// ================ Reducer ================ //

const initialState = {

  getAllSignUpsInProgress: false,
  getAllSignUpsSuccess: false,
  getAllSignUpsError: null,
  allSignUpsAnalytics: [],

  getTeachersSignUpsInProgress: false,
  getTeachersSignUpsSuccess: false,
  getTeachersSignUpsError: null,
  teachersSignUpsAnalytics: [],

  getParentsSignUpsInProgress: false,
  getParentsSignUpsSuccess: false,
  getParentsSignUpsError: null,
  parentsSignUpsAnalytics: [],

  getSchoolAdminsSignUpsInProgress: false,
  getSchoolAdminsSignUpsSuccess: false,
  getSchoolAdminsSignUpsError: null,
  schoolAdminsSignUpsAnalytics: [],


};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_SIGNUPS_REQUEST:
      return { ...state, getAllSignUpsInProgress: true, getAllSignUpsSuccess: false, getAllSignUpsError: null };
    case GET_ALL_SIGNUPS_SUCCESS:
      return { ...state, getAllSignUpsInProgress: false, getAllSignUpsSuccess: true, allSignUpsAnalytics: payload, getAllSignUpsError: false };
    case GET_ALL_SIGNUPS_ERROR:
      return { ...state, getAllSignUpsInProgress: false, getAllSignUpsSuccess: false, getAllSignUpsError: payload };

    case GET_TEACHERS_SIGNUPS_REQUEST:
      return { ...state, getTeachersSignUpsInProgress: true, getTeachersSignUpsSuccess: false, getTeachersSignUpsError: null };
    case GET_TEACHERS_SIGNUPS_SUCCESS:
      return { ...state, getTeachersSignUpsInProgress: false, getTeachersSignUpsSuccess: true, teachersSignUpsAnalytics: payload, getTeachersSignUpsError: false };
    case GET_TEACHERS_SIGNUPS_ERROR:
      return { ...state, getTeachersSignUpsInProgress: false, getTeachersSignUpsSuccess: false, getTeachersSignUpsError: payload };

    case GET_PARENTS_SIGNUPS_REQUEST:
      return { ...state, getParentsSignUpsInProgress: true, getParentsSignUpsSuccess: false, getParentsSignUpsError: null };
    case GET_PARENTS_SIGNUPS_SUCCESS:
      return { ...state, getParentsSignUpsInProgress: false, getParentsSignUpsSuccess: true, parentsSignUpsAnalytics: payload, getParentsSignUpsError: false };
    case GET_PARENTS_SIGNUPS_ERROR:
      return { ...state, getParentsSignUpsInProgress: false, getParentsSignUpsSuccess: false, getParentsSignUpsError: payload };

    case GET_SCHOOL_ADMINS_SIGNUPS_REQUEST:
      return { ...state, getSchoolAdminsSignUpsInProgress: true, getSchoolAdminsSignUpsSuccess: false, getSchoolAdminsSignUpsError: null };
    case GET_SCHOOL_ADMINS_SIGNUPS_SUCCESS:
      return { ...state, getSchoolAdminsSignUpsInProgress: false, getSchoolAdminsSignUpsSuccess: true, schoolAdminsSignUpsAnalytics: payload, getSchoolAdminsSignUpsError: false };
    case GET_SCHOOL_ADMINS_SIGNUPS_ERROR:
      return { ...state, getSchoolAdminsSignUpsInProgress: false, getSchoolAdminsSignUpsSuccess: false, getSchoolAdminsSignUpsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const getAllSignUpsRequest = () => ({ type: GET_ALL_SIGNUPS_REQUEST });
const getAllSignUpsSuccess = response => ({ type: GET_ALL_SIGNUPS_SUCCESS, payload: response});
const getAllSignUpsError = e => ({ type: GET_ALL_SIGNUPS_ERROR, error: true, payload: e});

const getTeachersSignUpsRequest = () => ({ type: GET_TEACHERS_SIGNUPS_REQUEST });
const getTeachersSignUpsSuccess = response => ({ type: GET_TEACHERS_SIGNUPS_SUCCESS, payload: response});
const getTeachersSignUpsError = e => ({ type: GET_TEACHERS_SIGNUPS_ERROR, error: true, payload: e});

const getParentsSignUpsRequest = () => ({ type: GET_PARENTS_SIGNUPS_REQUEST });
const getParentsSignUpsSuccess = response => ({ type: GET_PARENTS_SIGNUPS_SUCCESS, payload: response});
const getParentsSignUpsError = e => ({ type: GET_PARENTS_SIGNUPS_ERROR, error: true, payload: e});

const getSchoolAdminsSignUpsRequest = () => ({ type: GET_SCHOOL_ADMINS_SIGNUPS_REQUEST });
const getSchoolAdminsSignUpsSuccess = response => ({ type: GET_SCHOOL_ADMINS_SIGNUPS_SUCCESS, payload: response});
const getSchoolAdminsSignUpsError = e => ({ type: GET_SCHOOL_ADMINS_SIGNUPS_ERROR, error: true, payload: e});

// ================ Thunks ================ //


export const getSignUpsAnalytics = params => (dispatch, getState, sdk) => {
  dispatch(getAllSignUpsRequest());

  const searchParams = new URLSearchParams(params).toString()

  return signups(searchParams)
    .then(response => {
      dispatch(getAllSignUpsSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch((getAllSignUpsError(e)));
    });
};


export const loadData = (params, search, location) => (dispatch, getState, sdk) => {

  const substringToRemove = '/dashboard/signup/';
  const getUserRoleString = location.pathname.replace(substringToRemove, '');
  let userRole;


  if(getUserRoleString === 'teachers') {
    userRole = 'teacher';
  }
  if(getUserRoleString === 'parents') {
    userRole = 'parent';
  }
  if(getUserRoleString === 'school-admins'){
    userRole = 'schoolAdministrator';
  }


  const bodyParams = userRole ? {pub_userRole: userRole, year: '2023'} : {year: '2023'};

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(getSignUpsAnalytics(bodyParams)),
  ]);
};
