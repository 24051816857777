import pick from 'lodash/pick';
import config from '../../config';
import {
  initiatePrivileged,
  saveTransaction,
  transitionPrivileged,
  chargeCustomer,
  updateUserProfile
} from '../../util/api';
import {denormalisedResponseEntities, ensureStripeCustomer} from '../../util/data';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { deletePaymentMethod } from '../../ducks/paymentMethods.duck';
import { closeListing } from '../../containers/ManageListingsPage/ManageListingsPage.duck';


import { storableError } from '../../util/errors';
import moment from 'moment';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_PAY_FOR_JOB,
  TRANSITION_REQUEST_PAYMENT_AFTER_NEGOTIATION,
  TRANSITION_FREE_REQUEST_PAYMENT_AFTER_NEGOTIATION,
  TRANSITION_FREE_REQUEST_PAYMENT,
  isPrivileged,
  TRANSITION_FREE_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_JOB_PAYMENT,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { postUpdateTransactionMetadata } from '../TransactionPage/TransactionPage.duck';
import { updateMetaData } from '../../util/metaDataHelper';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const FREE_CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/FREE_CONFIRM_PAYMENT_REQUEST';
export const FREE_CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/FREE_CONFIRM_PAYMENT_SUCCESS';
export const FREE_CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/FREE_CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  freeConfirmPaymentError: null,
  stripeCustomerFetched: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case FREE_CONFIRM_PAYMENT_REQUEST:
      return { ...state, freeConfirmPaymentError: null };
    case FREE_CONFIRM_PAYMENT_SUCCESS:
      return state;
    case FREE_CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, freeConfirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });
const confirmPaymentSuccess = orderId => ({ type: CONFIRM_PAYMENT_SUCCESS, payload: orderId,});
const confirmPaymentError = e => ({ type: CONFIRM_PAYMENT_ERROR, error: true, payload: e,});

const freeConfirmPaymentRequest = () => ({ type: FREE_CONFIRM_PAYMENT_REQUEST });
const freeConfirmPaymentSuccess = orderId => ({ type: FREE_CONFIRM_PAYMENT_SUCCESS, payload: orderId,});
const freeConfirmPaymentError = e => ({ type: FREE_CONFIRM_PAYMENT_ERROR, error: true, payload: e,});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId, transaction) => async (dispatch, getState, sdk) => {
  await dispatch(fetchCurrentUser());
  let currentUser = getState().user.currentUser;
  const {
    discount,
    halfPriceTotalWithCommission,
    token,
    deliveryMethod,
    quantity,
    seats,
    units,
    bookingDates,
    negotiation,
    isJob,
    isProviderStripeAccountConnected,
    ...otherOrderParams
  } = orderParams;

  const discountExist =
    currentUser?.attributes?.profile?.privateData?.reward > 0 &&
    currentUser?.attributes?.profile?.privateData.transactionTokens.includes(token);

  const last4Digits = currentUser?.stripeCustomer?.defaultPaymentMethod?.attributes?.card?.last4Digits;
  const cardBrand = currentUser?.stripeCustomer?.defaultPaymentMethod?.attributes?.card?.brand;

  const percentage = (num, per) => (num / 100) * per;

  dispatch(initiateOrderRequest());

  console.log('init1111')

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition && negotiation && isProviderStripeAccountConnected
    ? TRANSITION_REQUEST_PAYMENT_AFTER_NEGOTIATION
    : isTransition && negotiation && !isProviderStripeAccountConnected
    ? TRANSITION_FREE_REQUEST_PAYMENT_AFTER_NEGOTIATION
    : isTransition && isJob
      ? TRANSITION_PAY_FOR_JOB
    : isTransition
      ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : !isProviderStripeAccountConnected
      ? TRANSITION_FREE_REQUEST_PAYMENT
      : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingParamsMaybe = bookingDates || {};
  let bookingStart, bookingEnd;
  // if(isJob) {
  //   bookingStart = bookingDates?.bookingStart;
  //   bookingEnd = bookingDates?.bookingEnd;
  // }

  bookingStart = bookingDates?.bookingStart;
  bookingEnd = bookingDates?.bookingEnd;
  const middleDate = new Date(bookingEnd - (bookingEnd-bookingStart)/2);

  let endRefundPeriod = new Date(bookingStart);
  endRefundPeriod = endRefundPeriod.setDate(endRefundPeriod.getDate() + 7); // 7 - days
  let diff = (new Date(bookingStart).getTime() - new Date(bookingEnd).getTime()) / 1000;
  diff /= (60 * 60 * 24);
  const over3Weeks = Math.abs(Math.round(diff)) > 21;  // 21 - it's 3 weeks
  const halfPriceTotalWithCommission40 = percentage(halfPriceTotalWithCommission * 2, 40).toFixed(2);
  const halfPriceTotalWithCommission60 = percentage(halfPriceTotalWithCommission * 2, 60).toFixed(2);


  // Parameters only for client app's server
  const orderData = {
    ...(isJob && {bookingStart}),
    ...(isJob && {bookingEnd}),
    seats,
    units,
    pd: orderParams.pd || null,
  };

  if (discount && discountExist) orderData.discount = discount;

  // Parameters for Flex API
  const transitionParams = {
    seats,
    units,
    protectedData: {
      last4Digits,
      cardBrand,
      halfPriceTotalWithCommission,
      halfPriceTotalWithCommission40,
      halfPriceTotalWithCommission60,
      ...(!isJob && {middleDate: moment(middleDate).format('MMM DD, YYYY'),}),
      ...(!isJob && {firstPayoutDate: moment(new Date(over3Weeks ? endRefundPeriod : bookingStart)).format('MMM DD, YYYY'),}),
      isProviderStripeAccountConnected,
      ...(isTransition && isJob && transition === TRANSITION_PAY_FOR_JOB && {stripePaymentIntents: true})
    },
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };


  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params: transitionParams,
    } : {
      processAlias: config.transactionProcessAlias,
      transition,
      params: transitionParams,
  };


  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    const transaction = response.data.data;
    updateMetaData(transaction, getState, dispatch, isJob);
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      seats,
      units,
      // ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  const discountMaybe = async (discount, discountExist, res) => {
    if (discount && discountExist) {
      const usedRewards = res?.data?.data?.attributes?.lineItems.find(i => i.code === 'line-item/discount')?.unitPrice?.amount / 100 / 100;
      currentUser = getState().user.currentUser;
      const reward = currentUser?.attributes?.profile?.privateData?.reward || 1;
      let currTokens = currentUser?.attributes?.profile?.privateData?.transactionTokens.filter(i => i !== token);
      if (currTokens.length > 20) currTokens = currTokens.slice(0, 10);
      await dispatch(updateProfile({ privateData: { reward: +reward + usedRewards, transactionTokens: currTokens } }));
    }
  }


  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(res => {
        discountMaybe(discount, discountExist, res);
        return res;
      })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(res => {
        discountMaybe(discount, discountExist, res);
        const transactionId = res.data.data.id;
        const transitions = res.data.data.attributes.transitions;
        const transitionsToNotify = transitions.map(t => {
          return {transition: t.transition, isReadByTeacher: false, isReadByAdmin: true, isCourse: true}
        })

        const metaDataParams = {
          transactionId,
          transitionsToNotify
        }
        dispatch(postUpdateTransactionMetadata(metaDataParams));

        return res;
      })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const { orderData, transaction } = orderParams;


  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  const currentUser = getState()?.user?.currentUser;
  const { customerDiscount, providerDiscount } = orderData;
  const providerId = transaction.provider.id.uuid;

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .then(async (response) => {
      if(customerDiscount) {
        await dispatch(updateProfile({publicData: {discountStatus: false}}));
      }
      return response;
    }).then(async (response) => {
      if(providerDiscount) {
        await updateUserProfile({userId: providerId, publicData: {discountStatus: false}})
      }
      return response;
    })
    .then(async order => {
      await saveTransaction({transactionId: orderParams.transactionId.uuid });
      return order;
    })
    .then(dispatch(updateProfile({})))
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const freeConfirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(freeConfirmPaymentRequest());

  const { orderData, transaction, paymentMethod, isCardSaved, isJob, listing } = orderParams;
  const stripeCustomerId = ensureStripeCustomer(getState().user.currentUser.stripeCustomer).attributes.stripeCustomerId || orderParams.attributes.stripeCustomerId;

  const currentUser = getState()?.user?.currentUser;
  const { customerDiscount, providerDiscount } = orderData;


  const bodyParams = {
    transactionId: transaction.id.uuid,
    payment_method: paymentMethod,
    customer_id: stripeCustomerId,
  };

  const providerId = transaction.provider.id.uuid;
  const customerId = currentUser.id.uuid;

  return chargeCustomer(bodyParams)
    .then(response => {
      isJob
        ? dispatch(transitionCustomTransition({transition: TRANSITION_CONFIRM_JOB_PAYMENT, transactionId: transaction.id.uuid}))
        : dispatch(transitionCustomTransition({transition: TRANSITION_FREE_CONFIRM_PAYMENT, transactionId: transaction.id.uuid}));


      updateMetaData(transaction, getState, dispatch, isJob);
      dispatch(freeConfirmPaymentSuccess(true));
      !isCardSaved && dispatch(deletePaymentMethod());
      dispatch(closeListing(listing.id))


      return response;
    }).then(async (response) => {
      if(customerDiscount) {
        await dispatch(updateProfile({publicData: {discountStatus: false}}));
      }
      return response;
    }).then(async (response) => {
      if(providerDiscount) {
        await updateUserProfile({userId: providerId, publicData: {discountStatus: false}})
      }
      return response;
    }).then(async order => {
      if(!isJob) {
        await saveTransaction({transactionId: transaction.id.uuid, userId: providerId });
      }
      return order;
    })
    .catch(e => {
      dispatch(freeConfirmPaymentError(storableError(e)));
      !isCardSaved && dispatch(deletePaymentMethod());
      throw e;
    });
};

export const transitionCustomTransition = param => (dispatch, getState, sdk) => {
  const { transactionId, transition } = param;

  return sdk.transactions
    .transition(
      {
        id: transactionId,
        transition,
        params: {},
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));

      return response;
    })
    .catch(e => {
      log.error(e, 'reject-sale-failed', {
        txId: transactionId,
        transition,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => async (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());
  await dispatch(fetchCurrentUser());

  const {
    deliveryMethod,
    quantity,
    seats,
    units,
    bookingDates,
    discount,
    negotiation,
    isJob,
    halfPriceTotalWithCommission,
    isProviderStripeAccountConnected,
    ...otherOrderParams
  } = orderParams;

  console.log('speculate111')

  const currentUser = getState().user.currentUser;
  const discountExist = currentUser?.attributes?.profile?.privateData?.reward > 0;

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition && negotiation && isProviderStripeAccountConnected
    ? TRANSITION_REQUEST_PAYMENT_AFTER_NEGOTIATION
    : isTransition && negotiation && !isProviderStripeAccountConnected
      ? TRANSITION_FREE_REQUEST_PAYMENT_AFTER_NEGOTIATION
    : isTransition && isJob
      ? TRANSITION_PAY_FOR_JOB
    : isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : !isProviderStripeAccountConnected
      ? TRANSITION_FREE_REQUEST_PAYMENT
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingParamsMaybe = bookingDates || {};
  let bookingStart, bookingEnd;
  // if(isJob) {
  //   bookingStart = bookingDates?.bookingStart;
  //   bookingEnd = bookingDates?.bookingEnd;
  // }

  bookingStart = bookingDates?.bookingStart;
  bookingEnd = bookingDates?.bookingEnd;

  // const middleDate = new Date((bookingStart.getTime() + bookingEnd.getTime()) / 2);

  const middleDate = new Date(bookingEnd - (bookingEnd-bookingStart)/2);


  // Parameters only for client app's server
  const orderData = {
    ...(isJob && {bookingStart}),
    ...(isJob && { bookingEnd}),
    pd: orderParams.pd || null,
    seats: orderParams.seats,
    units: orderParams.units,
  };

  if (discount && discountExist) orderData.discount = discount;

  // Parameters for Flex API
  const transitionParams = {
    seats,
    units,
    protectedData: {
      halfPriceTotalWithCommission,
      ...(!isJob && {middleDate: moment(middleDate).format('MMM DD, YYYY'),}),
      isProviderStripeAccountConnected,
    },
    // ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };


  const bodyParams =  isTransition
      ? {
        id: transactionId,
        transition,
        params: transitionParams,
      }
      : {
        processAlias: isJob ? config.jobProcessAlias : config.transactionProcessAlias,
        transition,
        params: transitionParams,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      seats,
      units: 1,
      // ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  }  else if (!isProviderStripeAccountConnected && !isJob) {
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};
