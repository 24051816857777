import React, { useState } from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaLinkedin,
  Logo,
  ExternalLink,
  NamedLink,
  Modal,
  ModalInviteFriend, Button,
} from '../../components';
import { manageDisableScrolling } from '../../ducks/UI.duck';

import css from './Footer.module.css';
import {isTeacherRole} from "../../util/types";
import ModalSignUp from '../ModalSignUp/ModalSignUp';
import routeConfiguration from '../../routing/routeConfiguration';

const { REACT_APP_REWARD_FOR_INVITATION_TEACHER } = process.env;
const CENTS = 100;

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteLinkedinPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToLinkedIn = intl.formatMessage({ id: 'Footer.goToLinkedin' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const linkedinLink = siteLinkedinPage ? (
    <ExternalLink
      key="linkToLinkedin"
      href={siteLinkedinPage}
      className={css.icon}
      title={goToLinkedIn}
    >
      <IconSocialMediaLinkedin />
    </ExternalLink>
  ) : null;

  return [fbLink, twitterLink, instragramLink, linkedinLink].filter(v => v != null);
};

const FooterComponent = props => {
  const {
    rootClassName,
    className,
    intl,
    currentUser,
    onManageDisableScrolling,
    currentPage,
    handleGetStarted,
    location,
  } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, {[css.rootUnlogged] : !currentUser && currentPage !== "AuthenticationPage"} ,className);
  const isTeacher = isTeacherRole(currentUser);

  const [isInviteFriendOpen, setIsInviteFriendOpen] = useState(false);
  const [isOpenSigUpModal, setIsOpenSigUpModal] = useState(false);

  const handleInviteFriendClose = () => {
    setIsInviteFriendOpen(false)
  }

  return (
    <div className={classes}>
      <div className={css.content}>
        {/* <div className={css.someLiksMobile}>{socialMediaLinks}</div> */}
        <div className={css.links}>
          <div className={css.organization} id="organization">
            <NamedLink name="LandingPage" className={css.logoLink} ariaLabel="Logo">
              <Logo format="desktop" className={css.logo} />
            </NamedLink>
            {/*<div className={css.organizationInfo}>*/}
            {/*  <p className={css.organizationDescription}>*/}
            {/*    <FormattedMessage id="Footer.organizationDescription" />*/}
            {/*  </p>*/}
            {/*</div> */}

            <div className={css.organizationInfo}>
              <p className={css.organizationDescription}>
                Images Designed by Freepik
              </p>
            </div>
          </div>

          <div className={css.infoLinks}>
            <div className={css.footerListTitle}>
              <FormattedMessage id="Footer.usingAllCourse" />
            </div>
            <ul className={css.list}>
              {/*<li className={css.listItem}>*/}
              {/*  <NamedLink name="NewListingPage" className={css.link}>*/}
              {/*    <FormattedMessage id="Footer.teachACourse" />*/}
              {/*  </NamedLink>*/}
              {/*</li>*/}

              <li className={css.listItem}>
                <NamedLink name="OnlineTeachersForHirePage" className={css.link}>
                  <FormattedMessage id="Footer.findATeacher" />
                </NamedLink>
              </li>
              {isTeacher && <li className={css.listItem}>
                <NamedLink name="SearchPage" to={{search: 'type=job'}} className={css.link}>
                  <FormattedMessage id="Footer.findAJob"/>
                </NamedLink>
              </li>}
              <li className={css.listItem}>
                <NamedLink name="SignupTeacherPage" className={css.link}>
                  <FormattedMessage id="Footer.becomeATeacherLink" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="SearchPage" to={{ search: 'type=course' }} className={css.link}>
                  <FormattedMessage id="Footer.findACourse" />
                </NamedLink>
              </li>
            </ul>
          </div>
          <div className={css.searches}>
            <div className={css.footerListTitle}>
              <FormattedMessage id="Footer.about" />
            </div>
            <ul className={css.list}>
              <li className={css.listItem}>
                <NamedLink name="AboutPage" className={css.link}>
                  <FormattedMessage id="Footer.company" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="NewsListPage" className={css.link}>
                  <FormattedMessage id="Footer.news" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="JobsPage" className={css.link}>
                  <FormattedMessage id="Footer.jobsPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="TermsOfServicePage" className={css.link}>
                  <FormattedMessage id="Footer.termsOfUse" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="PrivacyPolicyPage" className={css.link}>
                  <FormattedMessage id="Footer.privacyPolicy" />
                </NamedLink>
              </li>
            </ul>
          </div>
          <div className={css.searchesExtra}>
            <div className={css.footerListTitle}>
              <FormattedMessage id="Footer.helpAndSupport" />
            </div>
            <ul className={css.list}>
              <li className={css.listItem}>
                <NamedLink name="TeacherHandbookPage" className={css.link}>
                  <FormattedMessage id="Footer.teacherHandBook" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="FAQPage" to={{ hash: '#general' }} className={css.link}>
                  <FormattedMessage id="Footer.faq" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="ContactUsPage" className={css.link}>
                  <FormattedMessage id="Footer.toContactPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <ExternalLink href="/blog"className={css.link}>
                 <FormattedMessage id="Footer.toBlog" />
                </ExternalLink>
              </li>
            </ul>
          </div>
          {/* <div className={css.infoLinksHolder}>
          </div> */}


          <div className={css.extraLinks}>
            <div className={css.someLinks}>{socialMediaLinks}</div>

            <NamedLink name="ReferralPage" className={css.referringLink}>
              <FormattedMessage id="Footer.earn200" values={{ price: <b>${ REACT_APP_REWARD_FOR_INVITATION_TEACHER / CENTS }</b> }} />
            </NamedLink>
          </div>

        </div>
      </div>
      <p className={css.organizationCopyright}>
        <NamedLink name="LandingPage" className={css.copyrightLink}>
          <FormattedMessage id="Footer.copyright" />
        </NamedLink>
      </p>

      {!currentUser && currentPage !== "AuthenticationPage" && currentPage !== "SearchPage" && (
        <Button className={css.becomeATeacher} onClick={() => setIsOpenSigUpModal(true)}>
          <FormattedMessage id="Footer.becomeATeacher" />
        </Button>
      )}

      <Modal
        id="TopbarInviteFriend"
        isOpen={isInviteFriendOpen}
        onClose={handleInviteFriendClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={" "}
      >
        <ModalInviteFriend
          currentUser={currentUser}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      </Modal>
      <Modal
        id="TopbarSignUp"
        isOpen={isOpenSigUpModal}
        onClose={() => setIsOpenSigUpModal(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={" "}
      >
        <ModalSignUp
          onClose={() => setIsOpenSigUpModal(false)}
          location={location}
          routes={routeConfiguration()}
        />
      </Modal>
    </div>
  );
};

FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {

  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) => dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const Footer = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(FooterComponent);

export default injectIntl(Footer);
