import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import Modal from '../Modal/Modal';
import { FormattedMessage } from '../../util/reactIntl';

import css from './TermsModal.module.css';

const TermsModalComponent = props => {
  const { className, rootClassName, currentUser, onManageDisableScrolling } = props;
  const [isOpen, setIsOpen] = useState(false)

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <span className={css.openModalBtn} onClick={() => setIsOpen(true)}>
        <FormattedMessage id={"TermsModal.openModalLinkText"} />
      </span>
      <Modal
        id='termsModal'
        // containerClassName={classes}
        // contentClassName={css.modalContent}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
        closeButtonMessage=' '
      >
        <div className={css.modalContent}>
          <p className={css.modalTitle}>
            <FormattedMessage id="TermsModal.title" />
          </p>
          <div className={css.modalList}>
            <div className={css.modalItem}>
              <p className={css.modalItemTitle}>
                <FormattedMessage id="TermsModal.scope" />
              </p>
              <div className={css.modalItemDescription}>
                <FormattedMessage id="TermsModal.scopeText" />
              </div>
            </div>
            <div className={css.modalItem}>
              <p className={css.modalItemTitle}>
                <FormattedMessage id="TermsModal.codeUtilization" />
              </p>
              <div className={css.modalItemDescription}>
                <FormattedMessage id="TermsModal.codeUtilizationText" />
              </div>
            </div>
            <div className={css.modalItem}>
              <p className={css.modalItemTitle}>
                <FormattedMessage id="TermsModal.beneficiaries" />
              </p>
              <div className={css.modalItemDescription}>
                <p className={css.modalDescritionItem}>
                  <FormattedMessage id="TermsModal.beneficiariesText1" />
                </p>
                <p className={css.modalDescritionItem}>
                  <FormattedMessage id="TermsModal.beneficiariesText2" />
                </p>
              </div>
            </div>
            <div className={css.modalItem}>
              <p className={css.modalItemTitle}>
                <FormattedMessage id="TermsModal.general" />
              </p>
              <div className={css.modalItemDescription}>
                <FormattedMessage id="TermsModal.generalText" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const { string } = PropTypes;

TermsModalComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

TermsModalComponent.propTypes = {
  className: string,
  rootClassName: string,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling))
});

const TermsModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(TermsModalComponent);

export default TermsModal;
