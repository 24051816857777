/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BreakdownHiddenJobMaybeCustom.module.css';
import { isSchoolAdminRole, isTeacherRole } from '../../util/types';

const { Money } = sdkTypes;

const CENTS = 100;
const PROVIDER_COMMISSION_PERCENTAGE = -12.5;

export const BreakdownHiddenJobMaybeCustom = props => {
  const {
    rootClassName,
    className,
    isJob,
    intl,
    currentUser,
    listing,
    conversationId,
    futureTransactions,
  } = props;

  let isProvider = props.isProvider;
  let isCustomer = props.isCustomer;
  const isTeacher = isTeacherRole(currentUser);
  const isAdmin = isSchoolAdminRole(currentUser);


  if (isJob && isTeacher) {
    isProvider = true;
    isCustomer = false;
  }

  if (isJob && isAdmin) {
    isCustomer = true;
    isProvider = false;
  }

  const classes = classNames(rootClassName || css.root, className);
  const transaction = futureTransactions?.find(t => t.conversationId === conversationId);

  if (!transaction) return null;

  const {
    commission,
    hours,
    pricePerSeats,
    priceTotalWithCommission,
  } = transaction;


  const commissionMoney = new Money(commission * CENTS, config.currency);
  const pricePerSeatsMoney = new Money(pricePerSeats * CENTS, config.currency);
  const pricePerSeatsMoneyTotal = new Money((pricePerSeats * hours) * CENTS, config.currency);
  const pricePerSeatsMoneyTotalWithCommission = new Money(priceTotalWithCommission * CENTS, config.currency);


  // Formatted values
  const commissionValue = formatMoney(intl, commissionMoney);
  const formattedPrice = formatMoney(intl, pricePerSeatsMoney);
  const formattedPriceTotal = formatMoney(intl, pricePerSeatsMoneyTotal);
  const formattedPriceTotalWithCommission = formatMoney(intl, pricePerSeatsMoneyTotalWithCommission);

  return (
    <div className={classes}>

      <hr className={css.divider} />

      <h3 className={css.orderBreakdownTitle}>
        <FormattedMessage id='TransactionPanel.jobPriceBreakdownTitle' />
      </h3>
      <div className={css.item}>

        <div className={css.pricePerSeatsItem}>
          <span className={css.label}>
            Number of hours:
          </span>
        </div>
        <div className={css.pricePerSeatsItem}>
          <span className={css.value}>
            {hours}
          </span>
        </div>
      </div>

      <div className={css.item}>
        <div className={css.pricePerSeatsItem}>
          <span className={css.label}>
            Rate:
          </span>
        </div>
        <div className={css.pricePerSeatsItem}>
          <span className={css.value}>
            {formattedPrice} per hour
          </span>
        </div>
      </div>


      {isProvider && (
        <>
          <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.label}>
                <FormattedMessage id='OrderBreakdown.subTotal' />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.value}>
                {formattedPriceTotal}
              </span>
            </div>
          </div>


          <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.label}>
                <FormattedMessage id='OrderBreakdown.commission' />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.value}>
                -{commissionValue}
              </span>
            </div>
          </div>

          <div className={classNames(css.item, css.itemTotal)}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalLabel}>
                <FormattedMessage id="OrderBreakdown.providerTotalDefault" />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalPrice}>
                {formattedPriceTotalWithCommission}
              </span>
            </div>
          </div>

          <span className={css.feeInfo}>
            <FormattedMessage id='OrderBreakdown.commissionFeeNote' />
          </span>
        </>
      )}

      {isCustomer && (
        <>

          <div className={classNames(css.item, css.itemTotal)}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalLabel}>
                <FormattedMessage id='OrderBreakdown.total' />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalPrice}>
                {formattedPriceTotal}
              </span>
            </div>
          </div>
        </>
      )}

    </div>
  );
};

export default BreakdownHiddenJobMaybeCustom;


