import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import moment from "moment";
import config from '../../config';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { getStartOf, addTime } from '../../util/dates';
import { isTransactionsTransitionInvalidTransition, storableError } from '../../util/errors';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { updateMetaData } from '../../util/metaDataHelper';

import {
  txIsEnquired,
  getReview1Transition,
  getReview2Transition,
  txIsInFirstReviewBy,
  txIsPaid,
  TRANSITION_DISPUTE,
  TRANSITION_MARK_RECEIVED,
  TRANSITION_MARK_RECEIVED_FROM_PURCHASED,
  TRANSITION_MARK_DELIVERED,
  TRANSITION_CANCEL_PROVIDER,
  TRANSITION_FREE_CANCEL_PROVIDER,
  TRANSITION_CANCEL_CUSTOMER,
  TRANSITION_FREE_CANCEL_CUSTOMER,
  TRANSITION_CANCEL_CUSTOMER_AFTER_START,
  TRANSITION_FREE_CANCEL_CUSTOMER_AFTER_START,
  TRANSITION_CANCEL_CUSTOMER_AFTER_START_WITHOUT_REFUND,
  TRANSITION_SET_PARAMS,
  TRANSITION_REVIEW_BY_CUSTOMER,
  TRANSITION_ACCEPT_ENQUIRY_JOB,
  TRANSITION_DECLINE_ENQUIRY_JOB,
  TRANSITION_ACCEPT_JOB,
  TRANSITION_DECLINE_JOB,
  TRANSITION_JOB_COMPLETE_REQUEST,
  TRANSITION_JOB_COMPLETE,
  TRANSITION_JOB_COMPLETE_DECLINE,
  TRANSITION_REWORK_DONE_REQUEST,
  TRANSITION_TEACHER_CANCEL_JOB,
  TRANSITION_ADMIN_CANCEL_JOB,
  TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE,
  TRANSITION_ADMIN_CANCEL_JOB_BEFORE_COMPLETE,
  TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK,
  TRANSITION_ADMIN_CANCEL_JOB_BEFORE_REWORK,
} from '../../util/transaction';
import {
  cancelTransaction,
  refundCustomer,
  transactionLineItems,
  transitionPrivileged,
  updateUserRating,
  jobRefund,
  updateTransactionMetadata,
  getUserById,
} from '../../util/api';
import * as log from '../../util/log';
import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import {addMarketplaceEntities} from '../../ducks/marketplaceData.duck';
import { fetchCurrentUserNotifications } from '../../ducks/user.duck';
import crypto from "crypto";
import { isJobHiddenType, isJobType, isTeacherRole } from '../../util/types';

const { UUID } = sdkTypes;

const MESSAGES_PAGE_SIZE = 100;
const REVIEW_TX_INCLUDES = ['reviews', 'reviews.author', 'reviews.subject'];

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/TransactionPage/SET_INITIAL_VALUES';

export const FETCH_TRANSACTION_REQUEST = 'app/TransactionPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/TransactionPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/TransactionPage/FETCH_TRANSACTION_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/TransactionPage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/TransactionPage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/TransactionPage/FETCH_TRANSITIONS_ERROR';

export const MARK_RECEIVED_FROM_PURCHASED_REQUEST =
  'app/TransactionPage/MARK_RECEIVED_FROM_PURCHASED_REQUEST';
export const MARK_RECEIVED_FROM_PURCHASED_SUCCESS =
  'app/TransactionPage/MARK_RECEIVED_FROM_PURCHASED_SUCCESS';
export const MARK_RECEIVED_FROM_PURCHASED_ERROR =
  'app/TransactionPage/MARK_RECEIVED_FROM_PURCHASED_ERROR';

export const CANCEL_REQUEST = 'app/TransactionPage/CANCEL_REQUEST';
export const CANCEL_SUCCESS = 'app/TransactionPage/CANCEL_SUCCESS';
export const CANCEL_ERROR = 'app/TransactionPage/CANCEL_ERROR';

export const DECLINE_ENQUIRY_JOB_REQUEST = 'app/TransactionPage/DECLINE_ENQUIRY_JOB_REQUEST';
export const DECLINE_ENQUIRY_JOB_SUCCESS = 'app/TransactionPage/DECLINE_ENQUIRY_JOB_SUCCESS';
export const DECLINE_ENQUIRY_JOB_ERROR = 'app/TransactionPage/DECLINE_ENQUIRY_JOB_ERROR';

export const ACCEPT_JOB_REQUEST = 'app/TransactionPage/ACCEPT_JOB_REQUEST';
export const ACCEPT_JOB_SUCCESS = 'app/TransactionPage/ACCEPT_JOB_SUCCESS';
export const ACCEPT_JOB_ERROR = 'app/TransactionPage/ACCEPT_JOB_ERROR';

export const DECLINE_JOB_REQUEST = 'app/TransactionPage/DECLINE_JOB_REQUEST';
export const DECLINE_JOB_SUCCESS = 'app/TransactionPage/DECLINE_JOB_SUCCESS';
export const DECLINE_JOB_ERROR = 'app/TransactionPage/DECLINE_JOB_ERROR';

export const SET_JOB_COMPLETE_REQUEST = 'app/TransactionPage/SET_JOB_COMPLETE_REQUEST';
export const SET_JOB_COMPLETE_SUCCESS = 'app/TransactionPage/SET_JOB_COMPLETE_SUCCESS';
export const SET_JOB_COMPLETE_ERROR = 'app/TransactionPage/SET_JOB_COMPLETE_ERROR';

export const JOB_COMPLETE_REQUEST = 'app/TransactionPage/JOB_COMPLETE_REQUEST';
export const JOB_COMPLETE_SUCCESS = 'app/TransactionPage/JOB_COMPLETE_SUCCESS';
export const JOB_COMPLETE_ERROR = 'app/TransactionPage/JOB_COMPLETE_ERROR';

export const DECLINE_JOB_COMPLETE_REQUEST = 'app/TransactionPage/DECLINE_JOB_COMPLETE_REQUEST';
export const DECLINE_JOB_COMPLETE_SUCCESS = 'app/TransactionPage/DECLINE_JOB_COMPLETE_SUCCESS';
export const DECLINE_JOB_COMPLETE_ERROR = 'app/TransactionPage/DECLINE_JOB_COMPLETE_ERROR';

export const REWORK_DONE_REQUEST = 'app/TransactionPage/REWORK_DONE_REQUEST';
export const REWORK_DONE_SUCCESS = 'app/TransactionPage/REWORK_DONE_SUCCESS';
export const REWORK_DONE_ERROR = 'app/TransactionPage/REWORK_DONE_ERROR';

export const TEACHER_CANCEL_JOB_REQUEST = 'app/TransactionPage/TEACHER_CANCEL_JOB_REQUEST';
export const TEACHER_CANCEL_JOB_SUCCESS = 'app/TransactionPage/TEACHER_CANCEL_JOB_SUCCESS';
export const TEACHER_CANCEL_JOB_ERROR = 'app/TransactionPage/TEACHER_CANCEL_JOB_ERROR';

export const ADMIN_CANCEL_JOB_REQUEST = 'app/TransactionPage/ADMIN_CANCEL_JOB_REQUEST';
export const ADMIN_CANCEL_JOB_SUCCESS = 'app/TransactionPage/ADMIN_CANCEL_JOB_SUCCESS';
export const ADMIN_CANCEL_JOB_ERROR = 'app/TransactionPage/ADMIN_CANCEL_JOB_ERROR';

export const ADMIN_CANCEL_JOB_BEFORE_COMPLETE_REQUEST = 'app/TransactionPage/ADMIN_CANCEL_JOB_BEFORE_COMPLETE_REQUEST';
export const ADMIN_CANCEL_JOB_BEFORE_COMPLETE_SUCCESS = 'app/TransactionPage/ADMIN_CANCEL_JOB_BEFORE_COMPLETE_SUCCESS';
export const ADMIN_CANCEL_JOB_BEFORE_COMPLETE_ERROR = 'app/TransactionPage/ADMIN_CANCEL_JOB_BEFORE_COMPLETE_ERROR';

export const SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_REQUEST = 'app/TransactionPage/SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_REQUEST';
export const SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_SUCCESS = 'app/TransactionPage/SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_SUCCESS';
export const SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_ERROR = 'app/TransactionPage/SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_ERROR';

export const ADMIN_CANCEL_JOB_BEFORE_REWORK_REQUEST = 'app/TransactionPage/ADMIN_CANCEL_JOB_BEFORE_REWORK_REQUEST';
export const ADMIN_CANCEL_JOB_BEFORE_REWORK_SUCCESS = 'app/TransactionPage/ADMIN_CANCEL_JOB_BEFORE_REWORK_SUCCESS';
export const ADMIN_CANCEL_JOB_BEFORE_REWORK_ERROR = 'app/TransactionPage/ADMIN_CANCEL_JOB_BEFORE_REWORK_ERROR';

export const SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_REQUEST = 'app/TransactionPage/SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_REQUEST';
export const SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_SUCCESS = 'app/TransactionPage/SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_SUCCESS';
export const SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_ERROR = 'app/TransactionPage/SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_ERROR';


export const REFUND_REQUEST = 'app/TransactionPage/REFUND_REQUEST';
export const REFUND_SUCCESS = 'app/TransactionPage/REFUND_SUCCESS';
export const REFUND_ERROR = 'app/TransactionPage/REFUND_ERROR';

export const NEGOTIATION_PARAMS_REQUEST = 'app/TransactionPage/NEGOTIATION_PARAMS_REQUEST';
export const NEGOTIATION_PARAMS_SUCCESS = 'app/TransactionPage/NEGOTIATION_PARAMS_SUCCESS';
export const NEGOTIATION_PARAMS_ERROR = 'app/TransactionPage/NEGOTIATION_PARAMS_ERROR';

export const JOB_SET_PARAMS_REQUEST = 'app/TransactionPage/JOB_SET_PARAMS_REQUEST';
export const JOB_SET_PARAMS_SUCCESS = 'app/TransactionPage/JOB_SET_PARAMS_SUCCESS';
export const JOB_SET_PARAMS_ERROR = 'app/TransactionPage/JOB_SET_PARAMS_ERROR';

export const MARK_DELIVERED_REQUEST = 'app/TransactionPage/MARK_DELIVERED_REQUEST';
export const MARK_DELIVERED_SUCCESS = 'app/TransactionPage/MARK_DELIVERED_SUCCESS';
export const MARK_DELIVERED_ERROR = 'app/TransactionPage/MARK_DELIVERED_ERROR';

export const MARK_RECEIVED_REQUEST = 'app/TransactionPage/MARK_RECEIVED_REQUEST';
export const MARK_RECEIVED_SUCCESS = 'app/TransactionPage/MARK_RECEIVED_SUCCESS';
export const MARK_RECEIVED_ERROR = 'app/TransactionPage/MARK_RECEIVED_ERROR';

export const DISPUTE_REQUEST = 'app/TransactionPage/DISPUTE_REQUEST';
export const DISPUTE_SUCCESS = 'app/TransactionPage/DISPUTE_SUCCESS';
export const DISPUTE_ERROR = 'app/TransactionPage/DISPUTE_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/TransactionPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/TransactionPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/TransactionPage/FETCH_MESSAGES_ERROR';

export const SEND_MESSAGE_REQUEST = 'app/TransactionPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/TransactionPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/TransactionPage/SEND_MESSAGE_ERROR';

export const SEND_REVIEW_REQUEST = 'app/TransactionPage/SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCESS = 'app/TransactionPage/SEND_REVIEW_SUCCESS';
export const SEND_REVIEW_ERROR = 'app/TransactionPage/SEND_REVIEW_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/TransactionPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/TransactionPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/TransactionPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/TransactionPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/TransactionPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/TransactionPage/FETCH_LINE_ITEMS_ERROR';

export const FETCH_PROVIDER_REQUEST = 'app/TransactionPage/FETCH_PROVIDER_REQUEST';
export const FETCH_PROVIDER_SUCCESS = 'app/TransactionPage/FETCH_PROVIDER_SUCCESS';
export const FETCH_PROVIDER_ERROR = 'app/TransactionPage/FETCH_PROVIDER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchTransactionInProgress: false,
  fetchTransactionError: null,
  transactionRef: null,


  cancelInProgress: false,
  cancelError: null,
  acceptJobInProgress: false,
  acceptJobError: null,
  declineJobInProgress: false,
  declineJobError: null,
  declineEnquiryJobInProgress: false,
  declineEnquiryJobError: null,
  setJobCompleteInProgress: false,
  setJobCompleteSuccess: false,
  setJobCompleteError: null,
  jobCompleteInProgress: false,
  jobCompleteSuccess: false,
  jobCompleteError: null,
  declineJobCompleteInProgress: false,
  declineJobCompleteSuccess: false,
  declineJobCompleteError: null,
  reworkDoneInProgress: false,
  reworkDoneSuccess: false,
  reworkDoneError: false,

  teacherCancelJobInProgress: false,
  teacherCancelJobSuccess: false,
  teacherCancelJobError: false,

  adminCancelJobInProgress: false,
  adminCancelJobSuccess: false,
  adminCancelJobError: false,

  adminCancelJobBeforeCompleteInProgress: false,
  adminCancelJobBeforeCompleteSuccess: false,
  adminCancelJobBeforeCompleteError: false,

  schoolAdminCancelJobBeforeCompleteInProgress: false,
  schoolAdminCancelJobBeforeCompleteSuccess: false,
  schoolAdminCancelJobBeforeCompleteError: false,

  adminCancelJobBeforeReworkInProgress: false,
  adminCancelJobBeforeReworkSuccess: false,
  adminCancelJobBeforeReworkError: false,

  schoolAdminCancelJobBeforeReworkInProgress: false,
  schoolAdminCancelJobBeforeReworkSuccess: false,
  schoolAdminCancelJobBeforeReworkError: false,

  refundInProgress: false,
  refundError: null,
  markReceivedInProgress: false,
  markReceivedError: null,
  markReceivedFromPurchasedInProgress: false,
  markReceivedFromPurchasedError: null,
  markDeliveredInProgress: false,
  markDeliveredError: null,
  disputeInProgress: false,
  disputeError: null,
  fetchMessagesInProgress: false,
  fetchMessagesError: null,
  totalMessages: 0,
  totalMessagePages: 0,
  oldestMessagePageFetched: 0,
  messages: [],
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageInProgress: false,
  sendMessageError: null,
  sendReviewInProgress: false,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  fetchTransitionsInProgress: false,
  fetchTransitionsError: null,
  processTransitions: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  negotiationParams: false,
  negotiationParamsInProgress: false,
  negotiationParamsError: null,

  jobParams: false,
  jobSetParamsInProgress: false,
  jobSetParamsError: null,

  provider: null,
  fetchProviderInProgress: false,
  fetchProviderError: null,
};

// Merge entity arrays using ids, so that conflicting items in newer array (b) overwrite old values (a).
// const a = [{ id: { uuid: 1 } }, { id: { uuid: 3 } }];
// const b = [{ id: : { uuid: 2 } }, { id: : { uuid: 1 } }];
// mergeEntityArrays(a, b)
// => [{ id: { uuid: 3 } }, { id: : { uuid: 2 } }, { id: : { uuid: 1 } }]
const mergeEntityArrays = (a, b) => {
  return a.filter(aEntity => !b.find(bEntity => aEntity.id.uuid === bEntity.id.uuid)).concat(b);
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TRANSACTION_REQUEST:
      return { ...state, fetchTransactionInProgress: true, fetchTransactionError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchTransactionInProgress: false, transactionRef };
    }
    case FETCH_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransactionsInProgress: false, fetchTransactionsError: payload };


    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case MARK_RECEIVED_FROM_PURCHASED_REQUEST:
      return {
        ...state,
        markReceivedFromPurchasedInProgress: true,
        markReceivedFromPurchasedError: null,
      };
    case MARK_RECEIVED_FROM_PURCHASED_SUCCESS:
      return { ...state, markReceivedFromPurchasedInProgress: false };
    case MARK_RECEIVED_FROM_PURCHASED_ERROR:
      return {
        ...state,
        markReceivedFromPurchasedInProgress: false,
        markReceivedFromPurchasedError: payload,
      };

    case DECLINE_ENQUIRY_JOB_REQUEST:
      return { ...state, declineEnquiryJobInProgress: true, declineEnquiryJobError: null };
    case DECLINE_ENQUIRY_JOB_SUCCESS:
      return { ...state, declineEnquiryJobInProgress: false };
    case DECLINE_ENQUIRY_JOB_ERROR:
      return { ...state, declineEnquiryJobInProgress: false, declineEnquiryJobError: payload };

    case ACCEPT_JOB_REQUEST:
      return { ...state, acceptJobInProgress: true, acceptJobError: null };
    case ACCEPT_JOB_SUCCESS:
      return { ...state, acceptJobInProgress: false };
    case ACCEPT_JOB_ERROR:
      return { ...state, acceptJobInProgress: false, acceptJobError: payload };

    case DECLINE_JOB_REQUEST:
      return { ...state, declineJobInProgress: true, declineJobError: null };
    case DECLINE_JOB_SUCCESS:
      return { ...state, declineJobInProgress: false };
    case DECLINE_JOB_ERROR:
      return { ...state, declineJobInProgress: false, declineJobError: payload };

    case SET_JOB_COMPLETE_REQUEST:
      return { ...state, setJobCompleteInProgress: true, setJobCompleteSuccess: false, setJobCompleteError: null };
    case SET_JOB_COMPLETE_SUCCESS:
      return { ...state, setJobCompleteInProgress: false, setJobCompleteSuccess: true, };
    case SET_JOB_COMPLETE_ERROR:
      return { ...state, setJobCompleteInProgress: false, setJobCompleteSuccess: false, setJobCompleteError: payload };

    case JOB_COMPLETE_REQUEST:
      return { ...state, jobCompleteInProgress: true, jobCompleteSuccess: false, jobCompleteError: null };
    case JOB_COMPLETE_SUCCESS:
      return { ...state, jobCompleteInProgress: false, jobCompleteSuccess: true, jobCompleteError: false, };
    case JOB_COMPLETE_ERROR:
      return { ...state, jobCompleteInProgress: false, jobCompleteSuccess: false, jobCompleteError: payload };

    case DECLINE_JOB_COMPLETE_REQUEST:
      return { ...state, declineJobCompleteInProgress: true, declineJobCompleteError: null };
    case DECLINE_JOB_COMPLETE_SUCCESS:
      return { ...state, declineJobCompleteInProgress: false, declineJobCompleteSuccess: true, declineEnquiryJobError: null };
    case DECLINE_JOB_COMPLETE_ERROR:
      return { ...state, declineJobCompleteInProgress: false, declineJobCompleteError: payload };

    case REWORK_DONE_REQUEST:
      return { ...state, reworkDoneInProgress: true, reworkDoneError: null, reworkDoneSuccess: false };
    case REWORK_DONE_SUCCESS:
      return { ...state, reworkDoneInProgress: false, reworkDoneError: false, reworkDoneSuccess: true };
    case REWORK_DONE_ERROR:
      return { ...state, reworkDoneInProgress: false, reworkDoneError: payload };

    case TEACHER_CANCEL_JOB_REQUEST:
      return {...state, teacherCancelJobInProgress: true, teacherCancelJobError: null, teacherCancelJobSuccess: false};
    case TEACHER_CANCEL_JOB_SUCCESS:
      return {...state, teacherCancelJobInProgress: false, teacherCancelJobError: false, teacherCancelJobSuccess: true};
    case TEACHER_CANCEL_JOB_ERROR:
      return {...state, teacherCancelJobInProgress: false, teacherCancelJobError: payload, teacherCancelJobSuccess: false};

    case ADMIN_CANCEL_JOB_REQUEST:
      return {...state, adminCancelJobInProgress: true, adminCancelJobError: null, adminCancelJobSuccess: false};
    case ADMIN_CANCEL_JOB_SUCCESS:
      return { ...state, adminCancelJobInProgress: false, adminCancelJobError: false, adminCancelJobSuccess: true};
    case ADMIN_CANCEL_JOB_ERROR:
      return {...state, adminCancelJobInProgress: false, adminCancelJobError: payload, adminCancelJobSuccess: false};

    case ADMIN_CANCEL_JOB_BEFORE_COMPLETE_REQUEST:
      return {...state, adminCancelJobBeforeCompleteInProgress: true, adminCancelJobBeforeCompleteError: null, adminCancelJobBeforeCompleteSuccess: false};
    case ADMIN_CANCEL_JOB_BEFORE_COMPLETE_SUCCESS:
      return {...state, adminCancelJobBeforeCompleteInProgress: false, adminCancelJobBeforeCompleteError: false, adminCancelJobBeforeCompleteSuccess: true};
    case ADMIN_CANCEL_JOB_BEFORE_COMPLETE_ERROR:
      return {...state, adminCancelJobBeforeCompleteInProgress: false, adminCancelJobBeforeCompleteSuccess: false, adminCancelJobBeforeCompleteError: payload};

    case SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_REQUEST:
      return {...state, schoolAdminCancelJobBeforeCompleteInProgress: true, schoolAdminCancelJobBeforeCompleteError: null, schoolAdminCancelJobBeforeCompleteSuccess: false};
    case SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_SUCCESS:
      return {...state, schoolAdminCancelJobBeforeCompleteInProgress: false, schoolAdminCancelJobBeforeCompleteError: false, schoolAdminCancelJobBeforeCompleteSuccess: true};
    case SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_ERROR:
      return {...state, schoolAdminCancelJobBeforeCompleteInProgress: false, schoolAdminCancelJobBeforeCompleteSuccess: false, schoolAdminCancelJobBeforeCompleteError: payload};

    case ADMIN_CANCEL_JOB_BEFORE_REWORK_REQUEST:
      return {...state, adminCancelJobBeforeReworkInProgress: true, adminCancelJobBeforeReworkError: null, adminCancelJobBeforeReworkSuccess: false};
    case ADMIN_CANCEL_JOB_BEFORE_REWORK_SUCCESS:
      return {...state, adminCancelJobBeforeReworkInProgress: false, adminCancelJobBeforeReworkError: false, adminCancelJobBeforeReworkSuccess: true};
    case ADMIN_CANCEL_JOB_BEFORE_REWORK_ERROR:
      return {...state, adminCancelJobBeforeReworkInProgress: false, adminCancelJobBeforeReworkSuccess: false, adminCancelJobBeforeReworkError: payload};

    case SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_REQUEST:
      return {...state, schoolAdminCancelJobBeforeReworkInProgress: true, schoolAdminCancelJobBeforeReworkError: null, schoolAdminCancelJobBeforeReworkSuccess: false};
    case SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_SUCCESS:
      return {...state, schoolAdminCancelJobBeforeReworkInProgress: false, schoolAdminCancelJobBeforeReworkError: false, schoolAdminCancelJobBeforeReworkSuccess: true};
    case SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_ERROR:
      return {...state, schoolAdminCancelJobBeforeReworkInProgress: false, schoolAdminCancelJobBeforeReworkSuccess: false, schoolAdminCancelJobBeforeReworkError: payload};

    case CANCEL_REQUEST:
      return { ...state, markDeliveredInProgress: true, markDeliveredError: null };
    case CANCEL_SUCCESS:
      return { ...state, markDeliveredInProgress: false };
    case CANCEL_ERROR:
      return { ...state, markDeliveredInProgress: false, markDeliveredError: payload };

    case REFUND_REQUEST:
      return { ...state, refundInProgress: true, refundError: false };
    case REFUND_SUCCESS:
      return { ...state, refundInProgress: false, refundError: false };
    case REFUND_ERROR:
      return { ...state, refundInProgress: false, refundError: payload };

    case NEGOTIATION_PARAMS_REQUEST:
      return { ...state, negotiationParamsInProgress: true, negotiationParamsError: null };
    case NEGOTIATION_PARAMS_SUCCESS:
      return { ...state, negotiationParamsInProgress: false, negotiationParams: true };
    case NEGOTIATION_PARAMS_ERROR:
      return { ...state, negotiationParamsInProgress: false, negotiationParamsError: payload };

    case JOB_SET_PARAMS_REQUEST:
      return { ...state, jobSetParamsInProgress: true, jobSetParamsError: null };
    case JOB_SET_PARAMS_SUCCESS:
      return { ...state, jobSetParamsInProgress: false, jobParams: true };
    case JOB_SET_PARAMS_ERROR:
      return { ...state, jobSetParamsInProgress: false, jobSetParamsError: payload };

    case MARK_DELIVERED_REQUEST:
      return { ...state, cancelInProgress: true, cancelError: null };
    case MARK_DELIVERED_SUCCESS:
      return { ...state, cancelInProgress: false };
    case MARK_DELIVERED_ERROR:
      return { ...state, cancelInProgress: false, cancelError: payload };

    case MARK_RECEIVED_REQUEST:
      return {
        ...state,
        markReceivedInProgress: true,
        markReceivedError: null,
      };
    case MARK_RECEIVED_SUCCESS:
      return { ...state, markReceivedInProgress: false };
    case MARK_RECEIVED_ERROR:
      return {
        ...state,
        markReceivedInProgress: false,
        markReceivedError: payload,
      };

    case DISPUTE_REQUEST:
      return { ...state, disputeInProgress: true, disputeError: null };
    case DISPUTE_SUCCESS:
      return { ...state, disputeInProgress: false };
    case DISPUTE_ERROR:
      return { ...state, disputeInProgress: false, disputeError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchMessagesInProgress: true, fetchMessagesError: null };
    case FETCH_MESSAGES_SUCCESS: {
      const oldestMessagePageFetched =
        state.oldestMessagePageFetched > payload.page
          ? state.oldestMessagePageFetched
          : payload.page;
      return {
        ...state,
        fetchMessagesInProgress: false,
        messages: mergeEntityArrays(state.messages, payload.messages),
        totalMessages: payload.totalItems,
        totalMessagePages: payload.totalPages,
        oldestMessagePageFetched,
      };
    }
    case FETCH_MESSAGES_ERROR:
      return { ...state, fetchMessagesInProgress: false, fetchMessagesError: payload };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
        initialMessageFailedToTransaction: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    case SEND_REVIEW_REQUEST:
      return { ...state, sendReviewInProgress: true, sendReviewError: null };
    case SEND_REVIEW_SUCCESS:
      return { ...state, sendReviewInProgress: false };
    case SEND_REVIEW_ERROR:
      return { ...state, sendReviewInProgress: false, sendReviewError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case FETCH_PROVIDER_REQUEST:
      return { ...state, fetchProviderInProgress: true, fetchProviderError: null };
    case FETCH_PROVIDER_SUCCESS:
      return { ...state, provider: payload };
    case FETCH_PROVIDER_ERROR:
      return { ...state, fetchProviderInProgress: false, fetchProviderError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const transitionInProgress = state => {
  const pageState = state.TransactionPage;
  return pageState.markReceivedFromPurchasedInProgress ||
         pageState.markDeliveredInProgress ||
         pageState.negotiationParamsInProgress;
};

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
const fetchTransactionSuccess = response => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: response,
});
const fetchTransactionError = e => ({ type: FETCH_TRANSACTION_ERROR, error: true, payload: e });

const fetchTransitionsRequest = () => ({ type: FETCH_TRANSITIONS_REQUEST });
const fetchTransitionsSuccess = response => ({
  type: FETCH_TRANSITIONS_SUCCESS,
  payload: response,
});
const fetchTransitionsError = e => ({ type: FETCH_TRANSITIONS_ERROR, error: true, payload: e });

const markReceivedFromPurchasedRequest = () => ({ type: MARK_RECEIVED_FROM_PURCHASED_REQUEST });
const markReceivedFromPurchasedSuccess = () => ({ type: MARK_RECEIVED_FROM_PURCHASED_SUCCESS });
const markReceivedFromPurchasedError = e => ({
  type: MARK_RECEIVED_FROM_PURCHASED_ERROR,
  error: true,
  payload: e,
});

const cancelRequest = () => ({ type: CANCEL_REQUEST });
const cancelSuccess = () => ({ type: CANCEL_SUCCESS });
const cancelError = e => ({ type: CANCEL_SUCCESS, error: true, payload: e });

const refundRequest = () => ({ type: REFUND_REQUEST });
const refundSuccess = () => ({ type: REFUND_SUCCESS });
const refundError = e => ({ type: REFUND_SUCCESS, error: true, payload: e });

const negotiationParamsRequest = () => ({ type: NEGOTIATION_PARAMS_REQUEST });
const negotiationParamsSuccess = () => ({ type: NEGOTIATION_PARAMS_SUCCESS });
const negotiationParamsError = e => ({ type: NEGOTIATION_PARAMS_ERROR, error: true, payload: e });

const jobSetParamsRequest = () => ({ type: JOB_SET_PARAMS_REQUEST });
const jobSetParamsSuccess = () => ({ type: JOB_SET_PARAMS_SUCCESS });
const jobSetParamsError = e => ({ type: JOB_SET_PARAMS_ERROR, error: true, payload: e });

const setJobCompleteRequest = () => ({ type: SET_JOB_COMPLETE_REQUEST });
const setJobCompleteSuccess = () => ({ type: SET_JOB_COMPLETE_SUCCESS });
const setJobCompleteError = e => ({ type: SET_JOB_COMPLETE_ERROR, error: true, payload: e });

const jobCompleteRequest = () => ({ type: JOB_COMPLETE_REQUEST });
const jobCompleteSuccess = () => ({ type: JOB_COMPLETE_SUCCESS });
const jobCompleteError = e => ({ type: JOB_COMPLETE_ERROR, error: true, payload: e });

const declineJobCompleteRequest = () => ({ type: DECLINE_JOB_COMPLETE_REQUEST });
const declineJobCompleteSuccess = () => ({ type: DECLINE_JOB_COMPLETE_SUCCESS });
const declineJobCompleteError = e => ({ type: DECLINE_JOB_COMPLETE_ERROR, error: true, payload: e });

const reworkDoneRequest = () => ({ type: REWORK_DONE_REQUEST });
const reworkDoneSuccess = () => ({ type: REWORK_DONE_SUCCESS });
const reworkDoneError = e => ({ type: REWORK_DONE_ERROR, error: true, payload: e });


const teacherCancelJobRequest = () => ({ type: TEACHER_CANCEL_JOB_REQUEST });
const teacherCancelJobSuccess = () => ({ type: TEACHER_CANCEL_JOB_SUCCESS });
const teacherCancelJobError = e => ({ type: TEACHER_CANCEL_JOB_ERROR, error: true, payload: e });

const adminCancelJobRequest = () => ({ type: ADMIN_CANCEL_JOB_REQUEST });
const adminCancelJobSuccess = () => ({ type: ADMIN_CANCEL_JOB_SUCCESS });
const adminCancelJobError = e => ({ type: ADMIN_CANCEL_JOB_ERROR, error: true, payload: e });

const adminCancelJobBeforeCompleteRequest = () => ({ type: ADMIN_CANCEL_JOB_BEFORE_COMPLETE_REQUEST });
const adminCancelJobBeforeCompleteSuccess = () => ({ type: ADMIN_CANCEL_JOB_BEFORE_COMPLETE_SUCCESS });
const adminCancelJobBeforeCompleteError = e => ({ type: ADMIN_CANCEL_JOB_BEFORE_COMPLETE_ERROR, error: true, payload: e });

const schoolAdminCancelJobBeforeCompleteRequest = () => ({ type: SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_REQUEST });
const schoolAdminCancelJobBeforeCompleteSuccess = () => ({ type: SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_SUCCESS });
const schoolAdminCancelJobBeforeCompleteError = e => ({ type: SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE_ERROR, error: true, payload: e });

const adminCancelJobBeforeReworkRequest = () => ({ type: ADMIN_CANCEL_JOB_BEFORE_REWORK_REQUEST });
const adminCancelJobBeforeReworkSuccess = () => ({ type: ADMIN_CANCEL_JOB_BEFORE_REWORK_SUCCESS });
const adminCancelJobBeforeReworkError = e => ({ type: ADMIN_CANCEL_JOB_BEFORE_REWORK_ERROR, error: true, payload: e });

const schoolAdminCancelJobBeforeReworkRequest = () => ({ type: SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_REQUEST });
const schoolAdminCancelJobBeforeReworkSuccess = () => ({ type: SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_SUCCESS });
const schoolAdminCancelJobBeforeReworkError = e => ({ type: SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK_ERROR, error: true, payload: e });

const markDeliveredRequest = () => ({ type: MARK_DELIVERED_REQUEST });
const markDeliveredSuccess = () => ({ type: MARK_DELIVERED_SUCCESS });
const markDeliveredError = e => ({ type: MARK_DELIVERED_ERROR, error: true, payload: e });

const markReceivedRequest = () => ({ type: MARK_RECEIVED_REQUEST });
const markReceivedSuccess = () => ({ type: MARK_RECEIVED_SUCCESS });
const markReceivedError = e => ({ type: MARK_RECEIVED_ERROR, error: true, payload: e });

const declineEnquiryJobRequest = () => ({ type: DECLINE_ENQUIRY_JOB_REQUEST });
const declineEnquiryJobSuccess = () => ({ type: DECLINE_ENQUIRY_JOB_SUCCESS });
const declineEnquiryJobError = e => ({ type: DECLINE_ENQUIRY_JOB_ERROR, error: true, payload: e });

const acceptJobRequest = () => ({ type: ACCEPT_JOB_REQUEST });
const acceptJobSuccess = () => ({ type: ACCEPT_JOB_SUCCESS });
const acceptJobError = e => ({ type: ACCEPT_JOB_ERROR, error: true, payload: e });

const declineJobRequest = () => ({ type: DECLINE_JOB_REQUEST });
const declineJobSuccess = () => ({ type: DECLINE_JOB_SUCCESS });
const declineJobError = e => ({ type: DECLINE_JOB_ERROR, error: true, payload: e });

const disputeRequest = () => ({ type: DISPUTE_REQUEST });
const disputeSuccess = () => ({ type: DISPUTE_SUCCESS });
const disputeError = e => ({ type: DISPUTE_ERROR, error: true, payload: e });

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = (messages, pagination) => ({ type: FETCH_MESSAGES_SUCCESS, payload: { messages, ...pagination },});
const fetchMessagesError = e => ({ type: FETCH_MESSAGES_ERROR, error: true, payload: e });

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

const sendReviewRequest = () => ({ type: SEND_REVIEW_REQUEST });
const sendReviewSuccess = () => ({ type: SEND_REVIEW_SUCCESS });
const sendReviewError = e => ({ type: SEND_REVIEW_ERROR, error: true, payload: e });

const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
const fetchTimeSlotsSuccess = timeSlots => ({ type: FETCH_TIME_SLOTS_SUCCESS, payload: timeSlots,});
const fetchTimeSlotsError = e => ({ type: FETCH_TIME_SLOTS_ERROR, error: true, payload: e,});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({ type: FETCH_LINE_ITEMS_SUCCESS, payload: lineItems,});
export const fetchLineItemsError = error => ({ type: FETCH_LINE_ITEMS_ERROR, error: true, payload: error,});

const fetchProviderRequest = () => ({ type: FETCH_PROVIDER_REQUEST });
const fetchProviderSuccess = lineItems => ({ type: FETCH_PROVIDER_SUCCESS, payload: lineItems });
const fetchProviderError = e => ({ type: FETCH_PROVIDER_ERROR, error: true, payload: e,});

// ================ Thunks ================ //

// Helper to fetch correct image variants for different thunk calls
const getImageVariants = () => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;
  return {
    'fields.image': [
      // Profile images
      'variants.square-small',
      'variants.square-small2x',

      // Listing images:
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };
};

const listingRelationship = txResponse => {
  return txResponse.data.data.relationships.listing.data;
};

const providerRelationship = txResponse => {
  return txResponse.data.data.relationships.provider.data;
};

export const fetchTransaction = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());
  let txResponse = null;

  return sdk.transactions
    .show(
      {
        id,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'listing.currentStock',
          'booking',
          'reviews',
          'reviews.author',
          'reviews.subject',
        ],
        ...getImageVariants(),
      },
      { expand: true }
    )
    .then(response => {
      txResponse = response;
      const listingId = listingRelationship(response).id;
      const providerId = providerRelationship(response).id;
      const entities = updatedEntities({}, response.data);

      const listingRef = { id: listingId, type: 'listing' };
      const transactionRef = { id, type: 'transaction' };
      const denormalised = denormalisedEntities(entities, [listingRef, transactionRef]);
      const listing = denormalised[0];
      const transaction = denormalised[1];
      const isShow = true;
      const isJob = isJobType(listing) || isJobHiddenType(listing);


      const isNotRead = transaction.attributes?.metadata?.transitionsToNotify?.some(t => !t.isReadByAdmin || !t.isReadByTeacher);
      isNotRead && updateMetaData(transaction, getState, dispatch, isJob, isShow);

      // Fetch time slots for transactions that are in enquired state
      const canFetchTimeslots =
        txRole === 'customer' &&
        config.listingManagementType === 'availability' &&
        transaction &&
        txIsEnquired(transaction) || (txIsPaid(transaction) && txRole === 'provider');

      if (canFetchTimeslots) {
        dispatch(fetchTimeSlots(listingId));
      }
      dispatch(fetchProvider(providerId.uuid));

      const canFetchListing = listing && listing.attributes && !listing.attributes.deleted;
      if (!canFetchListing) {
        return sdk.listings.show({
          id: listingId,
          include: ['author', 'author.profileImage', 'images'],
          ...getImageVariants(),
        });
      } else {

        return response;
      }
    })
    .then(response => {
      dispatch(addMarketplaceEntities(txResponse));
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchTransactionSuccess(txResponse));
      return response;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const markReceivedFromPurchased = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(markReceivedFromPurchasedRequest());

  return sdk.transactions
    .transition(
      { id, transition: TRANSITION_MARK_RECEIVED_FROM_PURCHASED, params: {} },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(markReceivedFromPurchasedSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(markReceivedFromPurchasedError(storableError(e)));
      log.error(e, 'mark-received-from-purchase-failed', {
        txId: id,
        transition: TRANSITION_MARK_RECEIVED_FROM_PURCHASED,
      });
      throw e;
    });
};

export const markDelivered = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(markDeliveredRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_MARK_DELIVERED, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(markDeliveredSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(markDeliveredError(storableError(e)));
      log.error(e, 'mark-delivered-failed', {
        txId: id,
        transition: TRANSITION_MARK_DELIVERED,
      });
      throw e;
    });
};

export const saveNegotiationParams = ({ txId, isJob, seats, pricePerSeats, dates }) => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }

  const currentUser = getState()?.user?.currentUser;
  const discountStatus = currentUser?.attributes?.profile?.publicData?.discountStatus;

  dispatch(negotiationParamsRequest());

  // 15% commission, so provider gets 85% from payment
  const marketplaceCommission = discountStatus ? 90 : 85;
  const priceTotal = pricePerSeats * seats;
  const priceTotalWithCommission = ((pricePerSeats * seats)*marketplaceCommission)/100;
  const halfPriceTotalWithCommission = (((pricePerSeats * seats)*marketplaceCommission)/100)/2;
  const startDate = dates?.startDate.toString();
  const endDate = dates?.endDate.toString();
  const momentStartDate = moment(startDate).format('MMM DD, YYYY');
  const momentEndDate = moment(endDate).format('MMM DD, YYYY');
  const today = new Date();
  const numberOfDayFromNowToStart = moment(startDate).diff(moment(today).format('MM/DD/YYYY'), 'days');
  const expirePaymentDate = numberOfDayFromNowToStart < 7 ? momentStartDate : moment(today).add(7, 'days').format('MMM DD, YYYY');

  const params = {
    protectedData: {
      seats,
      pricePerSeats,
      endDate,
      startDate,
      momentStartDate,
      momentEndDate,
      priceTotal,
      priceTotalWithCommission,
      halfPriceTotalWithCommission,
      expirePaymentDate
    }
  };

  return sdk.transactions
    .transition({ id: txId, transition: TRANSITION_SET_PARAMS, params }, { expand: true })
    .then(response => {
      const transaction = response.data.data;
      updateMetaData(transaction, getState, dispatch, isJob);
      dispatch(addMarketplaceEntities(response));
      dispatch(negotiationParamsSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(negotiationParamsError(storableError(e)));
      log.error(e, 'negotiation-params-failed', {
        txId: txId,
        transition: TRANSITION_SET_PARAMS,
      });
      throw e;
    });
};

export const saveJobParams = ({ txId, hours, pricePerSeats }) => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }


  dispatch(jobSetParamsRequest());

  // 15% commission, so provider gets 85% from payment
  const priceTotal = pricePerSeats * hours;
  const priceTotalWithCommission = ((pricePerSeats * hours)*85)/100;
  const commission = (((pricePerSeats * hours) * -15)/100) * -1;
  const halfPriceTotalWithCommission = (((pricePerSeats * hours)*85)/100)/2;


  const isJob = true;
  const params = {
    protectedData: {
      hours,
      pricePerSeats,
      priceTotal,
      commission,
      priceTotalWithCommission,
      halfPriceTotalWithCommission,
    }
  };

  return sdk.transactions
    .transition({ id: txId, transition: TRANSITION_ACCEPT_ENQUIRY_JOB, params }, { expand: true })
    .then(response => {
      const transaction = response.data.data;
      updateMetaData(transaction, getState, dispatch, isJob);
      dispatch(addMarketplaceEntities(response));
      dispatch(jobSetParamsSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(jobSetParamsError(storableError(e)));
      log.error(e, TRANSITION_ACCEPT_ENQUIRY_JOB + '-failed', {
        txId: txId,
        transition: TRANSITION_ACCEPT_ENQUIRY_JOB,
      });
      throw e;
    });
};


export const markReceived = id => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }
  dispatch(markReceivedRequest());

  return sdk.transactions
    .transition({ id, transition: TRANSITION_MARK_RECEIVED, params: {} }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(markReceivedSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(markReceivedError(storableError(e)));
      log.error(e, 'mark-received-failed', {
        txId: id,
        transition: TRANSITION_MARK_RECEIVED,
      });
      throw e;
    });
};

export const declineEnquiryJob = id => (dispatch, getState, sdk) => {

  dispatch(declineEnquiryJobRequest());
  const isJob = true;

  return sdk.transactions
    .transition({ id, transition: TRANSITION_DECLINE_ENQUIRY_JOB, params: {} }, { expand: true })
    .then(response => {
      const transaction = response.data.data;
      updateMetaData(transaction, getState, dispatch, isJob);
      dispatch(addMarketplaceEntities(response));
      dispatch(declineEnquiryJobSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(declineEnquiryJobError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_DECLINE_ENQUIRY_JOB,
      });
      throw e;
    });
};

export const acceptJob = (params) => (dispatch, getState, sdk) => {

  const { id } = params;

  const { currentUser } = getState().user;
  const isJob = true;
  const discount = currentUser?.attributes?.profile?.privateData?.reward;
  const token = crypto.randomBytes(8).toString('hex');

  if (!!discount) {
    const transactionTokensMaybe = currentUser?.attributes?.profile?.privateData?.transactionTokens || [];
    const privateData = { privateData: { transactionTokens: [...transactionTokensMaybe, token] } };
    dispatch(updateProfile(privateData));
  }

  const bookingDatesMaybe = {
    bookingStart: new Date(params.attributes.protectedData?.startDate),
    bookingEnd: new Date(params.attributes.protectedData?.endDate),
  };

  const isProviderStripeAccountConnected = params.listing?.attributes?.publicData?.isStripeAccount;

  const orderData = {
      hours: Number.parseInt(params.attributes.protectedData?.hours, 10),
      pd: params.attributes.protectedData,
      discount,
      token,
      isProviderStripeAccountConnected
  };

  const transitionParams = {
    listingId: params.listing.id,
    seats: 1,
    units: Number.parseInt(params.attributes.protectedData?.hours, 10),
  };

  const bodyParams = {
      id,
      transition: TRANSITION_ACCEPT_JOB,
      params: transitionParams,
    }

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };


  dispatch(acceptJobRequest());

  return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
    .then(response => {
      const transaction = response.data.data;
      updateMetaData(transaction, getState, dispatch, isJob);
      dispatch(addMarketplaceEntities(response));
      dispatch(acceptJobSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(acceptJobError(storableError(e)));
      log.error(e, 'accept-job-failed', {
        txId: id,
        transition: TRANSITION_ACCEPT_JOB,
      });
      throw e;
    });
};

export const declineJob = id => (dispatch, getState, sdk) => {

  dispatch(declineJobRequest());
  const isJob = true;
  return sdk.transactions
    .transition({ id, transition: TRANSITION_DECLINE_JOB, params: {} }, { expand: true })
    .then(response => {
      const transaction = response.data.data;
      updateMetaData(transaction, getState, dispatch, isJob);
      dispatch(addMarketplaceEntities(response));
      dispatch(declineJobSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(declineJobError(storableError(e)));
      log.error(e, 'decline-job-failed', {
        txId: id,
        transition: TRANSITION_DECLINE_JOB,
      });
      throw e;
    });
};


export const cancel = (id, role, started, firstWeekPassed, over3Weeks, availableSeatsLeftAfterCancel, isProviderStripeAccountConnected) => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }

  const isProvider = role === 'provider';

  if (isProvider && started) {
    return Promise.reject(new Error('Provider cannot cancel after the start'));
  }

  const params = {
    protectedData: {
      availableSeatsLeftAfterCancel
    }
  };

  dispatch(cancelRequest());

  const cancelCustomerAfterStart = isProviderStripeAccountConnected ? TRANSITION_CANCEL_CUSTOMER_AFTER_START : TRANSITION_FREE_CANCEL_CUSTOMER_AFTER_START;
  const cancelProvider = isProviderStripeAccountConnected ? TRANSITION_CANCEL_PROVIDER : TRANSITION_FREE_CANCEL_PROVIDER;
  const cancelCustomer = isProviderStripeAccountConnected ? TRANSITION_CANCEL_CUSTOMER : TRANSITION_FREE_CANCEL_CUSTOMER;

  const transition = (started && firstWeekPassed) || (started && !over3Weeks) ?
    TRANSITION_CANCEL_CUSTOMER_AFTER_START_WITHOUT_REFUND :
    started && !firstWeekPassed ?
      cancelCustomerAfterStart :
      isProvider ?
        cancelProvider :
        cancelCustomer;

  return sdk.transactions
    .transition({ id, transition: transition, params }, { expand: true })
    .then(async res => {
      if(!(transition === TRANSITION_CANCEL_CUSTOMER_AFTER_START_WITHOUT_REFUND)) await dispatch(customRefund(id));
      if (started && !firstWeekPassed) await cancelTransaction({ id });
      return res;
    })
    .then(response => {
      const transaction = response.data.data;
      const isJob = isJobType(transaction.listing) || isJobHiddenType(transaction.listing);

      updateMetaData(transaction, getState, dispatch, isJob);
      dispatch(addMarketplaceEntities(response));
      dispatch(cancelSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(cancelError(storableError(e)));
      log.error(e, 'cancel-failed', { txId: id, transition });
      throw e;
    });
};

export const customRefund = (id) => (dispatch, getState, sdk) => {
  dispatch(refundRequest());

  const bodyParams = { transactionId: id.uuid };

  return refundCustomer(bodyParams)
    .then(response => {
      dispatch(refundSuccess(true));

      return response;
    })
    .catch(e => {
      dispatch(refundError(storableError(e)));
    });
};

export const transitionCustomTransition = param => (dispatch, getState, sdk) => {
  const { transactionId, transition } = param;

  switch (transition) {
    case TRANSITION_JOB_COMPLETE_REQUEST:
      dispatch(setJobCompleteRequest());
      break;

    case TRANSITION_JOB_COMPLETE:
      dispatch(jobCompleteRequest());
      break;

    case TRANSITION_JOB_COMPLETE_DECLINE:
      dispatch(declineJobCompleteRequest());
      break;

    case TRANSITION_REWORK_DONE_REQUEST:
      dispatch(reworkDoneRequest());
      break;

    case TRANSITION_TEACHER_CANCEL_JOB:
      dispatch(teacherCancelJobRequest());
      break;

    case TRANSITION_ADMIN_CANCEL_JOB:
      dispatch(adminCancelJobRequest());
      break;

    case TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE:
      dispatch(schoolAdminCancelJobBeforeCompleteRequest());
      break;

    case TRANSITION_ADMIN_CANCEL_JOB_BEFORE_COMPLETE:
      dispatch(adminCancelJobBeforeCompleteRequest());
      break;

    case TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK:
      dispatch(schoolAdminCancelJobBeforeReworkRequest());
      break;

    case TRANSITION_ADMIN_CANCEL_JOB_BEFORE_REWORK:
      dispatch(adminCancelJobBeforeReworkRequest());
      break;
  }

  return sdk.transactions
    .transition(
      {
        id: transactionId,
        transition,
        params: {},
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      const transaction = response.data.data;
      const isJob = isJobType(transaction.listing) || isJobHiddenType(transaction.listing);

      updateMetaData(transaction, getState, dispatch, isJob);
      switch (transition) {
        case TRANSITION_JOB_COMPLETE_REQUEST:
          dispatch(setJobCompleteSuccess());
          break;

        case TRANSITION_JOB_COMPLETE:
          dispatch(jobCompleteSuccess());
          break;

        case TRANSITION_JOB_COMPLETE_DECLINE:
          dispatch(declineJobCompleteSuccess());
          break;

        case TRANSITION_REWORK_DONE_REQUEST:
          dispatch(reworkDoneSuccess());
          break;


        case TRANSITION_TEACHER_CANCEL_JOB:
          dispatch(teacherCancelJobSuccess());
          break;

        case TRANSITION_ADMIN_CANCEL_JOB:
          dispatch(adminCancelJobSuccess());
          break;

        case TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE:
          dispatch(schoolAdminCancelJobBeforeCompleteSuccess());
          break;

        case TRANSITION_ADMIN_CANCEL_JOB_BEFORE_COMPLETE:
          dispatch(adminCancelJobBeforeCompleteSuccess());
          break;

        case TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK:
          dispatch(schoolAdminCancelJobBeforeReworkSuccess());
          break;

        case TRANSITION_ADMIN_CANCEL_JOB_BEFORE_REWORK:
          dispatch(adminCancelJobBeforeReworkSuccess());
          break;
      }

      return response;
    })
    .catch(e => {
      log.error(e, 'reject-sale-failed', {
        txId: transactionId,
        transition,
      });
      switch (transition) {
        case TRANSITION_JOB_COMPLETE_REQUEST:
          dispatch(setJobCompleteError(storableError(e)));
          break;

        case TRANSITION_JOB_COMPLETE:
          dispatch(jobCompleteError(storableError(e)));
          break;

        case TRANSITION_JOB_COMPLETE_DECLINE:
          dispatch(declineJobCompleteError(storableError(e)));
          break;

        case TRANSITION_REWORK_DONE_REQUEST:
          dispatch(reworkDoneError(storableError(e)));
          break;


        case TRANSITION_TEACHER_CANCEL_JOB:
          dispatch(teacherCancelJobError(storableError(e)));
          break;

        case TRANSITION_ADMIN_CANCEL_JOB:
          dispatch(adminCancelJobError(storableError(e)));
          break;

        case TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_COMPLETE:
          dispatch(schoolAdminCancelJobBeforeCompleteError(storableError(e)));
          break;

        case TRANSITION_ADMIN_CANCEL_JOB_BEFORE_COMPLETE:
          dispatch(adminCancelJobBeforeCompleteError(storableError(e)));
          break;

        case TRANSITION_SCHOOL_ADMIN_CANCEL_JOB_BEFORE_REWORK:
          dispatch(schoolAdminCancelJobBeforeReworkError(storableError(e)));
          break;

        case TRANSITION_ADMIN_CANCEL_JOB_BEFORE_REWORK:
          dispatch(adminCancelJobBeforeReworkError(storableError(e)));
          break;
      }
      throw e;
    });
};


export const postJobRefund = orderParams => (dispatch, getState, sdk) => {

  const { transactionId, hoursWorked } = orderParams;

  const bodyParams = {
    transactionId,
    hoursWorked
  };

  return jobRefund(bodyParams)
      .then(response => {
        return response;
      })
      .catch(e => {
        log.error(e, 'job-refund-failed', {
          txId: transactionId,
        });
        throw e;
      });
};

export const postUpdateTransactionMetadata = orderParams => (dispatch, getState, sdk) => {

  const {
    transactionId,
    hoursWorked,
    priceTotal,
    priceForHoursWorked,
    refundValue,
    commission,
    commissionWithRefund,
    totalPriceWithRefundAndCommission,
    transitionsToNotify,
  } = orderParams;

  const metadataParams = {
    ...(hoursWorked && { hoursWorked }),
    ...(priceTotal && { priceTotal }),
    ...(priceForHoursWorked && { priceForHoursWorked }),
    ...(refundValue && { refundValue }),
    ...(commission && { commission }),
    ...(commissionWithRefund && { commissionWithRefund }),
    ...(totalPriceWithRefundAndCommission && { totalPriceWithRefundAndCommission }),
    ...(transitionsToNotify && { transitionsToNotify }),
  }

  const bodyParams = {
    transactionId,
    metadata: metadataParams,
  };

  return updateTransactionMetadata(bodyParams)
      .then(response => {
        return response;
      })
      .catch(e => {
        log.error(e, 'update-transaction-metadata-failed', {
          txId: transactionId,
        });
        throw e;
      });
};

export const dispute = (id, disputeReason) => (dispatch, getState, sdk) => {
  if (transitionInProgress(getState())) {
    return Promise.reject(new Error('Transition already in progress'));
  }

  const params = disputeReason ? { protectedData: { disputeReason } } : {};
  dispatch(disputeRequest());
  return sdk.transactions
    .transition({ id, transition: TRANSITION_DISPUTE, params }, { expand: true })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(disputeSuccess());
      dispatch(fetchCurrentUserNotifications());
      return response;
    })
    .catch(e => {
      dispatch(disputeError(storableError(e)));
      log.error(e, 'dispute-failed', {
        txId: id,
        transition: TRANSITION_DISPUTE,
      });
      throw e;
    });
};

const fetchMessages = (txId, page) => (dispatch, getState, sdk) => {
  const paging = { page, per_page: MESSAGES_PAGE_SIZE };
  dispatch(fetchMessagesRequest());

  return sdk.messages
    .query({
      transaction_id: txId,
      include: ['sender', 'sender.profileImage'],
      ...getImageVariants(),
      ...paging,
    })
    .then(response => {
      const messages = denormalisedResponseEntities(response);
      const { totalItems, totalPages, page: fetchedPage } = response.data.meta;
      const pagination = { totalItems, totalPages, page: fetchedPage };
      const totalMessages = getState().TransactionPage.totalMessages;

      // Original fetchMessages call succeeded
      dispatch(fetchMessagesSuccess(messages, pagination));

      // Check if totalItems has changed between fetched pagination pages
      // if totalItems has changed, fetch first page again to include new incoming messages.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      if (totalItems > totalMessages && page > 1) {
        dispatch(fetchMessages(txId, 1))
          .then(() => {
            // Original fetch was enough as a response for user action,
            // this just includes new incoming messages
          })
          .catch(() => {
            // Background update, no need to to do anything atm.
          });
      }
    })
    .catch(e => {
      dispatch(fetchMessagesError(storableError(e)));
      throw e;
    });
};

export const fetchMoreMessages = txId => (dispatch, getState, sdk) => {
  const state = getState();
  const { oldestMessagePageFetched, totalMessagePages } = state.TransactionPage;
  const hasMoreOldMessages = totalMessagePages > oldestMessagePageFetched;

  // In case there're no more old pages left we default to fetching the current cursor position
  const nextPage = hasMoreOldMessages ? oldestMessagePageFetched + 1 : oldestMessagePageFetched;

  return dispatch(fetchMessages(txId, nextPage));
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(response => {
      const messageId = response.data.data.id;

      // We fetch the first page again to add sent message to the page data
      // and update possible incoming messages too.
      // TODO if there're more than 100 incoming messages,
      // this should loop through most recent pages instead of fetching just the first one.
      return dispatch(fetchMessages(txId, 1))
        .then(() => {
          dispatch(sendMessageSuccess());
          return messageId;
        })
        .catch(() => dispatch(sendMessageSuccess()));
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has already sent a review, we need to make transition to
// TRANSITION_REVIEW_2_BY_<CUSTOMER/PROVIDER>
const sendReviewAsSecond = (id, params, role, dispatch, sdk) => {
  const transition = getReview2Transition(role === 'provider');

  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...getImageVariants() })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendReviewError(storableError(e)));

      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

// If other party has not yet sent a review, we need to make transition to
// TRANSITION_REVIEW_1_BY_<CUSTOMER/PROVIDER>
// However, the other party might have made the review after previous data synch point.
// So, error is likely to happen and then we must try another state transition
// by calling sendReviewAsSecond().
const sendReviewAsFirst = (id, tx, params, role, userId, dispatch, sdk) => {


  const lastTransition = tx?.attributes?.lastTransition;
  const isJobComplete = lastTransition === TRANSITION_JOB_COMPLETE;

  const transition = isJobComplete ? getReview1Transition(role === 'provider') : TRANSITION_REVIEW_BY_CUSTOMER;
  const include = REVIEW_TX_INCLUDES;

  return sdk.transactions
    .transition({ id, transition, params }, { expand: true, include, ...getImageVariants() })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(sendReviewSuccess());
      return response;
    })
    .then(async response => {
      await updateUserRating({userId, stars: params.reviewRating});
      return response;
    })
    .catch(e => {
      // If transaction transition is invalid, lets try another endpoint.
      if (isTransactionsTransitionInvalidTransition(e)) {
        return sendReviewAsSecond(id, params, role, dispatch, sdk);
      } else {
        dispatch(sendReviewError(storableError(e)));

        // Rethrow so the page can track whether the sending failed, and
        // keep the message in the form for a retry.
        throw e;
      }
    });
};

export const sendReview = (role, tx, reviewRating, reviewContent, userId) => (dispatch, getState, sdk) => {
  const params = { reviewRating, reviewContent };

  const transitions = tx?.attributes?.transitions;
  const isJobComplete = transitions.some(t => t.transition === TRANSITION_JOB_COMPLETE)

  const txStateOtherPartyFirst = txIsInFirstReviewBy(tx, role !== 'provider');


  dispatch(sendReviewRequest());

  return !isJobComplete
    ? sendReviewAsFirst(tx.id, tx, params, role, userId, dispatch, sdk)
    : isJobComplete && txStateOtherPartyFirst
    ? sendReviewAsSecond(tx.id, params, role, dispatch, sdk)
    : sendReviewAsFirst(tx.id, tx, params, role, userId, dispatch, sdk);
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const now = new Date();
  const start = getStartOf(now, 'day', 'Etc/UTC');
  const end = addTime(start, timeSlotsRange, 'days', 'Etc/UTC');
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: addTime(end, secondRange, 'days', 'Etc/UTC'),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const fetchNextTransitions = id => (dispatch, getState, sdk) => {
  dispatch(fetchTransitionsRequest());

  return sdk.processTransitions
    .query({ transactionId: id })
    .then(res => {
      dispatch(fetchTransitionsSuccess(res.data.data));
    })
    .catch(e => {
      dispatch(fetchTransitionsError(storableError(e)));
    });
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const fetchProvider = id => dispatch => {
  dispatch(fetchProviderRequest());
  return getUserById(id)
    .then(response => dispatch(fetchProviderSuccess(response)))
    .catch(e => {
      dispatch(fetchProviderError(storableError(e)));
      log.error(e, 'fetching-provider-failed', { userId: id });
    });
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return Promise.all([
    dispatch(fetchTransaction(txId, txRole)),
    dispatch(fetchMessages(txId, 1)),
    dispatch(fetchNextTransitions(txId)),
  ]).then(response => {
    const currentResponse = response.filter(res => res?.data?.data?.type === 'listing');
    dispatch(fetchTimeSlots(currentResponse[0].data.data.id));
  });
};
