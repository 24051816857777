/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import moment from 'moment';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BreakdownMaybeCustom.module.css';
import {isSchoolAdminRole, isTeacherRole} from "../../util/types";

const { Money } = sdkTypes;

const CENTS = 100;

export const BreakdownMaybeCustom = props => {
  const {
    rootClassName,
    className,
    transaction,
    isJob,
    intl,
    currentUser,
    isApplyDiscount
  } = props;

  let isProvider = props.isProvider;
  let isCustomer = props.isCustomer;
  const isTeacher = isTeacherRole(currentUser);
  const isAdmin = isSchoolAdminRole(currentUser);


  if(isJob && isTeacher) {
    isProvider = true;
    isCustomer = false;
  }

  if(isJob && isAdmin) {
    isCustomer = true;
    isProvider = false;
  }

  const classes = classNames(rootClassName || css.root, className);

  const discountStatus = currentUser?.attributes?.profile?.publicData?.discountStatus;
  const PROVIDER_COMMISSION_PERCENTAGE = discountStatus && isProvider ? -10 : -15;


  const startDateDay = transaction?.attributes?.protectedData?.startDate;
  const endDateDay = transaction?.attributes?.protectedData?.endDate;

  const pricePerSeats = transaction?.attributes?.protectedData?.pricePerSeats;
  const seats = transaction?.attributes?.protectedData?.seats;

  if(!startDateDay || !endDateDay || !pricePerSeats || !seats) {
    return null
  }

  const pricePerSeatsMoney = new Money(pricePerSeats * CENTS, config.currency)
  const pricePerSeatsMoneyTotal = new Money((pricePerSeats * seats) * CENTS, config.currency)
  const customerDiscount = isCustomer && discountStatus && isApplyDiscount ? new Money((pricePerSeatsMoneyTotal.amount*5)/100, config.currency) : false;
  const priceWithCustomerDiscount = customerDiscount && new Money((pricePerSeatsMoneyTotal.amount - customerDiscount.amount), config.currency);
  const pricePerSeatsMoneyTotalWithCommission = new Money((pricePerSeats * seats * 100) - ((pricePerSeats * seats * 100) / 100 * PROVIDER_COMMISSION_PERCENTAGE) * -1, config.currency)


  const commission = new Money(((pricePerSeats * seats * 100) / 100 * PROVIDER_COMMISSION_PERCENTAGE) * -1, config.currency)
  const commissionValue = formatMoney(intl, commission);


  const formattedPrice = formatMoney(intl, pricePerSeatsMoney);
  const formattedPriceTotal = formatMoney(intl, pricePerSeatsMoneyTotal);
  const formattedCustomerDiscount = customerDiscount && formatMoney(intl, customerDiscount);


  const formattedPriceTotalWithCommission = formatMoney(intl, pricePerSeatsMoneyTotalWithCommission);
  const formattedPriceTotalWithCustomerDiscount = customerDiscount && formatMoney(intl, priceWithCustomerDiscount);


  return (
    <div className={classes}>

      <h3 className={css.title}>
        <FormattedMessage id="OrderBreakdown.title" />
      </h3>

      <div className={css.item}>
        <div className={css.bookingPeriodItem}>
          <div className={css.bookingPeriodLabel}>
            <FormattedMessage id="OrderBreakdown.bookingStart" />
          </div>
          <div className={css.bookingPeriodDay}>
            {moment(startDateDay).format('dddd')}
          </div>
          <div className={css.bookingPeriodMonth}>
            {moment(startDateDay).format("MMM D")}
          </div>
        </div>

        <div className={css.bookingPeriodItem}>
          <div className={css.bookingPeriodLabel}>
            <FormattedMessage id="OrderBreakdown.bookingEnd" />
          </div>
          <div className={css.bookingPeriodDay}>
            {moment(endDateDay).format('dddd')}
          </div>
          <div className={css.bookingPeriodMonth}>
            {moment(endDateDay).format("MMM D")}
          </div>
        </div>
      </div>

      <hr className={css.divider} />

      <div className={css.item}>
        <div className={css.pricePerSeatsItem}>
          <span className={css.label}>
            {formattedPrice}
            <FormattedMessage id="OrderBreakdown.seats" values={{ seats }} />
          </span>
        </div>
        <div className={css.pricePerSeatsItem}>
          <span className={css.value}>
            {formattedPriceTotal}
          </span>
        </div>
      </div>

      {customerDiscount && <div className={css.item}>
        <div className={css.pricePerSeatsItem}>
          <span className={css.label}>
            Discount
          </span>
        </div>
        <div className={css.pricePerSeatsItem}>
          <span className={css.value}>
            {formattedCustomerDiscount}
          </span>
        </div>
      </div>}


      {isProvider && (
        <>
          <hr className={css.divider} />

          <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.label}>
                <FormattedMessage id="OrderBreakdown.subTotal" />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.value}>
                {formattedPriceTotal}
              </span>
            </div>
          </div>
          <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.label}>
                <FormattedMessage id="OrderBreakdown.commission" />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.value}>
                -{commissionValue}
              </span>
            </div>
          </div>

          <hr className={css.divider} />

          <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalLabel}>
                <FormattedMessage id="OrderBreakdown.providerTotalDefault" />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalPrice}>
                {formattedPriceTotalWithCommission}
              </span>
            </div>
          </div>
          <span className={css.feeInfo}>
            <FormattedMessage id="OrderBreakdown.commissionFeeNote" />
          </span>
        </>
      )}

      {isCustomer && (
        <>
          <hr className={css.divider} />

          <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalLabel}>
                <FormattedMessage id="OrderBreakdown.total" />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalPrice}>
                {customerDiscount ? formattedPriceTotalWithCustomerDiscount : formattedPriceTotal}
              </span>
            </div>
          </div>
        </>
      )}

    </div>
  );
};

export default BreakdownMaybeCustom;


