import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';

import css from './ContactTeacherForm.module.css';
import Reaptcha from 'reaptcha';

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

const ContactTeacherFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        intl,
        contactTeacherRequest,
      } = fieldRenderProps;

      const [isVerified, setVerified] = useState(false);

      const onVerify = recaptchaResponse => {
        setVerified(true);
      };

      const messageLabel = intl.formatMessage({ id: 'ContactTeacherForm.messageLabel' });
      const messagePlaceholder = intl.formatMessage({ id: 'ContactTeacherForm.messagePlaceholder' });

      const classes = classNames(rootClassName || css.root, className);

      const submitDisabled = contactTeacherRequest || !isVerified;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.field}
            type="text"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
          />
          <div className={css.recaptchaHolder}>
            <Reaptcha sitekey={REACT_APP_RECAPTCHA_SITE_KEY} onVerify={onVerify} />
          </div>
          <div className={css.buttonHolder}>
            <Button className={css.submitButton} type="submit" inProgress={contactTeacherRequest} disabled={submitDisabled}>
              <FormattedMessage id="ContactTeacherForm.submitButtonText" />
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

ContactTeacherFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

ContactTeacherFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ContactTeacherForm = compose(injectIntl)(ContactTeacherFormComponent);

ContactTeacherForm.displayName = 'ContactTeacherForm';

export default ContactTeacherForm;
