import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';

// ================ Action types ================ //

export const FETCH_VALID_USER_REQUEST = 'app/InboxPage/FETCH_VALID_USER_REQUEST';
export const FETCH_VALID_USER_SUCCESS = 'app/InboxPage/FETCH_VALID_USER_SUCCESS';
export const FETCH_VALID_USER_ERROR = 'app/InboxPage/FETCH_VALID_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchUserInProgress: false,
  fetchUserId: null,
  fetchUserError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_VALID_USER_REQUEST:
      return { ...state, fetchUserInProgress: true, fetchUserId: null, fetchUserError: null };
    case FETCH_VALID_USER_SUCCESS:
      return { ...state, fetchUserInProgress: false, fetchUserId: payload, fetchUserError: null };
    case FETCH_VALID_USER_ERROR:
      return { ...state, fetchUserInProgress: false, fetchUserError: payload };

    default:
      return state;
  }
}

const fetchValidUserRequest = () => ({ type: FETCH_VALID_USER_REQUEST });
const fetchValidUserSuccess = response => ({
  type: FETCH_VALID_USER_SUCCESS,
  payload: response,
});
const fetchValidUserError = e => ({
  type: FETCH_VALID_USER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchValidUser = userId => (dispatch, getState, sdk) => {
  dispatch(fetchValidUserRequest());
  return sdk.users
    .show({id: userId})
    .then(response => {

      const userData = denormalisedResponseEntities(response);

      dispatch(fetchValidUserSuccess(userData));
      return response;
    })
    .catch(e => dispatch(fetchValidUserError(storableError(e))));
};
