import React from 'react';
import PrivacyPolicyContentEn from './PrivacyPolicyContentEn';

const PrivacyPolicy = () => {

  // prettier-ignore
  return (
    <PrivacyPolicyContentEn />
  );
};

export default PrivacyPolicy;
