import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';

import config from '../../config';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {isTeacherRole, propTypes, isParentRole} from '../../util/types';
import {
  Button,
  LimitedAccessBanner,
  Logo,
  Modal, ModalInviteFriend,
  ModalMissingInformation,
  NamedLink,
} from '../../components';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm';
import TopbarSearchFormTeachers from './TopbarSearchForm/TopbarSearchFormTeachers';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';

import css from './Topbar.module.css';
import ModalSignUp from '../ModalSignUp/ModalSignUp';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isInviteFriendOpen: false, isSignUpOpen: false };
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitListings = this.handleSubmitListings.bind(this);
    this.handleSubmitTeachers = this.handleSubmitTeachers.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleInviteFriendClose = this.handleInviteFriendClose.bind(this);
    this.handleInviteFriendOpen = this.handleInviteFriendOpen.bind(this);
    this.handleSignUpClose = this.handleSignUpClose.bind(this);
    this.handleSignUpOpen = this.handleSignUpOpen.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps?.isOpenSigUpModal !== this.props?.isOpenSigUpModal) {
      this.props.isOpenSigUpModal ? this.handleSignUpOpen() : this. handleSignUpClose();
    }
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleInviteFriendClose() { this.setState({ isInviteFriendOpen: false }) };
  handleInviteFriendOpen() { this.setState({ isInviteFriendOpen: true }) };

  handleSignUpClose() {
    this.setState({ isSignUpOpen: false });
    this.props.isOpenSigUpModal && this.props.setOpenSigUpModal(false)
  };
  handleSignUpOpen() { this.setState({ isSignUpOpen: true }) };

  handleSubmitListings(values, type) {
    const { currentSearchParams, currentUser } = this.props;
    const { history, location } = this.props;

    // const isJobSearch = location?.search?.includes('type=job');
    const searchType = type === 'course' ? 'course' : 'job';


    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return {
          keywords: values?.keywords,
          keywordsTeachers: null,
          keywordsTeachersValue: null,
          type: searchType,
        };
      }
      // topbar search defaults to 'location' search
      // const { search, selectedPlace } = values?.location;
      // const { origin, bounds } = selectedPlace;
      // const originMaybe = isOriginInUse(config) ? { origin } : {};

      // return {
      //   ...originMaybe,
      //   address: search,
      //   bounds,
      // };
    };
    const searchParams = {
      // ...currentSearchParams,
      ...topbarSearchParams(),
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleSubmitTeachers(values) {
    const { currentSearchParams } = this.props;
    const { history } = this.props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return {
          keywords: null,
          keywordsTeachers: !!values?.keywords && values.keywords.toLowerCase(),
          keywordsTeachersValue: values?.keywords,
          type: "teacher",
        };
      }
      // topbar search defaults to 'location' search
      // const { search, selectedPlace } = values?.location;
      // const { origin, bounds } = selectedPlace;
      // const originMaybe = isOriginInUse(config) ? { origin } : {};

      // return {
      //   ...originMaybe,
      //   address: search,
      //   bounds,
      // };
    };
    const searchParams = {
      // ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      // notificationCount,
      viewport,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      newSearchDesign,
      teacherTopbar,
      offerPage,
      unreadConversations,
    } = this.props;


    const notificationCount = this.props.notificationCount + unreadConversations;


    const { mobilemenu, mobilesearch, keywords, keywordsTeachers, keywordsTeachersValue, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const isTeacher = isTeacherRole(currentUser);
    const isJobSearch = location?.search?.includes('type=job');

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
        handleInviteFriendOpen={this.handleInviteFriendOpen}
        handleSignUpOpen={this.handleSignUpOpen}
      />
    );

    const topbarSearcInitialValues = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords, keywordsTeachers, keywordsTeachersValue };
      }

      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds;
      return {
        location: locationFieldsPresent
          ? {
              search: address,
              selectedPlace: { address, origin, bounds },
            }
          : null,
      };
    };
    const initialSearchFormValues = topbarSearcInitialValues();

    const classes = classNames(rootClassName || css.root, className);

    const referText = !isParentRole(currentUser) ? 'TopbarDesktop.referTeacher' : 'TopbarDesktop.referOther'
    const navLinks = (
      <div className={css.topbarNavLinksContainer}>
        {!currentUser && (
          <>
            <NamedLink name="OnlineTeachersForHirePage" className={css.createListingLink}>
              <FormattedMessage id="TopbarDesktop.hireTeacher" />
            </NamedLink>
            <NamedLink name="OnlineTeachingJobsPage" className={css.createListingLink}>
              <FormattedMessage id="TopbarDesktop.findJob"/>
            </NamedLink>
          </>
        )}

        {currentUser && (!isTeacherRole(currentUser) ? (
          <NamedLink name="SearchPage" to={{ search: 'keywordsTeachers=false&type=teacher' }} className={css.createListingLink}>
            <FormattedMessage id="TopbarDesktop.findTeachers" />
          </NamedLink>
        ) : (
          <NamedLink name="SearchPage" to={{ search: 'type=job' }} className={css.createListingLink}>
            <FormattedMessage id="TopbarDesktop.browseJob" />
          </NamedLink>
        ))}

        {currentUser && (!isTeacherRole(currentUser) ? (
          <NamedLink className={css.createListingLink} name="NewJobPage">
            <FormattedMessage id="TopbarDesktop.createJob" />
          </NamedLink>
        ) : (
          <NamedLink className={css.createListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarDesktop.createCourse" />
          </NamedLink>
        ))}

        {currentUser && (
          <NamedLink className={css.createListingLink} name="ReferralPage">
            <FormattedMessage id={referText} />
          </NamedLink>
        )}

        <NamedLink name="ContactUsPage" className={classNames(css.createListingLink, css.createListingLinkRegular)}>
          <FormattedMessage id="TopbarDesktop.contactUs" />
        </NamedLink>
        <NamedLink name="AboutPage" className={classNames(css.createListingLink, css.createListingLinkRegular)}>
          <FormattedMessage id="TopbarDesktop.about" />
        </NamedLink>
      </div>
    )

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <Button
            rootClassName={css.menu}
            onClick={this.handleMobileMenuOpen}
            title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
          >
            <MenuIcon className={css.menuIcon} />
            {notificationDot}
          </Button>
          <NamedLink
            className={css.home}
            name="LandingPage"
            title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
          >
            <Logo className={css.logo} format="mobile" />
          </NamedLink>
          <Button
            rootClassName={css.searchMenu}
            onClick={this.handleMobileSearchOpen}
            title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
          >
            <SearchIcon className={css.searchMenuIcon} />
          </Button>
        </div>

        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmitListings={this.handleSubmitListings}
            onSearchSubmitTeachers={this.handleSubmitTeachers}
            appConfig={config}
            handleInviteFriendOpen={this.handleInviteFriendOpen}
            handleSignUpOpen={this.handleSignUpOpen}
            isJobSearch={isJobSearch}
            newSearchDesign={newSearchDesign}
            teacherTopbar={teacherTopbar}
            location={location}
          />
        </div>
        <div className={css.inviteLinkHolder}>
          {navLinks}

        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={values => {
                return values.type === 'teacher' ? this.handleSubmitTeachers(values) : this.handleSubmitListings(values);
              }}
              initialValues={initialSearchFormValues}
              isMobile
              appConfig={config}
              searchPlaceholder={intl.formatMessage({ id: isJobSearch ? 'TopbarSearchForm.jobsPlaceholder' : 'TopbarSearchForm.coursesPlaceholder' })}

            />
            <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p>
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />
        <Modal
          id="TopbarInviteFriend"
          isOpen={this.state.isInviteFriendOpen}
          onClose={this.handleInviteFriendClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
          closeButtonMessage={" "}
        >
          <ModalInviteFriend
            currentUser={currentUser}
            currentPage={currentPage}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        </Modal>
        <Modal
          id="TopbarSignUp"
          isOpen={this.state.isSignUpOpen}
          onClose={this.handleSignUpClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
          closeButtonMessage={" "}
        >
          <ModalSignUp
            offerPage={offerPage}
            onClose={this.handleSignUpClose}
            location={location}
            routes={routeConfiguration()}
          />
        </Modal>

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  unreadConversations: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

const { array, func, number, shape, string } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(
  withViewport,
  injectIntl
)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
