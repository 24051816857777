import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaInstagram.module.css';

const IconSocialMediaInstagram = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        d="M7.998 5.332A2.673 2.673 0 005.33 8a2.673 2.673 0 002.668 2.668A2.673 2.673 0 0010.666 8a2.673 2.673 0 00-2.668-2.668zM15.999 8c0-1.105.01-2.2-.052-3.302-.062-1.281-.354-2.418-1.29-3.355-.94-.938-2.074-1.228-3.355-1.29C10.197-.01 9.102 0 8 0 6.895 0 5.8-.01 4.698.053 3.417.115 2.28.407 1.343 1.343.405 2.284.115 3.417.053 4.699-.01 5.803 0 6.898 0 8c0 1.103-.01 2.2.052 3.302.062 1.281.354 2.418 1.29 3.354.94.94 2.074 1.23 3.355 1.291C5.802 16.01 6.898 16 8 16c1.105 0 2.2.01 3.302-.052 1.28-.062 2.418-.354 3.354-1.29.939-.94 1.229-2.074 1.291-3.355C16.011 10.2 16 9.105 16 8zm-8.001 4.105A4.099 4.099 0 013.893 8a4.099 4.099 0 014.105-4.105A4.099 4.099 0 0112.102 8a4.099 4.099 0 01-4.104 4.105zm4.273-7.42a.958.958 0 110-1.917.957.957 0 110 1.918z"
      ></path>
    </svg>
  );
};

IconSocialMediaInstagram.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaInstagram.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaInstagram;
