/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect } from 'react';
import { node, number, string, shape } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';
import { createGlobalState } from './hookGlobalState';

import teachersImg from './images/teachers.svg';
import teachersBlueImg from './images/teachersBlue.svg';
import parentsImg from './images/parents.svg';
import parentsBlueImg from './images/parentsBlue.svg';
import referralsImg from './images/referrals.svg';
import referralsImgWhite from './images/referralsWhite.svg';
import schoolAdminsImg from './images/schoolAdmins.svg';
import schoolAdminsBlueImg from './images/schoolAdminsBlue.svg';
import signUpImg from './images/signup.svg';
import signUpWhiteImg from './images/signupWhite.svg';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

// Add global state for tab scrolling effect
const initialScrollState = { scrollLeft: 0 };
const { useGlobalState } = createGlobalState(initialScrollState);

// Horizontal scroll animation using element.scrollTo()
const scrollToTab = (currentTab, scrollLeft, setScrollLeft) => {
  const el = document.querySelector(`#${currentTab}Tab`);

  if (el) {
    // el.scrollIntoView doesn't work with Safari and it considers vertical positioning too.
    // This scroll behaviour affects horizontal scrolling only
    // and it expects that the immediate parent element is scrollable.
    const parent = el.parentElement;
    const parentRect = parent.getBoundingClientRect();
    const maxScrollDistance = parent.scrollWidth - parentRect.width;

    const hasParentScrolled = parent.scrollLeft > 0;
    const scrollPositionCurrent = hasParentScrolled ? parent.scrollLeft : scrollLeft;

    const tabRect = el.getBoundingClientRect();
    const diffLeftBetweenTabAndParent = tabRect.left - parentRect.left;
    const tabScrollPosition = parent.scrollLeft + diffLeftBetweenTabAndParent;

    const scrollPositionNew =
      tabScrollPosition > maxScrollDistance
        ? maxScrollDistance
        : parent.scrollLeft + diffLeftBetweenTabAndParent;

    const needsSmoothScroll = scrollPositionCurrent !== scrollPositionNew;

    if (!hasParentScrolled || (hasParentScrolled && needsSmoothScroll)) {
      // Ensure that smooth scroll animation uses old position as starting point after navigation.
      parent.scrollTo({ left: scrollPositionCurrent });
      // Scroll to new position
      parent.scrollTo({ left: scrollPositionNew, behavior: 'smooth' });
    }
    // Always keep track of new position (even if smooth scrolling is not applied)
    setScrollLeft(scrollPositionNew);
  }
};

const LayoutWrapperDashboardSideNavComponent = props => {
  const [scrollLeft, setScrollLeft] = useGlobalState('scrollLeft');
  useEffect(() => {
    const { currentTab, viewport } = props;

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasFontsLoaded =
      hasViewport && document.documentElement.classList.contains('fontsLoaded');

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasHorizontalTabLayout && hasFontsLoaded) {
      scrollToTab(currentTab, scrollLeft, setScrollLeft);
    }

    return () => {
      // Update scroll position when unmounting
      const el = document.querySelector(`#${currentTab}Tab`);
      !!el && setScrollLeft(el.parentElement.scrollLeft);
    };
  });

  const { currentTab, currentUser, rootClassName, customLinkClass, dashboard } = props;


  const tabs = [
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.signUpsTabTitle" />,
      selected: currentTab === 'SignUpAnalyticsPage',
      id: 'SignUpAnalyticsPageTab',
      linkProps: {
        name: 'SignUpAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'SignUpAnalyticsPage',
        mainLinkClassName: true,
        customLink: true,
        iconSrc: signUpImg,
        iconSelectedSrc: signUpWhiteImg,
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.teacherSignUpsTabTitle" />,
      selected: currentTab === 'SignUpByTeachersAnalyticsPage',
      id: 'SignUpByTeachersAnalyticsPage',
      linkProps: {
        name: 'SignUpByTeachersAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'SignUpByTeachersAnalyticsPage',
        customLink: true,
        iconSrc: teachersImg,
        iconSelectedSrc: teachersBlueImg,
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.schoolAdminSignUpsTabTitle" />,
      selected: currentTab === 'SignUpBySchoolAdminsAnalyticsPage',
      id: 'SignUpBySchoolAdminsAnalyticsPage',
      linkProps: {
        name: 'SignUpBySchoolAdminsAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'SignUpBySchoolAdminsAnalyticsPage',
        customLink: true,
        iconSrc: schoolAdminsImg,
        iconSelectedSrc: schoolAdminsBlueImg,
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.parentSignUpsTabTitle" />,
      selected: currentTab === 'SignUpByParentsAnalyticsPage',
      id: 'SignUpByParentsAnalyticsPage',
      linkProps: {
        name: 'SignUpByParentsAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'SignUpByParentsAnalyticsPage',
        customLink: true,
        iconSrc: parentsImg,
        iconSelectedSrc: parentsBlueImg,
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.signUpsByStateTitle" />,
      selected: currentTab === 'SignUpStateAnalyticsPage',
      id: 'SignUpStateAnalyticsPage',
      linkProps: {
        name: 'SignUpStateAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'all' && dashboard === 'state',
        mainLinkClassName: true,
        customLink: true,
        iconSrc: signUpImg,
        iconSelectedSrc: signUpWhiteImg,
        params: {id: 'all'}
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.teacherSignUpsTabTitle" />,
      selected: currentTab === 'SignUpStateAnalyticsPage',
      id: 'SignUpStateAnalyticsPage',
      linkProps: {
        name: 'SignUpStateAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'teacher' && dashboard === 'state',
        customLink: true,
        iconSrc: teachersImg,
        iconSelectedSrc: teachersBlueImg,
        params: {id: 'teachers'}
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.schoolAdminSignUpsTabTitle" />,
      selected: currentTab === 'SignUpStateAnalyticsPage',
      id: 'SignUpStateAnalyticsPage',
      linkProps: {
        name: 'SignUpStateAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'schoolAdministrator' && dashboard === 'state',
        customLink: true,
        iconSrc: schoolAdminsImg,
        iconSelectedSrc: schoolAdminsBlueImg,
        params: {id: 'school-admins'}
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.parentSignUpsTabTitle" />,
      selected: currentTab === 'SignUpStateAnalyticsPage',
      id: 'SignUpStateAnalyticsPage',
      linkProps: {
        name: 'SignUpStateAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'parent' && dashboard === 'state',
        customLink: true,
        iconSrc: parentsImg,
        iconSelectedSrc: parentsBlueImg,
        params: {id: 'parents'}
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.referralsTabTitle" />,
      selected: currentTab === 'ReferralsAnalyticsPage',
      id: 'ReferralsAnalyticsPage',
      linkProps: {
        name: 'ReferralsAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'all' && dashboard === 'referrals',
        mainLinkClassName: true,
        customLink: true,
        iconSrc: referralsImg,
        iconSelectedSrc: referralsImgWhite,
        params: {id: 'all'}
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.teacherReferralsTabTitle" />,
      selected: currentTab === 'ReferralsAnalyticsPage',
      id: 'ReferralsAnalyticsPage',
      linkProps: {
        name: 'ReferralsAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'teacher' && dashboard === 'referrals',
        customLink: true,
        iconSrc: teachersImg,
        iconSelectedSrc: teachersBlueImg,
        params: {id: 'teachers'}
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.schoolAdminReferralsTabTitle" />,
      selected: currentTab === 'ReferralsAnalyticsPage',
      id: 'ReferralsAnalyticsPage',
      linkProps: {
        name: 'ReferralsAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'schoolAdministrator' && dashboard === 'referrals',
        customLink: true,
        iconSrc: schoolAdminsImg,
        iconSelectedSrc: schoolAdminsBlueImg,
        params: {id: 'school-admins'}
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperDashboardSideNav.parentReferralsTabTitle" />,
      selected: currentTab === 'ReferralsAnalyticsPage',
      id: 'ReferralsAnalyticsPage',
      linkProps: {
        name: 'ReferralsAnalyticsPage',
        selectedCustomLinkClassName: currentTab === 'parent' && dashboard === 'referrals',
        customLink: true,
        iconSrc: parentsImg,
        iconSelectedSrc: parentsBlueImg,
        params: {id: 'parents'}
      },
    },
  ];

  return <LayoutWrapperSideNav customLinkClass={customLinkClass} rootClassName={rootClassName} tabs={tabs} />;
};

LayoutWrapperDashboardSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
};

LayoutWrapperDashboardSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperDashboardSideNav = compose(withViewport)(
  LayoutWrapperDashboardSideNavComponent
);

export default LayoutWrapperDashboardSideNav;
