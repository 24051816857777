import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, convertFromRaw, getDefaultKeyBinding, KeyBindingUtil } from 'draft-js'
// import { Editor } from 'react-draft-wysiwyg';
import './react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import css from './TextEditor.module.css';
import config from '../../config';
import classNames from 'classnames';

const {hasCommandModifier} = KeyBindingUtil;

const TextEditor = props => {

  if(typeof window === 'undefined') return;


  const { fieldValue, label, placeholder, input, meta } = props

  const fieldValueToString = !!fieldValue ? JSON.stringify(fieldValue) : JSON.stringify('');
  const fieldValueToObject = JSON.parse(fieldValueToString);

  const validContent = fieldValueToObject.indexOf("blocks") !== -1;
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const [convertedContent, setConvertedContent] = useState(null);




  const myKeyBindingFn = (e) => {
    if (e?.keyCode === 90 && e?.ctrlKey && hasCommandModifier(e)) {
      return 'myeditor-save';
    }
    return getDefaultKeyBinding(e);
  }

  useEffect(() => {
    if (!!fieldValue?.length) {

      const contentForOldListing = {
        "entityMap": {},
        "blocks": [{
          "key": "637gr", "text": `${fieldValueToString.replaceAll('"', '')}`,
          "type": "unstyled", "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }]
      };

      if (validContent) {
        const content = convertFromRaw(JSON.parse(fieldValue))
        const editorStateInitialValues = EditorState.createWithContent(content)
        setEditorState(editorStateInitialValues)
      } else {
        setEditorState(EditorState.createWithContent(convertFromRaw(contentForOldListing)))
      }
    }
  }, [])

  const handleEditorChange = (state) => {
    setEditorState(state);

    const html = convertToHTML(editorState.getCurrentContent());
    const json = JSON.stringify(convertToRaw(state.getCurrentContent()));
    return input.onChange({html, json})
  }

  const { Editor } = require('react-draft-wysiwyg');


  return (
    <div className={css.root}>
      {!!label && <label className={classNames(css.requiredLabel, css.title)}>{label}</label>}
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName={css.editorWrapper}
        editorClassName={css.editor}
        toolbar={config.editorToolbarOptions}
        placeholder={placeholder || null}
        stripPastedStyles={true}
      />
    </div>
  )
}

export default TextEditor;
