import React from 'react';

import css from './TopbarSearchForm.module.css';

const IconSearchDesktop = () => (
  // className={css.iconSvgGroup}
  <svg
    className={css.iconSvg}
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    fill="none"
    viewBox="0 0 21 22"
  >
    <path
      stroke="#454545"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 15l-5.241 5.241"
    ></path>
    <circle
      r="7.5"
      stroke="#454545"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="matrix(-1 0 0 1 12.5 8.5)"
    ></circle>
  </svg>
);

export default IconSearchDesktop;
