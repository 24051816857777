import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getContentfulPage, searchByUser } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import config from '../../config';
import { areasCertifiedOptions } from '../../config/marketplace-custom-config';

const RESULT_PAGE_SIZE = 25;

// ================ Action types ================ //

export const SEARCH_TEACHERS_REQUEST = 'app/OnlineTeachingJobsPage/SEARCH_TEACHERS_REQUEST';
export const SEARCH_TEACHERS_SUCCESS = 'app/OnlineTeachingJobsPage/SEARCH_TEACHERS_SUCCESS';
export const SEARCH_TEACHERS_ERROR = 'app/OnlineTeachingJobsPage/SEARCH_TEACHERS_ERROR';

export const CONTENT_REQUEST = 'app/OnlineTeachingJobsPage/CONTENT_REQUEST';
export const CONTENT_SUCCESS = 'app/OnlineTeachingJobsPage/CONTENT_SUCCESS';
export const CONTENT_ERROR = 'app/OnlineTeachingJobsPage/CONTENT_ERROR';

// ================ Reducer ================ //

const initialState = {
  searchTeachersInProgress: false,
  searchTeachersError: null,
  teachers: [],
  searchParams: null,
  pagination: null,
  //content
  content: null,
  contentError: null,
  contentInProgress: false,
};

const onlineTeachersForHirePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_TEACHERS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchTeachersInProgress: true,
        teachers: [],
        searchTeachersInError: null,
      };
    case SEARCH_TEACHERS_SUCCESS:
      return {
        ...state,
        teachers: payload?.users,
        pagination: payload?.response?.data?.meta || null,
        searchTeachersInProgress: false,
      };
    case SEARCH_TEACHERS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchTeachersInProgress: false, searchTeachersError: payload };

    case CONTENT_REQUEST:
      return { ...state, contentInProgress: true, contentError: null };
    case CONTENT_SUCCESS:
      return { ...state, contentInProgress: false, content: payload };
    case CONTENT_ERROR:
      return { ...state, contentInProgress: false, contentError: true };

    default:
      return state;
  }
};

export default onlineTeachersForHirePageReducer;

// ================ Action creators ================ //

export const searchTeachersRequest = searchParams => ({
  type: SEARCH_TEACHERS_REQUEST,
  payload: { searchParams },
});

export const searchTeachersSuccess = (response, users) => ({
  type: SEARCH_TEACHERS_SUCCESS,
  payload: {response, users},
});

export const searchTeachersError = e => ({
  type: SEARCH_TEACHERS_ERROR,
  error: true,
  payload: e,
});

const contentRequest = () => ({ type: CONTENT_REQUEST });
const contentSuccess = content => ({ type: CONTENT_SUCCESS, payload: content });
const contentError = () => ({ type: CONTENT_ERROR });

const generateSearchParams = parent => {
  return config.custom.areasCertifiedOptions.filter(i => i.parent === parent).map(({ key }) => key).join(',');
}

export const searchTeachers = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchTeachersRequest(searchParams));

  const params = {
    page: searchParams.page,
    perPage: searchParams.perPage,
    sort: 'pub_completeness,createdAt',
  };

  if (searchParams?.state) params.pub_state = `has_any:${config.custom.stateOptions.find(i => i.short === searchParams.state).key}`;
  if (searchParams?.subject) {
    const parentSubject = config.custom.areasCertifiedOptions.find(i => i.staticUrl === `${searchParams.subject}`);
    params.pub_subjects = `has_any:${generateSearchParams(parentSubject?.parent)}`;
  }

  return searchByUser(params)
    .then(res => {
      const users = !!res ? denormalisedResponseEntities(res) : null;
      dispatch(searchTeachersSuccess(res, users));
      return !!res?.data ? res?.data : res;
    })
    .catch(e => {
      dispatch(searchTeachersError(storableError(e)));
      throw e;
    });
}

export const getContent = (stateMaybe, subjectMaybe) => dispatch => {
  dispatch(contentRequest());

  let slug = stateMaybe ? `teacher-page-${stateMaybe}` : 'teacher-page';
  slug = subjectMaybe ? `${slug}-${subjectMaybe}` : slug;

  return getContentfulPage('teacherPage', slug)
    .then(response => dispatch(contentSuccess(response.items[0].fields)))
    .catch(e => dispatch((contentError(e))));
};

export const loadData = params => dispatch => {
  return Promise.all([
    dispatch(searchTeachers({ ...params, page: 1, perPage: RESULT_PAGE_SIZE })),
    dispatch(getContent(params?.state, params?.subject)),
  ]);
};
