import React, { Component } from 'react';
import { lazyLoadWithDimensions } from '../../../util/contextHelpers';
import AliceCarousel from 'react-alice-carousel';
import { randomImage } from '../../../util/randomImage';

import { FormattedMessage } from "../../../util/reactIntl";
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from "../../../components";
import config from "../../../config";
import { uniqBy } from "lodash";
import { associateResult, getAssociatedAreas } from "../../../util/areasHelpers";
import { isSchoolAdminRole } from '../../../util/types';
import { hideLicense, gradeLevelsFormatter } from '../../../util/data';
import { createSlug } from '../../../util/urlHelpers';

import css from './SectionTeachersCarousel.module.css';
import classNames from 'classnames';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <div className={css.imageContainer}><ResponsiveImage className={css.image} {...this.props} /></div>
  }
}

const LazyImage = lazyLoadWithDimensions(LocationImage);
const IMAGE_VARIANTS = [
  'square-xsmall',
  'square-xsmall2x',
  'square-small',
  'square-small2x',
];

const { aspectWidth = 1, aspectHeight = 1 } = config.listing;

const SectionTeachersCarousel = (props) => {
  const { teachers, currentUser, isCustomCard, successPage } = props;

  const items = () => {
    if (!teachers) {
      return '';
    }
    return teachers.map(teacher => {
      const { profileImage } = teacher;
      const { profile } = teacher?.attributes;
      const { displayName, bio, publicData } = profile;
      const { onboardingProcess, firstName, lastName } = publicData;
      const { experience, license, education } = onboardingProcess;
      const licenseNumber = onboardingProcess?.licenseNumber && onboardingProcess?.licenseNumber !== null ? onboardingProcess.licenseNumber : onboardingProcess?.licenses && onboardingProcess?.licenses[0] ? onboardingProcess?.licenses[0]?.licenseNumber : null;
      const usStateValue = onboardingProcess?.usState && onboardingProcess?.usState !== null ? onboardingProcess.usState : onboardingProcess?.licenses && onboardingProcess?.licenses[0] ? onboardingProcess?.licenses[0]?.usState.label : null;
      const usState = usStateValue?.label ? usStateValue?.label : usStateValue;
      const isLicense = license === 'Yes';
      const reformatLicenseNumber = isLicense && licenseNumber && (!currentUser || !isSchoolAdminRole(currentUser)) ? hideLicense(licenseNumber) : licenseNumber;
      const licenseState = license === 'Yes' ? 'Licensed in: ' : license === 'I don\'t have an active license' ? 'State: ' : license === 'My license is being processed' ? 'State: ' : null;
      const isNewUser = onboardingProcess && onboardingProcess?.licenses && onboardingProcess?.licenses.length > 0;
      const isTeacherHasState = isNewUser && onboardingProcess && onboardingProcess?.licenses && onboardingProcess?.licenses[0]?.usState !== null;


      // Grade levels
      const gradeLevelsArrayIn = onboardingProcess && onboardingProcess?.licenses ? onboardingProcess?.licenses.map(l => l.gradeLevels) : onboardingProcess.gradeLevels;
      const gradeLevelsArray = onboardingProcess && onboardingProcess?.licenses ? gradeLevelsArrayIn : onboardingProcess.gradeLevels;
      const isGradeLevelsArray = onboardingProcess && gradeLevelsArray && onboardingProcess?.licenses ? gradeLevelsArray[0] : gradeLevelsArray;

      const gradeLevels = onboardingProcess && gradeLevelsArray && gradeLevelsArray[0] && gradeLevelsArray[0].length > 0 && isGradeLevelsArray.length > 0 && isGradeLevelsArray.map(item => {
        if (item.label) {
          return <span className={css.itemSpan}>{item.label}</span>
        } else {
          return <span className={css.itemSpan}>{item}</span>
        }
      })

      const gradeLevelsArr = onboardingProcess && gradeLevelsArray && gradeLevelsArray[0] && gradeLevelsArray[0].length > 0 && isGradeLevelsArray.length > 0 && isGradeLevelsArray.map(item => {
        if (item.label) {
          return item.label
        } else {
          return item
        }
      })
      const gradeLevelsOptions = gradeLevelsFormatter(gradeLevelsArr);


      const associatedAreas = getAssociatedAreas(onboardingProcess);

      const areasCertified =
        <span className={css.subItem}>{
          associatedAreas?.map(item => {
            return Object.entries(item).map(([key, value], i) => {
              return (
                <span className={css.itemParent} key={i}>
                  <span className={css.itemParentValue}>{key} </span>
                  ({value.map(v => <span className={css.itemSpan}>{v}</span>)})
                </span>
              )
            })
          })}
        </span>

      const rImage = randomImage('listingCard');
      const slug = `${createSlug(`${firstName} ${lastName}`)}-${publicData.customId}`;

      return (
        <NamedLink
          className={classNames(css.teachersWrapper, {[css.teachersWrapperBlocked]: isCustomCard})}
          // name="ProfilePage"
          // to={{ state: {fromTexasTeachersLandingPage: true} }}
          // params={{ id: teacher.id.uuid }}
          name="TeachersProfilePage"
          params={{ slug }}
          >

          {<AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={displayName}
              image={teacher.profileImage}
              variants={IMAGE_VARIANTS}
              randomImage={rImage}
            />
          </AspectRatioWrapper>}

          <div className={css.teachersHolder}>
            <div className={css.titleHolder}>
              <h2 className={css.teachersTitle}>{firstName && lastName ? firstName + ' ' + lastName : displayName}</h2>
              <p className={css.teachersBio}>{bio}</p>
            </div>


            <ul className={css.teachersInfoList}>
              <li className={css.teachersInfoItem}>
                <FormattedMessage id="SectionTeachersCarousel.experience" />
                <span className={classNames(css.teachersInfoSpan, css.teachersInfoSpanBg)}>
                  {experience} years
                </span>
              </li>
              {usState &&
                <li className={css.teachersInfoItem}>
                  {licenseState}
                  <span className={css.teachersInfoSpan}>
                    {usState}
                  </span>
                </li>}
              {/*<li className={css.teachersInfoItem}>Level of education: <span*/}
              {/*  className={css.teachersInfoSpan}>{education}</span></li>*/}
              {gradeLevels &&
                <li className={css.teachersInfoItem}>
                  <FormattedMessage id="SectionTeachersCarousel.certifiedInGrades" />
                  <span className={css.teachersInfoSpan}>
                    {gradeLevelsOptions}
                  </span>
                </li>}
              {areasCertified &&
                <li className={css.teachersInfoItem}>
                  <FormattedMessage id="SectionTeachersCarousel.contentArea" />
                  <span className={css.teachersInfoSpan}>
                    {areasCertified}
                  </span>
                </li>}
            </ul>
          </div>
          {/*<div className={css.buttonProfile}>*/}
          {/*  <FormattedMessage id="SectionTeachersCarousel.viewProfile" />*/}
          {/*</div>*/}
        </NamedLink>
      )
    });
  };

  return <div className={css.teachersCarouselHolder}>
    <div className={css.rootTitleHolder}>
      <h2 className={css.rootTitle}>
        {successPage ? <FormattedMessage id='SectionTeachersCarouselSuccessPage.title' /> : <FormattedMessage id='LandingPage.teachersCarouselTitle' />}
      </h2>
    </div>


    <div className={css.itemsHolder}>
      <AliceCarousel
        mouseTrackingEnabled
        items={items()}
        disableDotsControls={true}
        // infinite={true}
        responsive={{
          0: {
            items: 1,
          },
          550: {
            items: 2,
          },
          1024: {
            items: 4,
          }
        }}
      />
    </div>
  </div>;
};

export default SectionTeachersCarousel;
