import unionWith from 'lodash/unionWith';

import config from '../../config';
import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { getExclusiveEndDate, parseDateFromISO8601 } from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { searchByUser } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_TEACHERS_REQUEST = 'app/SearchPage/SEARCH_TEACHERS_REQUEST';
export const SEARCH_TEACHERS_SUCCESS = 'app/SearchPage/SEARCH_TEACHERS_SUCCESS';
export const SEARCH_TEACHERS_ERROR = 'app/SearchPage/SEARCH_TEACHERS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  searchTeachersInProgress: false,
  searchTeachersError: null,
  teachers: [],
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_TEACHERS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchTeachersInProgress: true,
        teachers: [],
        searchTeachersInError: null,
      };
    case SEARCH_TEACHERS_SUCCESS:
      return {
        ...state,
        teachers: payload?.users,
        pagination: payload?.response?.data?.meta || null,
        searchTeachersInProgress: false,
      };
    case SEARCH_TEACHERS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchTeachersInProgress: false, searchTeachersError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid,
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchTeachersRequest = searchParams => ({
  type: SEARCH_TEACHERS_REQUEST,
  payload: { searchParams },
});

export const searchTeachersSuccess = (response, users) => ({
  type: SEARCH_TEACHERS_SUCCESS,
  payload: { response, users },
});

export const searchTeachersError = e => ({
  type: SEARCH_TEACHERS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});


const getAllInSubjectsForSearchParams = (searchParams) => {
  const arrayOfSubjects = getArrayOfSubjectsFromSearchParams(searchParams);
  const allInSubjects = config.custom.areasCertifiedOptions.filter(area => area.allIn);
  const arrayOfSubjectsObjects = arrayOfSubjects?.map(objFromA => config.custom.areasCertifiedOptions.filter(objFromB => objFromA === objFromB.key)).flat();
  const arrayOfParents = arrayOfSubjectsObjects?.map(a => a.parent);
  const uniqueArrayOfParents = [...new Set(arrayOfParents)];
  const correspondingAllInSubject = uniqueArrayOfParents?.map(objFromA => allInSubjects.filter(objFromB => objFromB.parent === objFromA)).flat();

  return correspondingAllInSubject.map(a => a.key).join(',');

};

const getArrayOfSubjectsFromSearchParams = (searchParams) => {
  const urlSearchParams = new URLSearchParams(searchParams);
  const objectFromUrl = Object.fromEntries([...urlSearchParams]);
  const listOfSubjectsFromUrl = objectFromUrl?.pub_subjects && objectFromUrl?.pub_subjects.replace('has_any:', '');

  return  listOfSubjectsFromUrl && listOfSubjectsFromUrl.split(',');
}

const isAllInSubjectSelected = (searchParams) => {
  const arrayOfSubjects = getArrayOfSubjectsFromSearchParams(searchParams);
  const objectOfSubjects = config.custom.areasCertifiedOptions.filter(a => arrayOfSubjects?.find(b => b === a.key));

  return objectOfSubjects.some(area => area.allIn);
}

export const getAllSubjectsWithSameParent = (searchParams) => {
  const arrayOfSubjects = getArrayOfSubjectsFromSearchParams(searchParams);
  const objectOfSubjects = config.custom.areasCertifiedOptions.filter(a => arrayOfSubjects?.find(b => b === a.key));
  const allInSubjects = objectOfSubjects?.filter(area => area.allIn);
  const allInSubjectsParent = allInSubjects?.map(o => o.parent);
  const optionsWithSameParent = config.custom.areasCertifiedOptions.filter(a => allInSubjectsParent?.find(b => a.parent === b))

  return optionsWithSameParent?.map(a => a.key).join(',')
}

export const searchTeachers = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchTeachersRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];

    return priceParam && values.length === 2
      ? {
        pub_rate: [values[0], parseInt(values[1]) + 1].join(','),
      }
      : {};
  };

  const rateMaybe = priceSearchParams(searchParams.pub_rate);

  // if user chose any subject --> add 'allIn' option too
  const allInKeys = getAllInSubjectsForSearchParams(searchParams);

  console.log(searchParams, 'searchParams')

  // if user chose "all in" option --> add all options with the same parent
  const allInWithSameParent = getAllSubjectsWithSameParent(searchParams);
  const allInSubjectSelected = isAllInSubjectSelected(searchParams);

  const subjectsSearchParams = allInSubjectSelected ? searchParams.pub_subjects + ',' + allInWithSameParent : allInKeys ? searchParams.pub_subjects + ',' + allInKeys : searchParams.pub_subjects;
  const sort = searchParams?.sort ? 'pub_completeness,' + searchParams.sort : 'pub_completeness,createdAt';

  const params = {
    page: searchParams.page,
    perPage: searchParams.perPage,
    pub_userRole: 'teacher',
    ...(searchParams.pub_education && { pub_education: searchParams.pub_education }),
    ...(searchParams.pub_experience && { pub_experience: searchParams.pub_experience }),
    ...(searchParams.pub_gradeLevels && { pub_gradeLevels: searchParams.pub_gradeLevels }),
    ...(searchParams.pub_license && { pub_license: searchParams.pub_license }),
    ...(searchParams.pub_state && { pub_state: searchParams.pub_state }),
    ...(searchParams.pub_subjects && { pub_subjects: subjectsSearchParams }),
    ...(searchParams.pub_rate && rateMaybe),

    // pub_gradeLevels: searchParams.pub_gradeLevels,
    // pub_license: searchParams.pub_license,
    // pub_state: searchParams.pub_state,
    // pub_subjects: searchParams.pub_subjects,
    // pub_rate: searchParams.pub_rate,

    sort,
    // sort: '-pub_experienceSort',
    // sort: 'pub_rating',
    // sort: '-pub_rating',
    // sort: 'pub_rate',
    // sort: '-pub_rate',
  };


  if (searchParams.keywordsTeachers) {
    const keywordsTeachers = searchParams.keywordsTeachers;
    params.pub_searchParams = `has_any:${keywordsTeachers}`;
  }

  return searchByUser(params)
    .then(res => {
      const users = !!res ? denormalisedResponseEntities(res) : null;
      dispatch(searchTeachersSuccess(res, users));
      return !!res?.data ? res?.data : res;
    })
    .catch(e => {
      dispatch(searchTeachersError(storableError(e)));
      throw e;
    });
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.lineItemUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking
        ? values[1]
        : hasValues
          ? getExclusiveEndDate(values[1], 'Etc/UTC')
          : null;

    return hasValues
      ? {
        start: parseDateFromISO8601(startDate, 'Etc/UTC'),
        end: parseDateFromISO8601(endDate, 'Etc/UTC'),
        // Availability can be full or partial. Default value is full.
        availability: 'full',
      }
      : {};
  };

  // if user chose any subject --> add 'allIn' option too
  const allInKeys = getAllInSubjectsForSearchParams(searchParams);

  // if user chose "all in" option --> add all options with the same parent
  const allInWithSameParent = getAllSubjectsWithSameParent(searchParams);
  const allInSubjectSelected = isAllInSubjectSelected(searchParams);


  const subjectsSearchParams = allInSubjectSelected ? searchParams.pub_subjects + ',' + allInWithSameParent : allInKeys ? searchParams.pub_subjects + ',' + allInKeys : searchParams.pub_subjects;

  const { perPage, price, dates, sort, type, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = sort === config.custom.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...sortMaybe,
    pub_subjects: subjectsSearchParams,
    pub_type: type,
    per_page: perPage,
  };


  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });


  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { page = 1, address, origin, type, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  if (type === 'teacher') {
    return searchTeachers({ ...rest, page, perPage: RESULT_PAGE_SIZE });
  } else {
    return searchListings({
      ...rest,
      ...originMaybe,
      type,
      // pub_education: `has_any:bachelors`,
      // pub_experience: `has_any:4-7,test`,
      // pub_gradeLevels: `has_any:4,5`,
      // pub_license: true,
      // pub_state: `has_any:alabama,test`,
      // pub_subjects: `has_any:mathematics,test`,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
      'fields.user': ['profile.firstName', 'profile.lastName', 'profile.displayName', 'profile.abbreviatedName', 'profile.publicData.nickname', 'profile.publicData.searchParams'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    });
  }
};
