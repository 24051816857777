import moment from 'moment/moment';
import config from '../../config';
import { zipWith } from 'lodash';

export const getFormattedByDayAndTimeCustomPeriods = (customPeriods) => {
  const formattedCustomPeriods = customPeriods?.map(period => {
    const day = moment(period.start_date).format('dddd');
    const startTime = moment(period.start_date).format('HH:mm a');
    const endTime = moment(period.end_date).format('HH:mm a');

    return { day, startTime, endTime };
  });

  formattedCustomPeriods?.sort((a, b) => config.custom.allWeekDays.indexOf(a.day) - config.custom.allWeekDays.indexOf(b.day));

  const arrayCustomPeriodsByDay = formattedCustomPeriods?.map((period) => {
    const day = period?.day;
    return { [day]: [{ startTime: period.startTime, endTime: period.endTime }] };
  });


  const mergedCustomPeriodsByDay = arrayCustomPeriodsByDay?.reduce((acc, curr) => {
    const key = Object.keys(curr)[0];
    const found = acc.find(i => i[key]);
    if (!found) {
      acc.push(curr);
    } else {
      found[key] = [...found[key], ...curr[key]];
    }
    return acc;
  }, []);

  const daysArray = config.custom.allWeekDays.filter(objFromA => (mergedCustomPeriodsByDay?.find(objFromB => (Object.keys(objFromB)[0] === objFromA))));

  const formattedByDayAndTime = zipWith(mergedCustomPeriodsByDay, daysArray, (day, customDay) => {
    if (day !== undefined) {
      if (Object.keys(day)[0] === customDay) {
        return {
          [customDay]: day[customDay].toSorted(
            ({ startTime: a }, { startTime: b }) => a < b ? -1 : a > b ? 1 : 0,
          ),
        };
      }
    }
  });

  return  formattedByDayAndTime.filter(item => item !== undefined);
}


