import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemBaseHoursMaybe = props => {
  const { lineItems, unitType, intl } = props;
  const unitPurchase = lineItems.find(item => item.code === unitType && !item.reversal);
  const hours = unitPurchase ? unitPurchase.units.toString() : null;

  return hours ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        Number of hours:
      </span>
      <span className={css.itemValue}>{hours}</span>
    </div>
  ) : null;
};

LineItemBaseHoursMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  unitType: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBaseHoursMaybe;
