import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicyContentEn = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Updated and Effective July 21st, 2022.</p>

      <p>
        This Privacy Policy applies to the AllCourse website at www.AllCourse.com (“Website”), owned and operated by AllCourse, Inc.
        (“AllCourse”, “we”, “us”)  The protection of your personal information is important to us, and we have provided this policy
        to inform our Website visitors (“you,” “your”), of how we may collect, use, maintain, disclose and protect your information.
        This Privacy Policy is incorporated into and part of our Terms of Service.
      </p>
      <p>
        By accessing or using this Website, you agree to the terms of this Privacy Policy.
      </p>

      <h2>Information You Provide</h2>
      <p>
        If you decide to subscribe to receive updates from AllCourse, we will ask you to provide your first name and email address so that
        we may personalize our messages to you.  You may also choose to share your contact information and resume or LinkedIn profile when
        applying for a position with AllCourse.
      </p>
      <p>
        If you choose to comment on an AllCourse blog post, we will ask for your name and email address and your comments.  In addition, if
        you volunteer to participate in focus group or related product research with us, or decide to attend a webinar or download a piece of
        information, we may ask you to provide your first and last name, email address, other contact information, school level, state, your bio
        from your school website or LinkedIn profile, and information about your teaching practices and experience so that we may understand your
        experience level and expertise.
      </p>
      <p>
        You may also provide information to be published or displayed (“posted”) on public areas of the Website, or transmitted to other users of
        the Website or third parties (collectively “User Contributions”).  Your User Contributions are posted and/or transmitted to others at your own risk.
      </p>



      <h2>Children Under the Age of 13</h2>
      <p>
        Our Website is not intended for children under the age of 13. We do not knowingly collect information from users under the age of 13.
        If we learn that we have inadvertently collected personal information from children under the age of 13, we will promptly delete that information.
      </p>

      <h2>Information We Collect Automatically</h2>
      <p>
        When you visit our Website, our servers automatically record information sent from your browser (“Log Data”).
        Log Data may include information such as your computer's Internet Protocol (“IP”) address, device ID, web browser,
        operating system, pages of the Website that you visit, the time spent on those pages, access times and dates, general geolocation,
        and referral URL. We use this information to monitor, analyze use of and administer the Website, and to better tailor it to your needs.
      </p>

      <p>
        To collect this information, we use technological tools including:
      </p>


      <h2>Cookies</h2>
      <p>
        A cookie is a small data file sent from a website or application and stored on your computer.
        Cookies allow us to recognize your browser when you return to our Website and to serve certain features, to better understand how you
        interact with our Website, and to monitor aggregated usage. You can set your browser to detect some cookies, to stop accepting cookies
        or to prompt you before accepting a cookie. To learn more about browser cookies, including how to manage or delete them, look in the Tools,
        Help or similar section of your web browser, or visit allaboutcookies.org.
      </p>

      <h2>
        Pixel tags
      </h2>
      <p>
        A pixel tag (also called a “clear GIF” or “web beacon”) is a tiny image that can be placed on a Website page or in an email to you,
        to tell us when you have displayed that page or opened that email.
      </p>

      <p>
        By using our Website, you agree to our use of these tracking technologies.
      </p>

      <h2>
        Third Party Web Analytics
      </h2>
      <p>
        We use Google Analytics to assist us in collecting and understanding Log Data. You can learn more about Google Analytics data
        practices at the Google Analytics Terms of Use  and the Google Privacy Policy. You can prevent Google Analytics from collecting
        information about you and recognizing you on return visits to our Website by downloading the Google Analytics opt-out browser
        add-on, available at https://tools.google.com/dlpage/gaoptout. We are not responsible for the Google Analytics opt-out tools.
      </p>

      <h2>
        Targeted Advertising
      </h2>
      <p>
        We use Google Analytics' 3rd-party audience data such as age, gender, and interests to better understanding the behavior of
        website visitors and work with companies that collect information about your online activities to provide advertising targeted
        to suit your interests and preferences. For example, you may see certain ads on other websites after you have visited this website
        because we contract with Google and other similar companies to target our ads based on information we or they have collected, including
        information that was collected through automated means (such as cookies). These companies also use automated technologies to collect
        information when you click on our ads, which helps track and manage the effectiveness of our marketing efforts.
      </p>
      <p>
        You may opt out of the automated collection of information by third-party ad networks for the purpose of delivering advertisements tailored
        to your interests, by visiting the consumer opt-out page for the Self-Regulatory Principles for Online Behavioral Advertising
        at <a href="http://www.aboutads.info/choices/" target='_blank'>http://www.aboutads.info/choices/</a> and you may edit or opt-out your Google Display Network ads'
        preferences at <a href="http://www.google.com/ads/preferences/" target='_blank'>http://www.google.com/ads/preferences/</a>.
      </p>

      <h2>
        Third-Party Website Tracking
      </h2>
      <p>
        We do not serve targeted advertising on our Website, however, when you use the Internet, unaffiliated parties such as ad networks,
        web analytics companies and social networking platforms may collect information about your online activities over time and across our and other websites.
        This information may be used to provide advertisements for products and services that may interest you, and those companies may use cookies,
        clear GIFs and other tracking technologies.
      </p>
      <p>
        We do not honor “do not track” signals transmitted by web browsers as there is no industry standard for implementing such programs.
        For more information about third-party advertisers and how to prevent them from using your information, visit the NAI's consumer
        website at <a href="http://www.networkadvertising.org" target='_blank'>http://www.networkadvertising.org</a> choices
        or <a href="http://www.aboutads.info/choices/" target='_blank'>http://www.aboutads.info/choices/</a>.  If you do want to opt out using these tools,
        you need to opt out separately for each of your devices and for each web browser (such as Internet Explorer, Firefox or Safari) that you use on each device.
      </p>

      <h2>
        How We May Use Your Information
      </h2>
      <p>
        We do not sell or rent your information.
        <br />
        We use and disclose your personal information only for the purposes described above, and for the following:
      </p>
      <ul>
        <li>
          To maintain and administer the Website
        </li>
        <li>
          To respond to your requests
        </li>
        <li>
          To analyze and improve the Website
        </li>
        <li>
          To send you marketing and other communications, including information about our products and services
        </li>
        <li>
          To tailor advertising displayed to you
        </li>
        <li>
          To protect our rights and the rights and safety of others
        </li>
        <li>
          To investigate suspected fraud or other unlawful activity
        </li>
      </ul>

      <p>
        As may be required by law or by a court order, in which case, where legally permissible, we will attempt to notify you and work
        with you to seek to limit the scope of the required disclosure;
        <br />
        To our third party service providers to allow them to provide Website features on our behalf.
      </p>
      <p>
        In the event of a reorganization, merger, sale, bankruptcy or other business change, in which case your information will remain
        subject to the terms of this Privacy Policy and with your consent and as permissible under applicable laws.
      </p>

      <h2>
        Third Party Service Providers
      </h2>
      <p>
        We may employ third party companies and individuals to facilitate delivery of our Website and to provide certain features on our behalf,
        such as data hosting, customer service, maintenance services, email management, database management, web analytics, review and processing
        of job applications and improvement of our Website.
      </p>

      <h2>
        How You Can Access and Modify Your Information
      </h2>
      <p>
        If you would like to review, correct, update or request that we delete personal information you have provided to us, please contact us
        at <a href="mailto:privacy@AllCourse.com">privacy@AllCourse.com</a> and specify what you would like to review, correct, update or have deleted. Please note that if you have written
        a blog post for AllCourse and that article has been shared by others, we are unable to retrieve and delete those shared versions.
      </p>

      <h2>
        Opt Out
      </h2>
      <p>
        If you have provided us with your information in order to subscribe to a newsletter or to receive information about our products,
        we may send you email marketing messages.  You may opt-out of these messages at any time by clicking on the unsubscribe link that
        appears at the bottom of each message, or by contacting us at <a href="mailto:unsubscribe@AllCourse.com">unsubscribe@AllCourse.com</a>.
      </p>

      <h2>
        Data Retention
      </h2>
      <p>
        We retain your information for as long as is necessary to fulfill the purposes as described in this Privacy Policy, or as otherwise permissible by law.
      </p>

      <h2>
        Security
      </h2>
      <p>
        We strive to provide commercially reasonable security practices, including administrative, technical, and physical safeguards.
        We host data in secure cloud-based environments, and use encryption technologies, firewalls, access controls and other industry-standard
        measure to protect the confidentiality, availability, and integrity of your data.  However the Internet is not entirely secure, and we are
        not responsible for security incidents not reasonably foreseeable or not reasonably within our control.
      </p>

      <h2>
        Links
      </h2>
      <p>
        Our Website may contain links to other websites. These websites may operate independently from us.  Those websites may have their own data
        collection and privacy policies that differ from ours, and we encourage you to read them. To the extent any linked websites you visit are
        not owned or controlled by us, we are not responsible for the site's content, any use of the sites, or the privacy practices of those sites.
      </p>

      <h2>
        Community and Social Network Features
      </h2>
      <p>
        In the blog section of the Website, you may read, comment and share blog posts.  Any comments you post in this area may be read,
        collected and used by others. You may also log onto third party social networking websites via social network sharing widgets to share,
        comment or recommend blog posts.  The social network sharing widgets may provide information to their associated social networks or
        third-parties about your interactions with our web pages that you visit, even if you do not click on or otherwise interact with the
        plug-in or widget. Information is transmitted from your browser and may include an identifier assigned by the social network or third party,
        information about your browser type, operating system, device type, IP address, and the URL of the web page where widget appears.
        If you use social network tools or visit social networking sites, you should read their privacy policies to learn what information
        they collect, use, and share.
      </p>

      <h2>
        Updates To This Privacy Policy
      </h2>
      <p>
        As we continue to develop our Website, we may, in our sole discretion, make changes to this Privacy Policy. The “last updated” date at
        the top of this policy indicates when it was last revised. Changes will become effective when posted.  If you do not agree to changes
        to this Privacy Policy, you may not continue to use this Website.
      </p>

      <h2>
        A Note for California Residents
      </h2>
      <p>
        If you are a resident of California, you may request certain information regarding our disclosure of personal information (if any)
        for direct marketing purposes.  To make such a request, please contact us at:
        <br />
        AllCourse Inc.
        <br />
        P.O. BOX 164
        <br />
        Milford, NJ 08848
      </p>

      <h2>
        Contact Information
      </h2>
      <p>
        For additional information regarding our privacy practices, please contact us at: <a href="mailto:privacy@AllCourse.com">privacy@AllCourse.com</a>.
      </p>
    </div>
  );
};

PrivacyPolicyContentEn.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicyContentEn.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicyContentEn;
