import { sendMail, uploadSubmitApplication } from '../util/api';
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const CONTACT_US_REQUEST = 'app/Contact/CONTACT_US_REQUEST';
export const CONTACT_US_SUCCESS = 'app/Contact/CONTACT_US_SUCCESS';
export const CONTACT_US_ERROR = 'app/Contact/CONTACT_US_ERROR';

export const SUBMIT_APPLICATION_REQUEST = 'app/Contact/SUBMIT_APPLICATION_REQUEST';
export const SUBMIT_APPLICATION_SUCCESS = 'app/Contact/SUBMIT_APPLICATION_SUCCESS';
export const SUBMIT_APPLICATION_ERROR = 'app/Contact/SUBMIT_APPLICATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  // contact
  contactUsError: null,
  contactUsInProgress: false,
  contactUsSuccess: false,
  // submitApplication
  submitApplicationError: null,
  submitApplicationInProgress: false,
  submitApplicationSuccess: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CONTACT_US_REQUEST:
      return {
        ...state,
        contactUsInProgress: true,
        contactUsSuccess: null,
        contactUsError: null,
      };
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        contactUsInProgress: false,
        contactUsSuccess: payload,
      };
    case CONTACT_US_ERROR:
      return {
        ...state,
        contactUsInProgress: false,
        contactUsSuccess: false,
        contactUsError: payload
      };

    case SUBMIT_APPLICATION_REQUEST:
      return {
        ...state,
        submitApplicationInProgress: true,
        submitApplicationSuccess: null,
        submitApplicationError: null,
      };
    case SUBMIT_APPLICATION_SUCCESS:
      return {
        ...state,
        submitApplicationInProgress: false,
        submitApplicationSuccess: payload,
      };
    case SUBMIT_APPLICATION_ERROR:
      return {
        ...state,
        submitApplicationInProgress: false,
        submitApplicationSuccess: false,
        submitApplicationError: payload
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const contactUsRequest = () => ({ type: CONTACT_US_REQUEST });
export const contactUsSuccess = (res) => ({ type: CONTACT_US_SUCCESS, payload: res });
export const contactUsError = error => ({ type: CONTACT_US_ERROR, payload: error, error: true });

export const submitApplicationRequest = () => ({ type: SUBMIT_APPLICATION_REQUEST });
export const submitApplicationSuccess = res => ({ type: SUBMIT_APPLICATION_SUCCESS, payload: res });
export const submitApplicationError = error => ({ type: SUBMIT_APPLICATION_ERROR, payload: error, error: true });

// ================ Thunks ================ //

export const contactUs = (email, name, message) => (dispatch) => {
  dispatch(contactUsRequest());

  const contactParams = {
    email,
    name,
    text: message,
  };

  return sendMail(contactParams)
    .then(response => {
      dispatch(contactUsSuccess(true));
    })
    .then(response => {
      setTimeout(() => {
        dispatch(contactUsSuccess(false));
      }, 5000)
    })
    .catch(e => {
      dispatch(contactUsError(storableError(e)));
    });
};

export const submitApplication = (email, name, files, jobTitle) => async (dispatch) => {
  dispatch(submitApplicationRequest());

  const formData = new FormData();

  if (files && !!files.length) {
    Object.values(files).forEach(file => formData.append('files', file));
  }

  await formData.append('email', email);
  await formData.append('name', name);
  await formData.append('jobTitle', jobTitle);

  return uploadSubmitApplication(formData)
    .then(() => {
      dispatch(submitApplicationSuccess(true));
    })
    .then(() => {
      setTimeout(() => {
        dispatch(contactUsSuccess(false));
      }, 5000)
    })
    .catch(e => {
      dispatch(submitApplicationError(storableError(e)));
    });
};
