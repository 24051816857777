import React, {useEffect, useRef, useState} from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../../util/reactIntl';
import { ExternalLink, NamedLink } from '../../../components';
import { Link } from 'react-scroll';


import css from './SectionHero.module.css';


const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const [index, setIndex] = useState(-1);
  const [isLastItem, setIsLastItem] = useState(false);
  const inputRef = useRef();
  const {
    rootClassName,
    className,
    currentUser,
    handleOpenInvetePopup,
    intl,
    location
  } = props;

  useEffect(() => {
    setMounted(true);
  }, []);


  const classes = classNames(rootClassName || css.root, className, {[css.resetBgTransition]: isLastItem});

  const mainContainerClass = `coloredContainer_${index}`;

  return (
    <div className={classes}>
      <div className={classNames(css.gradientContainer, mainContainerClass)}>
        <div className={css.heroContent}>
          <div className={css.heroContentText}>
            <h1 className={css.heroMainTitle}>
              <FormattedMessage id="SuccessPage.title" />
            </h1>
            <h2 className={classNames(css.heroSubTitle)}>
              <FormattedMessage id="SuccessPage.subTitle" values={{
                boldText: <span className={css.boldSubTitle}>
                  3,000+ teachers
                </span>,
              }} />
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const HeroImage = (props) => {
  const { imageIndex } = props;
  const i = imageIndex === -1 ? 0 : imageIndex;

  return <img src={imagesArray[i]} alt="AllCourse" />
}

export default injectIntl(SectionHero);
