import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  BarChart,
  ResponsiveContainer
} from "recharts";

import css from './BarChart.module.css';



const CustomizedDot = (props) => {
  const { cx, cy } = props;

  return (
    <svg x={cx - 8} y={cy - 8} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#23A0EC"/>
    </svg>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};


const BarChartComponent = props => {
  const { className, rootClassName, dataKey, xAxisDataKey, fillColor, data, responsive } = props;
  const classes = classNames(rootClassName || css.root, className);


  const renderBarChart = responsive ? (
    <ResponsiveContainer width='100%' height={200}>
      <BarChart width={150} height={40} data={data}>
        <CartesianGrid strokeDasharray="3" />
        <XAxis dataKey={xAxisDataKey} />
        <YAxis interval={0} tick={{stroke: '#616161', strokeWidth: 0.1}} />
        <Tooltip content={<CustomTooltip />} />
        {/*<Legend />*/}
        <Bar dataKey={dataKey} fill={fillColor} />
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <BarChart width={6000} height={365} marginLeft={0} data={data}>
      <CartesianGrid strokeDasharray="3" />
      <XAxis dataKey={xAxisDataKey} />
      <YAxis interval={0} tick={{stroke: '#616161', strokeWidth: 0.1}} />
      <Tooltip content={<CustomTooltip />} />
      {/*<Legend />*/}
      <Bar dataKey={dataKey} fill={fillColor} />
    </BarChart>
  );

  return (
    <div className={classes}>
      {renderBarChart}
    </div>
  );
};

const { string } = PropTypes;

BarChartComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

BarChartComponent.propTypes = {
  className: string,
  rootClassName: string,
};

export default BarChartComponent;
