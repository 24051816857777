/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import config from '../../config';
import moment from 'moment';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BreakdownJobMaybeCustom.module.css';
import {isSchoolAdminRole, isTeacherRole} from "../../util/types";
import JobInfoMaybe from "../../containers/TransactionPage/TransactionPanel/JobInfoMaybe";

const { Money } = sdkTypes;

const CENTS = 100;
const PROVIDER_COMMISSION_PERCENTAGE = -12.5;

export const BreakdownJobMaybeCustom  = props => {
  const {
    rootClassName,
    className,
    transaction,
    isJob,
    intl,
    currentUser,
    listing,
    isCancel,
  } = props;

  let isProvider = props.isProvider;
  let isCustomer = props.isCustomer;
  const isTeacher = isTeacherRole(currentUser);
  const isAdmin = isSchoolAdminRole(currentUser);


  if(isJob && isTeacher) {
    isProvider = true;
    isCustomer = false;
  }

  if(isJob && isAdmin) {
    isCustomer = true;
    isProvider = false;
  }

  const classes = classNames(rootClassName || css.root, className);


  const pricePerHours = transaction?.attributes?.protectedData?.pricePerSeats;
  const hours = transaction?.attributes?.protectedData?.hours;

  if(!hours || !pricePerHours) {
    return null
  }

  const { metadata } = transaction.attributes;


  const isRefund = metadata?.refund?.amount ? true : false;
  const hoursWorked = metadata?.hoursWorked;
  const refund = isRefund ? new Money(metadata?.refund?.amount, config.currency) : null;
  const priceWithRefund = isRefund ? new Money((pricePerHours * hours * 100) - metadata?.refund?.amount, config.currency) : null;

  const commission = new Money(((pricePerHours * hours * 100) / 100 * PROVIDER_COMMISSION_PERCENTAGE) * -1, config.currency);
  const commissionWithRefund = isRefund ? new Money((priceWithRefund.amount/100 * PROVIDER_COMMISSION_PERCENTAGE) * -1, config.currency) : null;

  const pricePerHoursMoney = new Money(pricePerHours * CENTS, config.currency)
  const pricePerHoursMoneyTotal = new Money((pricePerHours * hours) * CENTS, config.currency)
  const pricePerHoursMoneyTotalWithCommission = isCancel && isRefund
    ? new Money(priceWithRefund.amount - commissionWithRefund.amount, config.currency)
    : new Money((pricePerHours * hours * 100) - ((pricePerHours * hours * 100) / 100 * PROVIDER_COMMISSION_PERCENTAGE) * -1, config.currency)

  // Formatted values
  const commissionValue = formatMoney(intl, commission);
  const formattedCommissionWithRefund = isRefund ? formatMoney(intl, commissionWithRefund) : null;
  const formattedPrice = formatMoney(intl, pricePerHoursMoney);
  const formattedPriceTotal = formatMoney(intl, pricePerHoursMoneyTotal);
  const formattedRefund = isRefund ? formatMoney(intl, refund) : null;
  const formattedPriceTotalWithRefund = isRefund ? formatMoney(intl, priceWithRefund) : null;
  const formattedPriceTotalWithCommission = formatMoney(intl, pricePerHoursMoneyTotalWithCommission);

  return (
    <div className={classes}>

      <h3 className={css.title}>
        <FormattedMessage id="OrderJobBreakdown.title" />
      </h3>

      <JobInfoMaybe listing={listing} isJob={isJob}/>
      <h3 className={css.orderBreakdownTitle}>
        <FormattedMessage id="TransactionPanel.jobPriceBreakdownTitle"/>
      </h3>
      <div className={css.item}>

        <div className={css.pricePerSeatsItem}>
          <span className={css.label}>
            Number of hours:
          </span>
        </div>
        <div className={css.pricePerSeatsItem}>
          <span className={css.value}>
            {hours}
          </span>
        </div>
      </div>

      <div className={css.item}>
        <div className={css.pricePerSeatsItem}>
          <span className={css.label}>
            Rate:
          </span>
        </div>
        <div className={css.pricePerSeatsItem}>
          <span className={css.value}>
            {formattedPrice} per hour
          </span>
        </div>
      </div>



      {isProvider && (
        <>
          {isCancel && <hr className={css.divider} />}


          <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.label}>
                <FormattedMessage id="OrderBreakdown.subTotal" />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.value}>
                {formattedPriceTotal}
              </span>
            </div>
          </div>
          {isCancel && <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.label}>
                <FormattedMessage id="OrderBreakdown.hoursWorked"/>
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.value}>
                {hoursWorked}
              </span>
            </div>
          </div>}
          {isCancel &&  <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.label}>
                <FormattedMessage id="OrderBreakdown.refund" />
                :
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              {isRefund
                ? (<span className={css.value}>- {formattedRefund}</span>)
                : (<span className={css.value}>{'Processing'}</span>)}
            </div>
          </div>}

          <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.label}>
                <FormattedMessage id="OrderBreakdown.commission" />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.value}>
                -{isCancel && isRefund ? formattedCommissionWithRefund : commissionValue}
              </span>
            </div>
          </div>

          <div className={classNames(css.item, css.itemTotal)}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalLabel}>
                <FormattedMessage id="OrderBreakdown.providerTotalDefault" />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalPrice}>
                {formattedPriceTotalWithCommission}
              </span>
            </div>
          </div>
          <span className={css.feeInfo}>
            <FormattedMessage id="OrderBreakdown.commissionFeeNote" />
          </span>
        </>
      )}

      {isCustomer && (
        <>
          {isCancel && <hr className={css.divider} />}
          {isCancel && <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.label}>
                <FormattedMessage id="OrderBreakdown.subTotal" />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.value}>
                {formattedPriceTotal}
              </span>
            </div>
          </div>}
          {isCancel && <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.label}>
                <FormattedMessage id="OrderBreakdown.hoursWorked"/>
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.value}>
                {hoursWorked}
              </span>
            </div>
          </div>}
          {isCancel &&  <div className={css.item}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.label}>
                <FormattedMessage id="OrderBreakdown.refund" />
                :
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              {isRefund
                ? (<span className={css.value}>- {formattedRefund}</span>)
                : (<span className={css.value}>{'Processing'}</span>)}
            </div>
          </div>}

          <div className={classNames(css.item, css.itemTotal)}>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalLabel}>
                <FormattedMessage id="OrderBreakdown.total" />
              </span>
            </div>
            <div className={css.pricePerSeatsItem}>
              <span className={css.totalPrice}>
                {isCancel && isRefund ? formattedPriceTotalWithRefund : formattedPriceTotal}
              </span>
            </div>
          </div>
        </>
      )}

    </div>
  );
};

export default BreakdownJobMaybeCustom;


