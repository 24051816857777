import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { LINE_ITEM_UNITS } from '../../../util/types';
import { bookingDatesRequired, composeValidators, required } from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldDateRangeInput, SecondaryButton, FieldCheckbox,
} from '../../../components';
import moment from "moment";

import css from './OrderParamsForm.module.css';
import * as validators from '../../../util/validators';

const OrderParamsFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        handleSubmit,
        intl,
        formId,
        invalid,
        listing,
        negotiationParamsInProgress,
        negotiationParamsError,
        values,
        isJob,
        jobSetParamsInProgress,
        currentUserIsTeacher,
        declineEnquiryJobProps,
      } = fieldRenderProps;



      const identity = v => v;

      const startDateErrorMessage = intl.formatMessage({ id: 'FieldDateRangeInput.invalidStartDate',});
      const endDateErrorMessage = intl.formatMessage({id: 'FieldDateRangeInput.invalidEndDate',});


      // price
      const priceLabel = isJob ? intl.formatMessage({id: 'OrderParamsForm.pricePerHourLabel'}) : intl.formatMessage({id: 'OrderParamsForm.pricePerSeatsLabel'});
      const pricePlaceholder = isJob ? intl.formatMessage({id: 'OrderParamsForm.pricePerHourPlaceholder'}) : intl.formatMessage({id: 'OrderParamsForm.pricePerSeatsPlaceholder'});


      const maxSeats = listing?.attributes?.publicData?.maxSeats || 1000;
      const seatsValidator = validators.numberMinMax(
        intl.formatMessage({ id: 'OrderParamsForm.seatsIsRequired' }, { maxSeats }),
        1, parseInt(maxSeats)
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitDisabled = isJob ? invalid || jobSetParamsInProgress : invalid || negotiationParamsInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {!isJob && <FieldDateRangeInput
            className={css.datesRange}
            name="dates"
            unitType={LINE_ITEM_UNITS}
            startDateId={`${formId}.bookingStartDate`}
            startDateLabel={intl.formatMessage({id: 'OrderParamsForm.startDateLabel'})}
            startDatePlaceholderText={intl.formatMessage({id: 'OrderParamsForm.startDatePlaceholder'})}
            endDateId={`${formId}.bookingEndDate`}
            endDateLabel={intl.formatMessage({id: 'OrderParamsForm.bookingEndLabel'})}
            endDatePlaceholderText={intl.formatMessage({id: 'OrderParamsForm.bookingEndPlaceholder'})}
            format={identity}
            useMobileMargins
            validate={composeValidators(
              required('Required'),
              bookingDatesRequired(startDateErrorMessage, endDateErrorMessage)
            )}
            disabled={false}
          />}

          {!isJob && <FieldTextInput
            className={classNames(css.requiredLabel, css.input)}
            id="seats"
            name="seats"
            label={intl.formatMessage({id: 'OrderParamsForm.quantityLabel'})}
            placeholder={intl.formatMessage({id: 'OrderParamsForm.quantityPlaceholder'})}
            type="number"
            min={1}
            validate={seatsValidator}
          />}

          {isJob && <FieldTextInput
            className={classNames(css.requiredLabel, css.input)}
            id="hours"
            name="hours"
            label={intl.formatMessage({id: 'OrderParamsForm.hoursLabel'})}
            placeholder={intl.formatMessage({id: 'OrderParamsForm.hoursPlaceholder'})}
            type="number"
            min={1}
            validate={seatsValidator}
          />}

          <FieldTextInput
            className={classNames(css.requiredLabel, css.input)}
            id="pricePerSeats"
            name="pricePerSeats"
            label={priceLabel}
            placeholder={pricePlaceholder}
            type="number"
            min={1}
            validate={composeValidators(required(intl.formatMessage({id: 'OrderParamsForm.pricePerSeatsRequired'})))}
          />



          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={negotiationParamsInProgress}
            disabled={submitDisabled}
          >
            {isJob ? 'Accept' : <FormattedMessage id="PasswordChangeForm.saveChanges"/>}
          </PrimaryButton>

          <SecondaryButton
            className={css.declineButton}
            inProgress={declineEnquiryJobProps?.inProgress}
            disabled={declineEnquiryJobProps?.inProgress}
            onClick={declineEnquiryJobProps.onTransition}
          >
            {declineEnquiryJobProps.buttonText}
          </SecondaryButton>
        </Form>
      );
    }}
  />
);

OrderParamsFormComponent.defaultProps = { className: null, rootClassName: null, sendReviewError: null };

const { func, string } = PropTypes;

OrderParamsFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
};

const OrderParamsForm = compose(injectIntl)(OrderParamsFormComponent);
OrderParamsForm.displayName = 'OrderParamsForm';

export default OrderParamsForm;
