import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { apiBaseUrl } from '../../util/api';
import { propTypes } from '../../util/types';

import css from './Files.module.css';

const File = props => {
  const { file, intl } = props;

  return (
    <div className={css.file}>
      <span className={css.separator}>•</span>
      <span>{file.fileName}</span>
      <p>{file.mimeType}</p>
      <img src={file.path} alt={file.fileName} />
      <a href={`${apiBaseUrl()}/api/files/download/${file.key}`} title={`Download - ${file.fileName}`}>{file.fileName}</a>
    </div>
  );
};

File.propTypes = {
  file: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const FilesComponent = props => {
  const { className, rootClassName, files, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {files.map(r => {
        return (
          <li key={`File_${r._id}`} className={css.fileItem}>
            <File file={r} intl={intl} />
          </li>
        );
      })}
    </ul>
  );
};

FilesComponent.defaultProps = {
  className: null,
  rootClassName: null,
  files: [],
};

FilesComponent.propTypes = {
  className: string,
  rootCalssName: string,
  files: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Files = injectIntl(FilesComponent);

export default Files;
