import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { randomImage } from '../../util/randomImage';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import {
  AspectRatioWrapper,
  NamedLink,
  ResponsiveImage,
  ReviewRating,
  Reviews
} from '../../components';
import config from '../../config';
import css from './UserCardForSearch.module.css';
import {uniqBy} from "lodash";
import {getAssociatedAreas} from "../../util/areasHelpers";
import {createReview, createUser} from "../../util/test-data";
import { createSlug } from '../../util/urlHelpers';

class ListingImage extends Component {
  render() {
    return <ResponsiveImage className={css.image} {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage);

export const UserCardForSearchComponent = props => {
  const {
    className,
    rootClassName,
    areasCertifiedOptions,
    intl,
    teacher,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    reviews
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  // const currentListing = ensureListing(listing);
  // const id = currentListing.id.uuid;
  // const { title = '', price } = currentListing.attributes;
  // const slug = createSlug(title);
  // const author = ensureUser(listing.author);
  // const nickname = teacher?.attributes?.profile?.publicData?.nickname;

  const displayName = teacher.attributes.profile.displayName;
  const onboardingProcess = teacher?.attributes?.profile?.publicData?.onboardingProcess;

  // Areas Certified
  const associatedAreas = getAssociatedAreas(onboardingProcess);

  const areasCertified =
    <span className={css.subItem}>{
      associatedAreas?.map(item => {
        return Object.entries(item).map(([key, value], i) => {
          return (
            <span className={css.itemParent} key={i}>
                  <span className={css.itemParentValue}>{key} </span>
                  ({value.map(v => <span className={css.itemSpan}>{v}</span>)})
                </span>
          )
        })
      })}
        </span>

  const IMAGE_VARIANTS = [
    'square-xsmall',
    'square-xsmall2x',
    'square-small',
    'square-small2x',
  ];
  const { aspectWidth = 1, aspectHeight = 1 } = config.listing;

  const customId = teacher?.attributes?.profile?.publicData?.customId;
  const rate = teacher?.attributes?.profile?.publicData?.rate;
  const rating = teacher?.attributes?.profile?.publicData?.rating;
  const searchParams = teacher?.attributes?.profile?.publicData?.searchParams
  const lastName = teacher?.attributes?.profile?.publicData?.lastName ? teacher?.attributes?.profile?.publicData?.lastName : null;
  const firstName = teacher?.attributes?.profile?.publicData?.firstName ? teacher?.attributes?.profile?.publicData?.firstName : null;
  const teacherName =
    !lastName && !firstName && searchParams?.[0] && searchParams?.[1]
      ? (searchParams?.[0] + " " + searchParams?.[1])
      : lastName && firstName ? firstName + " " + lastName
      : displayName;

  const rImage = randomImage('listingCard');
  const slug = `${createSlug(teacherName)}-${customId}`;

  return (
    <NamedLink className={classes} name="TeachersProfilePage" params={{ slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={displayName}
          image={teacher.profileImage}
          variants={IMAGE_VARIANTS}
          randomImage={rImage}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        {!!teacherName && (
          <div className={css.title}>
            {teacherName}
          </div>
        )}

        {associatedAreas?.length > 0 && (
          <div className={css.areasCertified}>
            <span className={css.areasCertifiedTitle}><FormattedMessage id="UserCardForSearch.subjectAreas" /></span>
            {areasCertified}
          </div>
        )}

        <div className={css.bottomLineHolder}>
          <ReviewRating
            rating={rating}
            className={css.desktopReviewRating}
            reviewStarClassName={css.reviewRatingStar}
          />
          {rate && <div className={css.rateHolder}>
            <span>${rate}/hour</span>
          </div>}
        </div>



      </div>
    </NamedLink>
  );
};

UserCardForSearchComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  // setActiveListing: null,
  showAuthorInfo: true,
  areasCertifiedOptions: config.custom.areasCertifiedOptions,
  reviews: [
    createReview('review_1', { rating: 1 }, { author: createUser('author_1') }),
    createReview('review_2', { rating: 3 }, { author: createUser('author_2') }),
    createReview('review_3', { rating: 5 }, { author: createUser('author_3') }),
  ],
};

UserCardForSearchComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  // listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  // setActiveListing: func,
};

export default injectIntl(UserCardForSearchComponent);
