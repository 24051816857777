import React from 'react';
import loadable from '@loadable/component';

import config from '../config';

import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() => import(/* webpackChunkName: "AboutPage" */ '../containers/AboutPage/AboutPage'));
const OfferPage = loadable(() => import(/* webpackChunkName: "OfferPage" */ '../containers/OfferPage/OfferPage'));
const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'));
const ContactUsPage = loadable(() => import(/* webpackChunkName: "ContactUsPage" */ '../containers/ContactUsPage/ContactUsPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage'));
const EditJobPage = loadable(() => import(/* webpackChunkName: "EditJobPage" */ '../containers/EditJobPage/EditJobPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'));
const FAQPage = loadable(() => import(/* webpackChunkName: "FAQPage" */ '../containers/FAQPage/FAQPage'));
const HowItWorkPage = loadable(() => import(/* webpackChunkName: "HowItWorkPage" */ '../containers/HowItWorkPage/HowItWorkPage'));
const ReferralPage = loadable(() => import(/* webpackChunkName: "ReferralPage" */ '../containers/ReferralPage/ReferralPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage'));
const LandingPageTexasTeachers = loadable(() => import(/* webpackChunkName: "LandingPageTexasTeachers" */ '../containers/LandingPageTexasTeachers/LandingPageTexasTeachers'));
const LandingPageGeneralAdmin = loadable(() => import(/* webpackChunkName: "LandingPageGeneralAdmin" */ '../containers/LandingPageGeneralAdmin/LandingPageGeneralAdmin'));
const SuccessPage = loadable(() => import(/* webpackChunkName: "SuccessPage" */ '../containers/SuccessPage/SuccessPage'));
const ListingPageHeroImage = loadable(() => import(/* webpackChunkName: "ListingPageHeroImage" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageHeroImage'));
const ListingPageFullImage = loadable(() => import(/* webpackChunkName: "ListingPageFullImage" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageFullImage'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'));
const NewsArticle1Page = loadable(() => import(/* webpackChunkName: "NewsArticle1Page" */ '../containers/NewsArticle1Page/NewsArticle1Page'));
const NewsListPage = loadable(() => import(/* webpackChunkName: "NewsListPage" */ '../containers/NewsListPage/NewsListPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'));
const DiscountPage = loadable(() => import(/* webpackChunkName: "DiscountPage" */ '../containers/DiscountPage/DiscountPage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'));
const SearchPageWithMap = loadable(() => import(/* webpackChunkName: "SearchPageWithMap" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithMap'));
const SearchPageWithList = loadable(() => import(/* webpackChunkName: "SearchPageWithList" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithList'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage'));
const SuccessSignUpPage = loadable(() => import(/* webpackChunkName: "SuccessSignUpPage" */ '../containers/SuccessSignUpPage/SuccessSignUpPage'));
const SuitableJobsPage = loadable(() => import(/* webpackChunkName: "SuitableJobsPage" */ '../containers/SuitableJobsPage/SuitableJobsPage'));
const TeacherHandbookPage = loadable(() => import(/* webpackChunkName: "TeacherHandbookPage" */ '../containers/TeacherHandbookPage/TeacherHandbookPage'));
const JobsPage = loadable(() => import(/* webpackChunkName: "JobsPage" */ '../containers/JobsPage/JobsPage'));
const JobsPageVacancy1 = loadable(() => import(/* webpackChunkName: "JobsPageVacancy1" */ '../containers/JobsPageVacancy1/JobsPageVacancy1'));
const JobsPageVacancy2 = loadable(() => import(/* webpackChunkName: "JobsPageVacancy2" */ '../containers/JobsPageVacancy2/JobsPageVacancy2'));
const JobsPageVacancy3 = loadable(() => import(/* webpackChunkName: "JobsPageVacancy3" */ '../containers/JobsPageVacancy3/JobsPageVacancy3'));
const JobsPageVacancy4 = loadable(() => import(/* webpackChunkName: "JobsPageVacancy4" */ '../containers/JobsPageVacancy4/JobsPageVacancy4'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage'));
const OnboardingPage = loadable(() => import(/* webpackChunkName: "OnboardingPage" */ '../containers/OnboardingPage/OnboardingPage'));
const ConversationPage = loadable(() => import(/* webpackChunkName: "ConversationPage" */ '../containers/ConversationPage/ConversationPage'));
const ReferralsAnalyticsPage = loadable(() => import(/* webpackChunkName: "SignUpStateAnalyticsPage" */ '../containers/ReferralsAnalyticsPage/ReferralsAnalyticsPage'));
const SignUpStateAnalyticsPage = loadable(() => import(/* webpackChunkName: "SignUpStateAnalyticsPage" */ '../containers/SignUpStateAnalyticsPage/SignUpStateAnalyticsPage'));
const SignUpAnalyticsPage = loadable(() => import(/* webpackChunkName: "SignUpAnalyticsPage" */ '../containers/SignUpAnalyticsPage/SignUpAnalyticsPage'));
const SignUpBySchoolAdminsAnalyticsPage = loadable(() => import(/* webpackChunkName: "SignUpBySchoolAdminsAnalyticsPage" */ '../containers/SignUpBySchoolAdminsAnalyticsPage/SignUpBySchoolAdminsAnalyticsPage'));
const SignUpByTeachersAnalyticsPage = loadable(() => import(/* webpackChunkName: "SignUpByTeachersAnalyticsPage" */ '../containers/SignUpByTeachersAnalyticsPage/SignUpByTeachersAnalyticsPage'));
const SignUpByParentsAnalyticsPage = loadable(() => import(/* webpackChunkName: "SignUpByParentsAnalyticsPage" */ '../containers/SignUpByParentsAnalyticsPage/SignUpByParentsAnalyticsPage'));
const OnlineTeachingJobsPage = loadable(() => import(/* webpackChunkName: "OnlineTeachingJobsPage" */ '../containers/OnlineTeachingJobsPage/OnlineTeachingJobsPage'));
const ExternalOnlineTeachingJobsPage = loadable(() => import(/* webpackChunkName: "ExternalOnlineTeachingJobsPage" */ '../containers/ExternalOnlineTeachingJobsPage/ExternalOnlineTeachingJobsPage'));
const OnlineTeachersForHirePage = loadable(() => import(/* webpackChunkName: "OnlineTeachersForHirePage" */ '../containers/OnlineTeachersForHirePage/OnlineTeachersForHirePage'));


// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage'));


const SearchPage = config.searchPageVariant === 'map' ? SearchPageWithMap : SearchPageWithList;
const ListingPage = config.listingPageLayout === 'full-image' ? ListingPageFullImage : ListingPageHeroImage;

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name='LandingPage' />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    ...(
      config.custom.stateOptions.map(s => {
        return {
          name: 'OnlineTeachersForHireWithStatePage',
          path: `/teachers/${s.short}`,
          component: OnlineTeachersForHirePage,
          extraProps: { state: s.short },
          loadData: () =>
            pageDataLoadingAPI.OnlineTeachersForHirePage.loadData({ state: s.short }),
        };
      })
    ),
    ...(
      config.custom.stateOptions.map(s => {
        return {
          name: 'OnlineTeachersForHireWithStatePage',
          path: `/teachers/${s.short}/:subject`,
          component: OnlineTeachersForHirePage,
          extraProps: { state: s.short },
          loadData: params =>
            pageDataLoadingAPI.OnlineTeachersForHirePage.loadData({ state: s.short, ...params }),
        };
      })
    ),
    {
      path: '/teachers',
      name: 'OnlineTeachersForHirePage',
      component: OnlineTeachersForHirePage,
      loadData: pageDataLoadingAPI.OnlineTeachersForHirePage.loadData,
    },
    ...(config.custom.stateOptions.map(s => {
        return {
          path: `/online-teaching-jobs/${s.short}`,
          name: 'OnlineTeachingJobsWithStatePage',
          component: OnlineTeachingJobsPage,
          extraProps: { state: s.short },
          loadData: params =>
            pageDataLoadingAPI.OnlineTeachingJobsPage.loadData({ state: s.short, ...params }),
        };
      })
    ),
    ...(config.custom.stateOptions.map(s => {
        return {
          path: `/online-teaching-jobs/${s.short}/:subject`,
          name: 'OnlineTeachingJobsWithStatePage',
          component: OnlineTeachingJobsPage,
          extraProps: { state: s.short },
          loadData: params =>
            pageDataLoadingAPI.OnlineTeachingJobsPage.loadData({ state: s.short, ...params }),
        };
      })
    ),
    // {
    //   path: '/teaching-jobs',
    //   name: 'OldOnlineTeachingJobsPage',
    //   component: () => <NamedRedirect name='OnlineTeachingJobsPage' />,
    // },
    {
      path: '/online-teaching-jobs',
      name: 'OnlineTeachingJobsPage',
      component: OnlineTeachingJobsPage,
      loadData: pageDataLoadingAPI.OnlineTeachingJobsPage.loadData,
    },
    {
      path: '/ets/online-teaching-jobs',
      name: 'ExternalOnlineTeachingJobsPage',
      component: ExternalOnlineTeachingJobsPage,
      loadData: pageDataLoadingAPI.ExternalOnlineTeachingJobsPage.loadData,
    },
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/hire-online-texas-teachers',
      name: 'LandingPageTexasTeachers',
      component: () => <NamedRedirect name='LandingPageGeneralAdmin' />,
      // component: LandingPageTexasTeachers,
      // loadData: pageDataLoadingAPI.LandingPageTexasTeachers.loadData,
    },
    {
      path: '/p/success',
      name: 'SuccessPage',
      component: SuccessPage,
      loadData: pageDataLoadingAPI.SuccessPage.loadData,
    },
    {
      path: '/hire-online-teachers',
      name: 'LandingPageGeneralAdmin',
      component: LandingPageGeneralAdmin,
      loadData: pageDataLoadingAPI.LandingPageGeneralAdmin.loadData,
    },
    {
      path: '/p/success',
      name: 'SuccessPage',
      component: SuccessPage,
      loadData: pageDataLoadingAPI.SuccessPage.loadData,
    },
    {
      path: '/hire-online-teachers',
      name: 'LandingPageGeneralAdmin',
      component: LandingPageGeneralAdmin,
      loadData: pageDataLoadingAPI.LandingPageGeneralAdmin.loadData,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/offer',
      name: 'OfferPage',
      component: OfferPage,
    },
    {
      path: '/news-list',
      name: 'NewsListPage',
      component: NewsListPage,
    },
    {
      path: '/how-allcourse-can-solve-teacher-shortages-and-credit-recovery-challenges-in-your-district',
      name: 'NewsArticle1Page',
      component: NewsArticle1Page,
    },
    {
      path: '/faq',
      name: 'FAQPage',
      component: FAQPage,
    },
    {
      path: '/teacher-handbook',
      name: 'TeacherHandbookPage',
      component: TeacherHandbookPage,
    },
    {
      path: '/jobs',
      name: 'JobsPage',
      component: JobsPage,
    },
    {
      path: '/jobs/:slug',
      name: 'ListingJobPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/courses/:slug',
      name: 'ListingCoursePage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/jobs-at-allcourse/florida-lead-teacher-state-manager',
      name: 'JobsPageVacancy1',
      component: JobsPageVacancy1,
    },
    {
      path: '/jobs-at-allcourse/texas-lead-teacher-state-manager',
      name: 'JobsPageVacancy2',
      component: JobsPageVacancy2,
    },
    {
      path: '/jobs-at-allcourse/content-curator',
      name: 'JobsPageVacancy3',
      component: JobsPageVacancy3,
    },
    {
      path: '/jobs-at-allcourse/georgia-lead-teacher-state-manager',
      name: 'JobsPageVacancy4',
      component: JobsPageVacancy4,
    },
    {
      path: '/contact-us',
      name: 'ContactUsPage',
      component: ContactUsPage,
    },
    {
      path: '/how-it-works',
      name: 'HowItWorkPage',
      component: HowItWorkPage,
    },
    {
      path: '/referral',
      name: 'ReferralPage',
      component: ReferralPage,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name='EditListingPage'
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/j/new',
      name: 'NewJobPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name='EditJobPage'
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/onboarding',
      name: 'OnboardingPage',
      component: OnboardingPage,
    },
    {
      path: '/j/:slug/:id/:type/:tab',
      name: 'EditJobPage',
      auth: true,
      component: EditJobPage,
      loadData: pageDataLoadingAPI.EditJobPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/teachers/:slug',
      name: 'TeachersProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/school-admins/:slug',
      name: 'SchoolAdminProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/parents/:slug',
      name: 'ParentsProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: () => (
        <NamedRedirect
          name='LandingPage'
        />
      ),
    },
    {
      path: '/signup-hire',
      name: 'SignupSchoolAdminPage', // if we change the name, then we need to change it in the component ModalSignUp
      component: AuthenticationPage,
      extraProps: { userRole: 'schoolAdministrator' },
    },
    {
      path: '/signup-teach',
      name: 'SignupTeacherPage', // if we change the name, then we need to change it in the component ModalSignUp
      component: AuthenticationPage,
      extraProps: { userRole: 'teacher' },
    },
    {
      path: '/signup-parent',
      name: 'SignupParentPage', // if we change the name, then we need to change it in the component ModalSignUp
      component: AuthenticationPage,
      extraProps: { userRole: 'parent' },
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/success',
      name: 'SuccessSignUpPage',
      component: SuccessSignUpPage,
    },
    {
      path: '/jobs-list',
      name: 'SuitableJobsPage',
      component: SuitableJobsPage,
      auth: true,
      authPage: 'LoginPage',
      loadData: pageDataLoadingAPI.SuitableJobsPage.loadData,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name='InboxPage' params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name='OrderDetailsPage' params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name='SaleDetailsPage' params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/listings',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/dashboard',
      name: 'DashboardPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name='SignUpAnalyticsPage' />,
    },

    {
      path: '/dashboard/state/:id',
      name: 'SignUpStateAnalyticsPage',
      auth: true,
      authPage: 'LoginPage',
      component: SignUpStateAnalyticsPage,
      loadData: pageDataLoadingAPI.SignUpStateAnalyticsPage.loadData,
    },
    {
      path: '/dashboard/signup',
      name: 'SignUpAnalyticsPage',
      auth: true,
      authPage: 'LoginPage',
      component: SignUpAnalyticsPage,
      loadData: pageDataLoadingAPI.SignUpAnalyticsPage.loadData,
    },

    {
      path: '/dashboard/signup/teachers',
      name: 'SignUpByTeachersAnalyticsPage',
      auth: true,
      authPage: 'LoginPage',
      component: SignUpByTeachersAnalyticsPage,
      loadData: pageDataLoadingAPI.SignUpAnalyticsPage.loadData,
    },

    {
      path: '/dashboard/signup/school-admins',
      name: 'SignUpBySchoolAdminsAnalyticsPage',
      auth: true,
      authPage: 'LoginPage',
      component: SignUpBySchoolAdminsAnalyticsPage,
      loadData: pageDataLoadingAPI.SignUpAnalyticsPage.loadData,
    },

    {
      path: '/dashboard/signup/parents',
      name: 'SignUpByParentsAnalyticsPage',
      auth: true,
      authPage: 'LoginPage',
      component: SignUpByParentsAnalyticsPage,
      loadData: pageDataLoadingAPI.SignUpAnalyticsPage.loadData,
    },
    {
      path: '/dashboard/referrals/:id',
      name: 'ReferralsAnalyticsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ReferralsAnalyticsPage,
      loadData: pageDataLoadingAPI.ReferralsAnalyticsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name='ContactDetailsPage' />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/promotions',
      name: 'DiscountPage',
      auth: true,
      authPage: 'LoginPage',
      component: DiscountPage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    {
      path: '/conversation/:id',
      name: 'ConversationPage',
      auth: true,
      authPage: 'LoginPage',
      component: ConversationPage,
      loadData: pageDataLoadingAPI.ConversationPage.loadData,
    },
  ];
};

export default routeConfiguration;
