import config from '../config';
import { uniqWith, isEqual } from 'lodash';
import { licenseOptions } from '../config/marketplace-custom-config';

/**
 * SelectMultipleFilter needs to parse values from format
 * "has_all:a,b,c,d" or "a,b,c,d"
 */
export const parseSelectFilterOptions = uriComponentValue => {
  const startsWithHasAll = uriComponentValue && uriComponentValue.indexOf('has_all:') === 0;
  const startsWithHasAny = uriComponentValue && uriComponentValue.indexOf('has_any:') === 0;

  if (startsWithHasAll) {
    return uriComponentValue.substring(8).split(',');
  } else if (startsWithHasAny) {
    return uriComponentValue.substring(8).split(',');
  } else {
    return uriComponentValue.split(',');
  }
};

/**
 * SelectMultipleFilter needs to parse values from format
 * "has_all:a,b,c,d" or "a,b,c,d" to object
 */
export const parseSelect2FilterOptions = (uriComponentValue, options) => {
  const splittedUriComponentValue = uriComponentValue.substring(8).split(',');

  return splittedUriComponentValue ? options?.filter(objFromA => {
    return splittedUriComponentValue?.find(objFromB => {
      return objFromA.key === objFromB
    })
  }) : []
};

export const parseUnitedFilterOptions = (uriComponentValue, options) => {
  const splittedUriComponentValue = uriComponentValue.substring(8).split(',');

  return splittedUriComponentValue ? options.filter(objFromA => {
    return splittedUriComponentValue.find(objFromB => {
      if (objFromA.key === objFromB) {
        if (objFromA?.long) {
          return objFromA.long
        } else {
          return objFromA.label
        }
      }

    })
  }) : []
};

/**
 * Check if any of the filters (defined by filterIds) have currently active query parameter in URL.
 */
export const isAnyFilterActive = (filterIds, urlQueryParams, filterConfigs) => {
  const getQueryParamKeysOfGivenFilters = (keys, config) => {
    const isFilterIncluded = filterIds.includes(config.id);
    const addedQueryParamNamesMaybe = isFilterIncluded ? config.queryParamNames : [];
    return [...keys, ...addedQueryParamNamesMaybe];
  };
  const queryParamKeysOfGivenFilters = filterConfigs.reduce(getQueryParamKeysOfGivenFilters, []);

  const paramEntries = Object.entries(urlQueryParams);
  const activeKey = paramEntries.find(entry => {
    const [key, value] = entry;
    return queryParamKeysOfGivenFilters.includes(key) && value != null;
  });
  return !!activeKey;
};

/**
 * Check if the filter is currently active.
 */
export const findOptionsForSelectFilter = (filterId, filters) => {
  const filter = filters.find(f => f.id === filterId);
  return filter && filter.config && filter.config.options ? filter.config.options : [];
};

/**
 * Return filter config
 */
export const findConfigForSelectFilter = (filterId, filters) => {
  const filter = filters.find(f => f.id === filterId);
  return filter && filter.config ? filter.config : null;
};

/**
 * Check if the main search type is 'keywords'
 */
export const isMainSearchTypeKeywords = config => config.mainSearchType === 'keywords';

/**
 * Check if the origin parameter is currently active.
 */
export const isOriginInUse = config =>
  config.mainSearchType === 'location' && config.sortSearchByDistance;

/**
 * Check if the stock management is currently active.
 */
export const isStockInUse = config => config.listingManagementType === 'stock';

export const getSelectedFiltersArray = (urlQueryParams ) => {
  const getQueryParamName = queryParamNames => Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;

  const selectedFiltersArray = [];
  const currentFilterConfigWithOptions = config.custom.filters.filter(f => f.id !== 'rate' && f.id !== 'price' && f.id !== 'license' && f.id !== 'keyword');
  currentFilterConfigWithOptions.filter(f => {
    const queryParamName = getQueryParamName(f.queryParamNames);
    const currentOptions = f.config.options;
    if(urlQueryParams[queryParamName]) selectedFiltersArray.push(parseSelect2FilterOptions(urlQueryParams[queryParamName], currentOptions))
  });

  const currentFilterConfigWithoutOptions = config.custom.filters.filter(f => f.id === 'rate' || (f.id === 'price' && f.type === 'PriceCourseFilter') || f.id === 'license');

  currentFilterConfigWithoutOptions.filter(f => {
    const queryParamName = getQueryParamName(f.queryParamNames);

    if(urlQueryParams[queryParamName]) {
      if(queryParamName === 'pub_rate') {
        const  str = urlQueryParams[queryParamName];
        const result = str.split(',');
        const range = result.join(' - $')
        selectedFiltersArray.push({
          key: 'rate',
          label: 'rate: ' + '$' + range ,
          filterId: 'rate',
        });
      }
      if(queryParamName === 'pub_license') selectedFiltersArray.push({ key: 'license', label: 'Have a license', filterId: 'license'} );
      if(queryParamName === 'price') {

        const  str = urlQueryParams[queryParamName];
        const result = str.split(',');
        const range = result.join(' - $')

        selectedFiltersArray.push({
          key: 'price',
          label: 'price: ' + '$' + range,
          filterId: 'price',
        });
      }
    }
  });

  return selectedFiltersArray
}
