import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, IconEnquiry } from '../../components';

import css from './VacancyForm.module.css';

const VacancyFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        intl,
        authorDisplayName,
      } = fieldRenderProps;

      const nameLabel = intl.formatMessage(
        {
          id: 'VacancyForm.nameLabel',
        },
        { authorDisplayName }
      );
      const namePlaceholder = intl.formatMessage(
        {
          id: 'VacancyForm.namePlaceholder',
        },
        { authorDisplayName }
      );
      const emailLabel = intl.formatMessage(
        {
          id: 'VacancyForm.emailLabel',
        },
        { authorDisplayName }
      );
      const emailPlaceholder = intl.formatMessage(
        {
          id: 'VacancyForm.emailPlaceholder',
        },
        { authorDisplayName }
      );

      const classes = classNames(rootClassName || css.root, className);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={nameLabel}
            placeholder={namePlaceholder}
          />
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={emailLabel}
            placeholder={emailPlaceholder}
          />
          <div className={submitButtonWrapperClassName}>
            <Button type="submit">
              <FormattedMessage id="VacancyForm.submitButtonText" />
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

VacancyFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

VacancyFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const VacancyForm = compose(injectIntl)(VacancyFormComponent);

VacancyForm.displayName = 'VacancyForm';

export default VacancyForm;
